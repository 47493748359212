import React, { useEffect, useState, } from "react";
import "../../styles/screenStyles/BankInformation.css";
import { XCircle } from "react-feather";
import InputField from "../../components/InputField";
import { Bank, GeoAlt, Person, Safe } from "react-bootstrap-icons";
import FileUpload from "../../components/FileUpload";
import Alert from "../../components/Alert";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { MyContext } from "../../components/GlobalValues";
import Loading from "../../components/Loading";
import Metrixfunction from "../../components/MetrixFunction";
import AssetDisplay from "../../components/AssetDisplay";

export default function BankInformation({
  component,
  componenttype,
  nextclick,
  onnavigate = () => { },
}) {

  const navigate = useNavigate()
  const accountId = localStorage.getItem("accId");

  function removeSlash(port1) {
    let p1 = port1.split("/").join("");

    let np = p1;
    return np;
  }

  const [verify, setverify] = useState(false);

  const {userBankStatementPdf, setBankStatementPdf, Url, bankDetails, setBankDetails, reBankAccNo, setReBankAccNo, updatebank, getFileNameFromUrl, setinitialbankdetails, initialbankDetails,    metrixserver} = useContext(MyContext);

  const location = useLocation();

  const [loadingscreen, setloading] = useState(true)



  const [reAccNoError, setAccReError] = useState("");
  const [ifscError, setIfscError] = useState("");
  const [bankNameError, setBankNameError] = useState("");
  const [ifscLoading, setIfscLoading] = useState(false)

  const [bankAddress, setBankAddress] = useState("");
  const [encryptedAccNo, setEncryptedAccNo] = useState("");
  const [encryptedReAccNo, setEncryptedReAccNo] = useState("");

  const validateScreen =
    (bankDetails.bankIfsc ?? false) &&
    (bankDetails.bankAcctName ?? false) &&
    (bankDetails.bankAcctNum ?? false) &&
    (reBankAccNo ?? false) &&
    (bankDetails.bankName ?? false) && (bankDetails.bankAcctNum === reBankAccNo);

  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "",
    duration: 3000, // Default duration
  });

  const showAlert = (message, type, duration = 3000) => {
    setAlert({
      show: true,
      message,
      type,
      duration,
    });

    // Automatically close the alert after the duration
    setTimeout(() => {
      setAlert((prevAlert) => ({
        ...prevAlert,
        show: false,
      }));
    }, duration);
  };


  const handleEditUploadImage = (uploadType) => {

    if (uploadType === "bankStatement") {
      setBankDetails((prev) => ({ ...prev, bankStatement: "" }))
      setBankStatementPdf([])
    }

  }



  const verifyAccountName = (name) => {
    const nameInput = name.replace(/[^A-Za-z\s]/, "");
    if (nameInput.length >= 0) {
      setBankDetails({ ...bankDetails, bankAcctName: nameInput });
      setBankNameError("");
    }
  };

  const verifyAccountNumber = (accNo) => {
    const numericInput = accNo.replace(/[^0-9]/g, "");
    setBankDetails({ ...bankDetails, bankAcctNum: numericInput });
    setAccReError("")
  };

  const encryptAccountNo = (type) => {
    if (type === 'account') {
      const lastFour = bankDetails.bankAcctNum.slice(-4);
      const maskedText = '*'.repeat(Math.max(0, bankDetails.bankAcctNum.length - 4)) + lastFour;
      setEncryptedAccNo(maskedText)
    } else {
      const lastFour = reBankAccNo.slice(-4);
      const maskedText = '*'.repeat(Math.max(0, reBankAccNo.length - 4)) + lastFour;
      setEncryptedReAccNo(maskedText)
    }

  }
  const verifyReAccountNumber = (reAccNo) => {
    const numericInput = reAccNo.replace(/[^0-9]/g, "");
    setReBankAccNo(numericInput);
    if (bankDetails?.bankAcctNum) {
      if (
        bankDetails.bankAcctNum !== numericInput &&
        bankDetails.bankAcctNum.length <= numericInput.length
      ) {
        setAccReError("Account numbers do not match");
      } else {
        setAccReError("");
      }
    } else {
      setAccReError("First enter your account number");
    }
  };

  const verifyIfscCode = async (ifsc) => {

    let uppercaseifsc = ifsc.toUpperCase();

    // setIfscCode(ifsc);
    const valIFSC = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    setBankDetails({ ...bankDetails, bankIfsc: uppercaseifsc })
    if (uppercaseifsc.length === 11) {
      if (valIFSC.test(uppercaseifsc)) {
        setIfscLoading(true)
        setIfscError("");


        try {
          await fetch(
            `${Url}/business/validateIfsc?ifsc=${uppercaseifsc}`
          )
            .then((res) => res.json())
            .then((resJson) => {
              if (resJson && resJson.bank && resJson.address) {
                setIfscLoading(false)
                setBankAddress(resJson.address);
                setBankDetails({ ...bankDetails, bankIfsc: uppercaseifsc, bankName: resJson.bank });
              } else {
                setIfscLoading(false)

                setIfscError("Invalid IFSC code or bank details not found");
                setBankAddress("");

              }
            });
        } catch (error) {
          setIfscLoading(false)
          setIfscError("Invalid IFSC code");
          setBankAddress("");
          setBankDetails({ ...bankDetails, bankName: "" });

        }
      } else {
        setIfscLoading(false)
        setIfscError("Invalid IFSC code");
        setBankAddress("");
        setBankDetails({ ...bankDetails, bankName: "" });

      }
    } else {
      setIfscError("");
    }
  };

  const UpdateBankDetails = async () => {
    // setupdatedvalue({ Success: "value" });

    const accId = localStorage.getItem("accId");
    const apiKey = localStorage.getItem("apiKey");
    if (validateScreen) {

      if (accId && apiKey) {
        try {
          let resJson = await updatebank(removeSlash(location.pathname))

          if (resJson.Error) {
            showAlert(resJson.Error, "danger");
          }
          else if (resJson.Warning) {
            showAlert(resJson.Warning, "danger")
          }
          else {

            showAlert("Bank details updated successfully", "success");
            // !component && navigate("/myaccount");
          }
        }
        catch (err) {
          console.log("err", err)
        }

      } else {
        showAlert("Login required", "danger");
      }
    } else {
      showAlert("Please enter all details", "danger");
    }
  };

  const getaccountdetails = async () => {
    let query = {
      accId: localStorage.getItem("accId"),
      apiKey: localStorage.getItem("apiKey"),
      type: "kyc",
    };


    try {

      await fetch(`${Url}/business/getDetails`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(query),
      })
        .then((res) => res.json())
        .then((resJson) => {
          if (resJson.Success) {
            setBankDetails({
              ...bankDetails,
              bankAcctName: resJson.Success.bankAcctName,
              bankAcctNum: resJson.Success.bankAcctNum,
              bankName: resJson.Success.bankName,
              bankIfsc: resJson.Success.bankIfsc,
              bankStatement: resJson.Success.bankStatement
            });
            setinitialbankdetails({
              ...initialbankDetails,
              bankAcctName: resJson.Success.bankAcctName,
              bankAcctNum: resJson.Success.bankAcctNum,
              bankName: resJson.Success.bankName,
              bankIfsc: resJson.Success.bankIfsc,
              bankStatement: resJson.Success.bankStatement

            });
            if (resJson.Success.bankStatement.length > 0) {
              setBankStatementPdf([{ url: resJson.Success.bankStatement }]);
            }
            setReBankAccNo(resJson.Success.bankAcctNum);
            if (!resJson.Success.userEditable) {
              setverify(true)
            }

          }
          else {
            navigate("/dashboard")
          }
          setloading(false)
        })
        .finally(()=>{
      Metrixfunction(metrixserver, "getDetails", "ONLOAD");

        })
    } catch (error) {
      console.log("Error :", error);
      setloading(false)
      // setMobileNoError('Invalid Mobile No');
    }
  };

  useEffect(() => {


    if (componenttype !== "preview") {
      getaccountdetails();

    }
    else {
      setloading(false)
      setverify(true);



    }


  }, []);

  const handleCopy = (e) => {
    e.preventDefault();
  };






  return (
    loadingscreen ? <Loading /> : <>
      <div className="bankinformation-container">
        <form
          className="bank-form-wrapper"
          onSubmit={(e) => {
            e.preventDefault();

          }}
        >
          <div className="d-flex justify-content-between align-items-center ">
            {/* <p className="bankinformation-title">Bank Details</p> */}
            <h2 className="wi-input-title">Bank Information</h2>
            {/* {verify && (
              <div className="d-flex align-items-center">
                <Check style={{ animation: "none" }} color="green" size={20} />{" "}
                <span className="aadhar-verify">Verified</span>
              </div>
            )} */}
          </div>


          <div className="inner-bank-wrapper row">
            <div className="col-md-6">
              <label>Account Holder Name</label>
              <div className="mt-2">
                <InputField
                  placeholder="Account Holder Name"
                  maxLength={60}
                  value={bankDetails.bankAcctName}
                  onChangeText={verifyAccountName}
                  editable={!verify}
                  leftIcon={<Person style={{ animation: "none" }} />}
                  validationError={bankNameError}
                />
              </div>
            </div>

            <div className="col-md-6">
              <label>Bank Account Number</label>
              <div className="mt-2">
                <InputField
                  placeholder="Bank Account No"
                  maxLength={25}
                  value={encryptedAccNo.length !== 0 ? encryptedAccNo : bankDetails.bankAcctNum}
                  onChangeText={verifyAccountNumber}
                  onBlur={() => encryptAccountNo('account')}
                  onFocusText={() => { setEncryptedAccNo("") }}
                  onCopy={handleCopy}
                  onCut={handleCopy}
                  editable={!verify}
                  leftIcon={<Bank style={{ animation: "none" }} />}
                />
              </div>
            </div>

            <div className="col-md-6">
              <label>Re-Bank Account Number</label>
              <div className="mt-2">
                <InputField
                  placeholder="Re-Bank Account No"
                  maxLength={25}
                  value={encryptedReAccNo.length !== 0 ? encryptedReAccNo : reBankAccNo}
                  onChangeText={verifyReAccountNumber}
                  editable={!verify}
                  onBlur={() => encryptAccountNo()}
                  onFocusText={() => setEncryptedReAccNo('')}
                  onPaste={handleCopy}
                  leftIcon={<Bank style={{ animation: "none" }} />}
                  validationError={reAccNoError}
                />
              </div>
            </div>

            <div className="col-md-6">
              <label>Bank IFSC</label>
              <div className="mt-2">
                <InputField
                  placeholder="Bank IFSC"
                  maxLength={11}
                  value={bankDetails.bankIfsc}
                  onChangeText={(e) => {
                    verifyIfscCode(e);
                  }}
                  editable={!verify}
                  leftIcon={<GeoAlt style={{ animation: "none" }} />}
                  validationError={ifscError}
                />
              </div>
            </div>

            <div className="col-md-6">
              <label style={{ opacity: ifscError || bankDetails.bankIfsc.length !== 11 || bankDetails.bankName === "" ? 0.5 : 1 }}>Bank Name</label>
              <div style={{ opacity: ifscError || bankDetails.bankIfsc.length !== 11 || bankDetails.bankName === "" ? 0.5 : 1 }} className="mt-2">
                {ifscLoading ?
                  <div className="chat-message-loading" style={{ width: "20%", paddingBottom: "30px", paddingTop: "10px" }}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div> :
                  <InputField
                    placeholder="Bank Name"
                    maxLength={70}
                    value={bankDetails.bankName || ""}
                    editable={false}
                    leftIcon={<Safe style={{ animation: "none" }} />}
                  />
                }
              </div>
            </div>
            <span className="bankinformation-address" style={{ opacity: ifscError || bankDetails.bankIfsc.length !== 11 || bankDetails.bankName === "" ? 0.5 : 1 }}>
              {bankAddress.length > 5 && bankAddress}
            </span>

            {/* {!(component && componenttype==="preview") &&  */}

            {
              // bankDetails.bankStatement &&  bankDetails.bankStatement != "" ?
              (bankDetails.bankStatement === "" && userBankStatementPdf.length === 0) ?

                // <div className="col mt-5  ">
                //   <div className="d-flex justify-content-between align-items-center  " style={{width:"60%"}}>
                //     <label>Bank Statement</label>

                //   </div>
                //   <div className="mt-2"></div>
                //   <div style={{padding:10,display:"flex",alignItems:"center",flexWrap:"wrap",gap:"40px"}}>

                //     <div style={{height:"auto",width:"150px",position:"relative",backgroundColor:"white",display:"flex",flexDirection:"column",alignItems:"center",gap:"10px"}}  >
                //       <XCircle color="red" style={{position:"absolute",top:"10px",right:"10px",backgroundColor:"white",borderRadius:"50%",cursor:"pointer"}} onClick={()=> handleEditUploadImage("bankStatement")}/>
                //       <a href={bankDetails.bankStatement} target="/blank" ><img src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/pdf.png" style={{height:"80%",width:"80%"}}  ></img></a>
                //       <p style={{fontSize:12}}>{getFileNameFromUrl(bankDetails.bankStatement)}</p>
                //   </div>

                //   </div>
                // </div>

                <div className="col mt-5 ">
                  <label>Upload your last 3 months Bank Statement</label>
                  <div className="mt-2">
                    <FileUpload
                      fileType="application/pdf"
                      limitCount={1}
                      fileName={`${accountId}_BankStatement_`}
                      fileId={"userBankStatement"}
                      files={userBankStatementPdf}
                      setFiles={setBankStatementPdf}
                    />
                  </div>
                </div>

                :

                <AssetDisplay workinfo={bankDetails.bankStatement} fileUploadSate={userBankStatementPdf} handleEditUploadImage={handleEditUploadImage} fileType={"bankStatement"} editable={!verify}  labelName={"Bank Statement"}/>



            }



            {/* } */}
          </div>
        </form>
      </div>

      {(!component && !verify) && (
        <div className="stepper-button-outerwrapper">
          <div className="stepper-button-innerwrapper">
            <button className="loan-next-wrapper" onClick={UpdateBankDetails}>
              Update
            </button>
          </div>
        </div>
      )}
      {alert.show && (
        <Alert
          message={alert.message}
          type={alert.type}
          show={alert.show}
          duration={alert.duration}
          onClose={() => setAlert({ ...alert, show: false })}
        />
      )}
    </>
  );
}
