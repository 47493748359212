import React, { useContext, useEffect, useRef, useState } from 'react'
import Metrixfunction from '../../components/MetrixFunction';
import { MyContext } from '../../components/GlobalValues';
function BlogFour() {

  const {  metrixserver} = useContext(MyContext);


  const [imagesLoaded, setImagesLoaded] = useState(0);
  const totalImages = useRef(0);
  
  useEffect(() => {
    // Count the total number of images
    const imageElements = document.querySelectorAll('img');
    totalImages.current = imageElements.length;
  
  
    // Add load event listeners to all images
    if(imageElements.length>0)
    {
      imageElements.forEach(img => {
        if (img.complete) {
          handleImageLoad();
        } else {
          img.addEventListener('load', handleImageLoad);
          img.addEventListener('error', handleImageLoad); // Handle error case
        }
      });
    }
    else{
      Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");
  
    }
  
    // Cleanup function
    return () => {
      imageElements.forEach(img => {
        img.removeEventListener('load', handleImageLoad);
        img.removeEventListener('error', handleImageLoad);
      });
    };
  }, []);
  
  useEffect(() => {
    if (imagesLoaded === totalImages.current && totalImages.current > 0) {
      Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");
  
    }
  }, [imagesLoaded]);
  
  const handleImageLoad = () => {
    setImagesLoaded(prev => prev + 1);
  };
  
  return (
    <div className="bg-white p-0">
    {/* Spinner Start */}
    <div
      id="spinner"
      className=" bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
    >
      <div
        className="spinner-grow text-danger"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
    {/* Spinner End */}
    {/* Navbar & Hero Start */}
    <div className="position-relative p-0" id="home">
      <custom-header />
      <div className=" hero-heade ">
        <div className="container px-lg-5">
          <div className="row g-4">
            <div className="col-12 col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
              {/* <h1 className="mb-4" style="color: white" >Financial Articles to Ensure Business Success</h1> */}
              {/* <p className="mb-4" style="color: white">Posted At : 01/11/2023</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Navbar & Hero End */}
    <div className="container mt-4 px-3 ">
      <div className="row p-2">
        <div className="col-12">
          {/* ----header---- */}
          <div className="row   d-flex justify-content-start     ">
            <h1 className="w-auto  p-4 bg-custom-color rounded">
              How Can I Improve my CIBIL Score Inorder To Get Business Loan ?
            </h1>
            <p style={{ color: "black" }}>
              <span style={{ fontWeight: 500 }}>Posted :</span> October 31
              <sup>st</sup> 2023{" "}
            </p>
          </div>
          {/* -----------header end ----- */}
          {/* --------Body----------- */}
          <div className="row border-1 border-danger  d-flex align-items-center ">
            <div className="col-12 col-md-5  d-flex justify-content-center align-items-center p-0">
              <img alt="" src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/service2.png" height="100%" width="100%" />
            </div>
            <div className="col-12 col-md-7">
              <h3 className="bg-custom-color p-2" style={{ color: "#e32f45" }}>
                Introduction
              </h3>
              <p style={{ lineHeight: 2, fontSize: "1.1rem", fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In the world of
                business loans, your CIBIL score is your key to securing the funds
                you need. This article will provide an in-depth guide on how to
                improve your CIBIL score to enhance your chances of getting
                approved for a business loan. We will also discuss the
                significance of CIBIL, how it works, and its role in the loan
                approval process. Throughout this journey, we'll highlight the
                role of MyKaasu, a trusted financial platform, in assisting you
                with your financial needs.
              </p>
            </div>
            <div className="col-12 col-md-12">
              <h3 className="bg-custom-color p-2" style={{ color: "#e32f45" }}>
                Overview of CIBIL{" "}
              </h3>
              <ul style={{ lineHeight: 2, fontSize: "1.1rem", fontWeight: 400 }}>
                <li>
                  <h4>
                    Understanding CIBIL :{" "}
                    <span style={{ fontStyle: "oblique", fontWeight: 200 }}>
                      Your Financial Reputation{" "}
                    </span>
                  </h4>
                  <p>
                    CIBIL, or Credit Information Bureau (India) Limited, is a
                    credit information company that assesses and maintains records
                    of individuals' and businesses' credit histories. Lenders rely
                    on CIBIL scores to evaluate creditworthiness and determine
                    loan eligibility.
                  </p>
                </li>
                <li>
                  <h4>
                    Factors That Affect Your CIBIL Score for Business Loans :
                  </h4>
                  <p>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: "1.15rem",
                        color: "#e32f45"
                      }}
                    >
                      The Weight of Payment History (35%):{" "}
                    </span>{" "}
                    Timely payment of bills and EMIs contributes significantly to
                    your CIBIL score. Setting up automatic payments through
                    MyKaasu or other platforms can help ensure on-time payments.{" "}
                  </p>
                  <p>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: "1.15rem",
                        color: "#e32f45"
                      }}
                    >
                      Credit Utilization (30%):{" "}
                    </span>{" "}
                    Maintaining credit utilization below 30% of your available
                    credit limit across all cards is essential to maintain a
                    favorable score.{" "}
                  </p>
                  <p>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: "1.15rem",
                        color: "#e32f45"
                      }}
                    >
                      Credit History Length (15%):{" "}
                    </span>
                    The duration of your credit history impacts your score.
                    Keeping older credit cards active can positively influence
                    your credit history.{" "}
                  </p>
                  <p>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: "1.15rem",
                        color: "#e32f45"
                      }}
                    >
                      New Credit Inquiries (10%):
                    </span>
                    Each time you apply for a loan, it triggers a hard inquiry,
                    affecting your credit score. MyKaasu can help you explore loan
                    options with a soft inquiry to avoid multiple hard inquiries.{" "}
                  </p>
                  <p>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: "1.15rem",
                        color: "#e32f45"
                      }}
                    >
                      Credit Mix (10%):
                    </span>
                    Maintaining a mix of different types of credit accounts, such
                    as credit cards, bank loans, and mortgages, can diversify your
                    credit profile.{" "}
                  </p>
                </li>
                <li>
                  <h4>The Role of CIBIL in Business Loan Approvals :</h4>
                  <p>
                    CIBIL scores significantly influence the approval process for
                    business loans. A score of 750 or higher is generally
                    considered acceptable for loan approval. Lenders use CIBIL
                    scores to assess creditworthiness and determine the terms of
                    the loan.
                  </p>
                </li>
                <li>
                  <h4>How to Improve Your CIBIL Score for Business Loans</h4>
                  <p>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: "1.15rem",
                        color: "#e32f45"
                      }}
                    >
                      Pay Dues on Time:{" "}
                    </span>{" "}
                    Payment history accounts for 35% of your score. Timely
                    payments are crucial. MyKaasu can assist with reminders to
                    ensure you never miss a payment.{" "}
                  </p>
                  <p>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: "1.15rem",
                        color: "#e32f45"
                      }}
                    >
                      Reduce Credit Utilization:{" "}
                    </span>
                    Keeping credit utilization below 30% of the available limit on
                    all cards positively impacts your score.
                  </p>
                  <p>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: "1.15rem",
                        color: "#e32f45"
                      }}
                    >
                      Don't Deactivate Older Credit Cards:{" "}
                    </span>
                    The length of your credit history is essential. Keep older
                    cards active to maintain a positive credit history.{" "}
                  </p>
                  <p>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: "1.15rem",
                        color: "#e32f45"
                      }}
                    >
                      Repay Existing Business Loans:{" "}
                    </span>{" "}
                    Multiple hard inquiries from loan applications can affect your
                    credit score. MyKaasu's soft inquiry option can be beneficial.{" "}
                  </p>
                  <p>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: "1.15rem",
                        color: "#e32f45"
                      }}
                    >
                      Maintain Credit Mix:{" "}
                    </span>
                    While of lower priority, a diverse credit mix can help improve
                    your score.
                  </p>
                </li>
                <li>
                  <h4>
                    Why Maintaining a Good CIBIL Score is Vital for Business Loans
                    :
                  </h4>
                  <p>
                    A strong CIBIL score is vital for securing business loans. It
                    indicates your creditworthiness and demonstrates your ability
                    to manage financial obligations.
                  </p>
                </li>
                <li>
                  <h4> Leveraging MyKaasu for Business Loan Assistance :</h4>
                  <p>
                    MyKaasu is a valuable financial platform that can help you
                    explore business loan options with ease. It offers tools and
                    resources to manage your finances and improve your credit
                    score while searching for the right loan.
                  </p>
                </li>
              </ul>
            </div>
            {/* ----------footer --------- */}
            <div className="col-12 col-md-12">
              <h3 className="bg-custom-color p-2" style={{ color: "#e32f45" }}>
                Conclusion
              </h3>
              <p style={{ lineHeight: 2, fontSize: "1.1rem", fontWeight: 400 }}>
                In conclusion, understanding and improving your CIBIL score is a
                crucial step in securing business loans. MyKaasu is your trusted
                partner in this journey, offering support and tools to enhance
                your financial health and improve your credit score. By
                maintaining a good CIBIL score, you increase your chances of
                getting your business loan approved and achieving your
                entrepreneurial goals.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Footer Start */}
    <custom-footer />
    {/* Footer End */}
    {/* Back to Top */}
    <p href="#" className="btn btn-lg btn-lg-square back-to-top pt-2">
      <i className="bi bi-arrow-up home-text" />
    </p>
  </div>
  
  )
}

export default BlogFour