import React from "react";

const ProgressBar = (props) => {
  const { bgcolor, completed, bgcolorContainer } = props;

  const containerStyles = {
    height: 10,
    width: "100%",
    backgroundColor: bgcolorContainer,
    borderRadius: 50,
  };
  

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: bgcolor,
    transition: "width 1s ease-in-out",
    borderRadius: "inherit",
    textAlign: "right",
  };



  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
      </div>
    </div>
  );
};

export default ProgressBar;
