import React, { useEffect, useState } from "react";
import Stepper from "../../components/Stepper";
import "../../styles/screenStyles/checkeligibilty.css";
import Pandetails from "./Pandetails";
import Previewscreen from "./Previewscreen";
import UserInfo from "./UserInfo";
import WorkInfo from "./WorkInfo";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "../../components/Alert";
import { MyContext } from "../../components/GlobalValues";
import { useContext } from "react";
import { validationRegex } from "../../utlis/Regex";
import Metrixfunction from "../../components/MetrixFunction";


function CheckEligibility() {
  const [currentActive, setCurrentActive] = useState(1);

  const [showalert, setshowalert] = useState({ cond: false, msg: "", color: "" })

  const { Url,userinfo,workinfo,pandata,userverify,workverify,eligibilitydetails,setworkinfo,setuserinfo,setpandata,seteligibilitydetails,metrixserver} = useContext(MyContext);

  function removeSlash(port1) {
    let p1 = port1.split("/").join("");

    let np = p1;
    return np;
  }

  const location=useLocation()

  const alertmessage = (msg, color) => {
    setshowalert({ ...showalert, cond: true, msg: msg, color: color })

    setTimeout(() => {
      setshowalert({ ...showalert, cond: false, msg: "", color: "" })
    }, 2000);
  }

  const [eligiblebtn,seteligiblebtn]=useState(false)



  // const images=[call,download,employee,job,signzy]

  const navigate = useNavigate();

  const images = [
    "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/user-icon.png",
    "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/work-info.png",
    "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/preview-info.png",
    "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/job-search.png",
    "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/loan.png",
  ];

  const text = [
    "User Information",
    "Work Information",
    "Pan Details",
    "Documents",
  ];

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const panRegex = /^[A-Z]{5}\d{4}[A-Z]$/;
  const companyNameRegex = /^[a-zA-Z0-9,.'-][a-zA-Z0-9\s,.'-]{1,}[a-zA-Z0-9,.'-]( {0,3})?$/; // At least 3 characters, no leading/trailing spaces beyond 3


  const stepcount = 4;

  

  const handleNext = () => {
    // setCurrentActive(currentActive+1)

    if (currentActive === 1) {
      if ( userinfo.userName.length < 3 || !validationRegex["userName"].test(userinfo.userName)) {
        alertmessage("User name must contain only letters and be at least 3 characters long", "danger")
      }
    
      else if (!emailRegex.test(userinfo.userEmail)) {
        alertmessage("Enter the valid mail", "danger")
      }
      else if (userinfo.userDOB.length<5 || !validationRegex["userDOB"].test(userinfo.userDOB)) {
        alertmessage("Select the Date of Birth", "danger")
      }
      else if(!userverify)
        {
          alertmessage("Please verify the Email before proceed", "danger")
        }
      else {
        setCurrentActive((prevActive) =>
          prevActive < stepcount ? prevActive + 1 : prevActive
        );

        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }, 0);
      }
    }
    else if (currentActive === 2) {
      if (workinfo.companyName.length < 3 || !companyNameRegex.test(workinfo.companyName)) {
        alertmessage("Enter The valid Company Name", "danger")
      }
      else if (workinfo.companyType.length <1) {
        alertmessage("Select the valid company type", "danger")
      }
      else if (!emailRegex.test(workinfo.companyEmail)) {
        alertmessage("Enter the valid company mail", "danger")
      }
      else if (workinfo.companyDesignation.length<1) {
        alertmessage("Select the valid designation", "danger")
      }
      else if (workinfo.companyJoinDate.length<5) {
        alertmessage("Select the valid Joined date", "danger")
      }
      else if (workinfo.companySalary.length<5 || !(/^[0-9]*$/.test(workinfo.companySalary))) {
        alertmessage("Monthly salary must be greater than 10000 ", "danger")
      }
      else if(!workverify)
        {
          alertmessage("Please verify the Email before proceed", "danger")
        }
      else {
        setCurrentActive((prevActive) =>
          prevActive < stepcount ? prevActive + 1 : prevActive
        );

        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }, 0);
      }
    }
    else if (currentActive === 3) {
      if (! panRegex.test(pandata.panno) || pandata.panno.length < 10) {
        alertmessage("Enter the valid pan number", "danger")
      }
      else {
        setCurrentActive((prevActive) =>
          prevActive < stepcount ? prevActive + 1 : prevActive
        );

        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }, 0);
      }
    }
    else{
      // setCurrentActive((prevActive) =>
      //   prevActive < stepcount ? prevActive + 1 : prevActive
      // );

      // setTimeout(() => {
      //   window.scrollTo({
      //     top: 0,
      //     behavior: "smooth",
      //   });
      // }, 0);
    }

  };

  const handlenavigate = async () => {

   
      
  
      let query = {
        accId: localStorage.getItem("accId"),
        userName:userinfo.userName,
        apiKey: localStorage.getItem("apiKey"),
        userDOB: userinfo.userDOB,
        panNo: pandata.panno,
        companyType:workinfo.companyType,
        companyName: workinfo.companyName,
        companySalary: workinfo.companySalary,
        companyDesignation: workinfo.companyDesignation,
        companyJoinDate: workinfo.companyJoinDate,
        loanAmount: eligibilitydetails.loancount,
        loanTerm: eligibilitydetails.loanperiod,
      };
  
     
      seteligiblebtn(true)

      await fetch(`${Url}/business/checkEligibility`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(query),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
         
          if(data.Error){
            alertmessage(data.Error, "danger");
          }
          else{
            localStorage.setItem("eligibleApproved","yes")

            navigate("/dashboard");
            setTimeout(() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }, 100);
          }
          seteligiblebtn(false)
        })
        .catch((error) => {
          console.error("Error:", error);
          seteligiblebtn(false)

        })
        .finally(()=>{
      Metrixfunction(metrixserver, "checkEligibility", "UPDATE");

        })

  };

  const handlePrev = () => {
    setCurrentActive((prevActive) =>
      prevActive > 1 ? prevActive - 1 : prevActive
    );
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 0);
  };



  const preventClose = (e) => {
    e.preventDefault();
    e.returnValue = ''; 
  };

  useEffect(() => {
    window.addEventListener('beforeunload', preventClose);

    return () => {
      window.removeEventListener('beforeunload', preventClose);
      setworkinfo({
        ...workinfo,
        companyName: "",
        companyEmail: "",
        companyType: "",
        companyJoinDate: "",
        companySalary: "",
        companyDesignation: "",
      })

      setuserinfo({
       ...userinfo,
        userName: "",
        userEmail: "",
        userDOB: "",
      })

      setpandata({
        ...pandata,
        panno:"",
       
      })

      seteligibilitydetails({loancount:50000,loanperiod:6})

    };
  }, []);

  useEffect(()=>{
    let eligible=localStorage.getItem("eligibleApproved")
    if(eligible)
      {
        if(eligible==="yes")
          {
            navigate("/dashboard")
          }
      }
      else{
        localStorage.clear();
          navigate("/login")
      }
  },[])


  return (
    <div className="check-eligibility-container">
      <div className="eligibility-header-wrapper">
        <h2>Check Eligibility</h2>
        <span>Fill out this field to check your eligibility</span>
      </div>
      <div className="stepper-outer-wrapper">
        <div className="stepper-inner-wrapper">
          <Stepper
            stepcount={stepcount}
            circletype={"both"} // give a value either image or text
            circleimage={images} // if you give a value image
            circletext={text} // if you give a value image
            curretstepper={currentActive}
            // // text={text} // if you give a value text
          />
        </div>
      </div>
      <div
        onSubmit={(event) => {
           
          event.preventDefault();
          window.removeEventListener("beforeunload", preventClose);
        }}
      >
        <div className="eligibility-form-wrapper">
          {currentActive === 1 && (
            <UserInfo component={true} componenttype={"stepper"} />
          )}
          {currentActive === 2 && (
            <WorkInfo component={true} componenttype={"stepper"} />
          )}
          {currentActive === 3 && (
            <Pandetails component={true} componenttype={"stepper"} />
          )}
          {currentActive === 4 && <Previewscreen />}
        </div>

        <div className="stepper-button-outerwrapper">
          <div className="stepper-button-innerwrapper">
            {currentActive > 1 && (
              <button
                className="eligibility-next-wrapper"
                onClick={(event) => {
                  event.preventDefault();
                  window.removeEventListener("beforeunload", preventClose);
                  handlePrev();
                }}
              >
                Back
              </button>
            )}
            {currentActive === stepcount ? (
              <button
                className="eligibility-next-wrapper"
                onClick={(event) => {
                  event.preventDefault();
                  window.removeEventListener("beforeunload", preventClose);
                  handlenavigate();
                }}
                disabled={eligiblebtn}
              >
                Finish
              </button>
            ) : (
              <button
                className="eligibility-next-wrapper"
                onClick={(event) => {
                  event.preventDefault();
                  window.removeEventListener("beforeunload", preventClose);
                  handleNext();
                }}
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
      {showalert.cond && 
        <Alert
          show={true}
          message={showalert.msg}
          type={showalert.color}
          onClose={() => {}}
          duration={2000}
        />
      }
    </div>
  );
}

export default CheckEligibility;
