import React, { useEffect, useRef, useState } from "react";
import "../styles/componentStyles/CustomHeader.css"
import { ChevronDown, ChevronUp, Menu, X } from "react-feather";
import { ChevronBarDown } from "react-bootstrap-icons";
import { useLocation, useNavigate } from "react-router-dom";
import ChatBot from "../screens/dynamic/ChatBot";
// const { Menu } = require("feather-icons");

function CustomHeader() {

    const navigate = useNavigate();
    const location = useLocation();
    const navbarCollapseRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  function generateEpochWithRandom() {
    // Get current epoch time in seconds
    let epochTime = Math.floor(Date.now() / 1000);

    // Generate random last three digits
    let randomDigits = Math.floor(Math.random() * 1000);

    // Combine epoch time and random digits
    let epochWithRandom = epochTime * 1000 + randomDigits;

    return epochWithRandom;
  }


  useEffect(() => {
    if(localStorage.getItem("loginId")===null)
    {
      let LoginId=generateEpochWithRandom()
      localStorage.setItem("loginId",LoginId)
    }
    
    window.scrollTo(0, 0);
}, [location.pathname]);

  const handleLogin = () => {
    navigate("/login");
    handleClose()
  };

  const handleClose = () => {
   
    let sideBarWrapper = document.getElementsByClassName("cusNav-SideBar-wrapper")[0];
    let sideBar = document.getElementsByClassName("cusNav-SideBar")[0];
  
    if (!isOpen) {
      sideBarWrapper.style.visibility = "visible";
      sideBarWrapper.style.opacity = "1";
  

      if (window.innerWidth < 350) {
        sideBar.style.width = "100%";
      }else if (window.innerWidth < 400) {
        sideBar.style.width = "80%";
      } else if (window.innerWidth < 600) {
        sideBar.style.width = "70%";
      } else if (window.innerWidth < 1200) {
        sideBar.style.width = "40%";
      } else {
        sideBar.style.width = "50%";
      }
  
      setIsOpen(true);
    } else {
      setIsOpen(false);
      sideBarWrapper.style.visibility = "hidden";
      sideBarWrapper.style.opacity = "0";
      sideBar.style.width = "0";
    }
  };
  

  document.addEventListener('DOMContentLoaded', function() {
    var dropdownHeaders = document.querySelectorAll('#cusNav-Loans-sidebar-loans, #cusNav-Loans-sidebar-contact, #cusNav-Loans-sidebar-tools');
    
    dropdownHeaders.forEach(function(header) {
        header.addEventListener('click', function() {
            var dropdown = this.querySelector('.cusNav-drpdown-sidebar');
            dropdown.classList.toggle('open');
        });
    });
});


const [isShown, setIsShown] = useState({
    loans: false,
    contact: false,
    tools: false
});

const toggleDropdown = (key) => {
    setIsShown(prevState => {
        const newState = {};
        for (let k in prevState) {
            newState[k] = k === key ? !prevState[key] : false;
        }
        return newState;
    });
};


const [activeItem, setActiveItem] = useState('');

const toastref = useRef(null);

  const handleCloseToast = () => {
    toastref.current.style.display = "none";
  };

  const handleHome = (homeButton) => {
    const eligible = localStorage.getItem("eligibleApproved");
    const accId = localStorage.getItem("accId");
    const apiKey = localStorage.getItem("apiKey");

    if (accId !== null && apiKey !== null) {
      if (eligible) {
        if (eligible === "yes") {
          navigate("/dashboard");
        } else {
          navigate("/checkeligibility");
        }
      }
    } else {
      localStorage.clear();
      navigate("/home");
    }

    if(homeButton === "sidebarHome"){
        handleClose();
    }

   
  };

  const handleNavigation = (path, state = {}) => {
    handleClose();
    navigate(path, state);
  };

 


  return (
    // <div style={{height:"100Vh",width:"100vw"}}> 
    <>
            {location.pathname==="/home" && <div
            ref={toastref}
            style={{
            height: "auto",
            width: "100%",
            backgroundColor: "#e32f441a",
            // position: "absolute",
            // top: "80px",
            zIndex: "-1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px 15px",
            transition: "ease-in-out",
            transitionProperty: "all",
            transitionDuration: "1s",
            boxShadow: " 0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
        >
            <div
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding:"0px 5px  "
            }}
            >
            <p
                style={{
                marginTop: "5px",
                textAlign: "start",
                marginLeft: "auto",
                marginRight: "auto",
                color: "#e32f45",
                }}
                className="header-notify-text"
            >
                <span style={{ fontWeight: "600", color: "#e32f45" }}>
                {" "}
                Update :{" "}
                </span>{" "}
                Mykaasu Secures RBI In-Principal Approval! Get Ready for
                Hassle-Free Loans. Coming Soon!{" "}
            </p>
            </div>
            <X className="header-notify-close" onClick={handleCloseToast} />
        </div>  }
        <div className="cusNav-wrapper">
        {/* ---Company name & logo---   */}
        <div className="cusNav-logo-name-wrapper clickable" onClick={()=>navigate("/home")}>
            <img src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/logo.png" alt="logo"></img>
            <h1>Mykaasu</h1>
        </div>
        {/* -----Menu list ----- */}
        <div className="cusNav-menu-wrapper">
            <ul>
                <li className={activeItem === 'home' ? 'active' : ''} onClick={() =>{ setActiveItem('home'); handleHome("mainHome")}}>Home</li>
                <li id="cusNav-Loans" className={activeItem === 'loans' ? 'active' : ''} >
                    <p style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"5px"}}>Loans <span><ChevronDown /></span></p>
                    {/* {isShown.loans && ( */}
                        <div className="cusNav-drpdown">
                            <p onClick={() => {toggleDropdown('loans'); navigate("/personal-loans") }}>Personal Loan</p>
                            <p onClick={() => {toggleDropdown('loans'); navigate("/business-loans")}}>Business Loan</p>
                        </div>
                    {/* )} */}
                </li>
                <li className={activeItem === 'investors' ? 'active' : ''} onClick={() => {setActiveItem('investors'); navigate("/investors");}}>Investors</li>
                <li className={activeItem === 'articles' ? 'active' : ''} onClick={() => {setActiveItem('articles'); navigate("/articles");}}>Articles</li>
                <li className={activeItem === 'careers' ? 'active' : ''} onClick={() => {setActiveItem('careers'); navigate("/careers");}}>Careers</li>
                <li id="cusNav-contact" className={activeItem === 'contact' ? 'active' : ''} onClick={() => toggleDropdown('contact')}>
                    <p style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"5px"}}><span style={{display:"flex",justifyContent:"center",alignItems:"center",width:"max-content"}}>Contact Us</span> <span><ChevronDown /></span></p>
                    {/* {isShown.contact && ( */}
                        <div className="cusNav-drpdown">
                            <p  onClick={() => navigate("/faq")}>FAQ</p>
                            <p onClick={() => navigate("/contactus")}>Complaints / Queries</p>
                        </div>
                    {/* )} */}
                </li>
                <li id="cusNav-tools" className={activeItem === 'tools' ? 'active' : ''} onClick={() => toggleDropdown('tools')}>
                    <p style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"5px"}}>Tools <span><ChevronDown /></span></p>
                    {/* {isShown.tools && ( */}
                        <div className="cusNav-drpdown">
                            <p onClick={() =>
                            navigate("/calculator", {
                          state: { showpayments: false },
                        })
                      }>Calculator</p>
                        </div>
                    {/* )} */}
                </li>
            </ul>
        </div>
        {/* ---- Nav Button list ---- */}

        <div className="cusNav-navButtons-wrapper">
              {!localStorage.getItem("apiKey") &&
              !localStorage.getItem("accId") ? (
                <>
                  {/* <button
                  // href="/download-app"
                  className="nav-item nav-link nav-button d-flex justify-content-start align-items-center "
                  style={{
                    paddingLeft: "0px !important",
                    paddingRight: "0px !important",
                  }}
                  onClick={() => handleLogin()}
                >
                  <div
                    className=" d-flex justify-content-start  align-items-center px-3 py-2 header-apply-now rounded border "
                    style={{
                      padding: 0,
                      color: "white",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Sign in
                  </div>
                </button> */}
                </>
              ) : (
        <div className="cusNav-navButtons-wrapper">
             {location.pathname !== "/dashboard" &&
                    location.pathname !== "/checkeligibility" && (
                 <button className="cusNav-navButton-outline"
                 onClick={() => {
              
                  localStorage.getItem("eligibleApproved") === "yes"
                    ? navigate("/dashboard")
                    : navigate("/checkeligibility");
                    
                }}
                 >Dashboard</button>
            
             )}
            {location.pathname !== "/profile" && (
                <button className="cusNav-navButton-normal"
                onClick={() => {
                  navigate("/profile");
                }}
                >Profile</button>
             )}
        </div>
          )}
          </div>

        {/* ----Side Bar ---- */}
        <div className="cusNav-SideBar-icon" onClick={() => handleClose()}>
            {/* <p>Menu</p> */}
            <Menu style={{color:"white"}} />
        </div>

        <div className="cusNav-SideBar-wrapper" onClick={() => { handleClose(); }}>
    <div className="cusNav-SideBar" onClick={(e) => { e.stopPropagation(); }}>
      <div className="cusNav-menu-sidebar-wrapper">

        <div  className="cusNav-sidear-navbuttons-wrapper" style={{backgroundColor:(!localStorage.getItem("apiKey") &&!localStorage.getItem("accId")) && "transparent"}}>

          <button
            className="cusNav-SideBar-close-btn"
            onClick={() => handleClose()}
            style={{color:(!localStorage.getItem("apiKey") &&!localStorage.getItem("accId")) && "#FF6F59"}}
          >
            <X   />
          </button>

          <div className="cusNav-sidear-navbuttons">
          {/* <div className="header-dash"> */}
              {!localStorage.getItem("apiKey") &&
              !localStorage.getItem("accId") ? (
                <>
                  {/* <button
                  // href="/download-app"
                  className="cusNav-sidebar-navButton-outline"
                  style={{
                    paddingLeft: "0px !important",
                    paddingRight: "0px !important",
                  }}
                  onClick={() => handleLogin()}
                >
                  
                    Sign in
                
                </button> */}
                </>
              ) : (
               
                <>
                  {location.pathname !== "/dashboard" &&
                    location.pathname !== "/checkeligibility" && (
                      <button
                        // href="/download-app"
                        className="cusNav-sidebar-navButton-outline"
                        style={{
                          paddingLeft: "0px !important",
                          paddingRight: "0px !important",
                        }}
                        onClick={() => {
                          localStorage.getItem("eligibleApproved") === "yes"
                            ? navigate("/dashboard")
                            : navigate("/checkeligibility");
                            handleClose()
                          // closeNavbar();
                        }}
                        
                      >
                          Dashboard
                        
                      </button>
                    )}
                  {location.pathname !== "/profile" && (
                    <button
                      // href="/download-app"
                     className="cusNav-sidebar-navButton-outline"
                      style={{
                        paddingLeft: "0px !important",
                        paddingRight: "0px !important",
                      }}
                      onClick={() => {
                        navigate("/profile");
                        handleClose()
                        // closeNavbar();
                      }}
                    >
                      
                        Profile
                      
                    </button>
                  )}
                </>
              )}

              
            {/* </div> */}

          </div>

        </div>      


        <ul>
          <li onClick={() =>handleHome("sidebarHome")}>Home</li>
          <li id="cusNav-Loans-sidebar-loans" onClick={() => toggleDropdown('loans')}>
            <p>Loans {isShown.loans ? <ChevronUp /> : <ChevronDown />} </p>
            <div className={`cusNav-drpdown-sidebar ${isShown.loans ? "cusNav-open" : ""}`} >
              <p onClick={() => handleNavigation("/personal-loans")}>Personal Loan</p>
              <p onClick={() => handleNavigation("/business-loans")}>Business Loan</p>
            </div>
          </li>
          <li onClick={() => handleNavigation("/investors")}>Investors</li>
          <li onClick={() => handleNavigation("/articles")}>Articles</li>
          <li onClick={() => handleNavigation("/careers")}>Careers</li>
          <li id="cusNav-Loans-sidebar-contact" onClick={() => toggleDropdown('contact')}>
            <p>Contact Us {isShown.contact ? <ChevronUp /> : <ChevronDown />}</p>
            <div className={`cusNav-drpdown-sidebar ${isShown.contact ? "cusNav-open" : ""}`}>
              <p onClick={() => handleNavigation("/faq")}>FAQ</p>
              <p onClick={() => handleNavigation("/contactus")}>Complaints / Queries</p>
            </div>
          </li>
          <li id="cusNav-Loans-sidebar-tools" onClick={() => toggleDropdown('tools')}>
            <p>Tools {isShown.tools ? <ChevronUp /> : <ChevronDown />}</p>
            <div className={`cusNav-drpdown-sidebar ${isShown.tools ? "cusNav-open" : ""}`}>
              <p onClick={() => handleNavigation("/calculator")}>Calculator</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
      {localStorage.getItem('accId') && localStorage.getItem('apiKey') && <ChatBot />}
        
        </div>
    </> 
    // </div>
  );
}

export default CustomHeader;