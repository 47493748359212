import React, { useState, useEffect, useContext, useRef } from "react";
import "../../styles/screenStyles/ChatBot.css";
import { Send, X } from "react-feather";
import { MyContext } from "../../components/GlobalValues";
import {
  Bank,
  CardHeading,
  Person,
  PersonVcard,
  PiggyBank,
  ThreeDots,
} from "react-bootstrap-icons";
import Alert from "../../components/Alert";
import Metrixfunction from "../../components/MetrixFunction";
import { useLocation } from "react-router-dom";
// import { useLocation } from "react-router-dom";

function ChatBot() {
  // const location = useLocation();

  const ticketOptions = [
    {
      title: "Loan related",
      icon: <PiggyBank className="chat-options-icon" />,
    },
    {
      title: "Pan Details",
      icon: <CardHeading className="chat-options-icon" />,
    },
    {
      title: "Bank Details",
      icon: <Bank className="chat-options-icon" />,
    },
    {
      title: "Aadhar Details",
      icon: <PersonVcard className="chat-options-icon" />,
    },
    {
      title: "Personal Details",
      icon: <Person className="chat-options-icon" />,
    },
    {
      title: "Other",
      icon: <ThreeDots className="chat-options-icon" />,
    },
  ];

  const [showFirstMessage, setShowFirstMessage] = useState(false);
  const [showSecondMessage, setShowSecondMessage] = useState(false);
  const [dummyLoading, setDummyLoading] = useState(false);

  const [validMessages, setValidMessages] = useState(false);
  const [chatError, setChatError] = useState("");
  const [lastCreatedTicketStatus, setLastCreatedTicketStatus] =
    useState("ASSIGNED");
  const [lastCreatedTicketId, setLastCreatedTicketId] = useState(null);

  const [disableCreateTicket, setDisableCreateTicket] = useState(false);
  const inputRef = useRef(null);

  function removeSlash(port1) {
    let p1 = port1.split("/").join("");

    let np = p1;
    return np;
  }

  const location = useLocation();

  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "",
    duration: 3000,
  });

  const {
    openChat,
    setOpenChat,
    chatMessage,
    setChatMessage,
    ticketId,
    setTicketId,
    ticketCreated,
    setTicketCreated,
    messages,
    setMessages,
    newQuestion,
    setNewQuestion,
    createNewTicket,
    setCreateNewTicket,
    ticketStatus,
    Url,
    metrixserver,
  } = useContext(MyContext);

  const showAlert = (message, type, duration = 3000) => {
    setAlert({
      show: true,
      message,
      type,
      duration,
    });

    // Automatically close the alert after the duration
    setTimeout(() => {
      setAlert((prevAlert) => ({
        ...prevAlert,
        show: false,
      }));
    }, duration);
  };

  // useEffect(() => {
  //     const lastTicketId = localStorage.getItem("ticketId");
  //     console.log("inside the first useEffect :", lastTicketId);
  //     if (lastTicketId !== null && lastTicketId !== undefined && openChat) {
  //         lastTicketComments(lastTicketId);
  //         setTicketCreated(true);
  //     }

  // }, [openChat]);

  useEffect(() => {
    const lastTicketId = localStorage.getItem("ticketId");
    const validTicketId = (id) => id !== null && id !== undefined && id !== "";

    if (
      (validTicketId(ticketId) || validTicketId(lastTicketId)) &&
      openChat &&
      !ticketCreated
    ) {
      setLastCreatedTicketId(true);
      getAllComments();
      // setTicketCreated(true);
    }
  }, [ticketId, openChat]);

  const chatBodyRef = useRef(null);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [newQuestion, messages, openChat, ticketStatus]);

  useEffect(() => {
    // if (!ticketCreated || openChat || createNewTicket) {
    const lastTicketId = localStorage.getItem("ticketId");

    const validTicketId = (id) => id !== null && id !== undefined && id !== "";

    if (openChat && !createNewTicket) {
      setTimeout(() => {
        setShowFirstMessage(true);
      }, 1000);

      // setTimeout(() => {
      //     setShowSecondMessage(true);
      // }, 3000);
    } else {
      setShowFirstMessage(true);
    }
  }, [ticketCreated, openChat, createNewTicket]);

  useEffect(() => {
    if (!dummyLoading && inputRef.current) {
      inputRef.current.focus();
    }
  }, [dummyLoading]);

  const askQuestion = async () => {
    const accId = localStorage.getItem("accId");
    const apiKey = localStorage.getItem("apiKey");
    const regexMessage = /^(?!null$|undefined$)\s*(\S\s*){2,}$/;
    const lastTicketId = localStorage.getItem("ticketId");

    if (regexMessage.test(newQuestion) && accId && apiKey && ticketId) {
      const question = {
        text: newQuestion,
        type: "question",
        time: getCurrentTime(),
      };
      setMessages((prevMessages) => [...prevMessages, question]);
      setNewQuestion("");
      setDummyLoading(true);
      try {
        await fetch(`${Url}/business/addTicketComment`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            accId: accId,
            apiKey: apiKey,
            comment: newQuestion,
            commentBy: "user",
            ticketId: ticketId,
          }),
        })
          .then((res) => res.json())
          .then((resJson) => {
            if (resJson.Success) {
              // setMessageLoading(false);
              setDummyLoading(false);
              const answer = {
                text: resJson.Success,
                type: "answer",
                time: getCurrentTime(),
              };
              setMessages((prevMessages) => [...prevMessages, answer]);
            }
          })
          .finally(() => {
            Metrixfunction(metrixserver, "addTicketComment", "UPDATE");
          });
      } catch (error) {
        console.log("Error :", error);
      }
    } else if (
      !ticketCreated &&
      (ticketId == null || ticketId == undefined || ticketId == "")
    ) {
      setChatError("Please select a ticket option");
      setValidMessages(true);
      setTimeout(() => {
        setValidMessages(false);
      }, 2500);
    } else {
      setValidMessages(true);
      setChatError("Minimum 3 characters");
      setTimeout(() => {
        setValidMessages(false);
      }, 2500);
      // showAlert('Please select a ticket','danger')
    }
  };

  // const askQuestionLastTicket = async (lastTicketId) => {
  //     const accId = localStorage.getItem("accId");
  //     const apiKey = localStorage.getItem("apiKey");
  //     const regexMessage = /^(?!null$|undefined$)\s*(\S\s*){2,}$/;

  //     if(regexMessage.test(newQuestion) && accId && apiKey && lastTicketId){
  //         const question = { text: newQuestion, type: 'question', time: getCurrentTime() };
  //         setMessages((prevMessages) => [...prevMessages, question]);
  //         setNewQuestion('');
  //         setDummyLoading(true);
  //         try {
  //             await fetch(
  //                 `${Url}/business/addTicketComment`,
  //                 {
  //                     method: "POST",
  //                     headers: { "Content-Type": "application/json" },
  //                     body: JSON.stringify({
  //                         accId: accId,
  //                         apiKey: apiKey,
  //                         comment: newQuestion,
  //                         commentBy: "user",
  //                         ticketId: lastTicketId,
  //                     }),
  //                 }).then((res) => res.json())
  //                 .then((resJson) => {
  //                     console.log("resJson from the create last tickets", resJson);
  //                     if (resJson.Success) {
  //                         // setMessageLoading(false);
  //                         setDummyLoading(false);
  //                         const answer = { text: resJson.Success, type: 'answer', time: getCurrentTime() };
  //                         setMessages((prevMessages) => [...prevMessages, answer]);
  //                     }
  //                 });
  //         } catch (error) {
  //             console.log("Error :", error);
  //         }
  //     }
  //     else {
  //         console.log("Error in ask question");
  //         setValidMessages(true);
  //         console.log("Minimum 3 characters");
  //         setChatError("Minimum 3 characters");
  //         setTimeout(() => {
  //             setValidMessages(false);
  //         }, 2500);
  //         // showAlert('Please select a ticket','danger')
  //     }
  // }

  const createTicket = async (ticket) => {
    setDisableCreateTicket(true);
    const accId = localStorage.getItem("accId");
    const apiKey = localStorage.getItem("apiKey");

    if (ticket && accId && apiKey) {
      try {
        await fetch(`${Url}/business/createTicket`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            accId: accId,
            apiKey: apiKey,
            createdBy: "user",
            comment: ticket,
            commentBy: "user",
            actionTo: "support",
            assignedTo: "EXTERNAL",
          }),
        })
          .then((res) => res.json())
          .then((resJson) => {
            if (resJson.Success) {
              setTicketCreated(true);
              // setSelectedTicketOption(ticket)

              const question = {
                text: ticket,
                type: "question",
                time: getCurrentTime(),
              };
              setMessages((prevMessages) => [...prevMessages, question]);
              setNewQuestion("");
              setTicketId(resJson.Success);
              setCreateNewTicket(true);
              setShowFirstMessage(false);
              showAlert("Ticket created Successfully", "success");
            }
          })
          .finally(() => {
            Metrixfunction(metrixserver, "createTicket", "UPDATE");
          });
      } catch (error) {
        console.log("Error :", error);
      }
    } else {
      showAlert("Fist log-in with your account", "danger");
    }
  };

  const getAllComments = async () => {
    const accId = localStorage.getItem("accId");
    const apiKey = localStorage.getItem("apiKey");
    const lastTicketId = localStorage.getItem("ticketId");

    const validTicketId = (id) => id !== null && id !== undefined && id !== "";

    if (
      (validTicketId(ticketId) || validTicketId(lastTicketId)) &&
      accId &&
      apiKey
    ) {
      try {
        await fetch(`${Url}/business/getTicketById`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            accId: accId,
            apiKey: apiKey,
            ticketId: ticketId || lastTicketId,
          }),
        })
          .then((res) => res.json())
          .then((resJson) => {
            if (resJson.Success) {
               setChatMessage(resJson.Success[0].comments);
              setCreateNewTicket(true);
              // if (location.pathname == "/tickets" && resJson.Success[0].status === "CLOSED") {
              //     setChatMessage(resJson.Success[0].comments)
              //     setCreateNewTicket(true)
              //     console.log("inside the first if statement :", location.pathname);
              // }else if (resJson.Success[0].status !== "CLOSED") {
              //     console.log("inside the first else if statement :", location.pathname);
              //     setChatMessage(resJson.Success[0].comments);
              //     setCreateNewTicket(true)

              // }else{
              //     // setTicketCreated(false)
              //     // setCreateNewTicket(true)
              //     setTicketId("")
              // }
              setLastCreatedTicketStatus(resJson.Success[0].status);
            }
          })
          .finally(() => {
            Metrixfunction(metrixserver, "getTicketById", "ONLOAD");
          });
      } catch (error) {
        console.log("Error :", error);
      }
    } else {
     }
  };

  const lastTicketComments = async (lastId) => {
    const accId = localStorage.getItem("accId");
    const apiKey = localStorage.getItem("apiKey");

    if (lastId) {
      try {
        await fetch(`${Url}/business/getTicketById`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            accId: accId,
            apiKey: apiKey,
            ticketId: lastId,
          }),
        })
          .then((res) => res.json())
          .then((resJson) => {
            if (resJson.Success) {
              if (resJson.Success[0].status !== "CLOSED") {
                setChatMessage(resJson.Success[0].comments);
                setTicketCreated(true);
              } else {
                setLastCreatedTicketStatus(resJson.Success[0].status);
              }
            }
          });
      } catch (error) {
        console.log("Error :", error);
      }
    } else {
      // console.log("after empty the value of ticket :");
    }
  };

  function formatTimeFromTimestamp(date) {
    var timeString = new Date(date).toLocaleTimeString(undefined, {
      timeZone: "Asia/Kolkata",
      hour: "2-digit",
      minute: "2-digit",
    });

    return timeString;
  }

  const createNewTicketHome = () => {
    localStorage.setItem("ticketId", "");
    setDisableCreateTicket(false);
    setLastCreatedTicketId(null);
    setCreateNewTicket(false);
    setNewQuestion("");
    setChatMessage([]);
    setTicketId("");
    setTicketCreated(false);
    setMessages([]);
    setShowFirstMessage(false);
  };

  const handleBotClose = () => {
    setNewQuestion("");
    setOpenChat(!openChat);
    // setChatMessage([])
    // setTicketId(null)
  };

  function getCurrentTime() {
    // Create a new Date object
    var currentDate = new Date();

    // Get the current hour and minute
    var hours = currentDate.getHours();
    var minutes = currentDate.getMinutes();

    // Determine AM or PM
    var meridiem = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight

    // Add leading zeros to minutes if necessary
    minutes = minutes < 10 ? "0" + minutes : minutes;

    // Construct and return the current time in the desired format
    var currentTime = hours + ":" + minutes + " " + meridiem;
    return currentTime;
  }

  const handleInputChange = (event) => {
    const value = event.target.value;
    setNewQuestion(value);
  };

  return (
    <>
      {openChat ? (
        <div className="chatbot-container ">
          <div className="chatbot-header">
            <X
              onClick={handleBotClose}
              style={{ cursor: "pointer", alignSelf: "flex-end" }}
            />
            <div className="chatbot-title-wrapper">
              <p className="chatbot-title">MyKaasu Chatbot</p>
              {createNewTicket && ticketStatus !== "CLOSED" && (
                <button
                  className="chatbot-title-btn"
                  onClick={createNewTicketHome}
                >
                  New Ticket
                </button>
              )}
              {ticketStatus === "CLOSED" && (
                <p className="chatbot-title-ticket-closed">CLOSED</p>
              )}
            </div>
          </div>

          <div className="chatbot-body" ref={chatBodyRef}>
            {!ticketCreated && chatMessage.length === 0 && (
              <>
                {/* {showFirstMessage ?
                                    <div className="chat-message-left">
                                        Hi, I am MyKaasu AI Chatbot.
                                    </div> :
                                    <div className="chat-message-left">
                                        <div className="chat-message-loading">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                    </div>
                                } */}

                {
                  // !showFirstMessage ? null :
                  // showSecondMessage ?
                  showFirstMessage ? (
                    <div className="chat-message-left">
                      <p className="chat-message-left-title">
                        Select an option to create ticket and get help from
                        MyKaasu Support :
                      </p>
                      <div className="chat-message-left-wrapper">
                        {ticketOptions.map((mess, ind) => (
                          <button
                            key={ind}
                            className="chat-message-options"
                            disabled={disableCreateTicket}
                            onClick={() => {
                              createTicket(mess.title);
                            }}
                          >
                            {mess.icon}
                            {mess.title}
                          </button>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="chat-message-left">
                      <div className="chat-message-loading">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  )
                }
              </>
            )}

            {ticketCreated &&
              chatMessage.length === 0 &&
              messages.map((msg, index) => (
                <div
                  key={index}
                  className={
                    msg.type === "question"
                      ? "chat-wrapper-right"
                      : "chat-wrapper-left"
                  }
                >
                  <div className="chat-message-status-wrapper">
                    <p className="chat-message-time">{msg.time}</p>
                    <p className="chat-message-time">
                      {msg.type === "question" ? "YOU" : "SUPPORT"}
                    </p>
                  </div>
                  <div
                    className={
                      msg.type === "question"
                        ? "chat-message-right"
                        : "chat-message-left"
                    }
                  >
                    {msg.text}
                  </div>
                </div>
              ))}

            {chatMessage.length > 0 && (
              <>
                {chatMessage.map((mess, ind) => (
                  <div
                    key={ind}
                    className={
                      mess.commentBy === "user"
                        ? "chat-wrapper-right"
                        : "chat-wrapper-left"
                    }
                  >
                    <div className="chat-message-status-wrapper">
                      <p className="chat-message-time">
                        {formatTimeFromTimestamp(mess.commentTime)}
                      </p>
                      <p className="chat-message-time">
                        {mess.commentBy === "user" ? "YOU" : "SUPPORT"}
                      </p>
                    </div>
                    <div
                      className={
                        mess.commentBy === "user"
                          ? "chat-message-right"
                          : "chat-message-left"
                      }
                    >
                      {mess.comment}
                    </div>
                  </div>
                ))}

                {messages.map((msg, index) => (
                  <div
                    key={index}
                    className={
                      msg.type === "question"
                        ? "chat-wrapper-right"
                        : "chat-wrapper-left"
                    }
                  >
                    <div className="chat-message-status-wrapper">
                      <p className="chat-message-time">{msg.time}</p>
                      <p className="chat-message-time">
                        {msg.type === "question" ? "YOU" : "SUPPORT"}
                      </p>
                    </div>
                    <p
                      className={
                        msg.type === "question"
                          ? "chat-message-right"
                          : "chat-message-left"
                      }
                    >
                      {msg.text}
                    </p>
                  </div>
                ))}
              </>
            )}

            {dummyLoading && (
              <div className="chat-message-left">
                <div className="chat-message-loading">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            )}
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className="chatbot-footer"
          >
            <div className="chatbot-input-wrapper">
              <input
                ref={inputRef}
                className="chatbot-input"
                disabled={ticketStatus === "CLOSED" || dummyLoading}
                onChange={handleInputChange}
                title="Minimum 2 characters"
                value={newQuestion}
                placeholder={
                  ticketStatus === "CLOSED"
                    ? "Ticket Closed"
                    : "Type your message..."
                }
              />
              {validMessages && (
                <span className="chatbot-input-error">{chatError}</span>
              )}
              <button
                disabled={ticketStatus === "CLOSED"}
                style={{ opacity: ticketStatus === "CLOSED" ? 0.3 : 1 }}
                className="send-message-icon"
                onClick={askQuestion}
              >
                <Send />
              </button>
            </div>
          </form>
        </div>
      ) : (

        <div className="chatbot-icon" onClick={() => setOpenChat(!openChat)}>
          <img src={'https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/robo1.png'} height="70%" width="70%" alt="/" />

        </div>
      )}
      {alert.show && (
        <Alert
          message={alert.message}
          type={alert.type}
          show={alert.show}
          duration={alert.duration}
          onClose={() => setAlert({ ...alert, show: false })}
        />
      )}
    </>
  );
}

export default ChatBot;
