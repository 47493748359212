import React, { useEffect, useRef, useState } from "react";
import "../../styles/screenStyles/Loanselect.css";
import Slider from "../../components/Slider";
import { useLocation, useNavigate } from "react-router-dom";
import { Info, X } from "react-feather";
import { useContext } from "react";
import { MyContext } from "../../components/GlobalValues";

function Loanselect({componentdata,senddata,componenttype}) {

  const { Url,loandetails,setloandetails} = useContext(MyContext);
  const [minLoanAmount, setMinLoanAmount] = useState(0);
  const [sliderDisable, setSliderDisable] = useState(0);

  const location = useLocation();
  const navigate=useNavigate()
  const {
    eligibleLoanAmount,
    eligibleInterest,
    eligibleTenure,
    eligibleFee,
    eligibleMinTenure,
    eligibleMinAmount
  } = location.state || {};

 


  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the threshold as needed
    };

    // Set initial size
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  // const [minAmount, setMinAmount] = useState(eligibleLoanAmount * 0.10);
  //  const [loanAmount, setLoanAmount] = useState(eligibleLoanAmount);
    // const [interestRate, setinterestRate] = useState(eligibleInterest);
    // const [tenure, setTenure] = useState(eligibleTenure);
  

    // const [monthlyEMI, setMonthlyEMI] = useState(0)
    const [totalInterest, setTotalInterest] = useState(0)
    
    const [totalAmount, setTotalAmount] = useState(0)
    

    const handleLoanAmountChange = (newValue) => {
      setloandetails({...loandetails,loanAmount:newValue,processingCharges:Math.round((eligibleFee / 100) * newValue)})   
    };


    const handleTenureChange = (newValue) => {
        setloandetails({...loandetails,loanDuration:newValue})
      
    }

    const actualIntrest = (totalInterest, tenure, loanAmount) => {
      const actualIntrest = totalInterest / (tenure / 12);
      const onePercentLoan = loanAmount * 0.01;
      const result = (actualIntrest / onePercentLoan).toFixed(2);
      return result;
    };

    function calculateLoanDetails(loanAmount, interestRate, loanTenure) {
    
        const monthlyInterestRate = interestRate / (12 * 100);
        // const numberOfPayments = loanTenure * 12;

        // const emi = (loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments)) / (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);
        
        const emi =
          (loanAmount *
            monthlyInterestRate *
            Math.pow(1 + monthlyInterestRate, loanTenure)) /
          (Math.pow(1 + monthlyInterestRate, loanTenure) - 1);

        const roundedEmi = Math.round(emi);


        const totalAmount = emi * loanTenure;
        const roundedTotalAmount = Math.round(totalAmount);

        const totalInterest = roundedTotalAmount - loanAmount;
        const roundedTotalInterest = Math.round(totalInterest);

        return { emi: roundedEmi, totalAmount: roundedTotalAmount, totalInterest: roundedTotalInterest };
    }

    useEffect(() => {
        const { emi, totalAmount, totalInterest } = calculateLoanDetails(loandetails.loanAmount, loandetails.loanInterestRatePct, loandetails.loanDuration);

        setloandetails({...loandetails,monthlyEMI:emi,})
        setTotalInterest(totalInterest);
        setTotalAmount(totalAmount);

    }, [loandetails.loanAmount, loandetails.loanInterestRatePct, loandetails.loanDuration]);


    useEffect(()=>{
      
       if (Number(eligibleLoanAmount) === Number(eligibleMinAmount)) {
         setMinLoanAmount(0);
         setSliderDisable(true);
       } else {
         setMinLoanAmount(Number(eligibleMinAmount));
         setSliderDisable(false);

       }
      if(loandetails.loanAmount===0)
        {
          if(location.state){
            const { emi, } = calculateLoanDetails(location.state.eligibleLoanAmount, location.state.eligibleTenure, location.state.eligibleInterest,);
  
             setloandetails({...loandetails,loanAmount:location.state.eligibleLoanAmount,loanDuration:location.state.eligibleTenure,loanInterestRatePct:location.state.eligibleInterest,monthlyEMI:emi,})
          }
          else{
            navigate("/dashboard")
          }
        }
    },[])

function formatLoanDuration(months) {
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  const yearLabel = isMobile ? "yr" : "year";
  const monthLabel = isMobile ? "mth" : "month";

  let durationString = "";

  if (years > 0) {
    durationString += `${years} ${yearLabel}${years > 1 ? "s" : ""}`;
  }

  if (remainingMonths > 0) {
    if (years > 0) {
      durationString += " ";
    }
    durationString += `${remainingMonths} ${monthLabel}${
      remainingMonths > 1 ? "s" : ""
    }`;
  }

  return durationString || `0 ${monthLabel}`;
}


    // const calculateEMI = (loanAmount, interestRate, tenure) => {
    //     const interestRatePerMonth = interestRate / 12 / 100;
    //     const months = tenure * 12;
    //     const emi = (loanAmount * interestRatePerMonth * Math.pow(1 + interestRatePerMonth, months)) / (Math.pow(1 + interestRatePerMonth, months) - 1);
    //     //console.log(emi, "emi");

    //     let remainingAmount = loanAmount;
    //     let emiBreakup = [];

    //     for (let i = 1; i <= months; i++) {
    //         const interest = remainingAmount * interestRatePerMonth;
    //         const principal = Math.min(emi - interest, remainingAmount);
    //         remainingAmount -= principal;
    //         const openingBalance = remainingAmount + principal;


    //         emiBreakup.push({
    //             month: i,
    //             emi: Math.round(emi),
    //             openingBalance: Math.round(openingBalance),
    //             interest: Math.round(interest),
    //             currentPrincipal: Math.round(principal),
    //             remainingAmount: Math.round(remainingAmount)
    //         });

    //         if (remainingAmount <= 0) break;
    //     }

    //     return emiBreakup;
    // };

    // const upcomingPayments = calculateEMI(loanAmount,interestRate,tenure)

const [disbursedInfoModal, setDisbursedInfoModal] = useState(false);


  const disburseInfoCardRef = useRef(null);


useEffect(() => {
  const handleClickOutside = (event) => {
    if (
      disburseInfoCardRef.current &&
      !disburseInfoCardRef.current.contains(event.target)
    ) {
      setDisbursedInfoModal(false)
    }
  };

  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, []);

    return (
      <>
        <div
          className="loanSelect"
          style={{
            backgroundColor:
              componenttype !== "preview" ? "white" : "transparent",
          }}
        >
          <div className="loanSelect-container">
            <p className="lonSelect-title">Loan Apply</p>
            <div className="loanSelect-main">
              <div className="loanSelect-right">
                <div className="loanSelect-result-container">
                  <div className="loanSelect-result-wrapper">
                    <div className="loanSelect-result-head ">
                      <p>Loan Amount</p>
                      <p>
                        {loandetails.loanAmount.toLocaleString("en-IN", {
                          style: "currency",
                          currency: "INR",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </p>
                    </div>
                    <div>
                      <div className="loanSelect-result-head-interest ">
                        <p>₹{loandetails.monthlyEMI} EMI</p>
                      </div>
                      <div className="loanSelect-result-head ">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 10,
                          }}
                        >
                          <p>Disbursed Amount</p>
                          <Info
                            onClick={() => setDisbursedInfoModal(true)}
                            className="loanSelect-result-info"
                          />
                        </div>
                        <p>
                          ₹
                          {Math.round(
                            loandetails.loanAmount -
                              (eligibleFee / 100) * loandetails.loanAmount
                          )}
                        </p>
                      </div>
                    </div>
                    {disbursedInfoModal && (
                      <div
                        className="loanSelect-result-disbursed-info-modal"
                        ref={disburseInfoCardRef}
                      >
                        <div className="loanSelect-result-disbursed-info-wrapper-x">
                          <X
                            onClick={() => setDisbursedInfoModal(false)}
                            size={20}
                            style={{
                              marginBottom: 8,
                              marginTop: 10,
                              marginRight: 15,
                            }}
                          />
                        </div>
                        <div className="loanSelect-result-disbursed-info-container">
                          <div className="loanSelect-result-disbursed-info-wrapper-top">
                            <p className="loanSelect-result-disbursed-info-key">
                              Total Amount
                            </p>
                            <p className="loanSelect-result-disbursed-info-value">
                              ₹{loandetails.loanAmount}
                            </p>
                          </div>
                          <div className="loanSelect-result-disbursed-info-wrapper-top">
                            <p className="loanSelect-result-disbursed-info-key">
                              Processing Fee
                            </p>
                            <p className="loanSelect-result-disbursed-info-value">
                              - ₹
                              {Math.round(
                                (eligibleFee / 100) * loandetails.loanAmount
                              )}
                            </p>
                          </div>
                          <div className="loanSelect-result-disbursed-info-wrapper-bottom">
                            <p className="loanSelect-result-disbursed-info-value">
                              ₹
                              {Math.round(
                                loandetails.loanAmount -
                                  (eligibleFee / 100) * loandetails.loanAmount
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="loanSelect-result-wrapper">
                    <div className="loanSelect-result-tail ">
                      <p>
                        ₹
                        {Math.round(
                          (eligibleFee / 100) * loandetails.loanAmount
                        )}
                      </p>
                      <p>Processing Fee</p>
                    </div>
                    {/* <div className="loanSelect-result-tail ">
                      <p>
                        {monthlyEMI.toLocaleString("en-IN", {
                          style: "currency",
                          currency: "INR",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </p>
                      <p>Monthly EMI</p>
                    </div> */}
                    <div className="loanSelect-result-tail ">
                      <p>
                        {actualIntrest(
                          totalInterest,
                          loandetails.loanDuration,
                          loandetails.loanAmount
                        )}
                        %{" "}
                        <sub style={{ fontSize: 10 }}>
                          (APR {loandetails.loanInterestRatePct}%)
                        </sub>
                      </p>
                      <p>Actual Interest Rate</p>
                    </div>

                    <div className="loanSelect-result-tail ">
                      <p>{formatLoanDuration(loandetails.loanDuration)}</p>
                      <p>Tenure</p>
                    </div>

                    <div className="loanSelect-result-tail ">
                      <p>
                        {totalInterest.toLocaleString("en-IN", {
                          style: "currency",
                          currency: "INR",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </p>
                      <p>Total Interest</p>
                    </div>
                  </div>
                </div>
              </div>
              {componenttype !== "preview" && (
                <div className="loanSelect-left">
                  <div className="loanSelect-subHeading">
                    <p className="loanSelect-subHeading-title">Loan Amount</p>
                    <span className="loanSelect-input-container">
                      <p className="loanSelect-input-text">₹</p>
                      <input
                        className="loanSelect-input"
                        value={loandetails.loanAmount}
                        maxLength={8}
                        pattern="[0-9]*"
                        type="tel"
                        disabled={true}
                        // onInput={(e) => {
                        //   let newValue = e.target.value.replace(/[^0-9]/g, "");
                        //   if (newValue > eligibleLoanAmount) {
                        //     newValue = eligibleLoanAmount;
                        //   }
                        //   setloandetails({
                        //     ...loandetails,
                        //     loanAmount: newValue,
                        //     processingCharges: Math.round(
                        //       (eligibleFee / 100) * newValue
                        //     ),
                        //   });
                        // }}
                        placeholder="0"
                      />
                    </span>
                  </div>
                  <div className="loanSelect-slider">
                    <Slider
                      min={minLoanAmount}
                      max={eligibleLoanAmount}
                      initialValue={
                        loandetails.loanAmount === 0
                          ? eligibleLoanAmount
                          : loandetails.loanAmount
                      }
                      step={10000}
                      onChange={handleLoanAmountChange}
                      disable={sliderDisable}
                    />
                  </div>

                  <div className="loanSelect-subHeading">
                    <p className="loanSelect-subHeading-title">
                      Loan Tenure(month)
                    </p>
                    <span
                      className="loanSelect-input-container"
                      style={{ cursor: "default" }}
                    >
                      {formatLoanDuration(loandetails.loanDuration)}
                    </span>
                  </div>
                  <div className="loanSelect-slider m-0 ">
                    <Slider
                      min={eligibleMinTenure}
                      max={eligibleTenure}
                      initialValue={
                        loandetails.loanDuration === 0
                          ? eligibleTenure
                          : loandetails.loanDuration
                      }
                      step={3}
                      onChange={handleTenureChange}
                    />
                  </div>
                  <div className="loanSelect-subHeading">
                    <p className="loanSelect-subHeading-title">
                      Rate of interest(p.a)
                    </p>
                    <span
                      className="loanSelect-input-container"
                      style={{ cursor: "default" }}
                    >
                      <p className="loanSelect-input-text">
                        {eligibleInterest}
                      </p>
                      <p className="loanSelect-input-text">%</p>
                    </span>
                  </div>
                  <div
                    className="loanSelect-subHeading"
                    style={{ marginTop: 50 }}
                  >
                    <p className="loanSelect-subHeading-title">
                      Processing Fee
                    </p>
                    <span
                      className="loanSelect-input-container"
                      style={{ cursor: "default" }}
                    >
                      <p className="loanSelect-input-text">
                        ₹
                        {Math.round(
                          (eligibleFee / 100) * loandetails.loanAmount
                        )}
                      </p>
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  } 
export default Loanselect;
