import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "../../styles/screenStyles/UserInfo.css";
import Dropdown from "../../components/DropDown";
import InputField from "../../components/InputField";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet"; // Import Leaflet
import { Calendar, Check, Edit, Map, MapPin, Plus, Search, XCircle } from "react-feather";
import DateTimePicker from "../../components/DateTimePicker";
import Modal from "../../components/Modal";
import OtpInput from "../../components/OtpInput";
import Alert from "../../components/Alert";
import { validationRegex } from "../../utlis/Regex";
import FileUpload from "../../components/FileUpload";
import { MyContext } from "../../components/GlobalValues";
import Loading from "../../components/Loading";
import ModalAddress from "../../components/ModalAddress";
import { useLocation, useNavigate } from "react-router-dom";
import Metrixfunction from "../../components/MetrixFunction";
import AssetDisplay from "../../components/AssetDisplay";

function UserInfo({ component, componenttype }) {
  const [loadingscreen, setloading] = useState(true);
  const accountId = localStorage.getItem("accId");
  const apiKey = localStorage.getItem("apiKey");

  const location=useLocation()

  const {
    Url,
    utilityFile,
    setUtilityFile,
    userinfo,
    setuserinfo,
    userverify,
    setuserverify,
    getUserInfo,
    usercurrentPosition,
    setCurrentuserPosition,
    updateuserinfo,
    setsameAddress,
    sameAddress,
    windowWidth,
    getFileNameFromUrl,
    workinfo,
    metrixserver
  } = useContext(MyContext);

  const MaritalOption = ["Single", "Married", "Divorced", "Widowed"];
  const bloodTypes = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

  const residenceOptions = [
    "Rental",
    "Paying Guest",
    "Parental Property",
    "Staying with Relatives",
    "Other",
  ];

  const utilityBills = [
    "Electricity Bill",
    "Water Bill",
    "Gas Bill",
    "Internet Bill",
    "Property Tax Bill",
  ];
  const today = new Date();

  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "",
    duration: 3000, // Default duration
  });

  const navigate=useNavigate()
  const [isZooming, setIsZooming] = useState(false);
  const [resetDateTime, setResetDateTime] = useState(true);

  function removeSlash(port1) {
    let p1 = port1.split("/").join("");

    let np = p1;
    return np;
  }


  const showAlert = (message, type, duration = 3000) => {
    setAlert({
      show: true,
      message,
      type,
      duration,
    });

    // Automatically close the alert after the duration
    setTimeout(() => {
      setAlert((prevAlert) => ({
        ...prevAlert,
        show: false,
      }));
    }, duration);
  };

  const [haveotpshow, sethaveotp] = useState(false);

  const [resendotp, setresentotp] = useState(false);

  const [resenttime, setresenttime] = useState(60);

  const [verifybtn, setverifybtn] = useState(false);
  const [isFocusedDatePicker, setIsFocusedDatePicker] = useState(false);


  // const [userinfo, setuserinfo] = useState(

  //     {
  //       "loanStatus": "",
  //       "userName": "",
  //       "userEmail": "",
  //       "userDOB": "",
  //       "currentCountry": "",
  //       "currentState": "",
  //       "currentCity": "",
  //       "currentDistrict": "",
  //       "currentPincode": "",
  //       "currentHouseNo": "",
  //       "currentStreet": "",
  //       "currentLandmark": "",
  //       "permanentCountry": "",
  //       "permanentState": "",
  //       "permanentCity": "",
  //       "permanentDistrict": "",
  //       "permanentPincode": "",
  //       "permanentHouseNo": "",
  //       "permanentStreet": "",
  //       "permanentLandmark": "",
  //       "userProfilePic": "",
  //       "userAge": 0,
  //       "userGender": "",
  //       "userFatherName": "",
  //       "userMotherName": "",
  //       "userMaritalStatus": "",
  //       "userCommunity": "",
  //       "userNationality": "",
  //       "userBloodGroup": "",
  //       "userEducation": "",
  //       "userResidentialOwnerShip":"",
  //       "passcode": "SG5kQjZEVVV1ajBRN29jQytqZldIUT09",

  //   })

  const [isFocused, setIsFocused] = useState(false);

  const [componentedit, setcomponentedit] = useState(true);

  window.addEventListener("click", () => {
    setIsFocused(false);
  });

  const [showDropdown, setShowDropdown] = useState({
    date: false,
  });

  const DropdownRef = useRef({
    date: null,
  });

  const validationref = useRef({
    userName: null,
    userName: null,
    userDOB: null,
    userAddress: null,
    userEmail: null,
    userMaritalStatus: null,
    userBloodGroup: null,
    userFatherName: null,
    userMotherName: null,
    userNationality: null,
    userEducation: null,
    userLatitude: null,
    userLongitude: null,
  });

  const condref = useRef(false);


  const [emailotp, setemailotp] = useState("");

  const [otpModalOpen, setotpModalOpen] = useState(false);

  const [timeInterval, setTimeInterval] = useState(null);

   const [otpedit,setotpedit]=useState(true)

  const openotpModal = (haveotp) => {
    setotpModalOpen(true);
  
    !haveotp && setresentotp(false);
  };
  const closeotpModal = () => {
    setotpModalOpen(false);
    setTimeout(() => {
      setresentotp(true);
    }, 1000);
  };

  const otpmodalref = useRef(null);

  const handleemailotp = (value) => {
    setemailotp(value);
    if (value.length === 6) {
      handleverifyotp(value);
    }
  };

  const [map, setMap] = useState(null);

  const currentmarkerRef = useRef(null);
  const dateTimePickerRef = useRef(null);


  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.keys(DropdownRef.current).forEach((key) => {
        if (
          DropdownRef.current[key] &&
          !DropdownRef.current[key].contains(event.target)
        ) {
          
          setShowDropdown((prev) => ({ ...prev, [key]: false }));
        }
      });
      if (
        dateTimePickerRef.current &&
        !dateTimePickerRef.current.contains(event.target)
      ) {
        setIsFocusedDatePicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [validationErrors, setValidationErrors] = useState({
    userName: "",
    userEmail: "",
    userDOB: "",
    currentCountry: "",
    currentState: "",
    currentCity: "",
    currentDistrict: "",
    currentPincode: "",
    currentHouseNo: "",
    currentStreet: "",
    currentLandmark: "",
    permanentCountry: "",
    permanentState: "",
    permanentCity: "",
    permanentDistrict: "",
    permanentPincode: "",
    permanentHouseNo: "",
    permanentStreet: "",
    permanentLandmark: "",
    userGender: "",
    userFatherName: "",
    userMotherName: "",
    userMaritalStatus: "",
    userCommunity: "",
    userNationality: "",
    userBloodGroup: "",
    userEducation: "",
    userResidentialOwnerShip: "",
    userLatitude: "",
    userLongitude: "",
    utilityBillType: "",
    utilityBillImgUrl: "",
    userGST:""
  });

  const fieldConfigurations = {
    userName: { label: "User Name", minLength: 2 },
    userEmail: { label: "Email", minLength: 3 },
    userDOB: { label: " DOB", minLength: 3 },
    currentCountry: { label: "Current Country", minLength: 2 },
    currentState: { label: "Current State", minLength: 2 },
    currentCity: { label: "Current City", minLength: 2 },
    currentDistrict: { label: "Current District", minLength: 2 },
    currentPincode: { label: "Current Pincode", minLength: 6 },
    currentHouseNo: { label: "Current House No", minLength: 2 },
    currentStreet: { label: "Current Street", minLength: 1 },
    // currentArea: { label: "Current Area", minLength: 1 },
    currentLandmark: { label: "Current Landmark", minLength: 2 },
    permanentCountry: { label: "Permanent Country", minLength: 2 },
    permanentState: { label: "Permanent State", minLength: 2 },
    permanentCity: { label: "Permanent City", minLength: 2 },
    permanentDistrict: { label: "Permanent District", minLength: 2 },
    permanentPincode: { label: "Permanent Pincode", minLength: 6 },
    permanentHouseNo: { label: "Permanent House No", minLength: 1 },
    permanentStreet: { label: "Permanent Street", minLength: 2 },
    // permanentStreet: { label: "Permanent Area", minLength: 2 },
    permanentLandmark: { label: "Permanent Landmark", minLength: 2 },
    userGender: { label: " Gender", minLength: 3 },
    userFatherName: { label: " Father Name", minLength: 2 },
    userMotherName: { label: " Mother Name", minLength: 2 },
    userMaritalStatus: { label: " Marital Status", minLength: 3 },
    userCommunity: { label: " Religion", minLength: 2 },
    userNationality: { label: " Nationality", minLength: 2 },
    userBloodGroup: { label: " Blood Group", minLength: 1 },
    userEducation: { label: " Education", minLength: 2 },
    userResidentialOwnerShip: { label: " Residential Ownership", minLength: 3 },
    userLatitude: { label: " Latitude", minLength: 3 },
    userLongitude: { label: " Longitude", minLength: 3 },
    utilityBillType: { label: "Utility Bill Type", minLength: 3 },
    utilityBillImgUrl: { label: "Utility Bill Image", minLength: 3 },
    userGST: { label: "GST", minLength: 15 },
  };

  useEffect(() => {
    setUtilityFile([]);
  }, [userinfo.utilityBillType]);

  useEffect(() => {
    if (userinfo["utilityBillImgUrl"] === "" && utilityFile?.length > 0) {
      setuserinfo((prev) => ({
        ...prev,
        utilityBillImgUrl: utilityFile[0]?.url,
      }));
    }
  }, [utilityFile]);

  const handleUserInfo = (value, field) => {
    let errorMessage = "";
    const regex = validationRegex[field];

     

    const minCriteria = fieldConfigurations[field];

     

    if (value == "" || (regex && !regex.test(value))) {
      if (
        field === "userName" ||
        field === "userEducation" ||
        field === "userFatherName" ||
        field === "userMotherName" ||
        field === "userCommunity" ||
        field === "userNationality"
      ) {
        errorMessage =
          value?.length >= 2
            ? `Invalid ${minCriteria.label}. `
            : `Please enter valid ${minCriteria.label}`;
      }

      if (field === "userEmail") {
        errorMessage =
          value != ""
          ? `Invalid ${minCriteria.label}. `
          : `Please enter valid ${minCriteria.label}`;
      }
      if (field === "userGST") {
        if(value!=="")
          {
            errorMessage =
              value != ""
              ? `Invalid ${minCriteria.label}. `
              : `Please enter valid ${minCriteria.label}`;
          }
        
      }

      if (
        field === "userMaritalStatus" ||
        field === "userBloodGroup" ||
        field === "userDOB" ||
        field === "utilityBillType" ||
        field === "userResidentialOwnerShip"
      ) {
        errorMessage = `Please select ${minCriteria.label}`;
      }

      // if (
      //   field === "currentHouseNo" ||
      //   field === "currentStreet" ||
      //   field === "currentCity" ||
      //   field === "currentDistrict" ||
      //   field === "currentState" ||
      //   field === "currentCountry" ||
      //   field === "currentPincode" ||
      //   field === "currentLandmark"
      // ) {
      //   let length;
      //   length = field === "currentPincode" ? 6 : 2;
      //   errorMessage =
      //     value?.length >= length
      //       ? `Invalid ${minCriteria.label}.`
      //       : `${minCriteria.label} must have minimum ${minCriteria.minLength} characters. `;
      // }

      // if (
      //   field === "permanentHouseNo" ||
      //   field === "permanentStreet" ||
      //   field === "permanentCity" ||
      //   field === "permanentDistrict" ||
      //   field === "permanentState" ||
      //   field === "permanentCountry" ||
      //   field === "permanentPincode" ||
      //   field === "permanentLandmark"
      // ) {
      //   let length;
      //   length = field === "currentPincode" ? 6 : 2;
      //   errorMessage =
      //     value?.length >= length
      //       ? `Invalid ${minCriteria.label}.`
      //       : `${minCriteria.label} must have minimum ${minCriteria.minLength} characters.`;
      // }

      if (userinfo["utilityBillType"] != "" && field === "userUtilityBill") {
        errorMessage = `Please upload ${minCriteria.label}`;
      }
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [field]: errorMessage,
    }));

    setuserinfo((prevState) => ({
      ...prevState,
      [field]: value, // Use computed property names to dynamically set the key
    }));

     
  };

  const convertDateFormat = (dateStr) => {
    const [day, month, year] = dateStr.split('/');
    return `${month}/${day}/${year}`;
  };


  const handleChangeDateFormat = (e) => {
    handleUserInfo(e, "userDOB");
  };

  const handledateclick = () => {
    setShowDropdown({ ...showDropdown, date: false });
  };

  const handleUploadFiles = (value, field) => {
     
  };

  useEffect(() => {
    
    if (
      (component && componenttype === "preview") ||
      (component && componenttype === "loanpreview")
    ) {
      setcomponentedit(false);
    }
    const userDOB = new Date(userinfo.userDOB);
    const isValidDate = !isNaN(userDOB);


  }, []);

  const otpfocus=()=>{
    if (otpModalOpen && modalref.current) {
      const inputElement = modalref.current.querySelector(".otp-dummy-input");
      if (inputElement) {
        inputElement.focus();
      }
    }
  }

  useEffect(() => {
     
     otpfocus()
  }, [otpModalOpen]);

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    
    setModalOpen(true)
    setAddressValidState((prev) => ({ ...prev,"tempAddress": false }));
    document.body.classList.add("no-scroll");

  };
  const closeModal = () => {
    setModalOpen(false);
    setAddressValidState((prev) => ({ ...prev,"tempAddress": true }));
    document.body.classList.remove("no-scroll");
  };

  const modalref = useRef(null);
  const [permanentModalOpen, setPermanentModalOpen] = useState(false);

  const openPermanentModal = () => {
       
    setPermanentModalOpen(true)
    setAddressValidState((prev) => ({ ...prev,"permAddress": false }));
    document.body.classList.add("no-scroll");
  }
  const closePermanentModal = () => {
    setPermanentModalOpen(false)
    setAddressValidState((prev) => ({ ...prev,"permAddress": true }));
    document.body.classList.remove("no-scroll");
  };

  const [addressValidState, setAddressValidState] = useState({
    tempAddress: true,
    permAddress: true,
  });

  // let pageOne = document.getElementsByClassName("pageOne")[0];
  // let pageTwo = document.getElementsByClassName("pageTwo")[0];

  const pageOneRef = useRef(null);
  const pageTwoRef = useRef(null);

 
  useEffect(() => {
    if (isModalOpen && pageTwoRef.current) {
      // Ensure the modal is focused when it's opened
      pageTwoRef.current.focus();
    }
  }, [isModalOpen]);

 

  const [AddressPageNum,setAddressPageNum] = useState(0); 

  const handleAddAddress = (addressCategory,pageNum) => {

     
    let tempAddressObj = {
      // currentCountry: "",
      currentState: "",
      currentCity: "",
      currentDistrict: "",
      currentPincode: "",
      currentHouseNo: "",
      currentStreet: "",
      // currentArea:"",
      currentLandmark: "",
    };

    let permanentAddressObj = {
      // permanentCountry: "",
      permanentState: "",
      permanentCity: "",
      permanentDistrict: "",
      permanentPincode: "",
      permanentHouseNo: "",
      permanentStreet: "",
      // permanentArea:"",
      permanentLandmark: "",
    };

    

   

    if (addressCategory === "temp" && pageNum > 0 ) {

      // let resvalidTemp = handleAllInputsValid(tempAddressObj);
      let resvalidTemp = handleaddressvalid(tempAddressObj);
       

       

      if (resvalidTemp && resvalidTemp.isValid ) {


        let addressKeysTemp = Object.keys(tempAddressInputField);

         

        addressKeysTemp.forEach((key)=>{
           

        setuserinfo((prev)=>({...prev, [key]:tempAddressInputField[key]   }))

        })
        closeModal();
        setIsLocationFetched(false);
        // setAddressValidState((prev) => ({ ...prev,"tempAddress": true }));
         
      }else{
        showAlert("Please fill all the inputs", "danger");
        
      }
    }else{
    setAddressPageNum(1);
    pageOneRef.current.style.transform = "translate(-120%)";
    pageTwoRef.current.style.transform = "translate(0)";
    }


    if (addressCategory === "permanent") {
      let resvalidPermanent = handleaddressvalid(permanentAddressObj);

      if (resvalidPermanent && resvalidPermanent.isValid ) {
       
        let addressKeysPerm = Object.keys(permanentAddressInputField);

        addressKeysPerm.forEach((key)=>{

          userinfo[key] = permanentAddressInputField[key];

      })
      closePermanentModal();
        // setAddressValidState((prev) => ({ ...prev, "permAddress": true }));
      }else{
        showAlert("Please fill all the inputs", "danger");
        // setAddressValidState((prev) => ({ ...prev, "permAddress": false }));
      }
    }

   
    


  };

  const handleBack = ()=>{
   

    // setIsLocationFetched(false)
      setAddressPageNum(0);
      pageOneRef.current.style.transform = "translate(0)";
      pageTwoRef.current.style.transform = "translate(150%)";
  }

  const getAddressString = (address) => {
    openModal();
    const addressString = Object.values(address)
      .filter((value) => value) // Filter out empty values
      .join(", "); // Join the remaining values with a comma and space

    handleUserInfo(addressString);
  };

  const [ownership, setOwnership] = useState(false);

  const handleChangeOwnership = (event) => {
    let isOwned = event.target.value;

    if (isOwned === "Yes") {
      setOwnership(false);
      handleUserInfo("Owned", "userResidentialOwnerShip");
    } else {
      // handleUserInfo("No", "userResidentialOwnerShip");
      setuserinfo((prev) => ({ ...prev, userResidentialOwnerShip: "No" }));
      setOwnership(true);
    }
  };

  const mapcondref=useRef(false)
  const customIcon = new L.Icon({
    iconUrl: "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/gif/pinmap.gif", // Replace with the path to your custom icon image
    iconSize: [45, 45], // Size of the icon
    iconAnchor: [16, 32], // Anchor point of the icon
    popupAnchor: [0, -32], // Popup anchor point
  });

  const onMove = useCallback(() => {
     
    if ( mapcondref.current) return;
    const newCenter = map.getCenter();
    currentmarkerRef.current.setLatLng(newCenter);
  }, [map]);

  const Moveend = useCallback(async () => {
    
    if ( mapcondref.current) return;
    const newCenter = map.getCenter();
    let latitude = newCenter.lat.toFixed(13);
    let longitude = newCenter.lng.toFixed(13);


    // addressField.forEach((obj,index)=>{

    //   let key = Object.keys(obj)[0];

    //   setuserinfo((prev) => ({
    //     ...prev,
    //     [key]: "",
    //   }));
    // })

    settempAddressInputField((prev) => ({
          ...prev,
          "currentHouseNo":"","currentStreet":"","currentLandmark":""
        }));

    setCurrentuserPosition({
      ...usercurrentPosition,
      userLatitude: latitude,
      userLongitude: longitude,
    });

    addressfetch(latitude,longitude);

  }, [map]);

  const handleZoomStart = useCallback(() => {
    mapcondref.current=true
    
    
  }, []);

  const handleZoomEnd = useCallback(() => {
   

    mapcondref.current=false
   
    

  }, [map]);

  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }
  const debouncedHandleZoomEnd = useRef(
    debounce(() => {
      handleZoomEnd();
    }, 1500) // Adjust the delay (in milliseconds) as needed
  ).current;
  
  // Use this function to attach to the map's zoom end event
  


  useEffect(() => {
    if (map) {
      map.on("move", onMove);
      map.on("moveend", Moveend);
      map.on("zoomstart", handleZoomStart);
      map.on("zoomend", debouncedHandleZoomEnd);
      return async () => {
        map.off("moveend", Moveend);
        map.off("move", onMove);
        map.off("zoomstart", handleZoomStart);
        map.off("zoomend", handleZoomEnd);
      };
    }
  }, [map]);

  useEffect(() => {
    if (navigator.geolocation) {
      const { state } = navigator.permissions.query({ name: "geolocation" });
      

      navigator.geolocation.getCurrentPosition(
        (position) => {
          

          let latitude = position.coords.latitude;
          let longitude = position.coords.longitude;
          
          setCurrentuserPosition({
            ...usercurrentPosition,
            userLatitude: latitude.toFixed(13),
            userLongitude: longitude.toFixed(13),
          });
        },
        (err) => {
          // setError(err.message);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    } else {
    }
  }, []);

  const getdetails = async () => {

    let resJson = await getUserInfo();

     

    try {
      if (resJson.Error) {
        showAlert(resJson.Error, "danger");
        if(resJson.Error==="User not found")
          {
            localStorage.clear();
            navigate("/login")
          }
          else{
            navigate("/dashboard")
          }
         
      }
       else {
        if (component && componenttype === "stepper") {
          setuserinfo({
            ...userinfo,
            userEmail: resJson.Success.userEmail,
          });
        } else if (component && componenttype === "loanapply") {
     

          if (resJson.Success.userResidentialOwnerShip === "") {
            resJson.Success.userResidentialOwnerShip = "Owned";
          }

           
          

          setuserinfo(resJson.Success);

        

        } else {
          if (resJson.Success.userResidentialOwnerShip === "") {
            resJson.Success.userResidentialOwnerShip = "Owned";
          }
           
          setuserinfo(resJson.Success);
          
        }
        

        if (
          resJson.Success.currentCity === resJson.Success.permanentCity &&
          resJson.Success.currentCountry === resJson.Success.permanentCountry &&
          resJson.Success.currentDistrict ===
            resJson.Success.permanentDistrict &&
          resJson.Success.currentHouseNo === resJson.Success.permanentHouseNo &&
          resJson.Success.currentStreet === resJson.Success.permanentStreet &&
          resJson.Success.currentState === resJson.Success.permanentState &&
          resJson.Success.currentPincode === resJson.Success.permanentPincode &&
          resJson.Success.currentLandmark ===
            resJson.Success.permanentLandmark &&
          resJson.Success.currentCity !== "" &&
          resJson.Success.currentCountry !== "" &&
          resJson.Success.currentDistrict !== "" &&
          resJson.Success.currentHouseNo !== "" &&
          resJson.Success.currentStreet !== "" &&
          resJson.Success.currentState !== ""
        ) {
           
          setsameAddress("Yes");
        } else if (
          resJson.Success.permanentCountry !== "" &&
          resJson.Success.permanentState !== "" &&
          resJson.Success.permanentCity !== "" &&
          resJson.Success.permanentDistrict !== "" &&
          resJson.Success.permanentPincode !== "" &&
          resJson.Success.permanentHouseNo !== "" &&
          resJson.Success.permanentStreet !== "" &&
          resJson.Success.permanentLandmark !== ""
        ) {
           

          setsameAddress("No");
        }

        if (resJson.Success.userEmail?.length > 0) {
          setuserverify(true);
        }

        if (
          resJson.Success.userLatitude !== 0 &&
          resJson.Success.userLongitude !== 0
        ) {
          setCurrentuserPosition({
            ...usercurrentPosition,
            userLatitude: resJson.Success.userLatitude,
            userLongitude: resJson.Success.userLongitude,
          });
        }
      }
     
      if(!resJson.Success.userEditable)
        {
          setcomponentedit(false)
         
        }
         setloading(false);
         Metrixfunction(metrixserver,"getDetails","ONLOAD")

    } catch (err) {
      console.log(err, "error");
    }
  };

  
   const handleChange = (event, option) => {
    if (option === "address") {
      //console.log("changed");
      setsameAddress(event.target.value);

      if (event.target.value === "Yes") {
        setuserinfo((prevState) => ({
          ...prevState,
          permanentCountry: prevState.currentCountry,
          permanentCity: prevState.currentCity,
          permanentState: prevState.currentState,
          permanentDistrict: prevState.currentDistrict,
          permanentPincode: prevState.currentPincode,
          permanentHouseNo: prevState.currentHouseNo,
          permanentStreet: prevState.currentStreet,
          permanentLandmark: prevState.currentLandmark,
        }));
      } else {
        setuserinfo((prevState) => ({
          ...prevState,
          permanentCountry: "",
          permanentCity: "",
          permanentState: "",
          permanentDistrict: "",
          permanentPincode: "",
          permanentHouseNo: "",
          permanentStreet: "",
          permanentLandmark: "",
        }));
      }
    }
  };

  // Define the payload
  const payload = {
    accId: accountId,
    userName: userinfo.userName,
    apiKey: apiKey,
    userDOB: userinfo.userDOB,
    currentCountry: userinfo.currentCountry,
    currentCity: userinfo.currentCity,
    currentState: userinfo.currentState,
    currentDistrict: userinfo.currentDistrict,
    currentPincode: userinfo.currentPincode,
    currentHouseNo: userinfo.currentHouseNo,
    currentStreet: userinfo.currentStreet,
    currentLandmark: userinfo.currentLandmark,
    permanentCountry: userinfo.permanentCountry,
    permanentCity: userinfo.permanentCity,
    permanentState: userinfo.permanentState,
    permanentDistrict: userinfo.permanentDistrict,
    permanentPincode: userinfo.permanentPincode,
    permanentHouseNo: userinfo.permanentHouseNo,
    permanentStreet: userinfo.permanentStreet,
    permanentLandmark: userinfo.permanentLandmark,
    userLatitude: usercurrentPosition.userLatitude,
    userLongitude: usercurrentPosition.userLongitude,
    userFatherName: userinfo.userFatherName,
    userMotherName: userinfo.userMotherName,
    userMaritalStatus: userinfo.userMaritalStatus,
    userCommunity: userinfo.userCommunity,
    userNationality: userinfo.userNationality,
    userBloodGroup: userinfo.userBloodGroup,
    userEducation: userinfo.userEducation,
    userResidentialOwnerShip: userinfo.userResidentialOwnerShip,
    utilityBillType: userinfo.utilityBillType,
    utilityBillImgUrl: userinfo.utilityBillImgUrl,
  };

  const handleEditUploadImage = (uploadType) => {
    // switch (uploadType) {
    //   case "IdCard":
    //     //console.log("handleEditUploadImage working")
    //     setworkinfo((prev)=> ({...prev,companyIdPhoto:""}))
    //     setEmpIdFile([]);
    //     break;

    if (uploadType === "utilityBill") {
      setuserinfo((prev) => ({ ...prev, utilityBillImgUrl: "" }));
      setUtilityFile([]);
    }
  };

  // //console.log("Payload", payload)

  const handleaddressvalid=(ObjSchema)=>{
    let valid = true;
    let responseObj = {
      isValid: true,
      errorField: "",
      errorKeyvalue:"",
    };
    let errorMessage="";
    let errorKey="";


    const keys = Object.keys(ObjSchema);

    
    for (let index = 0; index < keys?.length; index++) {
      let key = keys[index];
    
       
        let value = "";
        const regex = validationRegex[key];

        // if(!addressValidState.tempAddress && addressValidState.permAddress){
          //console.log("addressValidState.tempAddress",addressValidState.tempAddress);
          //console.log("addressValidState.permAddress",addressValidState.permAddress);

        
        
        if(key.startsWith("current") || key.startsWith("permanent")){
          
          if(key.startsWith("current")){
              value = tempAddressInputField[key];
          }else{
              value = permanentAddressInputField[key];
          }
        
        }
       

        const minCriteria = fieldConfigurations[key];


        
        if (value === "" || (regex && !regex.test(value))) {
          
    
        
        
    
        
    
          if (
            key === "currentHouseNo" ||
            key === "currentStreet" ||
            // key === "currentArea"||
            key === "currentCity" ||
            key === "currentDistrict" ||
            key === "currentState" ||
            // key === "currentCountry" ||
            key === "currentPincode" ||
            key === "currentLandmark"
          ) {
            valid = false;
            let length;
            length = key === "currentPincode" ? 6 : 2;
            errorMessage =
              value?.length >= length
              ? `Invalid ${minCriteria.label}.`
              : `Please enter valid ${minCriteria.label}`;
                errorKey= key;
                break;
          }
    
          if (
            key === "permanentHouseNo" ||
            key === "permanentStreet" ||
            // key === "permanentArea" ||
            key === "permanentCity" ||
            key === "permanentDistrict" ||
            key === "permanentState" ||
            // key === "permanentCountry" ||
            key === "permanentPincode" ||
            key === "permanentLandmark"
          ) {
            valid = false;
            let length;
            length = key === "currentPincode" ? 6 : 2;
            errorMessage =
              value?.length >= length
              ? `Invalid ${minCriteria.label}.`
              : `Please enter valid ${minCriteria.label}`;
                errorKey= key;
                break;
          }
    
        
        }
       

      
      
    }
    //console.log("arrayy", valid);
    // emptyFields?.length > 0  && seterrorField(emptyFields[0])


    if (!valid) {
      responseObj.isValid = valid;
      responseObj.errorField =errorMessage;
      responseObj.errorKeyvalue = errorKey;

    }

    return responseObj;
  }

  const handleAllInputsValid = (ObjSchema) => {
    //console.log("initial User info 3", initialuserinfo);
    //console.log(ObjSchema,"valuee")
    let valid = true;
    let responseObj = {
      isValid: true,
      errorField: "",
      errorKeyvalue:"",
    };
    let errorMessage="";
    let errorKey="";


    const keys = Object.keys(ObjSchema);

    
    for (let index = 0; index < keys?.length; index++) {
      let key = keys[index];
      if (
        key !== "loanStatus" &&
        key !== "userProfilePic" &&
        key !== "userAge" &&
        key !== "userGender" &&
        key !== "passcode" &&
        key !== "userLatitude" &&
        key !== "userLongitude"
      ) {
       
        let value = "";
        const regex = validationRegex[key];

        // if(!addressValidState.tempAddress && addressValidState.permAddress){
          //console.log("addressValidState.tempAddress",addressValidState.tempAddress);
          //console.log("addressValidState.permAddress",addressValidState.permAddress);

        
        
      
          value =userinfo[key];
        
       

        const minCriteria = fieldConfigurations[key];


        
        if (value === "" || (regex && !regex.test(value))) {
           if (
            key === "userName" ||
            key === "userEducation" ||
            key === "userFatherName" ||
            key === "userMotherName" ||
            key === "userCommunity" ||
            key === "userNationality"
          ) {
            valid = false;
            errorMessage =
              value?.length >= 2
                ? `Invalid ${minCriteria.label}.`
                : `Please enter valid ${minCriteria.label}`;
                errorKey= key;
                break;
          }
    
          if (key === "userEmail") {
            valid = false;
            errorMessage =
              value !== ""
                ? `Invalid ${minCriteria.label}.`
                : `Please enter valid ${minCriteria.label}`;
                errorKey= key;
                break;
          }
          if (key === "userGST") {
            if(userinfo[key].length!==0)
              {

                valid = false;
                errorMessage =
                  value !== ""
                  ? `Invalid ${minCriteria.label}.`
                  : `Please enter valid ${minCriteria.label}`;
                    break;
              }
          }
    
          if (
            key === "userMaritalStatus" ||
            key === "userBloodGroup" ||
            key === "userDOB" ||
            key === "utilityBillType" ||
            key === "userResidentialOwnerShip"
          ) {
            //console.log(userinfo[key],"errmsggggg")
            valid = false;
            errorMessage = `Please select ${minCriteria.label}`;
            errorKey= key;
            break;
          }
    
          if (
            key === "currentHouseNo" ||
            key === "currentStreet" ||
            // key === "currentArea"||
            key === "currentCity" ||
            key === "currentDistrict" ||
            key === "currentState" ||
            // key === "currentCountry" ||
            key === "currentPincode" ||
            key === "currentLandmark"
          ) {
            valid = false;
            let length;
            length = key === "currentPincode" ? 6 : 2;
            errorMessage =
              value?.length >= length
              ? `Invalid ${minCriteria.label}.`
              : `Please enter valid ${minCriteria.label}`;
                errorKey= key;
                break;
          }
    
          if (
            key === "permanentHouseNo" ||
            key === "permanentStreet" ||
            // key === "permanentArea" ||
            key === "permanentCity" ||
            key === "permanentDistrict" ||
            key === "permanentState" ||
            // key === "permanentCountry" ||
            key === "permanentPincode" ||
            key === "permanentLandmark"
          ) {
            valid = false;
            let length;
            length = key === "currentPincode" ? 6 : 2;
            errorMessage =
              value?.length >= length
              ? `Invalid ${minCriteria.label}.`
              : `Please enter valid ${minCriteria.label}`;
                errorKey= key;
                break;
          }
    
          if (userinfo["utilityBillType"] != "" && key === "userUtilityBill") {
            valid = false;
            errorMessage = `Please upload ${minCriteria.label}`;
            errorKey= key;
            break;
          }
        }
       

        if (key === "userResidentialOwnerShip" && userinfo[key] === "No") {
          valid = false;
          errorMessage = `Please upload ${minCriteria.label}`;
          errorKey= key;
          break;
        }

        if(key==="utilityBillImgUrl" && (utilityFile.length<1 && userinfo[key].length<1))
          {
            valid = false;
          errorMessage = `Please upload ${minCriteria.label}`;
          errorKey= key;
          break;
          }
      }
    }
    //console.log("arrayy", valid);
    // emptyFields?.length > 0  && seterrorField(emptyFields[0])


    if (!valid) {
      responseObj.isValid = valid;
      responseObj.errorField =errorMessage;
      responseObj.errorKeyvalue = errorKey;

    }

    return responseObj;
  };

  // Function to make the API request
  async function sendUserInfo() {
    //console.log(payload, "payloadd");
    //console.log("initial User info final", initialuserinfo);
    const resvalid = handleAllInputsValid(userinfo);
     //console.log("isvalid,errorField",resvalid );

    if (resvalid && resvalid.isValid) {
      if (userverify) {
        try {

          const data = await updateuserinfo(removeSlash(location.pathname));
          if (data.Success) {
            setloading(true);
            getdetails();
            showAlert("Profile Updated", "success");
          } else if (data.Warning) {
            showAlert(data.Warning, "danger");
          } else {
            showAlert(data.Error, "danger");
          }
        } catch (err) {
          console.log(err, "error");
        }
      } else {
        showAlert("Please Verify Email before proceed", "danger");
      }
    }else {
      // setAddressValidState((prev) => ({ ...prev, "permAddress": false }));
      // setAddressValidState((prev) => ({ ...prev,"tempAddress": false }));
      if(resvalid.errorKeyvalue.startsWith("current")){
        showAlert(
          "Kindly Add Current House Address",
          "danger"
        );
      }else if(resvalid.errorKeyvalue.startsWith("permanent")){
        showAlert(
          "Kindly Add Permanent House Address",
          "danger"
        );
      }else{
        showAlert(
          resvalid.errorField,
          "danger"
        );
      }
      
    }
  }

  // Call the function to send the data
  // sendUserInfo();

  const [pincodeError, setPincodeError] = useState("");

  // const validatePincode = async (pincode) => {
 

  //   if (pincode?.length === 6) {
    
  //       setPincodeError("");
  //       try {
  //         const response = await fetch(
  //           `${Url}/business/isPincodeValid?pincode=${pincode}`
  //         );
  //         const resJson = await response.json();
  //         //console.log("pincode response:", resJson);
  //         if (resJson.Error) {
  //           setPincodeError(resJson.Error);
  //         } else {
  //           setPincodeError("");
  //           // setuserinfo((prevState) => ({
  //           //   ...prevState,
  //           //   permanentPincode: pincode,
  //           // }));
  //         }
  //       } catch (error) {
  //         //console.log("Error:", error);
  //         setPincodeError("Unable to verify Pincode,Try again later");
  //       }
      
  //   } else {
  //     setPincodeError("   ");

  //   }
  // };

  // joel codes

  const [emailError, setEmailError] = useState("");
  const [otpError, setOtpError] = useState("");

  useEffect(() => {
    if (component) {
      if (!(componenttype === "preview" || componenttype === "loanpreview")) {
        getdetails();

      } else {
        setloading(false);
      }
    } else {
      getdetails();
      
    }
  }, []);

  const verifyEmail = async () => {
    //console.log("userinfo :", userinfo.userEmail);
    const emailRegex = /^[\w.%+-]+@(gmail|outlook|yahoo|hotmail)\.com$/;
    const accId = localStorage.getItem("accId");
    const apiKey = localStorage.getItem("apiKey");

    //console.log("accId :", accId);
    //console.log("apiKey :", apiKey);

    if (!emailRegex.test(userinfo.userEmail) || !accId || !apiKey) {
      closeotpModal();
      setEmailError("Invalid Email");
    } else {
      if (userinfo.userEmail === workinfo.companyEmail) {
        showAlert("User email cannot be same as company email", "danger");
      } else {
        //console.log("inside else of verify email");
        setverifybtn(true);

        try {
          await fetch(`${Url}/business/sendEmail`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              accId: accId.toString(),
              apiKey: apiKey,
              email: userinfo.userEmail,
            }),
          })
            .then((res) => res.json())
            .then((resJson) => {
              //console.log("email response:", resJson);
              if (resJson.error) {
                setEmailError("Invalid Email");
              } else {
                setEmailError("");
                showAlert("OTP sent successfully", "success");
                resenttime < 60 && clearInterval(timeInterval);
                setresenttime(60);
                handleresent();
                sethaveotp(true);

                openotpModal();
              }
              setverifybtn(false);
            })
            .catch((err)=>{
              console.log(err,"error")
            })
            .finally(()=>{
        Metrixfunction(metrixserver, "sendEmail", "UPDATE");

            })
        } catch (error) {
          setverifybtn(false);
          console.log("Error :", error);
        }
      }
    }
  };

  const handleverifyotp = async (otpvalue) => {
    const accId = localStorage.getItem("accId");
    const apiKey = localStorage.getItem("apiKey");

    //console.log("Payload :", {
    //   accId: localStorage.getItem("accId"),
    //   apiKey: localStorage.getItem("apiKey"),
    //   token: otpvalue,
    //   email: userinfo.userEmail,
    //   verifyType: "user",
    // });

    if (otpvalue?.length < 6 || !accId || !apiKey) {
      setOtpError("Invalid OTP");
      showAlert("Invalid OTP", "danger");
    } else {
      try {
        setotpedit(false)


        await fetch(`${Url}/business/validateEmailOTP`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            accId: localStorage.getItem("accId"),
            apiKey: localStorage.getItem("apiKey"), 
            token: otpvalue,
            email: userinfo.userEmail,
            verifyType: "user",
          }),
        })
          .then((res) => res.json())
          .then((resJson) => {
            //console.log("otp get response :", resJson);
            if (resJson.Error) {
              setOtpError("Invalid OTP");
              showAlert(resJson.Error, "danger");
            } else {
              setuserverify(true);
              closeotpModal();
              showAlert("Email Verified", "success");
              sethaveotp(false);
              setresenttime(60);
            } setotpedit(true)
          setTimeout(() => {
            
            otpfocus()
          }, 100);
          })
          .finally(()=>{
        Metrixfunction(metrixserver,"validateEmailOTP", "UPDATE");

          })
         
      } catch (error) {
        console.log("Error :", error);
        setOtpError("Invalid OTP");
        setotpedit(true)
        setTimeout(() => {
            
          otpfocus()
        }, 100);
      }
    }
  };

  const addressfetch = async (lat, lng) => {
    const language = "en"; // Specify the language code for English
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&zoom=18&addressdetails=1&accept-language=${language}`
      );

      const data = await response.json();


      //console.log("logs from openMap",data);

      if (data.display_name) {
        const order = [
          // "house_number",
          "road",
          "neighbourhood",
          "suburb",
          "state_district",
          // "state",
          // "postcode",
          "city",
          "district",
          // "country",
        ];

        //console.log("tempAddressInputField", tempAddressInputField);
        //console.log("userinfo", userinfo);
        // //console.log("logs from openMap ==>",order);

        for (const component of order) {
          if (data.address[component]) {
            if (component === "house_number") {
              handleAddressInfo(data.address[component], "currentHouseNo");
            } else if (component === "road" || component === "neighbourhood") {
              handleAddressInfo(data.address[component], "currentStreet");
            } 

            // else if (component === "city") {
            //   handleAddressInfo(data.address[component], "currentCity");
            // } 
            // else if (
            //   component === "state_district" ||
            //   component === "district"
            // ) {
            //   handleAddressInfo(data.address[component], "currentDistrict");
            // } else if (component === "state") {
            //   handleAddressInfo(data.address[component], "currentState");
            // } else if (component === "country") {
            //   handleAddressInfo(data.address[component], "currentCountry");
            // } else if (component === "postcode") {
            //   handleAddressInfo(data.address[component], "currentPincode");
            // }
          } 
        }
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleresent = () => {
    let time = 60;
    const newinterval = setInterval(() => {
      time = time - 1;
      setresenttime(time);
      if (time === 0) {
        clearInterval(newinterval);
        setresenttime(60);
        setresentotp(true);
      }
    }, 1000);
    setTimeInterval(newinterval);
  };

  const getFileName = (url) => {
    if (!url) return "";
    const parts = url.split("/");
    const filename = parts[parts?.length - 1];
    return filename;
  };

  const isPdf = (filename) => {
    return filename.endsWith(".pdf");
  };



  const [isLocationFetched,setIsLocationFetched] = useState(false);
  const [formattedAddressArr,setformattedAddressArr]= useState([]);
  const googleApiKey =  process.env.REACT_APP_GOOGLE_API_KEY;

  let googleResponseArray = ["route","premise","sublocality_level_2","sublocality_level_1","locality","administrative_area_level_3","administrative_area_level_1","country","postal_code"];


  async function getLatLong(placeIds) {


    //console.log("googleApiKey",googleApiKey)

    try{
      setIsMapLoading(true);
      await fetch('https://places.googleapis.com/v1/places/'+placeIds, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Goog-Api-Key': googleApiKey,
          'X-Goog-FieldMask': 'id,formattedAddress,plusCode,location,addressComponents,adrFormatAddress'
        },
      })
      .then(response => response.json())
      .then(data => {
        //console.log("getLatLong response =>",data)
        if(data){
          setCurrentuserPosition({
            ...usercurrentPosition,
            userLatitude: data.location.latitude,
            userLongitude: data.location.longitude,
          });


    

          const addressObject = {};

         
            if(data.addressComponents?.length > 0){

              data.addressComponents.forEach((component,index)=>{

                googleResponseArray.forEach(type => {
                    const val =  component.types.includes(type);
                    if (val) {
                        addressObject[type] = component.longText;
                      return
                    }
                  });

              

              })

              //console.log("addressObject",addressObject);

            } 
           


         
          settempAddressInputField((prev) => ({
            ...prev,
            "currentHouseNo":"","currentStreet":"","currentLandmark":""
          }));

          // // handleAddressInfo(pincodeStr, "currentPincode");
          // validatePincode(addressObject["postal_code"]);
          
          settempAddressInputField((prev) => ({
            ...prev,
            "currentPincode": addressObject["postal_code"] ? addressObject["postal_code"] : "",
            // "currentCountry": addressObject["country"] ? addressObject["country"] : "",
            "currentState": (addressObject["administrative_area_level_1"] ? addressObject["administrative_area_level_1"] + ", " : ""),
            "currentDistrict": addressObject["administrative_area_level_3"] ? addressObject["administrative_area_level_3"] : "",
            "currentCity": addressObject["locality"] ? addressObject["locality"] : "",
            "currentStreet": (addressObject["route"] ? addressObject["route"] + ", " : "" )+
                (addressObject["sublocality_level_2"] ? addressObject["sublocality_level_2"] + ", " : "") +
                (addressObject["sublocality_level_1"] ? addressObject["sublocality_level_1"] : ""),                            
            "currentHouseNo": addressObject["premise"] ? addressObject["premise"] : ""
          }));
          

          // tempAddressInputField["currentPincode"] = pincodeStr;

          // tempAddressInputField["currentCountry"] = formattedAddressArr[formattedAddressArr.length-1];
          // tempAddressInputField["currentState"] = updatedStateStr;
          // tempAddressInputField["currentDistrict"] = formattedAddressArr[formattedAddressArr.length-3] == undefined ?"" : formattedAddressArr[formattedAddressArr.length-3];
          // userinfo["currentCity"] = formattedAddressArr[formattedAddressArr.length-4] == undefined ?"" : formattedAddressArr[formattedAddressArr.length-4];


        //  //console.log("formattedAddressArr",formattedAddressArr);

          setIsLocationFetched(true)
          setIsMapLoading(false);
        }else{
          setIsLocationFetched(false)
          setIsMapLoading(false);
        }
      })
      .catch(error => console.error('Error:', error));
    }catch(e){
      console.log(e)
      }

  }

  const [searchTerm, setsearchTerm] = useState("");
  const [options,setOptions] = useState([]);
  const [IsMapLoading,setIsMapLoading] = useState(false);

  const regex = /^(?!\s*$).+/;

  function validateSearchInput(input) {
    return regex.test(input);
  }

  async function handleSearch(searchcode) {
    //console.log("Handle search api triggered",searchcode,+" "+ searchcode.length)
    try{
      if (searchcode.length > 2 && validateSearchInput(searchcode)) {
        setIsMapLoading(true);
        //console.log("searchTerm",searchcode)

        await fetch(`${Url}/business/searchMapAddress`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            accId: '1234567890',
            searchStr: searchcode,
            apiKey: 'M2hZZytlZU1vL3h0aWR2TXVoOUFhdTV1RmNRaWVnaGYxZ0Vpb0hBVmFKbz'
          })
        })
        .then(response => response.json())
        .then(data => {

          //console.log("handleSearch response",data.Success);
          if (data.Success) {
            setOptions(data.Success);
            setIsLocationFetched(false);
            setIsMapLoading(false);
          }else{
          //console.log(data)
          setIsMapLoading(false);
          setIsLocationFetched(false);
          setOptions([])
          }
        })
        .catch(error => console.error('Error:', error))
        .finally(()=>{
        Metrixfunction(metrixserver,"searchMapAddress", "ONLOAD");

        })
      }
    }catch(e){
      console.log(e)
    }


  }

  const debouncer = useRef(null);

  // var debouncer;
  const handleSearchLocation = (value) => {
    clearTimeout(debouncer.current);

    setsearchTerm(value);


    debouncer.current = setTimeout(() => {

        handleSearch(value);
       if(value.length < 3){

         setIsLocationFetched(false);
         setOptions([]);

       }  
        //console.log("value length",value.length)
    }, 1000);
};


  const handleSplit = (location,isName)=>{
    let result="";
    
    
    if(isName){
      result = location.split(', ')[0];
    }else{
      let parts = location.split(", ");
      let addressParts = parts.slice(1); 
      let address = addressParts.join(", ");
       result = address;
    }
     

    return result;
  }

  const addressField = [

    {
      "currentHouseNo": {
        "labelName": "Door No.",
        "placeHolder": "Ex: 12/E",
        "leftIcon": <i className="fa fa-user" />,
        "maxLength": "100",
        "errorKey": "currentHouseNo"
      }
    },
    {
      "currentLandmark": {
        "labelName": "LandMark",
        "placeHolder": "Ex: near xyz apartment",
        "leftIcon": <i className="fa fa-user" />,
        "maxLength": "100",
        "errorKey": "currentLandmark"
      }
    },
    {
      "currentStreet": {
        "labelName": "Street & Area",
        "placeHolder": "Ex: abc street , Perungudi",
        "leftIcon": <i className="fa fa-user" />,
        "maxLength": "100",
        "errorKey": "currentStreet"
      }
    },

    // {
    //   "currentArea": {
    //     "labelName": "Area",
    //     "placeHolder": "Ex: Perungudi",
    //     "leftIcon": <i className="fa fa-user" />,
    //     "maxLength": "100",
    //     "errorKey": "currentStreet"
    //   }
    // },

    {
      "currentCity": {
        "labelName": "City / Town / Village",
        "placeHolder": "Ex: chennai",
        "leftIcon": <i className="fa fa-user" />,
        "maxLength": "100",
        "errorKey": "currentCity"
      }
    },
    {
      "currentDistrict": {
        "labelName": "District",
        "placeHolder": "Ex: xxxxxx",
        "leftIcon": <i className="fa fa-user" />,
        "maxLength": "100",
        "errorKey": "currentDistrict"
      }
    },
    {
      "currentState": {
        "labelName": "State",
        "placeHolder": "Ex: Tamil Nadu",
        "leftIcon": <i className="fa fa-user" />,
        "maxLength": "100",
        "errorKey": "currentState"
      }
    },
    // {
    //   "currentCountry": {
    //     "labelName": "Country",
    //     "placeHolder": "Ex: India",
    //     "leftIcon": <i className="fa fa-user" />,
    //     "maxLength": "100",
    //     "errorKey": "currentCountry"
    //   }
    // },
    {
      "currentPincode": {
        "labelName": "Pincode",
        "placeHolder": "Ex: 600096",
        "leftIcon": <i className="fa fa-user" />,
        "maxLength": "6",
        "errorKey": "currentPincode"
      }
    },
    
  ];

  const permanentAddressField = [
    {
      "permanentHouseNo": {
        "labelName": "Door No.",
        "placeHolder": "Ex: 12/E",
        "leftIcon": <i className="fa fa-user" />,
        "maxLength": "100",
        "errorKey": "permanentHouseNo"
      }
    },
    {
      "permanentLandmark": {
        "labelName": "LandMark",
        "placeHolder": "Ex: near xyz apartment",
        "leftIcon": <i className="fa fa-user" />,
        "maxLength": "100",
        "errorKey": "permanentLandmark"
      }
    },
    {
      "permanentStreet": {
        "labelName": "Street & Area",
        "placeHolder": "Ex: abc street",
        "leftIcon": <i className="fa fa-user" />,
        "maxLength": "100",
        "errorKey": "permanentStreet"
      }
    },
    // {
    //   "permanentArea": {
    //     "labelName": "Area",
    //     "placeHolder": "Ex: Perungudi",
    //     "leftIcon": <i className="fa fa-user" />,
    //     "maxLength": "100",
    //     "errorKey": "permanentStreet"
    //   }
    // },
    {
      "permanentCity": {
        "labelName": "City",
        "placeHolder": "Ex: Chennai",
        "leftIcon": <i className="fa fa-user" />,
        "maxLength": "100",
        "errorKey": "permanentCity"
      }
    },
    {
      "permanentDistrict": {
        "labelName": "District",
        "placeHolder": "Ex: xxxxxx",
        "leftIcon": <i className="fa fa-user" />,
        "maxLength": "100",
        "errorKey": "permanentDistrict"
      }
    },
    {
      "permanentState": {
        "labelName": "State",
        "placeHolder": "Ex: Tamil Nadu",
        "leftIcon": <i className="fa fa-user" />,
        "maxLength": "100",
        "errorKey": "permanentState"
      }
    },
    // {
    //   "permanentCountry": {
    //     "labelName": "Country",
    //     "placeHolder": "Ex: India",
    //     "leftIcon": <i className="fa fa-user" />,
    //     "maxLength": "100",
    //     "errorKey": "permanentCountry"
    //   }
    // },
    {
      "permanentPincode": {
        "labelName": "Pincode",
        "placeHolder": "Ex: 600096",
        "leftIcon": <i className="fa fa-user" />,
        "maxLength": "6",
        "errorKey": "permanentPincode"
      }
    }
  ];
  


  const handleEditAddress = (addressCategory)=>{

    

    if(addressCategory === "current"){
      let addressKeysTemp = Object.keys(tempAddressInputField);

      addressKeysTemp.forEach((key)=>{
  
        if(userinfo[key] !== ""){
          tempAddressInputField[key] = userinfo[key]; 

          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [key]: "",
          }));
          
        } 
  
      })
     


      openModal();
      // setAddressValidState((prev) => ({ ...prev,"tempAddress":  }));
      setIsLocationFetched(true);
      setAddressPageNum(1);
      // if(pageOneRef.current && pageOneRef.current){

      setTimeout(() => {
        if (pageOneRef.current && pageTwoRef.current) {
          pageOneRef.current.style.transform = "translate(-150%)";
          pageTwoRef.current.style.transform = "translate(0)";
        }
      }, 0);


      // }
      
     
    }else{

      let addressKeysPermanent = Object.keys(permanentAddressInputField);

      addressKeysPermanent.forEach((key)=>{
  
        if(userinfo[key] !== ""){
          permanentAddressInputField[key] = userinfo[key]; 
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [key]: "",
          }));
        } 
  
      })


      openPermanentModal()
    }
    

    

  }

  const [tempAddressInputField, settempAddressInputField] = useState({
    
      // currentCountry: "",
      currentState: "",
      currentCity: "",
      currentDistrict: "",
      currentPincode: "",
      currentHouseNo: "",
      currentStreet: "",
      currentLandmark: "", 
      // currentArea:"",

  })

  const [permanentAddressInputField, setpermanentAddressInputField] = useState({
    
    // permanentCountry: "",
    permanentState: "",
    permanentCity: "",
    permanentDistrict: "",
    permanentPincode: "",
    permanentHouseNo: "",
    permanentStreet: "",
    // permanentArea: "",
    permanentLandmark: "",

})





  const handleAddressInfo = (value, field)=>{

        //console.log("value key =>",value);
        //console.log("initial User info", initialuserinfo);

        let errorMessage = "";
        const regex = validationRegex[field];
        const minCriteria = fieldConfigurations[field];

        if (value == "" || (regex && !regex.test(value))) {

        if (
          field === "currentHouseNo" ||
          field === "currentStreet" ||
          field === "currentCity" ||
          field === "currentDistrict" ||
          field === "currentState" ||
          field === "currentCountry" ||
          field === "currentPincode" ||
          field === "currentLandmark"
        ) {
          let length;
          length = field === "currentPincode" ? 6 : 2;
          errorMessage =
            value?.length >= length
              ? `Invalid ${minCriteria.label}.`
              : `Please enter valid ${minCriteria.label}`;
            
            // break;
            
          
        }

        if (
          field === "permanentHouseNo" ||
          field === "permanentStreet" ||
          field === "permanentCity" ||
          field === "permanentDistrict" ||
          field === "permanentState" ||
          field === "permanentCountry" ||
          field === "permanentPincode" ||
          field === "permanentLandmark"
        ) {
          let length;
          length = field === "permanentPincode" ? 6 : 2;
          errorMessage =
            value?.length >= length
              ? `Invalid ${minCriteria.label}.`
              : `Please enter valid ${minCriteria.label}`;

            //  break;
          
        }
      }

        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [field]: errorMessage,
        }));

        if(field.startsWith("current")){
          //console.log("Fieldd ==>",field)
          if ( field === "currentPincode" &&  /^\d{0,6}$/.test(value)) { // Allows up to 6 digits
            settempAddressInputField((prevState) => ({
              ...prevState,
              [field]: value, // Use computed property names to dynamically set the key
            }));
      
          }

          if(field !== "currentPincode"){

            settempAddressInputField((prevState) => ({
              ...prevState,
              [field]: value, // Use computed property names to dynamically set the key
            }));
      
          }

          
        }else{

          //console.log("Fieldd ==>",field)

          if ( field === "permanentPincode" &&  /^\d{0,6}$/.test(value)) { // Allows up to 6 digits
            setpermanentAddressInputField((prevState) => ({
              ...prevState,
              [field]: value, // Use computed property names to dynamically set the key
            }));

          }
      
          if( field !== "permanentPincode"){
            setpermanentAddressInputField((prevState) => ({
              ...prevState,
              [field]: value, // Use computed property names to dynamically set the key
            }));
          
        
          }
      

      }
   
  }



const resetMonthDropdown = () => {
  setResetDateTime(false)
      setTimeout(() => {
      setResetDateTime(true)
    }, 300);
};

const inputRefs = useRef([]);
  
  // Add event listener to handle tab key press
  const handleKeyDown = (event, index) => {
    //console.log("Checking TAB ")
    if (event.key === 'Tab' && !event.shiftKey) {
      event.preventDefault();
      if (index === 4) {
        inputRefs.current[5].focus();
      }
    }
  };





  return loadingscreen ? (
    <Loading />
  ) : (
    <div className="ui-main-container">
      <div className="ui-main-content-wrapper">
        <div className="ui-input-main">
          <h2 className="ui-input-title">User Information</h2>

          <div className="ui-input-name-wrapper">
            <div className="ui-input-wrapper">
              <label>Your Name</label>
              <InputField
                placeholder={"Ex: John Doe"}
                leftIcon={<i className="fa fa-user" />}
                onChangeText={(e) => handleUserInfo(e, "userName")}
                value={userinfo.userName}
                editable={componentedit}
                inputref={validationref}
                validationError={validationErrors.userName}
              />
            </div>
            {/* <div className="ui-input-wrapper">
              <label>Last Name</label>
              <InputField
                placeholder={"Ex: Doe"}
                leftIcon={<i className="fa fa-user" />}
                onChangeText={(e) => handleUserInfo(e, "userName")}
                value={userinfo.userName}
                editable={componentedit}
              />
            </div> */}

            <form
              className="wi-input-wrapper"
              onSubmit={(e) => {
                e.preventDefault();
                //console.log("innerform executed");
              }}
            >
              <div className="d-flex align-items-center justify-content-between ">
                <label>Email ID</label>
                {componentedit ? (
                  userverify ? (
                    <div className="d-flex align-items-center gap-3">
                      {/* <button
                        onClick={() => setuserverify(false)}
                        className="email-validate-button"
                        tabIndex="-1"
                      >
                        ReVerify
                      </button> */}
                      <p className="email-validate-button-verified">
                        <Check />
                      </p>
                    </div>
                  ) : (
                    <div className="usermail-otpwrapper">
                      {haveotpshow && (
                        <u
                          className="haveotp"
                          onClick={() => {
                            openotpModal(false);
                          }}
                        >
                          I have an OTP
                        </u>
                      )}
                      <button
                        onClick={() => {
                          verifyEmail();
                        }}
                        className="email-validate-button"
                        tabIndex="-1"
                        disabled={verifybtn}
                      >
                        Verify
                      </button>
                    </div>
                  )
                ) : (
                  ""
                )}
              </div>
              <InputField
                placeholder={"Ex: xyz@abc.com"}
                leftIcon={<i className="fa fa-user" />}
                onChangeText={(e) => {
                  handleUserInfo(e, "userEmail");
                  haveotpshow && sethaveotp(false);
                }}
                value={userinfo.userEmail}
                editable={!userverify && componentedit}
                validationError={validationErrors.userEmail}
              />
              {emailError && (
                <span className="bank-error-text">{emailError}</span>
              )}
            </form>

            <div
              className="ui-input-dob ui-input-wrapper"
              onClick={(e) => {
                e.stopPropagation();
                // setIsFocused(true)
                componentedit &&
                  setShowDropdown({
                    ...showDropdown,
                    date: !showDropdown.date,
                  });
              }}
            >
              <label>Date of Birth</label>
              {/* <InputField
                placeholder={"Ex: 01/01/2024"}
                leftIcon={<Calendar />}
                onChangeText={(e) => {}}
                value={userinfo.userDOB}
                editable={componentedit}
                validationError={validationErrors.userDOB}
                // onFocusText={(val)=> setIsFocused(val) }
              /> */}
              <p>{validationErrors.userDOB}</p>
              <button
                style={{ border: "none" }}
                className={`input-field-container ${
                  validationErrors.userDOB != "" ||
                  validationErrors.userDOB == undefined
                    ? validationErrors.userDOB
                      ? "warninginputfield"
                      : ""
                    : ""
                } ${isFocusedDatePicker ? "focuseddatetime" : ""}`}
                ref={(el) => (dateTimePickerRef.current = el)}
                onClick={() => {
                  setIsFocusedDatePicker(true);
                }}
                disabled={!componentedit}
              >
                <Calendar color="#666666" />
                {userinfo.userDOB ? (
                  <p
                    style={{
                      color: componentedit ? "black" : "#4a4343",
                      marginLeft: "10px",
                      fontFamily: "'Jost', sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    {userinfo.userDOB}
                  </p>
                ) : (
                  <p
                    style={{
                      color: componentedit ? "#c4c4c4" : "#4a4343",
                      marginLeft: "10px",
                      fontFamily: "'Jost', sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    Ex: 01/01/2024
                  </p>
                )}
              </button>
            </div>

            {!(componenttype === "preview" || componenttype === "stepper") && (
              <>
                {/* <div className="ui-input-wrapper">
              <label>Gender</label>

                  <Dropdown
                    options={GenderOption}
                    leftIcon={
                      <i className="fa fa-users" style={{ color: "grey" }} />
                    }
                    onSelect={handleSelect}
                    drpdownWidth={"100%"}
                    drpdownHeight={"50px"}
                    placeHolder={"Select the Gender"}
                    value={userinfo.gender}
                    editable={componentedit}

                  />
                 
            </div> */}

                <div className="ui-input-wrapper">
                  <label>Marital Status</label>

                  <Dropdown
                    options={MaritalOption}
                    leftIcon={
                      <i className="fa fa-users" style={{ color: "grey" }} />
                    }
                    onSelect={(e) => handleUserInfo(e, "userMaritalStatus")}
                    drpdownWidth={"100%"}
                    drpdownHeight={"50px"}
                    placeHolder={"Marital Status"}
                    value={userinfo.userMaritalStatus}
                    editable={componentedit}
                    validationError={validationErrors.userMaritalStatus}
                  />
                </div>

                <div className="ui-input-wrapper">
                  <label>Blood Group</label>

                  <Dropdown
                    options={bloodTypes}
                    leftIcon={
                      <i className="fa fa-users" style={{ color: "grey" }} />
                    }
                    onSelect={(e) => handleUserInfo(e, "userBloodGroup")}
                    drpdownWidth={"100%"}
                    drpdownHeight={"50px"}
                    placeHolder={"Select your Blood Group"}
                    value={userinfo.userBloodGroup}
                    editable={componentedit}
                    validationError={validationErrors.userBloodGroup}
                  />
                </div>

                <div className="ui-input-wrapper">
                  <label>Educational Qualification</label>
                  <InputField
                    placeholder={"Ex: MCA"}
                    leftIcon={<i className="fa fa-user" />}
                    onChangeText={(e) => handleUserInfo(e, "userEducation")}
                    value={userinfo.userEducation}
                    editable={componentedit}
                    validationError={validationErrors.userEducation}
                  />
                  {/* </div> */}
                </div>

                <div className="ui-textArea-wrapper">
                  <div className="d-flex justify-content-between align-items-center ">
                    <label>Current Address</label>

                    {componentedit &&
                      (userinfo.currentCity !== "" ||
                        // userinfo.currentCountry !== "" ||
                        userinfo.currentDistrict !== "" ||
                        userinfo.currentHouseNo !== "" ||
                        userinfo.currentStreet !== "" ||
                        userinfo.currentState !== "") && (
                        <Edit
                          style={{ cursor: "pointer" }}
                          onClick={() => handleEditAddress("current")}
                        ></Edit>
                      )}
                  </div>

                  {userinfo.currentCity === "" ||
                  // userinfo.currentCountry === "" ||
                  userinfo.currentDistrict === "" ||
                  userinfo.currentHouseNo === "" ||
                  userinfo.currentStreet === "" ||
                  userinfo.currentState === "" ? (
                    <div className="ui-address-container" onClick={openModal}>
                      <Plus />
                      <p>Add Current Address details</p>
                    </div>
                  ) : (
                    <div className="wi-actual-address-wrapper">
                      <p style={{ color: componentedit ? "black" : "#4a4343" }}>
                        {`${userinfo.currentHouseNo}, ${userinfo.currentStreet},${userinfo.currentCity}, ${userinfo.currentDistrict}, 
                            ${userinfo.currentState} - ${userinfo.currentPincode}. LandMark :${userinfo.currentLandmark}  `}
                      </p>
                    </div>
                  )}

                  {/* {!addressValidState.tempAddress && userinfo["currentPincode"]  ?  <p style={{marginTop:"10px",color:"#fa8391",fontSize:"small"}}>Kindly Add Current House Address</p>:"" } */}
                </div>

                {(userinfo.currentHouseNo !== "" ||
                  userinfo.currentState !== "" ||
                  userinfo.currentStreet !== "" ||
                  userinfo.currentCity !== "" ||
                  userinfo.currentDistrict !== "" ||
                  // userinfo.currentCountry !== "" ||
                  userinfo.currentPincode !== "") && (
                  <div className="ui-radio-ownership">
                    <label>
                      Permanent address is same as Current address ?
                    </label>
                    <div className="ui-radio-btn-wrapper">
                      <label className="ui-radio-btn">
                        <input
                          type="radio"
                          value="Yes"
                          checked={sameAddress === "Yes"}
                          onChange={(e) => handleChange(e, "address")}
                          disabled={!componentedit}
                        />
                        <span>Yes</span>
                      </label>

                      <label className="ui-radio-btn">
                        <input
                          type="radio"
                          value="No"
                          checked={sameAddress === "No"}
                          onChange={(e) => handleChange(e, "address")}
                          disabled={!componentedit}
                        />
                        <span>No</span>
                      </label>
                    </div>
                    {/* {!addressValidState.permAddress && sameAddress == "" && <p style={{marginTop:"10px",color:"#fa8391",fontSize:"small"}}>Kindly Add Permanent House Address</p>} */}
                  </div>
                )}
                {sameAddress == "No" ? (
                  <>
                    <div className="ui-textArea-wrapper">
                      <div className="d-flex justify-content-between align-items-center ">
                        <label>Permanent Address</label>

                        {componentedit &&
                          (userinfo.permanentHouseNo !== "" ||
                            userinfo.permanentStreet !== "" ||
                            userinfo.permanentCity !== "" ||
                            userinfo.permanentDistrict !== "" ||
                            userinfo.permanentState !== "" ||
                            // userinfo.permanentCountry !== "" ||
                            userinfo.permanentPincode !== "" ||
                            userinfo.permanentLandmark !== "") && (
                            <Edit
                              style={{ cursor: "pointer" }}
                              onClick={() => handleEditAddress("permanent")}
                            ></Edit>
                          )}
                      </div>

                      {userinfo.permanentHouseNo === "" ||
                      userinfo.permanentStreet === "" ||
                      userinfo.permanentCity === "" ||
                      userinfo.permanentDistrict === "" ||
                      userinfo.permanentState === "" ||
                      // userinfo.permanentCountry === "" ||
                      userinfo.permanentPincode === "" ||
                      userinfo.permanentLandmark === "" ? (
                        <div
                          className="ui-address-container"
                          onClick={openPermanentModal}
                        >
                          <Plus />
                          <p>Add Permanent Address details</p>
                        </div>
                      ) : (
                        <div className="wi-actual-address-wrapper">
                          <p
                            style={{
                              color: componentedit ? "black" : "#4a4343",
                            }}
                          >
                            {`${userinfo.permanentHouseNo}, ${userinfo.permanentStreet},
                            ${userinfo.permanentCity}, ${userinfo.permanentDistrict}, ${userinfo.permanentState} - ${userinfo.permanentPincode}. LandMark : ${userinfo.permanentLandmark}`}
                          </p>
                        </div>
                      )}
                      {/* {!addressValidState.permAddress && <p style={{marginTop:"10px",color:"#fa8391",fontSize:"small"}}>Kindly Add Permanent House Address</p>} */}

                      {/* <InputField 
              placeholder={"Enter your Residential Address"} 
              leftIcon={<Home />}
              containerStyle={{height:"100px"}}
              onChangeText={(e) => handleUserInfo(e,"userinfo")}
              value={userinfo}
              /> */}
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div className="ui-input-wrapper">
                  <label>Father Name</label>
                  <InputField
                    placeholder={"Ex: Robert"}
                    leftIcon={<i className="fa fa-user" />}
                    onChangeText={(e) => handleUserInfo(e, "userFatherName")}
                    value={userinfo.userFatherName}
                    editable={componentedit}
                    validationError={validationErrors.userFatherName}
                  />
                </div>

                <div className="ui-input-wrapper">
                  <label>Mother Name</label>
                  <InputField
                    placeholder={"Ex: catheline"}
                    leftIcon={<i className="fa fa-user" />}
                    onChangeText={(e) => handleUserInfo(e, "userMotherName")}
                    value={userinfo.userMotherName}
                    editable={componentedit}
                    validationError={validationErrors.userMotherName}
                  />
                </div>

                <div className="ui-input-wrapper">
                  <label>Religion</label>
                  <InputField
                    placeholder={"Ex: Hindu"}
                    leftIcon={<i className="fa fa-user" />}
                    onChangeText={(e) => handleUserInfo(e, "userCommunity")}
                    value={userinfo.userCommunity}
                    editable={componentedit}
                    validationError={validationErrors.userCommunity}
                  />
                </div>

                <div className="ui-input-wrapper">
                  <label>Nationality</label>
                  <InputField
                    placeholder={"Ex: Indian"}
                    leftIcon={<i className="fa fa-user" />}
                    onChangeText={(e) => handleUserInfo(e, "userNationality")}
                    value={userinfo.userNationality}
                    editable={componentedit}
                    validationError={validationErrors.userNationality}
                  />
                </div>
                <div className="ui-input-wrapper">
                  <label>
                    GST <span style={{ fontSize: "13px" }}>(Optional)</span>
                  </label>
                  <InputField
                    placeholder={"Ex: 22AAAAA0000A1Z5"}
                    leftIcon={<i className="fa fa-user" />}
                    onChangeText={(e) => handleUserInfo(e, "userGST")}
                    value={userinfo.userGST}
                    editable={componentedit}
                    validationError={validationErrors.userGST}
                    maxLength={15}
                  />
                </div>

                <div className="ui-textArea-wrapper">
                  <label>
                    Select the Utility Bill which you want to upload
                  </label>
                  <div className="ui-drpdown-wrapper">
                    <Dropdown
                      options={utilityBills}
                      leftIcon={
                        <i className="fa fa-file" style={{ color: "grey" }} />
                      }
                      onSelect={(e) => handleUserInfo(e, "utilityBillType")}
                      drpdownWidth={"100%"}
                      drpdownHeight={"50px"}
                      placeHolder={"Select the utility bill"}
                      value={
                        userinfo.utilityBillType === ""
                          ? ""
                          : userinfo.utilityBillType
                      }
                      editable={componentedit}
                      validationError={validationErrors.utilityBillType}
                    />
                  </div>
                </div>

                {
                  userinfo.utilityBillType === "" ? (
                    ""
                  ) : // isFilesUploaded ?
                  userinfo.utilityBillImgUrl === "" &&
                    utilityFile?.length === 0 ? (
                    <div className="col ">
                      <label>Upload your {userinfo.utilityBillType} </label>
                      <div className="mt-2">
                        <FileUpload
                          fileType="application/pdf,image/jpeg,image/jpg,image/png"
                          limitCount={1}
                          fileName={`${accountId}_user${userinfo.utilityBillType}_`}
                          fileId={"userUtilityBill"}
                          files={utilityFile}
                          setFiles={setUtilityFile}
                          // files={(e)=> handleUploadFiles(e,"utilityBillType")}
                        />
                      </div>
                    </div>
                  ) : (
                    <div style={{ minHeight: "160px" }}>
                      <AssetDisplay
                        workinfo={userinfo.utilityBillImgUrl}
                        fileUploadSate={utilityFile}
                        handleEditUploadImage={handleEditUploadImage}
                        fileType={"utilityBill"}
                        editable={componentedit}
                        labelName={"Utility Bill"}
                      />
                    </div>
                  )
                  // :
                  // <SkeletonLoader />
                }

                {/* {utilityFile && 

                    utilityFile.map((file,index)=> {

                      return(
                        <>
                        <p key={index}>{file.url}</p>
                        <p key={index}>{file.name}</p>
                        </>
                      )
                    })
                  } */}

                <div className="ui-radio-ownership">
                  <label>Do you own your residence?</label>
                  <div className="ui-radio-btn-wrapper">
                    <label className="ui-radio-btn">
                      <input
                        type="radio"
                        value="Yes"
                        checked={
                          userinfo.userResidentialOwnerShip === "" ||
                          userinfo.userResidentialOwnerShip === "Owned"
                            ? true
                            : false
                        }
                        onChange={handleChangeOwnership}
                        disabled={!componentedit}
                      />
                      <span>Yes</span>
                    </label>

                    <label className="ui-radio-btn">
                      <input
                        type="radio"
                        value="No"
                        checked={
                          userinfo.userResidentialOwnerShip === "" ||
                          userinfo.userResidentialOwnerShip === "Owned"
                            ? false
                            : true
                        }
                        onChange={handleChangeOwnership}
                        disabled={!componentedit}
                      />
                      <span>No</span>
                    </label>
                  </div>
                </div>
                {/* {userinfo.userResidentialOwnerShip} */}

                {userinfo.userResidentialOwnerShip !== "Owned" &&
                  userinfo.userResidentialOwnerShip !== "" && (
                    <div className="ui-textArea-wrapper">
                      <label>Please specify your Residential status</label>
                      <div style={{ width: "60%" }}>
                        <Dropdown
                          options={residenceOptions}
                          leftIcon={
                            <i
                              className="fa fa-home"
                              style={{ color: "grey" }}
                            />
                          }
                          onSelect={(e) =>
                            handleUserInfo(e, "userResidentialOwnerShip")
                          }
                          drpdownWidth={"100%"}
                          drpdownHeight={"50px"}
                          placeHolder={"Select your residential status"}
                          value={
                            userinfo.userResidentialOwnerShip === "No"
                              ? ""
                              : userinfo.userResidentialOwnerShip
                          }
                          editable={componentedit}
                          validationError={
                            validationErrors.userResidentialOwnerShip
                          }
                        />
                      </div>
                    </div>
                  )}

                {/* </> */}
              </>
            )}

            {/* <div className="ui-input-name-wrapper"> */}

            <ModalAddress
              isOpen={isModalOpen}
              onClose={closeModal}
              modalTitle={
                AddressPageNum == 0
                  ? "Add Current House Location"
                  : "Confirm Current House Address"
              }
              submitButtonPlaceHolder={
                AddressPageNum == 0 ? "Confirm Location" : "Add Address"
              }
              onSave={() => handleAddAddress("temp", AddressPageNum)}
              modalwidth={"50%"}
              modalHeight={"95%"}
              style={{ maxHeight: "none" }}
              savebtn={
                !IsMapLoading && (options?.length < 1 || isLocationFetched)
                  ? true
                  : false
              }
              isMap={AddressPageNum == 0 ? true : false}
              onBack={handleBack}
              InitialCloseButton={
                !isLocationFetched && AddressPageNum == 0 && options?.length < 1
                  ? true
                  : false
              }
            >
              <div
                className="pageAnimationWrapper"
                style={{ position: "relative", height: "100%", width: "100%" }}
              >
                <div
                  className="userinfo-address-container pageOne"
                  ref={pageOneRef}
                >
                  <div
                    style={{
                      width: "100%",
                      position: "Sticky",
                      display: "flex",
                      alignItems: "center",
                      top: "-5px",
                      zIndex: "9999",
                    }}
                  >
                    <input
                      className="userinfo-searchbar"
                      placeholder="Search for Area, Landmarks or nearby place"
                      onChange={(e) => handleSearchLocation(e.target.value)}
                      value={searchTerm}
                    />

                    {/* <button style={{height:"100%",width:"100px",borderRadius:"0px 10px 10px 0px"}} onClick={handleSearch}>Search</button> */}
                    <Search
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "10px",
                      }}
                    />
                  </div>

                  {IsMapLoading ? (
                    <div className="custom-loader"></div>
                  ) : isLocationFetched ? (
                    <div style={{ width: "100%" }}>
                      <div className="leafletmap-wrapper">
                        <MapContainer
                          center={[
                            usercurrentPosition.userLatitude,
                            usercurrentPosition.userLongitude,
                          ]}
                          scrollWheelZoom={true}
                          zoom={17}
                          ref={setMap}
                          style={{ height: "60vh", width: "100%" }}
                        >
                          <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />

                          <Marker
                            ref={currentmarkerRef}
                            position={[
                              usercurrentPosition.userLatitude,
                              usercurrentPosition.userLongitude,
                            ]}
                            icon={customIcon}
                          ></Marker>
                        </MapContainer>
                      </div>
                    </div>
                  ) : (
                    <div className="userinfo-searchResults">
                      {options && options.length > 0 ? (
                        options.map(({ address, placeId }, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                padding: "10px",
                                marginBottom: "5px",
                                borderBottom: "1px solid lightgrey",
                                position: "relative",
                                cursor: "pointer",
                              }}
                              onClick={() => getLatLong(placeId)}
                            >
                              <div>
                                <MapPin
                                  style={{
                                    position: "absolute",
                                    left: "10px",
                                    top: "20px",
                                  }}
                                />
                              </div>
                              <div style={{ marginLeft: "40px" }}>
                                <p style={{ color: "#E32f45" }}>
                                  {handleSplit(address, true)}
                                </p>
                                <p style={{ fontSize: "13px" }}>
                                  {handleSplit(address, false)}
                                </p>
                              </div>
                            </div>
                          );
                        })
                      ) : searchTerm.length > 2 ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginBottom: "60px",
                          }}
                        >
                          <div
                            style={{
                              height: "300px",
                              width: "fit-content",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          >
                            <img
                              src={
                                "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/No-data.png"
                              }
                              style={{ height: "100%", width: "100%" }}
                            ></img>
                          </div>
                          <h4>No Results Found</h4>
                        </div>
                      ) : (
                        <div
                          style={{
                            height: "300px",
                            width: "fit-content",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: "80px",
                            marginBottom: "30px",
                          }}
                        >
                          <img
                            src={
                              "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/Location-search.png"
                            }
                            style={{ height: "100%", width: "100%" }}
                          ></img>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div
                  className="userinfo-address-container pageTwo"
                  ref={pageTwoRef}
                >
                  {AddressPageNum === 1 &&
                    addressField &&
                    addressField.map((data, index) => {
                      let key = Object.keys(data)[0];
                      let value = data[Object.keys(data)[0]];
                      return key == "currentPincode" ? (
                        <div className="ui-input-wrapper" key={index}>
                          <label>Pincode</label>
                          <InputField
                            placeholder={"Ex: 600096"}
                            // leftIcon={<i className="fa fa-user" />}
                            onChangeText={(e) => {
                              handleAddressInfo(e, "currentPincode");
                              // validatePincode(e);
                            }}
                            value={tempAddressInputField.currentPincode}
                            editable={componentedit}
                            maxLength={6}
                            enableTab={false}
                            containerStyle={{
                              border:
                                pincodeError != "" ||
                                tempAddressInputField[key] == ""
                                  ? "1px solid red"
                                  : "",
                            }}
                            validationError={validationErrors.currentPincode}
                          />
                          {pincodeError && (
                            <span className="bank-error-text">
                              {pincodeError}
                            </span>
                          )}
                        </div>
                      ) : (
                        <div className="ui-input-wrapper" key={index}>
                          <label>{value.labelName}</label>
                          <InputField
                            placeholder={value.placeHolder}
                            // leftIcon={value.leftIcon}

                            onChangeText={(e) => handleAddressInfo(e, key)}
                            value={tempAddressInputField[key]}
                            editable={componentedit}
                            maxLength={value.maxLength}
                            validationError={validationErrors[key]}
                            enableTab={key == "currentHouseNo" ? false : true}
                            containerStyle={{
                              border:
                                validationErrors[key] != "" ||
                                tempAddressInputField[key] == ""
                                  ? "1px solid red"
                                  : "",
                            }}
                          />
                        </div>
                      );
                    })}
                  {addressField && (
                    <p style={{ fontSize: "14px" }}>
                      Note: If any information is incorrect, kindly edit and
                      provide the Correct details.
                    </p>
                  )}
                </div>

                {/* <div className="ui-input-wrapper">
                              <label>Door No.</label>
                              <InputField
                                placeholder={"Ex: 12/E"}
                                leftIcon={<i className="fa fa-user" />}
                                onChangeText={(e) => handleUserInfo(e, "currentHouseNo")}
                                value={userinfo.currentHouseNo}
                                editable={componentedit}
                                validationError={validationErrors.currentHouseNo}
                              />
                            </div> */}
              </div>
            </ModalAddress>

            <ModalAddress
              isOpen={permanentModalOpen}
              onClose={closePermanentModal}
              modalTitle={"Permanent Address"}
              submitButtonPlaceHolder={"Save Address"}
              onSave={() => handleAddAddress("permanent", 0)}
              modalwidth={"50%"}
              modalHeight={"95%"}
              isMap={true}
            >
              <div
                className="userinfo-address-container"
                style={{ padding: "5px" }}
              >
                {permanentAddressField &&
                  permanentAddressField.map((data, index) => {
                    let key = Object.keys(data)[0];
                    let value = data[Object.keys(data)[0]];
                    return key == "permanentPincode" ? (
                      <div className="ui-input-wrapper">
                        <label>Pincode</label>
                        <InputField
                          placeholder={"Ex: 600096"}
                          leftIcon={<i className="fa fa-user" />}
                          onChangeText={(e) => {
                            handleAddressInfo(e, "permanentPincode");
                            // validatePincode(e);
                          }}
                          value={permanentAddressInputField.permanentPincode}
                          editable={componentedit}
                          maxLength={6}
                          // containerStyle={{border:pincodeError != "" || permanentAddressInputField[key] == ""?"1px solid red":""}}
                          validationError={validationErrors.permanentPincode}
                        />
                        {pincodeError && (
                          <span className="bank-error-text">
                            {pincodeError}
                          </span>
                        )}
                      </div>
                    ) : (
                      <div className="ui-input-wrapper" key={index}>
                        <label>{value.labelName}</label>
                        <InputField
                          placeholder={value.placeHolder}
                          leftIcon={value.leftIcon}
                          onChangeText={(e) => handleAddressInfo(e, key)}
                          value={permanentAddressInputField[key]}
                          editable={componentedit}
                          maxLength={value.maxLength}
                          validationError={validationErrors[key]}
                          // containerStyle={{border: validationErrors[key] != "" || permanentAddressInputField[key] == "" ?"1px solid red":""}}
                        />
                      </div>
                    );
                  })}
                {/* 
                <div className="ui-input-wrapper">
                  <label>Door No.</label>
                  <InputField
                    placeholder={"Ex: 12/E"}
                    leftIcon={<i className="fa fa-user" />}
                    onChangeText={(e) => handleUserInfo(e, "permanentHouseNo")}
                    value={userinfo.permanentHouseNo}
                    editable={componentedit}
                    validationError={validationErrors.permanentHouseNo}
                  />
                </div> */}

                <div className="ui-input-wrapper">
                  {/* <label>LandMark</label>
                <InputField
                  placeholder={"Ex: 600096"}
                  leftIcon={<i className="fa fa-user" />}
                  onChangeText={(e) => handleUserInfo(e, "userName")}
                  value={userinfo.userName}
                  editable={componentedit}
                /> */}
                </div>

                {/* <div className="ui-input-wrapper">
                  <label>Latitude</label>
                  <InputField
                    placeholder={"Ex: chennai"}
                    leftIcon={<GeoAltFill />}
                    value={currentPosition.userLatitude}
                    editable={false}
                  />
                </div>
                <div className="ui-input-wrapper">
                  <label>Longitude</label>
                  <InputField
                    placeholder={"Ex: xxxxxx"}
                    leftIcon={<GeoAltFill />}
                    value={currentPosition.userLongitude}
                    editable={false}
                  />
                </div> */}
              </div>
            </ModalAddress>
          </div>
          {!component && componentedit && (
            <div className="wi-action-btns-wrapper">
              <button onClick={sendUserInfo}>Update</button>
            </div>
          )}
          {/* <InputField />   */}
        </div>
      </div>

      <Modal
        isOpen={otpModalOpen}
        onClose={closeotpModal}
        submitButtonPlaceHolder={"Submit OTP"}
        onSave={() => {
          handleverifyotp(emailotp);
        }}
        modalref={modalref}
        modalwidth={"600px"}
        savebtn={false}
      >
        <div className="otp-title-wrapper">
          <div className="otp-title">
            Please enter the One Time Password to verify your account
          </div>
          <div className="otp-body">
            A One-time Password has been sent to {userinfo.userEmail}
          </div>
        </div>
        <div
          className="otpcontainer"
          onSubmit={(event) => {
            event.preventDefault();
            //console.log("clicked");
          }}
        >
          <div className="mb-2">
            <OtpInput
              sendotp={handleemailotp}
              reset={!resendotp}
              editable={otpedit}
            />
          </div>
          <div className="resent-wrapper">
            {resendotp ? (
              <button
                className="resend-otp"
                disabled={verifybtn}
                onClick={() => {
                  setemailotp("");

                  otpfocus();

                  verifyEmail();
                }}
              >
                Resend OTP
              </button>
            ) : (
              <span className="resent-time">Resent OTP in {resenttime}s</span>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={showDropdown.date}
        onClose={() => {
          setShowDropdown({ ...showDropdown, date: false });
          resetMonthDropdown();
        }}
        modalTitle={"Pick Your Date of Birth"}
        modalwidth={"45%"}
      >
        <DateTimePicker
          // selectedDate={
          //   userinfo.userDOB
          //     ? new Date(convertDateFormat(userinfo.userDOB))
          //     : new Date(
          //         today.getFullYear() - 18,
          //         today.getMonth(),
          //         today.getDate()
          //       )
          // }
          selectedDate={
            userinfo.userDOB
              ? new Date(userinfo.userDOB)
              : new Date(
                  today.getFullYear() - 18,
                  today.getMonth(),
                  today.getDate()
                )
          }
          eighteenYearsCheck={true}
          onChange={(e) => handleChangeDateFormat(e)}
          ondateclick={handledateclick}
          resetMonthDropdown={resetDateTime}
        />
      </Modal>
      {alert.show && (
        <Alert
          message={alert.message}
          type={alert.type}
          show={alert.show}
          duration={alert.duration}
          onClose={() => setAlert({ ...alert, show: false })}
        />
      )}
    </div>
  );
}

export default UserInfo;



