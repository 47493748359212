import React, { useEffect, useRef, useState } from "react";
import '../styles/componentStyles/Alert.css'; 

function Alert({ message, type, duration = 3000, onClose, show }) {
  const [visible, setVisible] = useState(true);
  const overlayRef = useRef(null);
  const progressBarRef = useRef(null);


  useEffect(() => {


    if (message && show) {
      setVisible(true);

      // Animate the alert
      setTimeout(() => {
        if (overlayRef.current) {
          overlayRef.current.classList.add('alert-show');
        }

        if (progressBarRef.current) {
          progressBarRef.current.classList.add('progress-bar-animate');
        }

        // Hide alert after duration
        setTimeout(() => {
          if (overlayRef.current) {
            overlayRef.current.classList.remove('alert-show');

            setTimeout(() => {
              setVisible(false);
              onClose();
            }, 300); // Hide the alert after animation
          }
        }, duration);
      }, 100); // Wait a bit before animating
    }
  }, [message, duration, onClose, show]);

  if (!visible) return null;

  return (
    <div ref={overlayRef} className={`alert-container alert-${type}`}>
        <p className={`alert-text alert-${type}`}>{message}</p>
        <div ref={progressBarRef} className={`progress-bar progress-bar-${type}`}></div>
    </div>
  );
}

export default Alert;

// user manual

// {alert.show && (
//   <Alert
//     message={alert.message}
//     type={alert.type}
//     show={alert.show}
//     duration={alert.duration}
//     onClose={() => setAlert({ ...alert, show: false })}
//   />
// )}

// const [alert, setAlert] = useState({
//   show: false,
//   message: '',
//   type: '',
//   duration: 3000, // Default duration
// });

// const showAlert = (message, type, duration = 3000) => {
//   setAlert({
//     show: true,
//     message,
//     type,
//     duration,
//   });

//   // Automatically close the alert after the duration
//   setTimeout(() => {
//     setAlert((prevAlert) => ({
//       ...prevAlert,
//       show: false,
//     }));
//   }, duration);
// };
