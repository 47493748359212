import React, { useState, useEffect, useContext } from "react";
import '../../styles/screenStyles/Settings.css';
import { ArrowRightShort, Bell, PersonSlash } from "react-bootstrap-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { MyContext } from "../../components//GlobalValues";
import Metrixfunction from "../../components/MetrixFunction";



function Settings() {

    const navigate = useNavigate()
    const { 
        notification,setNotification,Url,metrixserver
     } = useContext(MyContext);

     function removeSlash(port1) {
        let p1 = port1.split("/").join("");
    
        let np = p1;
        return np;
      }

      const location=useLocation()
    useEffect(() => {
        setNotificationApi()
    }, [notification])

    const triggerNotification = () => {
        setNotification(notification === "yes" ? "no" : "yes");
    }

    const setNotificationApi = async () => {
        const accId = localStorage.getItem("accId");
        const apiKey = localStorage.getItem("apiKey");

        if (accId !== null && apiKey !== null) {

            try {
                Metrixfunction(metrixserver,"updateNotification", "UPDATE");

                await fetch(
                    `${Url}/business/updateNotification`,
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            accId: accId,
                            apiKey: apiKey,
                            status:notification
                        }),
                    }).then((res) => res.json())
                    .then((resJson) => {
                    });

            } catch (error) {
                console.log("Error :", error);
            }
        } else {
           
        }

    }

    return (
        <div className="settings-main">
            <div className="settings-main-container">
                <p className="settings-heading">Settings</p>
                <div className="settings-card-container">

                    <div className="profile-content-card" onClick={triggerNotification}>
                        <span className="d-flex align-items-center gap-3">
                            <Bell className="profile-card-icon" />
                            <p>Notification</p>
                        </span>
                        <div className={`profile-toggle-container ${notification === 'yes' ? 'profile-toggle-active' : ''}`} onClick={triggerNotification}>
                            <span className={`profile-toggle-button ${notification === 'no' ? 'profile-active' : ''}`}></span>
                            <span className={`profile-toggle-button ${notification === 'yes' ? 'profile-active' : ''}`}></span>
                        </div>
                    </div>

                    <div className="profile-content-card" onClick={() => {
                        let options = { updateBreadcrumbs: false };

                        navigate("/grievance", {
                        
                                state: {
                                    options: options
                                },
                        })
                    }}>
                        <span className="d-flex align-items-center gap-3">
                            <PersonSlash className="profile-card-icon" />
                            <p>Grievance Redressal</p>
                        </span>
                        <ArrowRightShort className="profile-card-icon" />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Settings;