import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ChatBot from "../screens/dynamic/ChatBot";
import { X, XCircle } from "react-feather";
import CustomHeader from "./customHeader";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const navbarCollapseRef = useRef(null);

  const handleLogin = () => {
    navigate("/login");
  };

  const handleHome = () => {
    const eligible = localStorage.getItem("eligibleApproved");
    const accId = localStorage.getItem("accId");
    const apiKey = localStorage.getItem("apiKey");

    if (accId !== null && apiKey !== null) {
      if (eligible) {
        if (eligible === "yes") {
          navigate("/dashboard");
        } else {
          navigate("/checkeligibility");
        }
      }
    } else {
      localStorage.clear();
      navigate("/home");
    }
  };

  // const closeNavbar = () => {
  //   if (navbarCollapseRef.current) {
  //     navbarCollapseRef.current.classList.remove("show");
  //   }
  // };

  // const handleClickOutside = (event) => {
  //   if (
  //     navbarCollapseRef.current &&
  //     !navbarCollapseRef.current.contains(event.target)
  //   ) {
  //     closeNavbar();
  //   }
  // };

  const closeNavbar = () => {
    if (navbarCollapseRef.current && navbarCollapseRef.current.classList.contains('show')) {
      navbarCollapseRef.current.classList.remove('show');
    }
  };

  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside, true);
  //   };
  // }, []);

  const toastref = useRef(null);

  const handleCloseToast = () => {
    toastref.current.style.top = "-40px";
  };

  return (
    <div style={{ position: "relative" }}>
      <nav
        className="navbar navbar-expand-lg navbar-light px-2 px-lg-5"
        style={{ padding: 0, zIndex: 9999 }}
      >
        <div
          className="container-fluid  navigationBar"
          style={{ margin: 0, width: "95%" }}
        >
          <div
            onClick={() => navigate("/home")}
            className="navbar-brand m-3 m-md-2 p-0 d-flex align-items-center justify-content-center clickable"
            style={{gap:"0.7rem"}}
          >
          <img alt="" src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/logo.png" 
          style={{
            filter:"brightness(5)",
            margintop:"0.5rem",
            height:"45px",
            width:"45px"
            }}/>

            <h1 className="m-0">MyKaasu</h1>
            {/* <img src="img/logo.png" alt="Logo"> */}
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="fa fa-bars" />
          </button>
          <div
            ref={navbarCollapseRef}
            className="collapse navbar-collapse"
            id="navbarCollapse"
            style={{ justifyContent: "flex-end" }}
          >
            <div
              className="navbar-nav mx-auto py-0"
              style={{ margin: "0px !important" }}
            >
              <div
                onClick={handleHome}
                className="nav-item nav-link nav-button"
              >
                Home
              </div>
              <li className="nav-item dropdown">
                <p
                  className=" nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Loans
                </p>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <div
                      className="dropdown-item  nav-button"
                      onClick={() => navigate("/business-loans")}
                    >
                      Business Loan
                    </div>
                  </li>
                  <li>{/* <hr className="dropdown-divider" /> */}</li>
                  <li>
                    <div
                      className="dropdown-item nav-button"
                      onClick={() => navigate("/personal-loans")}
                    >
                      Personal Loan
                    </div>
                  </li>
                </ul>
              </li>
              <div
                onClick={() => navigate("/investors")}
                className="nav-item nav-link nav-button"
              >
                Investors
              </div>
              {/* <a href="#feature" className="nav-item nav-link scrollto">Calculator</a> */}
              {/* <a href="#pricing" className="nav-item nav-link">Pricing</a> */}
              <li className="nav-item ">
                <div
                  className="nav-link nav-button"
                  onClick={() => navigate("/articles")}
                  id="navbarDropdown"
                >
                  Articles
                </div>
                {/* <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><a className="dropdown-item" href="articles.html">Articles</a></li>
                <li><hr className="dropdown-divider"></li>   
                <li><a className="dropdown-item" href="#">Testimonials</a></li>
              </ul> */}
              </li>
              <div
                onClick={() => navigate("/careers")}
                id="careersLink"
                className="nav-item nav-link nav-button "
              >
                Careers
              </div>
              <li className="nav-item dropdown">
                <p
                  className="nav-link dropdown-toggle nav-button"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Contact Us
                </p>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <div
                      onClick={() => navigate("/faq")}
                      className="dropdown-item nav-button"
                    >
                      FAQ
                    </div>
                  </li>
                  <li>{/* <hr className="dropdown-divider" /> */}</li>
                  <li>
                    <div
                      onClick={() => navigate("/contactus")}
                      className="dropdown-item nav-button"
                    >
                      Complaints/Queries
                    </div>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <p
                  className="nav-link dropdown-toggle nav-button"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Tools
                </p>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <div
                      onClick={() =>
                        navigate("/calculator", {
                          state: { showpayments: false },
                        })
                      }
                      className="dropdown-item nav-button"
                    >
                      Loan Calculator
                    </div>
                  </li>
                  <li>{/* <hr className="dropdown-divider" /> */}</li>
                </ul>
              </li>
              {/* <a href="file:///D:/myKaasu%20onepage/myKaasu/termsandconditions.html" className="nav-item nav-link">Terms & Conditions</a> */}
            </div>
            {/* <a href="" className="btn btn-primary-gradient rounded-pill py-2 px-4 ms-3  d-lg-block">Start Free Trial</a> */}

            <div className="header-dash">
              {!localStorage.getItem("apiKey") &&
              !localStorage.getItem("accId") ? (
                <>
                  {/* <button
                  // href="/download-app"
                  className="nav-item nav-link nav-button d-flex justify-content-start align-items-center "
                  style={{
                    paddingLeft: "0px !important",
                    paddingRight: "0px !important",
                  }}
                  onClick={() => handleLogin()}
                >
                  <div
                    className=" d-flex justify-content-start  align-items-center px-3 py-2 header-apply-now rounded border "
                    style={{
                      padding: 0,
                      color: "white",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Sign in
                  </div>
                </button> */}
                </>
              ) : (
               
                <>
                  {location.pathname !== "/dashboard" &&
                    location.pathname !== "/checkeligibility" && (
                      <button
                        // href="/download-app"
                        className="nav-item nav-link nav-button d-flex justify-content-start align-items-center "
                        style={{
                          paddingLeft: "0px !important",
                          paddingRight: "0px !important",
                        }}
                        onClick={() => {
                      
                          localStorage.getItem("eligibleApproved") === "yes"
                            ? navigate("/dashboard")
                            : navigate("/checkeligibility");
                          closeNavbar();
                        }}
                      >
                        <div
                          className=" d-flex justify-content-start  align-items-center px-3 py-2 header-apply-now rounded border "
                          style={{
                            padding: 0,
                            color: "white",
                            fontFamily: '"Jost", sans-serif',
                          }}
                        >
                          Dashboard
                        </div>
                      </button>
                    )}
                  {location.pathname !== "/profile" && (
                    <button
                      // href="/download-app"
                      className="nav-item nav-link nav-button d-flex justify-content-start align-items-center "
                      style={{
                        paddingLeft: "0px !important",
                        paddingRight: "0px !important",
                      }}
                      onClick={() => {
                        navigate("/profile");
                        closeNavbar();
                      }}
                    >
                      <div
                        className=" d-flex justify-content-start  align-items-center px-3 py-2 header-apply-now rounded border "
                        style={{
                          padding: 0,
                          color: "white",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        Profile
                      </div>
                    </button>
                  )}
                </>
              )}

              {/* <a  href="/download"  className="nav-item nav-link nav-button ">
          <span className="bg-danger px-4 py-2 rounded  " style="color:white">Download App</span>
      </a> */}
            </div>
          </div>
        </div>
        {localStorage.getItem("accId") && localStorage.getItem("apiKey") && (
          <ChatBot />
        )}
      </nav>
      {location.pathname === "/home" && (
        <div
          ref={toastref}
          style={{
            height: "auto",
            width: "100%",
            backgroundColor: "#fff7a1",
            position: "absolute",
            top: "80px",
            zIndex: "100",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px 15px",
            transition: "ease-in-out",
            transitionProperty: "all",
            transitionDuration: "1s",
            boxShadow: " 0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            style={{
              width: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                marginTop: "5px",
                textAlign: "start",
                marginLeft: "auto",
                marginRight: "auto",
                color: "#807a39",
              }}
              className="header-notify-text"
            >
              <span style={{ fontWeight: "600", color: "#807a39" }}>
                {" "}
                Update :{" "}
              </span>{" "}
              Mykaasu Secures RBI In-Principal Approval! Get Ready for
              Hassle-Free Loans. Coming Soon!{" "}
            </p>
          </div>
          <X className="header-notify-close" onClick={handleCloseToast} />
        </div>
      )}

      
    </div>
  );
}

export default Header;
