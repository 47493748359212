import React, { useContext, useEffect, useRef, useState } from 'react'
import Metrixfunction from '../components/MetrixFunction';
import { MyContext } from '../components/GlobalValues';
function Privacy() {

  const {  metrixserver} = useContext(MyContext);


  const [imagesLoaded, setImagesLoaded] = useState(0);
const totalImages = useRef(0);

useEffect(() => {
  // Count the total number of images
  const imageElements = document.querySelectorAll('img');
  totalImages.current = imageElements.length;


  // Add load event listeners to all images
  if(imageElements.length>0)
  {
    imageElements.forEach(img => {
      if (img.complete) {
        handleImageLoad();
      } else {
        img.addEventListener('load', handleImageLoad);
        img.addEventListener('error', handleImageLoad); // Handle error case
      }
    });
  }
  else{
    Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");

  }

  // Cleanup function
  return () => {
    imageElements.forEach(img => {
      img.removeEventListener('load', handleImageLoad);
      img.removeEventListener('error', handleImageLoad);
    });
  };
}, []);

useEffect(() => {
  if (imagesLoaded === totalImages.current && totalImages.current > 0) {
    Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");

  }
}, [imagesLoaded]);

const handleImageLoad = () => {
  setImagesLoaded(prev => prev + 1);
};
  return (
    <div className="bg-white p-0">
  
  {/* Navbar & Hero Start */}
  <div className="position-relative p-0" id="home">
    <custom-header />
    <div className="bg-primary hero-header">
      <div className="container px-lg-5">
        <div className="row g-4">
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
            {/* <h5 className="fw-medium" style="color: white">Download</h5> */}
            <h1 className="mb-4" style={{ color: "white", fontSize: "4rem" }}>
              Privacy Policy
            </h1>
          </div>
          <div
            className="col-lg-4 d-flex justify-content-center justify-content-lg-end wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <img src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/Privacy.png" width="100%" height="100%" />
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Navbar & Hero End */}
  {/* Contact Start */}
  <div className="container-xxl py-5">
    <div className="container py-5 px-lg-5">
      <div className="text-center wow fadeInUp" data-wow-delay="0.1s"></div>
      <div className="row justify-content-center">
        <div className="col-lg-9">
          <div className="wow fadeInUp" data-wow-delay="0.3s">
            <p className="mb-4">
            The Privacy Policy governs the use of this website<a href="/home" target='_blank'> www.mykaasu.com </a> (Website/Site) of Mykaasu Finance Private Limited (Mykaasu) values the privacy of the user (User).
              <br />
              <br />
            By visiting the Website and all other links there under, the User agrees to be bound by the terms and conditions of the Privacy Policy of Mykaasu Finance Private Limited. By accepting the Privacy Policy, the User expressly consents to Mykaasu Finance Private Limited’s use and disclosure of the User’s information in accordance with this Privacy Policy.
              <br />
              <br />

              Mykaasu Finance Private Limited is committed to protecting your privacy and working towards offering you a powerful, safe, online experience. Our website<a href="/home" target='_blank'> www.mykaasu.com </a> does not collect personal information about individuals except when such individuals specifically provide such information on a voluntary basis which information will not be sold or otherwise transferred to unaffiliated third parties unless otherwise stated at the time of collection or with the approval of the User as the case may be.
            
              <br />
              <br />
              Mykaasu Finance Private Limited treats your personal information or your
               use of the service as private and confidential and does not check, edit 
               or reveal it to any third parties except where it believes in good faith,
                such action is necessary to comply with the applicable legal and regulatory 
                processes or to protect and defend the rights of other users or to enforce
                 the terms of service which are binding on all the users of<a href="/home" target='_blank'> www.mykaasu.com </a> 
                 Mykaasu Finance Private Limited and/or its affiliates will share / sell 
                 some or all of the User’s personal information with another business entity, 
                 should the Company (or its assets) plan to merge with, or be acquired by that
                  business entity, or re-organization, amalgamation, restructuring of business.
                   In the event such a transaction occurs, that other business entity (or the new 
                   combined entity) will be required to follow this Privacy Policy.

              <br />
              <br />
              
              Except where specifically agreed or necessary for operational or regulatory
               reasons, Mykaasu Finance Private Limited will not send you any unsolicited
                information. If the User does not opt out, Mykaasu Finance Private Limited 
                may use any e-mail addresses of users to send occasional e-mails pertaining
                 to information on product and services.
              
              <br />
              <br />

              The User can nevertheless unsubscribe from receipt of such e-mails by 
              following instructions therein. Mykaasu Finance Private Limited may collect
               unnamed statistics, which do not personally identify you. Mykaasu Finance
                Private Limited reserves the right to perform statistical analysis of user
                 behaviour and characteristics in order to measure interest in and use of
                  the various areas of the Site and to inform advertisers of such information
                   as well as the number of users that have been exposed to or clicked on their
                    advertising banners. We will provide only aggregated data from these analysis
                     to third parties. We will communicate with Users via e-mail or by other online
                      delivery devices only if the User agrees to/elects to receive/send those communications.
                       Users, who believe they are receiving our communications in error or no longer desire to
                        receive them, should inform us and we will remove those Users' names from our mailing lists.
                         Mykaasu Finance Private Limited will be judicious in the use of e-mail and paper mail to
                          communicate with users. Mykaasu Finance Private Limited reserves its right to correct any
                           part of the content of the Website at any time as and when required at its sole discretion.
                            The content of this Website shall not be displayed or printed in any form in part or whole without
                             the prior written approval of Mykaasu Finance Private Limited. The information contents provided on
                              this Website or the company logo cannot be copied, modified, uploaded, downloaded, published
                               or republished, transmitted or otherwise distributed for commercial purposes without prior and
                                explicit permission from Mykaasu Finance Private Limited. Reproduction of any information or
                                 material provided on this Website, with or without any modification, is prohibited unless,
                                  with prior permission of Mykaasu Finance Private Limited, and shall amount to violation of
                                   copyright and would be deemed an illegal act. You are requested to note that our financial products
                                    and the information, tools and planners contained in the Site,<a href="/home" target='_blank'> www.mykaasu.com </a> are meant for your general
                                     information only and not to be used for deciding on your financial requirements. You are specifically advised
                                      to contact one of our Branches for any of your particular financial needs.

            
            </p>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="wow fadeInUp" data-wow-delay="0.3s">
            <h2 className="mb-4">Protection of privacy of children/minor</h2>
            <p className="mb-4">
            Use of the Website is available only to persons who can form legally binding contracts under applicable law. 
            Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including un-discharged
             insolvents, etc. are not eligible to use the Website.
              
            </p>
          </div>
        </div>

        <div className="col-lg-9">
          <div className="wow fadeInUp" data-wow-delay="0.3s">
            <h2 className="mb-4">Use of Information and material</h2>
            <p className="mb-4">
            The information and materials contained in these pages and the terms,
             conditions, and descriptions that appear thereon are subject to change.
              They should not be regarded as an offer, solicitation, invitation, advice regarding
               any financial product of Mykaasu Finance Private Limited. You are therefore requested
                to verify the veracity of all information with one of the Branches before relying on the same.
                 Financial Products and services shown on the Website may be withdrawn or amended or varied at any time
                  at the sole discretion of Mykaasu Finance Private Limited. Save and except with Mykaasu Finance Private
                   Limited’s prior written consent, you shall not insert a hyperlink to this Website or any part thereof on
                    any other website or "mirror" or frame this Website, any part thereof, or any information or materials
                     contained in this Website on any other server, website or web-page.
              
              <br />
              <br />
              Wherever certain information is requested and necessary to enable you to use our web enabled services
               we gather such information, including personal information, so that we can better develop our relationship
                with Users. You may choose not to share any such information with us, but then you may not be able to take
                 advantage of the features on the Site.
              
            </p>
          </div>
        </div>


        <div className="col-lg-9">
          <div className="wow fadeInUp" data-wow-delay="0.3s">
            <h2 className="mb-4">Cookies</h2>
            <p className="mb-4">
              
            'Cookies' are computer data storage programs which enable a website to record a visitor's activities from his/ her
             computer hard drive. A cookie is placed on our server for as long as a session is active. No data from the user's
              computer is accessed from our Site. Our cookies tag each visitor's browser with a random, unique number. The cookie
               simply assigns a number and does not disclose any personal information about the person using the browser. Additionally,
                the User may encounter “cookies” or other similar data collection devices on certain pages of the Website that are placed
                 by third parties. Mykaasu Finance Private Limited hereby disclaims that it does not control the use of and are not responsible
                  for any activities of cookies by third parties.
              
            </p>
          </div>
        </div>
          
        <div className="col-lg-9">
          <div className="wow fadeInUp" data-wow-delay="0.3s">
            <h2 className="mb-4">No Warranty</h2>
            <p className="mb-4">
            The information, product descriptions, material, advices, suggestions, illustrations notifications, circulars etc. 
            are collectively stated "the content" in this Website. If the said content contains any mistakes, omissions, inaccuracies
             and typographical errors, etc. Mykaasu Finance Private Limited assumes no responsibility thereof.

             <br />
             <br />
             The information and materials contained in this Site, are provided "as is," "as available" basis. Mykaasu Finance Private
              Limited does not warrant the accuracy, adequacy or completeness of any of such information and materials. Mykaasu Finance
               Private Limited expressly disclaims any and all liability for errors or omissions in all such information and materials.
                No warranty of any kind, implied, express or statutory, including but not limited to the warranties relating to non-infringement
                 of third party rights, title, merchantability, fitness for a particular purpose and freedom from computer virus, is given in conjunction
                  with any of the information and materials published herein.
              
            </p>
          </div>
        </div>

        <div className="col-lg-9">
          <div className="wow fadeInUp" data-wow-delay="0.3s">
            <h2 className="mb-4">Limitation and Liability</h2>
            <p className="mb-4">
            Mykaasu Finance Private Limited shall not be liable for any loss or damage, including without limitation direct or
             indirect, special, economic, incidental, or consequential damages, losses or expenses arising in connection with this
              Site or use thereof or inability to use by any party, or in connection with any failure of performance, error, omission,
               interruption, defect, delay in operation or transmission, computer virus or line or system failure,
                even if Mykaasu Finance Private Limited has been advised of the possibility of such damages, losses or expenses.
              
            </p>
          </div>
        </div>

        <div className="col-lg-9">
          <div className="wow fadeInUp" data-wow-delay="0.3s">
            <h2 className="mb-4">Hyperlinks to other internet resources</h2>
            <p className="mb-4">
            Hyperlinks to other internet resources are for the User's convenience only and are at your own risk; the content, accuracy,
             legality of or restrictions imposed or opinions expressed by and other links provided by or for these resources are not
              investigated, verified, monitored, nor endorsed by Mykaasu Finance Private Limited. Mykaasu Finance Private Limited is
               not responsible either for their standards of public order and decency or for their personal data protection policy or
                the use that could be made of such data. When you access another website through a hypertext link, you are accepting that
                 this access is at your own risk. Consequently, Mykaasu Finance Private Limited will not be liable for any loss or damage
                  arising directly or indirectly from the accuracy or otherwise of materials or information contained on the pages of
                   such sites, or for any loss arising directly or indirectly from the defects with such sites.
            </p>
          </div>
        </div>


        <div className="col-lg-9">
          <div className="wow fadeInUp" data-wow-delay="0.3s">
            <h2 className="mb-4">Information</h2>
            <p className="mb-4">
            <b>Submission of Information:</b> Submissions to Mykaasu Finance Private Limited of information via Mykaasu Finance
             Private Limited's Website and pages thereon shall be deemed to be the property of Mykaasu Finance Private Limited.
              Please note that Mykaasu Finance Private Limited does not warrant that any e-mail from its Website is secure and does not
               guarantee that any such e-mail from you or from Mykaasu Finance Private Limited are secure during Internet transmission.

               <br />
               <br />
               <b>Public Access to Personal Information:</b> Please note that you should exercise caution when deciding to disclose
                or key-in your personal information on-line, and ensure that no third party is able to access such personal
                 information as you key it in or view it on the screen of your computer. This caution is to be exercised in
                  respect of the information that you may provide, to apply to become a customer of Mykaasu Finance Private Limited as well.
                
                <br />
                <br />
                This Privacy Policy shall be subject to change without notice. Mykaasu Finance Private Limited recommends visiting
                 this Privacy Policy every time the User visits the Website, in order to be informed about change, if any. In the event
                  the modified privacy policy is not acceptable to the User, the User should discontinue using the Website. However,
                   if the User continues to use the service the User shall be deemed to have agreed to accept and abide by the modified
                    privacy policy of this Website.
            </p>
          </div>
        </div>


        <div className="col-lg-9">
          <div className="wow fadeInUp" data-wow-delay="0.3s">
            <h2 className="mb-4">Privacy of Aadhaar</h2>
            <p className="mb-4">
            Mykaasu Finance Private Limited shall maintain the privacy aspects of Aadhaar as defined under Aadhar act, regulation, and specifications.
            </p>
          </div>
        </div>



      </div>
    </div>
  </div>
  {/* Contact End */}
  
  {/* Associate Partners End*/}
  {/* Footer Start */}
  <custom-footer />
  {/* Footer End */}
  {/* Back to Top */}
  <a href="#" className="btn btn-lg btn-lg-square back-to-top pt-2">
    <i className="bi bi-arrow-up home-text" />
  </a>
</div>

  )
}

export default Privacy