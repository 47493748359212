import React from 'react'
import '../styles/componentStyles/Error404.css'
import { ArrowRight } from 'react-bootstrap-icons';
import Header from './Header';

function Error404() {
  return (
    <>
    <Header/>
    <div className='error404-main'>
      <p className='error404-text'>404</p>
      <div className='error404-content-text-wrapper'>
        <p className='error404-content-text'>So sorry,</p>
        <p className='error404-content-text'>we couldn't find what you were looking for...</p>
        <button onClick={() => window.location.href = '/home'} className='error404-button'>Back to the home page <ArrowRight /></button>
      </div>
    </div>
    </>
  )
}

export default Error404;