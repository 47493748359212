import { Chart } from 'chart.js/auto';
import React, { useContext, useEffect, useRef, useState } from 'react'
import Metrixfunction from '../components/MetrixFunction';
import { MyContext } from '../components/GlobalValues';
import LoanCalculatorapp from './dynamic/LoanCalculator';

function LoansBusiness() {

  const {  metrixserver} = useContext(MyContext);


  const [imagesLoaded, setImagesLoaded] = useState(0);
const totalImages = useRef(0);
useEffect(() => {
  // Count the total number of images
  const imageElements = document.querySelectorAll('img');
  totalImages.current = imageElements.length;


  // Add load event listeners to all images
  if(imageElements.length>0)
  {
    imageElements.forEach(img => {
      if (img.complete) {
        handleImageLoad();
      } else {
        img.addEventListener('load', handleImageLoad);
        img.addEventListener('error', handleImageLoad); // Handle error case
      }
    });
  }
  else{
    Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");

  }

  // Cleanup function
  return () => {
    imageElements.forEach(img => {
      img.removeEventListener('load', handleImageLoad);
      img.removeEventListener('error', handleImageLoad);
    });
  };
}, []);

useEffect(() => {
  if (imagesLoaded === totalImages.current && totalImages.current > 0) {
    Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");

  }
}, [imagesLoaded]);

const handleImageLoad = () => {
  setImagesLoaded(prev => prev + 1);
};

    // ===============   Calculator ==========================================================

    // ========================
    useEffect(() => {
      const accItems = document.querySelectorAll('.accordion__item');
    
      function toggleAcc(event) {
        // Remove active class from all items except the current item
        accItems.forEach((item) => {
          if (item !== event.currentTarget) {
            item.classList.remove('accordion__item--active');
          }
        });
    
        // Toggle active class on the current item
        event.currentTarget.classList.toggle('accordion__item--active');
      }
    
      // Add click event listener to all items
      accItems.forEach((acc) => acc.addEventListener('click', toggleAcc));
    
      // Cleanup function to remove event listeners
      return () => {
        accItems.forEach((acc) => acc.removeEventListener('click', toggleAcc));
      };
    }, []); 


  return (
    <div className="bg-white container-fluid p-0">
    {/* Spinner Start */}
    <div
      id="spinner"
      className=" bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
    >
      <div
        className="spinner-grow text-danger"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
    {/* Spinner End */}
    {/* Navbar & Hero Start */}
    <div className="position-relative p-0" id="home">
      <custom-header />
      <div className="bg-primary hero-header">
        <div className="container px-lg-5">
          <div className="row  g-4 mb-4">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h1 className="mb-4" style={{ color: "white" }}>
                Unlock your dreams with our hassle-free loans.
              </h1>
              <h6 style={{ color: "white" }}>
                *Low Processing Charges &amp; Quick Loan Disbursals!
              </h6>
              {/* <div className="row g-4 mb-2" style="padding: 10px;">
                            <div className="col-sm-5 wow fadeIn d-flex bg-home-text rounded" style="padding: 5px;"  data-wow-delay="0.5s">
                                 <div className="col-8"><h5 className="home-text ms-2" style="margin-top: 7px; text-align: center; height: 30px !important;">Apply Now!</h5></div>
                                 <div className="col"><a href="#"  className="icon"><i className="fab fa-apple fa-2x home-text flex-shrink-0"></i></a></div>
                                 <div className="col"><a href="#" className="icon"><img src="./img/right-arrow (1).png" width="40px" style=" margin-top: 2px;  text-align: center"></a></div>
                            </div>
                        </div> */}
            </div>
            <div
              className="col-lg-6 mb-3  justify-content-center justify-content-lg-end wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <img
                src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/logo.png"
                width="100%"
                height="100%"
                style={{
                  filter:
                    "brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(10deg) brightness(101%) contrast(102%)"
                }}
              />
              {/* <div className="owl-carousel screenshot-carousel">
                            <img className="img-fluid" src="img/01.jpg" alt="">
                            <img className="img-fluid" src="img/02.jpg" alt="">
                            <img className="img-fluid" src="img/03.jpg" alt="">
                            <img className="img-fluid" src="img/04.jpg" alt="">
                            <img className="img-fluid" src="img/05.jpg" alt="">
                        </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Navbar & Hero End */}
    {/* --- Loan OverView Bar starts --- */}
    <main id="loanContent">
      {/* ------ Type of Loan ------- */}
      <section className="row d-flex flex-column justify-content-center align-items-center">
        <div className="col-md-12 col-lg-10  d-flex flex-column justify-content-center align-items-center ">
          <h1>Business Loan</h1>
          <p
            className="p-3 p-md-5"
            style={{ textAlign: "justify", fontFamily: '"Jost", sans-serif' ,color:"GrayText",fontSize:"19px" }}
          >
            With our business loans, you'll benefit from low-interest rates,
            helping your business flourish while managing costs effectively. In
            addition to low-interest rates, our business loans often come with
            flexible repayment terms. This means you can tailor the loan to your
            specific needs, whether it's a short-term injection of capital or a
            longer-term investment in your business's future.
          </p>
        </div>
      </section>
      {/* Features Start */}
      <div className="container-xxl bg-light  " id="feature">
        <div className=" py-5 px-lg-5">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h1 className="mb-5">Features &amp; Benefits</h1>
          </div>
          <div className="row g-4">
            <div className="col-lg-4 col-12 wow fadeInUp" data-wow-delay="0.1s">
              <div className="feature-item bg-home-text rounded p-4">
                <div
                  className="d-flex align-items-center"
                  style={{
                    gap: 30,
                    borderBottom: "1px solid lightgray",
                    marginBottom: 15
                  }}
                >
                  <div
                    className="d-inline-flex align-items-center justify-content-center  rounded-circle mb-4"
                    style={{ width: 80, height: 80 }}
                  >
                    <img
                      className="img-fluid"
                      src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/expenses.png"
                      alt=""
                    />
                  </div>
                  <h5 className="mb-3">Quick Disbursals</h5>
                </div>
                <p className="m-0" style={{ fontFamily: '"Jost", sans-serif' }}>
                  Get your loan amount as quick as 48 hours.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-12 wow fadeInUp" data-wow-delay="0.1s">
              <div className="feature-item bg-home-text rounded p-4">
                <div
                  className="d-flex align-items-center"
                  style={{
                    gap: 30,
                    borderBottom: "1px solid lightgray",
                    marginBottom: 15
                  }}
                >
                  <div
                    className="d-inline-flex align-items-center justify-content-center  rounded-circle mb-4"
                    style={{ width: 80, height: 80 }}
                  >
                    <img
                      className="img-fluid"
                      src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/documentation.png"
                      alt=""
                    />
                  </div>
                  <h5
                    className="mb-3"
                    style={{ fontFamily: '"Jost", sans-serif' }}
                  >
                    Minimal Documentation
                  </h5>
                </div>
                <p className="m-0" style={{ fontFamily: '"Jost", sans-serif' }}>
                  Save time with less paperwork.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-12 wow fadeInUp" data-wow-delay="0.1s">
              <div className="feature-item bg-home-text rounded p-4">
                <div
                  className="d-flex align-items-center"
                  style={{
                    gap: 30,
                    borderBottom: "1px solid lightgray",
                    marginBottom: 15
                  }}
                >
                  <div
                    className="d-inline-flex align-items-center justify-content-center  rounded-circle mb-4"
                    style={{ width: 80, height: 80 }}
                  >
                    <img
                      className="img-fluid"
                      src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/interestRate.png"
                      alt=""
                    />
                  </div>
                  <h5
                    className="mb-3"
                    style={{ fontFamily: '"Jost", sans-serif' }}
                  >
                    Interest Rate
                  </h5>
                </div>
                <p className="m-0" style={{ fontFamily: '"Jost", sans-serif' }}>
                  Our interest rate starts from 10% p.a.*
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Features End */}
      {/* ------ Loan Calculator------------- */}
      <div className='w-100'>
        <LoanCalculatorapp showcontent={false}/>
      </div>      {/* --------- how to apply? ---------- */}
      {/* ---------Eligilibility ----------- */}
      <div className="container-xxl bg-light " id="feature">
        <div className=" py-5 px-lg-5">
          <div
            className="text-center wow fadeInUp  "
            style={{ marginBottom: 100 }}
            data-wow-delay="0.1s"
          >
            <h1 className="mb-5">Eligibility</h1>
          </div>
          <div className="row gap-md-5 gap-lg-0 " style={{ gap: 70 }}>
            <div className="col-lg-4 col-12 wow fadeInUp" data-wow-delay="0.1s">
              <div
                className="feature-item bg-home-text rounded p-4"
                style={{ borderRadius: 0 }}
              >
                <div
                  className="d-flex align-items-center"
                  style={{
                    gap: 20,
                    borderBottom: "1px solid lightgray",
                    marginBottom: 15
                  }}
                >
                  <div
                    className="d-flex justify-content-center rounded-circle "
                    style={{
                      width: 100,
                      height: 95,
                      marginTop: "-75px",
                      backgroundColor: "white",
                      borderBottom: "2px solid #E32f45"
                    }}
                  >
                    <div
                      className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle "
                      style={{ width: 80, height: 80, padding: 15 }}
                    >
                      <img
                        className="img-fluid"
                        src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/employee.png"
                        alt=""
                        style={{ filter: "invert(1)" }}
                      />
                    </div>
                  </div>
                  <h5 className="mb-3">Age 21 - 59 years</h5>
                </div>
                <p className="m-0" style={{ fontFamily: '"Jost", sans-serif' }}>
                  Applicant aged 21 to 59 years are eligible to apply for Loan.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-12 wow fadeInUp" data-wow-delay="0.1s">
              <div className="feature-item bg-home-text rounded p-4">
                <div
                  className="d-flex align-items-center"
                  style={{
                    gap: 30,
                    borderBottom: "1px solid lightgray",
                    marginBottom: 15
                  }}
                >
                  <div
                    className="d-flex justify-content-center rounded-circle "
                    style={{
                      width: 100,
                      height: 95,
                      marginTop: "-75px",
                      backgroundColor: "white",
                      borderBottom: "2px solid #E32f45"
                    }}
                  >
                    <div
                      className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle "
                      style={{ width: 80, height: 80, padding: 15 }}
                    >
                      <img
                        className="img-fluid"
                        src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/businessman.png"
                        alt=""
                        style={{ filter: "invert(1)" }}
                      />
                    </div>
                  </div>
                  <h5 className="mb-3">Business Proof</h5>
                </div>
                <p className="m-0" style={{ fontFamily: '"Jost", sans-serif' }}>
                  Applicant must have a running business that's been successful
                  for at least two years.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-12 wow fadeInUp  " data-wow-delay="0.1s">
              <div className="feature-item bg-home-text rounded p-4">
                <div
                  className="d-flex align-items-center"
                  style={{
                    gap: 30,
                    borderBottom: "1px solid lightgray",
                    marginBottom: 15
                  }}
                >
                  <div
                    className="d-flex justify-content-center rounded-circle "
                    style={{
                      width: 100,
                      height: 95,
                      marginTop: "-75px",
                      backgroundColor: "white",
                      borderBottom: "2px solid #E32f45"
                    }}
                  >
                    <div
                      className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle "
                      style={{ width: 80, height: 80, padding: 15 }}
                    >
                      <img
                        className="img-fluid"
                        src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/house.png"
                        alt=""
                        style={{ filter: "invert(1)" }}
                      />
                    </div>
                  </div>
                  <h5 className="mb-3">Residential Proof</h5>
                </div>
                <p className="m-0" style={{ fontFamily: '"Jost", sans-serif' }}>
                  Applicant must be residing at least one year at the current
                  address.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* --------------FAQ----------------- */}
      {/* FAQ start */}
      <div className="wow fadeInUp dis row" data-wow-delay="0.3s" id="faq">
        <div className=" py-5 col-sm-12 col-lg-8 mx-auto">
          <h2 className="accordion__heading">Frequently Asked Questions</h2>
          <br />
          <div className="accordion__item">
            <button className="accordion__btn">
              <span
                className="accordion__caption"
                style={{ fontFamily: '"Jost", sans-serif' }}
              >
                What are the eligibility criteria for a business loan?
              </span>
              <span className="accordion__icon">
                <i className="fa fa-plus" />
              </span>
            </button>
            <div className="accordion__content">
              <p style={{ fontFamily: '"Jost", sans-serif' }}>
              To qualify for a business loan, you need to have a business with a good revenue stream and a strong credit score. Additionally, lenders may look at the business's age, profitability, and existing debts.
              </p>
            </div>
          </div>
          <div className="accordion__item">
            <button className="accordion__btn">
              <span
                className="accordion__caption"
                style={{ fontFamily: '"Jost", sans-serif' }}
              >
               How much can I borrow with a business loan?{" "}
              </span>
              <span className="accordion__icon">
                <i className="fa fa-plus" />
              </span>
            </button>
            <div className="accordion__content">
              <p style={{ fontFamily: '"Jost", sans-serif' }}>
              The loan amount for business loans depends on your business's revenue, credit score, and overall financial health. Business loans can range from a few lakhs to several crores.
              </p>
            </div>
          </div>
          <div className="accordion__item">
            <button className="accordion__btn">
              <span
                className="accordion__caption"
                style={{ fontFamily: '"Jost", sans-serif' }}
              >
                What is the interest rate for business loans?{" "}
              </span>
              <span className="accordion__icon">
                <i className="fa fa-plus" />
              </span>
            </button>
            <div className="accordion__content">
              <p style={{ fontFamily: '"Jost", sans-serif' }}>
              Business loan interest rates vary based on the lender, your business's creditworthiness, and the loan amount. Typically, business loan interest rates range from 12% to 36% per annum
              </p>
            </div>
          </div>
          <div className="accordion__item">
            <button className="accordion__btn">
              <span
                className="accordion__caption"
                style={{ fontFamily: '"Jost", sans-serif' }}
              >
               What documents are required to apply for a business loan?{" "}
              </span>
              <span className="accordion__icon">
                <i className="fa fa-plus" />
              </span>
            </button>
            <div className="accordion__content">
              <p style={{ fontFamily: '"Jost", sans-serif' }}>
              Commonly required documents include proof of identity, business registration documents, financial statements (profit and loss statement, balance sheet), bank statements, and tax returns
              </p>
            </div>
          </div>
          <div className="accordion__item">
            <button className="accordion__btn">
              <span
                className="accordion__caption"
                style={{ fontFamily: '"Jost", sans-serif' }}
              >
               How long does it take to get a business loan approved?{" "}
              </span>
              <span className="accordion__icon">
                <i className="fa fa-plus" />
              </span>
            </button>
            <div className="accordion__content">
              <p style={{ fontFamily: '"Jost", sans-serif' }}>
              The approval process for business loans can vary by lender and the complexity of your business. It typically takes a few days to a week, depending on the thoroughness of your application and documentation.              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
    {/* --------------Interest Rate Policy---------------- */}
    {/* Footer Start */}
    <custom-footer />
    {/* Footer End */}
    {/* Back to Top */}
    <a href="#" className="btn btn-lg btn-lg-square back-to-top pt-2">
      <i className="bi bi-arrow-up home-text" />
    </a>
  </div>
  

  )
}

export default LoansBusiness