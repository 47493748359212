
const Metrixfunction=async(Url,apiname,action)=>{

  function detectUserAgent(userAgent) {
    const platform = {
        Mobile: /Mobi/i.test(userAgent),
        Tablet: /Tablet/i.test(userAgent),
        Bot: /bot|crawler|spider/i.test(userAgent),
        Windows: /Windows/i.test(userAgent),
        Mac: /Macintosh/i.test(userAgent),
        Linux: /Linux/i.test(userAgent)
    };

    let device;
    if (platform.Mobile) {
        device = "Mobile";
    } else if (platform.Tablet) {
        device = "Tablet";
    } else {
        device = "Desktop";
    }

    let truePlatform = Object.keys(platform).find(key => platform[key]);


    return {
        platform: truePlatform,
        device: device,
        isBot: platform.Bot
    };
}

// Example usage:
const userAgent = navigator.userAgent; // Gets the user agent string from the browser
const userAgentResult = detectUserAgent(userAgent);

  
  // Usage



  function removeSlash(port1) {
    let p1 = port1.split("/").join("");

    let np = p1;
    return np;
  }

  let accId=localStorage.getItem("accId")
  

   
    await fetch(`${Url}/metrics/createMetrics`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            accId:accId===null?"NA":accId,
            pageName: removeSlash(window.location.pathname),
            apiName:apiname,
            siteName: "MYKAASU",
            action: action,
            userAgent:userAgentResult,
            sessionId:localStorage.getItem("loginId")
          }),
        }
      )
      .then((res) => res.json())
      .then((resJson) => {
      
       })
      .catch((err) => {
     
      console.log(err,"error")
      })
}

export default Metrixfunction