import React, { useContext, useEffect, useRef, useState } from 'react'
import Metrixfunction from '../components/MetrixFunction';
import { MyContext } from '../components/GlobalValues';
function Investors() {

  const {  metrixserver} = useContext(MyContext);


  const [imagesLoaded, setImagesLoaded] = useState(0);
const totalImages = useRef(0);
useEffect(() => {
  // Count the total number of images
  const imageElements = document.querySelectorAll('img');
  totalImages.current = imageElements.length;


  // Add load event listeners to all images
  if(imageElements.length>0)
  {
    imageElements.forEach(img => {
      if (img.complete) {
        handleImageLoad();
      } else {
        img.addEventListener('load', handleImageLoad);
        img.addEventListener('error', handleImageLoad); // Handle error case
      }
    });
  }
  else{
    Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");

  }

  // Cleanup function
  return () => {
    imageElements.forEach(img => {
      img.removeEventListener('load', handleImageLoad);
      img.removeEventListener('error', handleImageLoad);
    });
  };
}, []);

useEffect(() => {
  if (imagesLoaded === totalImages.current && totalImages.current > 0) {
    Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");

  }
}, [imagesLoaded]);

const handleImageLoad = () => {
  setImagesLoaded(prev => prev + 1);
};
  return (
    <div className="bg-white p-0 container-fluid ">
    {/* Spinner Start */}
    <div
      id="spinner"
      className=" bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
    >
      <div
        className="spinner-grow text-danger"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
    {/* Spinner End */}
    {/* Navbar & Hero Start */}
    <div className="position-relative p-0" id="home">
      <custom-header />
      <div className="bg-primary hero-header">
        <div className="container px-lg-5">
          <div className="row g-4 mb-4">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h1 className="mb-4" style={{ color: "white" }}>
                MyKaasu Financials And Business Overview
              </h1>
              {/* <p className="mb-4" style="color: white">Apply Now!</p> */}
            </div>
            <div
              className="col-lg-6 d-flex justify-content-center justify-content-lg-end wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <img src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/Financials.png" width="100%" height="100%" />
              {/* <div className="owl-carousel screenshot-carousel">
                            <img className="img-fluid" src="img/01.jpg" alt="">
                            <img className="img-fluid" src="img/02.jpg" alt="">
                            <img className="img-fluid" src="img/03.jpg" alt="">
                            <img className="img-fluid" src="img/04.jpg" alt="">
                            <img className="img-fluid" src="img/05.jpg" alt="">
                        </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Navbar & Hero End */}
    <div className="container mb-5 mt-5 ">
      <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h5 className="text-primary-gradient fw-medium" />
        <h1 className="mb-5">Investor Information</h1>
      </div>

      {/* <div className="row  flex-row-reverse ">
        <div className="col-12 col-md-4 col-lg-2 align-self-end  mb-5">
         
          <h4>Select Year</h4>
          surround the select box with a "custom-select" DIV element. Remember to set the width:
          <div className="custom-select" style={{ width: 200 }}>
            <select>
              <option value={0}>Select Year:</option>
              <option value={1}>2022</option>
              <option value={2}>2023</option>
            </select>
          </div>
        </div>
      </div> */}

      <div className="row d-flex flex-column gap-4 ">
        <div className="col-12 d-flex justify-content-between   flex-wrap   border-bottom mb-4 ">
          <h5 style={{ width: 310 }}>RBI NBFC Approval</h5>
          <div className=" p-2 rounded pdfDownload">
            <a href="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/docs/Mykaasu%20Finance%20Private%20Limited%20-%20In%20principle%20approval.pdf" target="_blank" rel="noreferrer">
              <img
                src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/download.png"
                height="18px"
                width="18px"
                style={{
                  filter:
                    "brightness(0) saturate(100%) invert(52%) sepia(73%) saturate(1457%) hue-rotate(186deg) brightness(93%) contrast(91%)"
                }}
                alt=""
              />
              <span> Download</span>
            </a>
          </div>
        </div>
       
      </div>
      <div className="row d-flex flex-column gap-4 ">
        <div className="col-12 d-flex justify-content-between   flex-wrap   border-bottom mb-4 ">
          <h5 style={{ width: 310 }}>Annual FY 23-24 Report</h5>
          <div className=" p-2 rounded pdfDownload" style={{cursor:"default"}}>
            <a   rel="noreferrer" className="nlbtn">
              <img
                src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/download.png"
                height="18px"
                width="18px"
                style={{
                  opacity:"0.7",
                  // filter:
                  //   "brightness(0) saturate(100%) invert(52%) sepia(73%) saturate(1457%) hue-rotate(186deg) brightness(93%) contrast(91%)"
                  filter: "brightness(0) saturate(100%) invert(79%) sepia(0%) saturate(0%) hue-rotate(158deg) brightness(82%) contrast(92%)"
                }}
                alt=""
              />
              <span> Download</span>
            </a>
          </div>
        </div>
       
      </div>
    </div>
    {/* Footer Start */}
    <custom-footer />
    {/* Footer End */}
    {/* Back to Top */}
    <a href="#" className="btn btn-lg btn-lg-square back-to-top pt-2">
      <i className="bi bi-arrow-up home-text" />
    </a>
  </div>
  
  
  )
}

export default Investors