import React, { useEffect, useRef, useState } from "react";
import "../../styles/screenStyles/Previewscreen.css";
import InputField from "../../components/InputField";
import Dropdown from "../../components/DropDown";
import { Calendar, CreditCard } from "react-feather";
import { Bank, CurrencyRupee } from "react-bootstrap-icons";
import Slider from "../../components/Slider";
import UserInfo from "./UserInfo";
import WorkInfo from "./WorkInfo";
import Aadhardetails from "./Aadhardetails";
import Pandetails from "./Pandetails";
import BankInformation from "../dynamic/BankInformation";
import { useContext } from "react";
import { MyContext } from "../../components/GlobalValues";
import Loanselect from "./Loanselect";
import Additionalinformation from "./Additionalinformation";
import Modal from "../../components/Modal";
import Termscomponent from "../../components/Termscomponent";

function Previewscreen({
  eligibilitydata,
  component,
  componenttype,
  senddata,
}) {
  const { Url, eligibilitydetails, seteligibilitydetails, hasScrolled,setHasScrolled } =
    useContext(MyContext);

  const [isopen, setisopen] = useState(false);

  const openModal = () => setisopen(true);
  const closeModal = () => setisopen(false);

  const modalRef = useRef(null);


  const handlescrolldetect=()=>{
    setHasScrolled(true)

  }



  const handleOptionChange = (event) => {
    setSelectedOption({ ...selectedOption, creditcard: event.target.value });
  };

  const handleloanChange = (event) => {
    setSelectedOption({ ...selectedOption, currentloan: event.target.value });
  };

  const handleLoanChange = (e) => {
    seteligibilitydetails({
      ...eligibilitydetails,
      loancount: parseFloat(e.target.value),
    });
  };

  const handleLoanmonthChange = (e) => {
    seteligibilitydetails({
      ...eligibilitydetails,
      loanperiod: parseFloat(e.target.value),
    });
  };

  const options = [1, 2, 3, 4, "more than 4"];

  const [selectedOption, setSelectedOption] = useState({
    creditcard: "",
    cardcount: "",
    currentloan: "",
    outstandingAmount: "",
    loancount: "",
  });

  const handleSelect = (option) => {
    setSelectedOption({ ...selectedOption, cardcount: option });
    // Handle selected option here
    // console.log("Selected option:", option);
    // console.log("Selected option:", option);
  };

  const handleloanSelect = (option) => {
    setSelectedOption({ ...selectedOption, loancount: option });
    // Handle selected option here
    // console.log("Selected option:", option);
  };

  const numberregex = /^[0-9-]+$/;

  const handleamount = (value) => {
    if (numberregex.test(value) || value === "") {
      setSelectedOption({ ...selectedOption, outstandingAmount: value });
    }
  };

  const footerterm = () => {
    return (
      <div className="preview-agree-modal">
        <input
          type="checkbox"
          className="preview-agreeinput"
          checked={eligibilitydetails.termagree}
          onChange={() => {
            seteligibilitydetails({
              ...eligibilitydetails,
              termagree: !eligibilitydetails.termagree,
            });
          }}
          disabled={!hasScrolled}
        />
        {/* <b style={{cursor:"pointer"}} onClick={openModal}><a href="#" onClick={(e)=>{e.preventDefault()}}> I agree to the terms and conditions of the loan application </a></b> */}
        <a
          className="text-primary"     
        >
          I agree to the <span> terms and conditions</span> of the loan
          application
        </a>
      </div>
    );
  };

  return (
    <>
      {component && (
        <div>
          <Loanselect
            className="preview-component-container"
            componenttype={"preview"}
          />
        </div>
      )}
      <div>
        <UserInfo
          className="preview-component-container"
          componenttype={component ? "loanpreview" : "preview"}
          component={true}
        />
      </div>
      <div className="preview-component-container">
        <WorkInfo
          componenttype={component ? "loanpreview" : "preview"}
          component={true}
        />
      </div>

      <div className="preview-component-container">
        <Pandetails
          componenttype={component ? "loanpreview" : "preview"}
          component={true}
        />
      </div>

      {component ? (
        <>
          {" "}
          <div className="preview-component-container">
            <Aadhardetails componenttype={"preview"} component={true} />
          </div>
          <Additionalinformation componenttype={"preview"} />
          <div className="preview-component-container">
            <BankInformation componenttype={"preview"} component={true} />
          </div>
          <div className="preview-Terms-container">
            <div className="preview-Terms-wrapper">
              <p className="terms-word-preview">Terms and Conditions</p>
              <div className="preview-agree">
                <input
                  type="checkbox"
                  className="preview-agreeinput"
                  checked={eligibilitydetails.termagree}
                  onChange={() => {
                   openModal()
                  }}
                />
                {/* <b style={{cursor:"pointer"}} onClick={openModal}><a href="#" onClick={(e)=>{e.preventDefault()}}> I agree to the terms and conditions of the loan application </a></b> */}
                <a
                  style={{ cursor: "pointer" }}
                  target="_blank"
                  className="text-primary"
                  onClick={openModal}
                >
                  I agree to the <span> terms and conditions</span> of the loan
                  application
                </a>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="preview-container">
          <div className="preview-wrapper">
            <div className="preview-form">
              <div className="preview-form-wrapper">
                {/* <div className="preview-label-container">
              <label className="preview-label">
                {" "}
                Do you have a Credit card
              </label>

              <div className="preview-label-wrapper">
                <label className="preview-radio-label">
                  <input
                    type="radio"
                    name="Credit-card"
                    value="yes"
                    checked={selectedOption.creditcard === "yes"}
                    className="preview-radio-input"
                    onChange={handleOptionChange}
                  />
                  <div className="w9-form-font">Yes</div>
                </label>
                <label className="preview-radio-label">
                  <input
                    type="radio"
                    name="Credit-card"
                    value="no"
                    checked={selectedOption.creditcard === "no"}
                    className="preview-radio-input"
                    onChange={handleOptionChange}
                  />
                  <div className="w9-form-font">No</div>
                </label>
              </div>
            </div> */}

                {/* {selectedOption.creditcard === "yes" && (
              <>
                <div className="preview-label-container">
                  <label className="preview-label">
                    {" "}
                    What's your outstanding balance
                  </label>
                  <div className="preview-input-wrapper">
                    <InputField
                      editable={true}
                      containerclassname={"preview-balance-input"}
                      leftIcon={<span className="currencyspan">₹</span>}
                      value={selectedOption.outstandingAmount}
                      onChangeText={handleamount}
                    />
                  </div>
                </div>
                <div className="preview-label-container">
                  <label className="preview-label">
                    {" "}
                    How many credit cards do you have
                  </label>
                  <div className="preview-input-wrapper">
                    <Dropdown
                      options={options}
                      leftIcon={
                        <CreditCard size={20} className="currencyspan" />
                      }
                      onSelect={handleSelect}
                      drpdownWidth={"100%"}
                      drpdownHeight={"50px"}
                      placeHolder={"Select"}
                      editable={true}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="preview-label-container">
              <label className="preview-label">
                {" "}
                Do you have any Current loans
              </label>

              <div className="preview-label-wrapper">
                <label className="preview-radio-label">
                  <input
                    type="radio"
                    name="loan"
                    value="yes"
                    checked={selectedOption.currentloan === "yes"}
                    className="preview-radio-input"
                    onChange={handleloanChange}
                  />
                  <div className="w9-form-font">Yes</div>
                </label>
                <label className="preview-radio-label">
                  <input
                    type="radio"
                    name="loan"
                    value="no"
                    checked={selectedOption.currentloan === "no"}
                    className="preview-radio-input"
                    onChange={handleloanChange}
                  />
                  <div className="w9-form-font">No</div>
                </label>
              </div>
            </div> */}

                {/* {console.log(selectedOption,"selectedOption")} */}

                {/* {selectedOption.currentloan === "yes" && (
              <>
                <div className="preview-label-container">
                  <label className="preview-label">
                    {" "}
                    How many Loans do you have
                  </label>
                  <div className="preview-input-wrapper">
                    <Dropdown
                      options={options}
                      leftIcon={
                        <Bank size={18} className="currencyspan" color="gray" />
                      }
                      onSelect={handleloanSelect}
                      drpdownWidth={"100%"}
                      drpdownHeight={"50px"}
                      placeHolder={"Select"}
                      editable={true}
                    />
                  </div>
                </div>
              </>
            )} */}
                <div className="preview-label-loan mb-3">
                  <label className="preview-label">
                    {" "}
                    How much loan do you need
                  </label>
                  <div className="">
                    <div
                      style={{
                        // backgroundColor: "rgba(227, 224, 224, 0.277)",
                        paddingLeft: 15,
                        border: "none",
                        borderRadius: 10,
                      }}
                      className="preview-range-wrapper"
                    >
                      {/* <h5 id="loan-period-text" style={{ color: "#E32f45" }}>{N}</h5> */}
                      <div className="loan-range-container">
                        <input
                          type="range"
                          className="range-loan"
                          id="loan-period"
                          min={50000}
                          max={500000}
                          step={10000}
                          value={eligibilitydetails.loancount}
                          onChange={handleLoanChange}
                        />
                      </div>
                      <div className="range-show-input-wrapper">
                        <InputField
                          value={eligibilitydetails.loancount}
                          containerclassname={"range-show-input"}
                          leftIcon={<CurrencyRupee />}
                          editable={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="preview-label-loan mb-4 mt-5">
                  <label className="preview-label">
                    {" "}
                    What's your loan tenure period
                  </label>
                  <div className="">
                    <div
                      style={{
                        // backgroundColor: "rgba(227, 224, 224, 0.277)",
                        paddingLeft: 15,
                        border: "none",
                        borderRadius: 10,
                      }}
                      className="preview-range-wrapper"
                    >
                      {/* <h5 id="loan-period-text" style={{ color: "#E32f45" }}>{N}</h5> */}
                      <div className="loan-range-container">
                        <input
                          type="range"
                          className="range-loan"
                          id="loan-period"
                          min={6}
                          max={42}
                          step={1}
                          value={eligibilitydetails.loanperiod}
                          onChange={handleLoanmonthChange}
                        />
                      </div>
                      <div className="range-show-input-wrapper">
                        <InputField
                          value={eligibilitydetails.loanperiod + "  Months"}
                          containerclassname={"range-show-input"}
                          leftIcon={<Calendar size={17} />}
                          editable={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        isOpen={isopen}
        onClose={closeModal}
        modalTitle={"Terms And Conditions"}
        modalwidth={"55%"}
        savebtn={false}
        closebtn={true} 
        footerContent={footerterm()}
        modalref={modalRef}
        scrolldetection={true}
        handlescrolldetect={handlescrolldetect}
        hasScrolled={hasScrolled}
      >
        <Termscomponent />
      </Modal>
    </>
  );
}

export default Previewscreen;
