import React, { useState ,useEffect, useRef} from 'react';
import '../styles/componentStyles/Slider.css'; 

const Slider = ({ min, max, step, onChange,initialValue,disable=false,onstepcomplete=()=>{} }) => {
    const [value, setValue] = useState(initialValue || min);

  const handleChange = (event) => {
    const newValue = parseFloat(event.target.value);
    setValue(newValue);
    onChange(newValue);
  };

//   const handleChange = (event) => {
//     let newValue;
//     if (valueType === 'float') {
//       newValue = parseFloat(event.target.value);
//     } else {
//       newValue = parseInt(event.target.value, 10);
//     }
//     setValue(newValue);
//     onChange(newValue);
//   };

const [isInteracting, setIsInteracting] = useState(false);

 
  useEffect(() => {
    onChange(value);
  }, []); 

  useEffect(() => {
    setValue(initialValue || min);
  }, [initialValue]);

  const sliderRef = useRef(null);


  useEffect(() => {
    const slider = sliderRef.current;

    const handleMouseDown = () => setIsInteracting(true);
    const handleMouseUp = () => { 
 
      onstepcomplete(slider.value)};
  

    if (slider) {
      slider.addEventListener('mousedown', handleMouseDown);
      slider.addEventListener('mouseup', handleMouseUp);
      // slider.addEventListener('touchstart', handleTouchStart);
      // slider.addEventListener('touchend', handleTouchEnd);
    }

    return () => {
      if (slider) {
        slider.removeEventListener('mousedown', handleMouseDown);
        slider.removeEventListener('mouseup', handleMouseUp);
        // slider.removeEventListener('touchstart', handleTouchStart);
        // slider.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, []);

  return (
    <div className="range-slider">
      <input
        ref={sliderRef}
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
        onFocus={() => {
          
        }}
        onBlur={() => {
           
        }}
        style={{
          background: `linear-gradient(to right, #E32f45 ${
            ((value - min) / (max - min)) * 100
          }%, #ddd 0%)`,
        }}
        disabled={disable}
      />
    </div>
  );
};

export default Slider;

// <Slider min={} max={} step={} initialValue={} onChange={} />

