import React, { useContext, useEffect, useRef, useState } from 'react'
import { MyContext } from '../components/GlobalValues';
import Alert from '../components/Alert';
import { validationRegex } from "../utlis/Regex";
import Metrixfunction from '../components/MetrixFunction';


function ContactUs() {



  const [imagesLoaded, setImagesLoaded] = useState(0);
const totalImages = useRef(0);

useEffect(() => {
  // Count the total number of images
  const imageElements = document.querySelectorAll('img');
  totalImages.current = imageElements.length;


  // Add load event listeners to all images
  if(imageElements.length>0)
  {
    imageElements.forEach(img => {
      if (img.complete) {
        handleImageLoad();
      } else {
        img.addEventListener('load', handleImageLoad);
        img.addEventListener('error', handleImageLoad); // Handle error case
      }
    });
  }
  else{
    Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");

  }

  // Cleanup function
  return () => {
    imageElements.forEach(img => {
      img.removeEventListener('load', handleImageLoad);
      img.removeEventListener('error', handleImageLoad);
    });
  };
}, []);

useEffect(() => {
  if (imagesLoaded === totalImages.current && totalImages.current > 0) {
    Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");

  }
}, [imagesLoaded]);

const handleImageLoad = () => {
  setImagesLoaded(prev => prev + 1);
};

  const { Url,metrixserver} =useContext(MyContext);

  const [sendbtn,setsendbtn]=useState(false)


  const [feedbackdata,setfeedbackdata]=useState({name:"",phone:"",email:"",subject:"",message:""})

  const [showalert, setshowalert] = useState({
    show: false,
    msg: "",
    color: "",
  });

  const alertmessage = (msg, color) => {
    setshowalert({ ...showalert, show: true, msg: msg, type: color });

    setTimeout(() => {
      setshowalert({ ...showalert, show: false, msg: "", type: "" });
    }, 3000);
  };

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const cmntregex = /^(?=.*[a-zA-Z0-9]).+$/;


  const sendform = async () => {

    if(!validationRegex.userName.test(feedbackdata.name))
      {
        alertmessage("Enter a valid Name","danger")
      }
      else if(!emailRegex.test(feedbackdata.email))
        {
          alertmessage("Enter a valid Mail","danger")
        }
        else if(!validationRegex.phone.test(feedbackdata.phone))
          {
            alertmessage("Enter a valid Phone Number","danger")
          }
        else if(!cmntregex.test(feedbackdata.subject) || feedbackdata.subject.length<3)
          {
            alertmessage("Enter a valid Subject","danger")
          }
          else if(!cmntregex.test(feedbackdata.message) || feedbackdata.message.length<3)
            {
              alertmessage("Enter a valid Message","danger")
            }
          else{
            let query = {
              name: feedbackdata.name,
              phone: feedbackdata.phone,
              email: feedbackdata.email,
              subject: feedbackdata.subject,
              message: feedbackdata.message,
            };
        
        
            try {
              setsendbtn(true)

              await fetch(`${Url}/business/submitForm`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(query),
              })
                .then((res) => res.json())
                .then((resJson) => {
                  if(resJson.Success)
                    {
                      alertmessage("Feedback Submitted","success")
                      setfeedbackdata({name:"",phone:"",email:"",subject:"",message:""})
                    }
                    else{
                      alertmessage(resJson.Error,"danger")
                    }
                    setsendbtn(false)
        
                })
                .catch((err)=>{
                    console.log(err,"error")
                    setsendbtn(false)
                })
                .finally(()=>{
              Metrixfunction(metrixserver, "submitForm", "UPDATE");

                })
            } catch (error) {
              console.log("Error :", error);
              setsendbtn(false)
        
        
              // setMobileNoError('Invalid Mobile No');
            }
            
          }
  };

 
  return (
    <div className="bg-white p-0">
      {/* Spinner Start */}
      <div
        id="spinner"
        className=" bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <div
          className="spinner-grow text-danger"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      {/* Spinner End */}
      {/* Navbar & Hero Start */}
      <div className="position-relative p-0" id="home">
        <custom-header />
        <div className="bg-primary hero-header">
          <div className="container px-lg-5">
            <div className="row g-4">
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                <h1 className="mb-4" style={{ color: "white" }}>
                  Let's connect and make your questions our priority.
                </h1>
                {/* <p className="mb-4" style="color: white">Apply Now!</p> */}
              </div>
              <div
                className="col-lg-6 d-flex justify-content-center justify-content-lg-end wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <img
                  src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/Contactus.png"
                  width="100%"
                  height="100%"
                />
                {/* <div className="owl-carousel screenshot-carousel">
                          <img className="img-fluid" src="img/01.jpg" alt="">
                          <img className="img-fluid" src="img/02.jpg" alt="">
                          <img className="img-fluid" src="img/03.jpg" alt="">
                          <img className="img-fluid" src="img/04.jpg" alt="">
                          <img className="img-fluid" src="img/05.jpg" alt="">
                      </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Navbar & Hero End */}
      {/* Contact Start */}
      <div className="container-xxl py-5" id="contact">
        <div className="container py-5 px-lg-5">
          <div className="text-center wow fadeInUp " data-wow-delay="0.1s" style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
            <h1 className="mb-3">We value your feedback. </h1>
            <h6 className="text-primary-gradient mb-5" style={{textAlign:"left", }}>
              Please share your questions, concerns, or any feedback with us,
              and we'll do our best to address them promptly
            </h6>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="wow fadeInUp" data-wow-delay="0.3s">
                {/* <p className="text-center mb-4">The contact form is currently inactive. Get a functional and working contact form with Ajax & PHP in a few minutes. Just copy and paste the files, add a little code and you're done. <a href="https://htmlcodex.com/contact-form">Download Now</a>.</p> */}
                <form>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Your Name"
                          value={feedbackdata.name}
                          onChange={(e) => {
                            if (
                              /^[a-zA-Z ]+$/.test(e.target.value) ||
                              e.target.value === ""
                            ) {
                              setfeedbackdata({
                                ...feedbackdata,
                                name: e.target.value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="name">Your Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Your Email"
                          value={feedbackdata.email}
                          onChange={(e) => {
                            setfeedbackdata({
                              ...feedbackdata,
                              email: e.target.value,
                            });
                          }}
                        />
                        <label htmlFor="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="tel"
                          className="form-control"
                          id="phoneNumber"
                          placeholder="Your Phone Number"
                          value={feedbackdata.phone}
                          maxLength={10}
                          onChange={(e) => {
                            if (
                              /^[0-9]+$/.test(e.target.value) ||
                              e.target.value === ""
                            ) {
                              setfeedbackdata({
                                ...feedbackdata,
                                phone: e.target.value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="phoneNumber">Your Phone Number</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="subject"
                          placeholder="Subject"
                          value={feedbackdata.subject}
                          onChange={(e) => {
                            setfeedbackdata({
                              ...feedbackdata,
                              subject: e.target.value,
                            });
                          }}
                        />
                        <label htmlFor="subject">Subject</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          placeholder="Leave a message here"
                          id="message"
                          style={{ height: 150 }}
                          value={feedbackdata.message}
                          onChange={(e) => {
                            setfeedbackdata({
                              ...feedbackdata,
                              message: e.target.value,
                            });
                          }}
                        />
                        <label htmlFor="message">Message</label>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="col-12 text-center" style={{ margin: 10 }}>
                  <div
                    id="success-show"
                    className="alert alert-success"
                    role="alert"
                  >
                    <span className="d-flex align-items-center flex-wrap ">
                      {" "}
                      <input
                        id="Txt-value-success"
                        className="txt-style text-center"
                        type="text"
                        style={{ fontWeight: 500, width: "100% !important" }}
                        disabled=""
                      />
                    </span>
                  </div>
                  <div
                    id="danger-show"
                    className="alert alert-danger"
                    role="alert"
                  >
                    <span>
                      {" "}
                      <input
                        id="Txt-value-danger"
                        className="txt-style text-center"
                        type="text"
                        style={{ fontWeight: 500, width: "100% !important" }}
                        disabled=""
                      />
                    </span>
                  </div>
                  <button
                    className="btn btn-primary-gradient rounded-pill py-3 px-5  "
                    type="submit"
                    onClick={sendform}
                    disabled={
                      sendbtn ||
                      feedbackdata.email.length < 5 ||
                      feedbackdata.message.length < 3 ||
                      feedbackdata.name.length < 3 ||
                      feedbackdata.phone.length < 10 ||
                      feedbackdata.subject.length < 3
                    }
                  >
                    <span id="buttonText">Send Message</span>
                    <span id="loader" style={{ display: "none" }}>
                      <svg viewBox="25 25 50 50">
                        <circle r={20} cy={50} cx={50} />
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact End */}
      {/* Footer Start */}
      <custom-footer />
      {/* Footer End */}
      {showalert.show && (
        <Alert
          show={true}
          message={showalert.msg}
          type={showalert.type}
          duration={3000}
          onClose={() => {
            setshowalert({ show: false, message: "", type: "" });
          }}
        />
      )}
    </div>
  );
}

export default ContactUs