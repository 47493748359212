import React from 'react'

function Footer() {
  return (
    <div
      className="container-fluid bg-primary text-light footer wow fadeIn"
      data-wow-delay="0.1s"
    >
      <img
        alt=""
        src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/bg-top.png"
        className="bg-top-filter"
      />

      <div className="container py-5 px-lg-5">
        <div className="row g-5">
          <div className="col-md-8 col-lg-6">
            <h4 className="text-white mb-4">Address</h4>
            <p>
              <i className="fa fa-map-marker-alt me-3" />
              MyKaasu Finance Pvt Ltd,
              <div style={{marginLeft:"27px"}}>
              <p>GREETA TOWERS, Industrial Estate, Perungudi,</p>
              <p>Chennai, Tamil Nadu 600096</p>
              </div>
            </p>
            <p>
              <i className="fa fa-phone-alt me-3" />
              +91-7200490494
            </p>
            <p>
              <i className="fa fa-envelope me-3" />
              support@mykaasu.com
            </p>
            <div className="d-flex pt-2">
              <a
                className="btn btn-outline-light btn-social"
                href="https://twitter.com/My_Kaasu?t=xgnLj6cJzbmhd4WVQtNpZA&s=08"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-twitter" />
              </a>
              <a
                className="btn btn-outline-light btn-social"
                href="https://www.facebook.com/MyKaasuoffical?mibextid=ZbWKwL"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook-f" />
              </a>
              <a
                className="btn btn-outline-light btn-social"
                href="https://www.instagram.com/my_kaasu/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram" />
              </a>
             
            </div>
          </div>
          {/* <div className="col-md-6 col-lg-3">
                <h4 className="text-white mb-4">Quick Link</h4>
                <a className="btn btn-link" href="">About Us</a>
                <a className="btn btn-link" href="">Contact Us</a>
                <a className="btn btn-link" href="/privacypolicy">Privacy Policy</a>
                <a className="btn btn-link" href="/termsandconditions">Terms & Condition</a>
                <a className="btn btn-link" href="">Career</a>
            </div> */}
          <div className="col-md-8 col-lg-6">
            <h4 className="text-white mb-4">Quick Link</h4>
            <a className="btn btn-link" href="/">
              About Us
            </a>
            <a className="btn btn-link" href="/contactus">
              Contact Us
            </a>
            <a className="btn btn-link" href="/privacy">
              Privacy Policy
            </a>
            <a className="btn btn-link" href="/terms">
              Terms &amp; Condition
            </a>
          </div>
          {/* <div className="col-md-6 col-lg-3">
                <h4 className="text-white mb-4">Newsletter</h4>
                <p>Lorem ipsum dolor sit amet elit. Phasellus nec pretium mi. Curabitur facilisis ornare velit non vulpu</p>
                <div className="position-relative w-100 mt-3">
                    <input className="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="text" placeholder="Your Email" style="height: 48px;">
                    <button type="button" className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i className="fa fa-paper-plane text-primary-gradient fs-4"></i></button>
                </div>
            </div> */}
        </div>
      </div>
      <div className="container px-lg-5">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              ©{" "}
              <span className="border-bottom" x>
                myKaasu, All Right Reserved.
              </span>
              
              <span> Developed by @ </span>
              <a
                className="border-bottom"
                href="https://www.renambl.com"
                target="_blank"
                rel="noreferrer"
              >
                Renambl Technologies
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer