import React, { useContext, useEffect, useState } from "react";
import "../../styles/screenStyles/LoanHistoryScreen.css";
import { Accordion } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { MyContext } from "../../components/GlobalValues";
import Metrixfunction from "../../components/MetrixFunction";

function LoanHistoryScreen() {
  const navigate = useNavigate();
  const [loanHistoryList, setLoanHistoryList] = useState([]);
  const {Url,metrixserver} = useContext(MyContext);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [fetchData, setFetchData] = useState(true);
  

  function removeSlash(port1) {
    let p1 = port1.split("/").join("");

    let np = p1;
    return np;
  }

  const location=useLocation()

  const getLoanHistoryApi = async (pagenum) => {
    try {
      const accId = localStorage.getItem("accId");
      const apiKey = localStorage.getItem("apiKey");

      if (!accId || !apiKey || accId.trim() === "" || apiKey.trim() === "") {
        
        localStorage.clear()
        navigate("/login");
        return;
      }



      const response = await fetch(
        `${Url}/business/getInactiveLoans`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            accId: accId,
            apiKey: apiKey,
            page: pagenum,
          }),
        }
      );

      const resJson = await response.json();
       

      if (!resJson.Success) {
     
        localStorage.clear()
        navigate("/login");
        return;
      }

      setLoanHistoryList((prevData) => {
        const uniqueln = [...prevData];
        resJson.Success.forEach((newln) => {
          if (!uniqueln.some((ln) => ln.loanId === newln.loanId)) {
            uniqueln.push(newln);
          }
        });
        return uniqueln;
      });

      if (resJson.Success.length < 10) {
        setFetchData(false);
      } else {
        setFetchData(true);
      }

      setPage(pagenum);
      setIsLoading(false);
      setIsFetching(false);

      Metrixfunction(metrixserver, "getInactiveLoans", "ONLOAD");

    } catch (error) {
      console.log(error);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    getLoanHistoryApi(page);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
    
      if (
        window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - 1 &&
        !isFetching &&
        fetchData
      ) {
        setIsFetching(true);
        
        getLoanHistoryApi(page + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [page, isFetching, fetchData]);

  return (
    (isLoading && <Loading />) || (
      <div className="loan-history-container">
        <div className="loan-history-title-wrapper">
          <h2>Loan History</h2>
          <p>View your past loans</p>
        </div>
        {loanHistoryList.length === 0 ? (
          <img
            src={"https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/No-data.png"}
            alt="No loans found"
            className="nothing-img"
          />
        ) : (
          <div className="loan-history-tab-content">
            <Accordion className="mt-3">
              {loanHistoryList.map((item, index) => (
                <Accordion.Item
                  className="p-0 m-1 mb-2"
                  eventKey={index}
                  key={index}
                >
                  <Accordion.Header>
                    <div className="loan-history-tab-content-card">
                      <div className="loan-history-tab-content-img-container">
                        <div className="loan-history-tab-content-img-wrapper">
                          <img
                            className="loan-history-tab-content-img-style"
                            src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/transaction.png"
                            alt="transaction"
                          />
                        </div>
                        <div className="loan-history-tab-content-paid-wrapper">
                          <p className="loan-history-tab-content-paid-title">
                            {item.loanId}
                          </p>
                          <p className="loan-history-tab-content-paid-subtitle">
                            {item.loanStartDate}
                          </p>
                        </div>
                      </div>
                      <p className="loan-history-tab-content-amount">
                        ₹{item.loanAmount}
                      </p>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="loan-history-tab-Body-container-cover">
                      <div className="loan-history-tab-Body-container">
                        <div className="loan-history-tab-Body-wrapper">
                          <p className="loan-history-tab-Body-key">
                            Principle Amount
                          </p>
                          <p className="loan-history-tab-Body-value">
                            ₹{item.paidPrincipalAmount}
                          </p>
                        </div>
                        <div className="loan-history-tab-Body-wrapper">
                          <p className="loan-history-tab-Body-key">
                            Interest Amount
                          </p>
                          <p className="loan-history-tab-Body-value">
                            ₹{item.loanInterestAmount}
                          </p>
                        </div>
                      </div>
                      <div className="loan-history-tab-Body-container">
                        <div className="loan-history-tab-Body-wrapper">
                          <p className="loan-history-tab-Body-key">
                            EMI Amount
                          </p>
                          <p className="loan-history-tab-Body-value">
                            ₹{item.dailyEMI}
                          </p>
                        </div>
                        <div className="loan-history-tab-Body-wrapper">
                          <p className="loan-history-tab-Body-key">
                            Pending Amount
                          </p>
                          <p className="loan-history-tab-Body-value">
                            ₹{item.unpaidPrincipalAmount}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div style={{display:"flex",justifyContent:"flex-end"}}>
                      <button
                        className="loan-history-tab-Body-button"
                        onClick={() => {
                          let options = { updateBreadcrumbs: false };
                          navigate("/loanDetails", {
                            state: { loanId: item.loanId, options: options },
                          });
                        }}
                      >
                        Details
                      </button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        )}

        {isFetching && (
          <div className="d-flex justify-content-center" id="ordershowmore">
            <div style={{ marginTop: "1.5rem" }}>
              <img
                src={"https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/loading.gif"}
                alt="Loading..."
                style={{ width: "35px" }}
              />
            </div>
          </div>
        )}
      </div>
    )
  );
}

export default LoanHistoryScreen;
