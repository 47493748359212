import React from 'react'

function Termscomponent() {

    

  return (
    <div className="row justify-content-center w-100">
    <p>

    <div >
        <b>Mykaasu Finance Private Limited</b>
      
      <span>
        {" "}
        , a Company within the meaning of Companies Act, 2013, is a
        Non-Banking Financial Company (NBFC) registered with Reserve
        Bank of India (RBI) under Section 45-IB of RBI classified as an
        Investment and Credit Company, having its registered office at{" "}
        <b>
          <b>
            96 Greeta towers, VSI Industrial estate, Perungudi, Chennai
            - 600 096, Tamil Nadu
          </b>
          , India
        </b>{" "}
        (hereinafter shall be referred to as “Mykaasu” or "Company" or
        "us" or "we") and is the licensed User of the website{" "}
        <a href="/home" target="_blank">
          www.mykaasu.com
        </a>{" "}
        (“Website”).
      </span>
      <br />
      <br />
      This Agreement includes, and incorporates by this reference, the
      policies and guidelines referenced below. If you do not agree to
      these Terms, you may not access or use the Services. This Terms of
      use (the "Agreement") govern the access or use by you, an
      individual or an entity, of website, applications, content,
      products, and services (the “Services”) offered by{" "}
      <a href="/" target="_blank">
        www.mykaasu.com
      </a>{" "}
      .
      <br />
      <br />
      <b>
        <b>Mykaasu Finance Private Limited</b>
      </b>{" "}
      reserves the right to change or revise the terms and conditions of
      this Agreement at any time by posting any changes or a revised
      Agreement on this Website. <b>Mykaasu Finance Private Limited</b>{" "}
      may amend this Agreement from time to time. Amendments will be
      effective upon posting of such updated Agreement at this location
      or the amended policies or supplemental terms on the applicable
      Services. Your continued access or use of the Services after such
      posting constitutes your consent to be bound by the Agreement, as
      amended.
      <br />
      <br />
      <b>Mykaasu Finance Private Limited</b> encourages you to review
      this Agreement whenever you visit the Website to make sure that
      you understand the terms and conditions governing use of the
      Website.
      <br />
      <br />
      This Agreement does not alter in any way the terms or conditions
      of any other written agreement(s) you may have with{" "}
      <b>Mykaasu Finance Private Limited</b> for other products or
      services. If you do not agree to this Agreement (including any
      referenced policies or guidelines), please immediately terminate
      your use of the Services. If you would like to print this
      Agreement, please click the print button on your browser toolbar.
      <br />
      <br />
      </div>
      <div >
      <h2>Use of information collected</h2>
      <br />
      <b>Mykaasu Finance Private Limited</b> may use the personal
      information that is collected from you to provide requested
      products and Services and for our internal business purposes,
      including, but not limited to:
      <br />
      <br />
      <ul style={{ listStyleType: "disc" }}>
        <li>Maintaining and managing your account with us;</li>
        <li>Responding to your requests for our services;</li>
        <li>
          To process your transactions and to provide you with
          transaction and post transaction-related services,
        </li>
        <li>
          Providing, improving, and marketing our products and services,
          including site content and performance;
        </li>
        <li>Evaluating your use of the services;</li>
        <li>
          Providing advertising for products and services that might
          interest you;
        </li>
        <li>
          Communicating with you by e-mail, postal mail or courier,
          and/or telephone about products or services that may be of
          interest to you;
        </li>
        <li>
          Keeping you updated on the issues that are relevant to your
          business relationship with us;
        </li>
        <li>
          Monitoring your compliance with any terms and conditions
          associated with our products and services, including to
          confirm your identity;
        </li>
        <li>
          Performing other functions as described to you at the time of
          collection or pursuant to your consent.
        </li>
      </ul>
      In addition, certain information we collect, such as the IP
      address of any computer or other device that you use to access our
      website and any other domains that may be operated by{" "}
      <b>Mykaasu Finance Private Limited</b>, will be used to monitor
      and investigate possible violations of and enforce any terms and
      conditions associated with our products and services or other
      applicable agreements between you and{" "}
      <b>Mykaasu Finance Private Limited</b>. Our use and sharing of
      your personal information in the manner described in this Privacy
      Policy may involve the transfer of your personal information to{" "}
      <b>Mykaasu Finance Private Limited</b>, Shriram group companies or
      to third parties.
      <br />
      <br />
      Customer Solicitation: Unless you notify our third-party call
      center representatives or direct{" "}
      <b>Mykaasu Finance Private Limited</b> sales representatives,
      while they are calling you, of your desire to opt out from further
      direct company communications and solicitations, you are agreeing
      to continue to receive further emails and call solicitations from{" "}
      <b>Mykaasu Finance Private Limited</b> and it's designated in
      house or third-party call team(s).
      <br />
      <br />
      Opt-Out Procedure: We provide 2 easy ways to opt out of future
      solicitations.
      <br />
      <br />
      <ul style={{ listStyleType: "disc" }}>
        <li>
          You may use the opt out link found in any email solicitation
          that you may receive.
        </li>
        <li>
          You may send a written remove request to{" "}
          <b>Mykaasu Finance Private Limited</b>,{" "}
          <b>
            96 Greeta towers, VSI Industrial estate, Perungudi, Chennai
            - 600 096, Tamil Nadu
          </b>
          .
        </li>
      </ul>
      <br />
      <br />
      Proprietary Rights: <b>Mykaasu Finance Private Limited</b> has
      proprietary rights and trade secrets in the Services. You may not
      copy, reproduce, resell or redistribute any Service provided by{" "}
      <b>Mykaasu Finance Private Limited</b>.{" "}
      <b>Mykaasu Finance Private Limited</b> also has rights to all
      trademarks and specific layouts of this webpage, including calls
      to action, text placement, images and other information.
      <br />
      <br />
      </div> 
      <div >
      <h2>Website policy</h2>
      <br />
      <b>Mykaasu Finance Private Limited</b> may collect and store
      information about you when you visit our site, use our Services,
      or view our online advertisements. The information which{" "}
      <b>Mykaasu Finance Private Limited</b> collects and store during
      normal use of the site is used to monitor use of the site and to
      help further development of our products and Services. We use this
      information to improve our Web-based offerings.
      <br />
      <br />
      Use of Website and/or Services:{" "}
      <b>Mykaasu Finance Private Limited</b> is not responsible for any
      damages resulting from use of this website or Services by anyone.
      You will not use the Website or Services for illegal purposes.
      <br />
      <br />
      You will:
      <br />
      <br />
      <ul style={{ listStyleType: "disc" }}>
        <li>
          Abide by all applicable local, state, national, and
          international laws and regulations in your use of the Website
          (including laws regarding intellectual property),
        </li>
        <li>
          Not interfere with or disrupt the use and enjoyment of the
          Website or Services by other users,
        </li>
        <li>Not resell material on the Website,</li>
        <li>
          Not engage, directly or indirectly, in transmission of "spam",
          chain letters, junk mail or any other type of unsolicited
          communication,
        </li>
        <li>
          Not defame, harass, abuse, or disrupt other users of the
          Website
        </li>
        <li>
          Be at least [18] years of age to use the Services; by using
          our Services you warrant and represent to us that you are at
          least [18] years of age.{" "}
          <b>Mykaasu Finance Private Limited</b> reserves the right to
          terminate and/or refuse to provide such person with access to
          the website, applications, content, products, and Services, if
          it is brought to <b>Mykaasu Finance Private Limited</b>'s
          notice or if it is discovered that such person is not eligible
          to use the website, applications, content, products, and
          Services, we will delete that information from our database.
        </li>
        <li>
          <b>Mykaasu Finance Private Limited</b> may invite your
          participation in various promotional initiatives through its
          presence on social networking websites, surveys and blogs
          operated by third parties, such as LinkedIn, Facebook,
          Instagram, YouTube.
        </li>
        <li>
          <b>Mykaasu Finance Private Limited</b> may call, send you text
          (SMS, WhatsApp), push notification, and email messages as part
          of the normal business operation of your use of the Services.
          You may opt-out of receiving text (SMS, WhatsApp) and email
          messages from <b>Mykaasu Finance Private Limited</b> at any
          time by following the directions found in Opt-out procedure.
          You acknowledge that opting out of receiving text (SMS,
          WhatsApp) and/or email messages may impact your use of the
          Services.
        </li>
      </ul>
      <br />
      <br />
      <h2>License: </h2>
      <br />
      By using this Website, you may not copy, reproduce, transmit,
      distribute, or create derivative works of such content or
      information without express written authorization from{" "}
      <b>Mykaasu Finance Private Limited</b> or the applicable third
      party (if third party content is at issue).
      <br />
      <br />
      </div>
      <div >
      <h2>Posting: </h2>
      <br />
      By posting, storing, or transmitting any content on the Website,
      you hereby grant <b>Mykaasu Finance Private Limited</b> a
      perpetual, worldwide, non-exclusive, royalty-free, assignable,
      right and license to use, copy, display, perform, create
      derivative works from, distribute, have distributed, transmit and
      assign such content in any form, in all media now known or
      hereinafter created, anywhere in the world.
      <br />
      <br />
      <b>Mykaasu Finance Private Limited</b> does not have the ability
      to control the nature of the user-generated content offered
      through the Website or Third party. You are solely responsible for
      your interactions with other users of the Website or Third party
      and any content you post. <b>Mykaasu Finance Private Limited</b>{" "}
      is not liable for any damage or harm resulting from any posts by
      or interactions between users.{" "}
      <b>Mykaasu Finance Private Limited</b> reserves the right, but has
      no obligation, to monitor interactions between and among users of
      the Website or Third party and to remove any content{" "}
      <b>Mykaasu Finance Private Limited</b> deems objectionable, in
      Shriram Finance's sole discretion.
      <br />
      <br />
      <h2>Notice of Copyright Infringement</h2>
      <br />
      <b>Mykaasu Finance Private Limited</b> does not permit copyright
      infringing activities on these site/applications and may remove
      any content of any kind, for example, if properly informed that
      the content infringes upon another's copyright rights.{" "}
      <b>Mykaasu Finance Private Limited</b> may terminate the ability
      to submit content if, under appropriate circumstances, a person
      submitting content to its site/application is determined to be a
      repeat infringer.
      <br />
      <br />
      If you are a copyright owner or an agent thereof and believe that
      any content on the site/application infringes upon your
      copyrights, you may notify <b>
        Mykaasu Finance Private Limited
      </b>{" "}
      by providing the following information in writing:
      <br />
      <br />
      <ul style={{ listStyleType: "disc" }}>
        <li>
          A physical or electronic signature of a person authorized to
          act on behalf of the owner of an exclusive copyright that is
          allegedly infringed;
        </li>
        <li>
          Identification of the location where the original or an
          authorized copy of the copyrighted work exists;
        </li>
        <li>
          Identification of the material that is claimed to be
          infringing and a description of the infringing activity and
          information reasonably sufficient to permit{" "}
          <b>Mykaasu Finance Private Limited</b> to locate the same;
        </li>
        <li>
          Information reasonably sufficient to permit{" "}
          <b>Mykaasu Finance Private Limited</b> to contact you, such as
          an address, telephone number, and, if available, an email
          address;
        </li>
        <li>
          A statement that you have a good faith and belief that use of
          the material in the manner complained of is not authorized by
          the copyright owner, its agent, or the law;
        </li>
        <li>
          A statement that the information in the notification is
          accurate, and, under penalty of perjury, that you are
          authorized to act on behalf of the owner of an exclusive
          copyright that is allegedly infringed.
        </li>
      </ul>
      <br />
      <br />
      <h2>Disclaimers</h2>
      <br />
      The Services are provided “as is” and “as available.”{" "}
      <b>Mykaasu Finance Private Limited</b> disclaims all
      representations and warranties, express, implied or statutory, not
      expressly set out in these terms, including the implied warranties
      of merchantability, fitness for a particular purpose and
      non-infringement. In addition,{" "}
      <b>Mykaasu Finance Private Limited</b> makes no representation,
      warranty, or guarantee regarding the reliability, timeliness,
      quality, suitability or availability of the Services or any
      services or goods requested through the use of the Services, or
      that the Services will be uninterrupted or error-free.{" "}
      <b>Mykaasu Finance Private Limited</b> does not guarantee the
      quality, suitability, safety or ability of third-party providers.
      You agree that the entire risk arising out of your use of the
      Services, and any service or good requested in connection
      therewith, remains solely with you, to the maximum extent
      permitted under applicable law.
      <br />
      <br />
      </div>
      <div >
      <h2>Indemnity</h2>
      <br />
      You agree to indemnify and hold{" "}
      <b>Mykaasu Finance Private Limited</b> and its officers,
      directors, employees and agents harmless from any and all claims,
      demands, losses, liabilities, and expenses (including attorneys'
      fees) arising out of or in connection with:
      <br />
      <br />
      <ul>
        <li>
          Your use of the Services or services or goods obtained through
          your use of the Services;
        </li>
        <li>Your breach or violation of any of these Terms;</li>
        <li>
          <b>Mykaasu Finance Private Limited</b>'s use of your User
          Content;
        </li>
        <li>
          Your violation of the rights of any third party, including
          Third Party Providers.
        </li>
      </ul>
      <br />
      <br />
      <h2>Privacy</h2>
      Privacy of communication is subject to the terms set out in the
      Privacy Policy.
      <br />
      <br />
      <h2>Agreement to be bound</h2>
      By using the Services, you acknowledge that you have read and
      agree to be bound by this Agreement and all terms and conditions
      on this Website.
      <br />
      <br />
      <h2>General</h2>
      <br />
      <h3>Force Majeure:</h3>
      <br />
      <b>Mykaasu Finance Private Limited</b> will not be deemed in
      default hereunder or held responsible for any cessation,
      interruption or delay in the performance of its obligations
      hereunder due to earthquake, flood, fire, storm, natural disaster,
      act of God, war, terrorism, armed conflict, labour strike,
      lockout, or boycott of Operation.
      <br />
      <br />
      <b>Mykaasu Finance Private Limited</b> may at any time, in its
      sole discretion and without advance notice to you, cease operation
      of the Website and Services.
      <br />
      <br />
      <h3>Effect of Waive:</h3>
      <br />
      The failure of <b>Mykaasu Finance Private Limited</b> to exercise
      or enforce any right or provision of this Agreement will not
      constitute a waiver of such right or provision. If any provision
      of this Agreement is found by a court of competent jurisdiction to
      be invalid, the parties nevertheless agree that the court should
      endeavour to give effect to the parties' intentions as reflected
      in the provision, and the other provisions of this Agreement
      remain in full force and effect.
      <br />
      <br />
      <h3>Governing Law:</h3>
      <br />
      This Website originates from the City of Chennai, Tamil Nadu state
      of India. This Agreement will be governed by the laws of the State
      of Tamil Nadu without regard to its conflict of law principles to
      the contrary. Neither you nor{" "}
      <b>Mykaasu Finance Private Limited</b> will commence or prosecute
      any suit, proceeding or claim to enforce the provisions of this
      Agreement, to recover damages for breach of or default of this
      Agreement, or otherwise arising under or by reason of this
      Agreement, other than in courts located in State of Tamil Nadu,
      India. By using this Website, you consent to the jurisdiction and
      venue of such courts in connection with any action, suit,
      proceeding or claim arising under or by reason of this Agreement.
      You hereby waive any right to trial by jury arising out of this
      Agreement and any related documents.
      <br />
      <br />
      <h3>Waiver of Class Action Rights:</h3>
      <br />
      BY ENTERING INTO THIS AGREEMENT, YOU HEREBY IRREVOCABLY WAIVE ANY
      RIGHT YOU MAY HAVE TO JOIN CLAIMS WITH THOSE OF OTHER IN THE FORM
      OF A CLASS ACTION OR SIMILAR PROCEDURAL DEVICE. ANY CLAIMS ARISING
      OUT OF, RELATING TO, OR CONNECTION WITH THIS AGREEMENT MUST BE
      ASSERTED INDIVIDUALLY.
      <br />
      <br />
      <h3>Statute of Limitation:</h3>
      <br />
      You agree that regardless of any statute or law to the contrary,
      any claim or cause of action arising out of or related to use of
      the Products or Services or this Agreement must be filed within
      one (1) year after such claim or cause of action arose or be
      forever barred.
      <br />
      <br />
      <h3>Termination:</h3>
      <br />
      <b>Mykaasu Finance Private Limited</b> reserves the right to
      terminate your access to the Website if it reasonably believes, in
      its sole discretion, that you have breached any of the terms and
      conditions of this Agreement. Following termination, you will not
      be permitted to use the Website and{" "}
      <b>Mykaasu Finance Private Limited</b> may, in its sole discretion
      and without advance notice to you, cancel Product or Services. If
      your access to the Website is terminated,{" "}
      <b>Mykaasu Finance Private Limited</b> reserves the right to
      exercise whatever means it deems necessary to prevent unauthorized
      access of the Website. This Agreement will survive indefinitely
      unless and until <b>Mykaasu Finance Private Limited</b> chooses,
      in its sole discretion and without advance to you, to terminate
      it.
      <br />
      <br />
      <h3>Domestic Use:</h3>
      <br />
      <b>Mykaasu Finance Private Limited</b> makes no representation
      that the Services are appropriate or available for use in
      locations outside India. Users who access the Website from outside
      India do so at their own risk and initiative and must bear all
      responsibility for compliance with any applicable local laws.
      <br />
      <br />
      
      <h3>Assignment:</h3>
      <br />
      You may not assign your rights and obligations under this
      Agreement to anyone. <b>Mykaasu Finance Private Limited</b> may
      assign its rights and obligations under this Agreement in its sole
      discretion and without advance notice to you.
      <br />
      <br />
      </div>
      <div >

      <h2>1.2 KYC related Terms and Conditions</h2>
      <br />
      <h4>1.2.1 CKYC Consent –</h4>
      <br />
      <ol type="a">
        <li>
          {" "}
          I hereby authorize Mykaasu to
          verify/check/obtain/download/upload/update my KYC details
          from/with the Central KYC Registry(CERSAI):
          <ol type="i">
            <li>
              {" "}
              by verifying such details through the CKYC number (i.e.
              KYC Identifier Number- KIN) provided by me or
            </li>

            <li>
              {" "}
              by obtaining such CKYC number/KIN, through details shared
              by me for this Application Form.
            </li>
          </ol>
        </li>
        <li>
          {" "}
          I hereby consent for receiving information from Central KYC
          Registry through SMS/Email on the above registered
          number/email address.
        </li>
      </ol>
      1.2.2. Aadhaar based authentication (Aadhaar eKYC) or verification
      (Aadhaar OKYC) facility provided by Unique Identification
      Authority of India “UIDAI” –
      <br />
      <br />
      <ol type="a">
        <li>
          {" "}
          CONSENT: At my own discretion, I voluntarily opt to perform
          and avail Aadhaar based authentication (eKYC) or verification
          (OKYC) from UIDAI and hereby submit to the Mykaasu, the
          physical copy of Aadhaar card / physical e-Aadhaar / masked
          Aadhaar / Aadhaar Secure QR code / offline electronic Aadhaar
          xml as issued by UIDAI (Aadhaar), Aadhaar number, Virtual ID,
          e-Aadhaar, Aadhaar Letter, XML file, date of birth, Aadhaar
          holder name, photo, demographic information, face
          authentication details and/or biometric information
          (collectively, “Information”) for the purpose of establishing
          my identity / address proof or in the capacity of guardian of
          my minor child’s identity / address. I am informed by the
          Mykaasu that in connection with Aadhaar e-KYC or Aadhaar OKYC
          services, Mykaasu shall share Aadhaar number and/or biometrics
          with CIDR/UIDAI , and in response, the CIDR/UIDAI shall share
          with Mykaasu, the authentication data or verification data
          such as Aadhaar Holder Name, Date of Birth, Address, Photo,
          and Registered Mobile Number.
        </li>
        <br />
        <li>
          PURPOSE: I for myself/as guardian of minor child, authorise
          and give my consent to the Mykaasu, for following informed
          purposes: -
          <ol type="i">
            <li>
              Above mentioned information shall be used for KYC and
              periodic KYC updation process as per the PML Act, 2002 and
              rules thereunder and RBI guidelines, or for establishing
              my identity, carrying out my identification, e-KYC, or
              OKYC, or Yes/No Authentication, demographic or other
              authentication/verification/ identification as may be
              permitted as per applicable law, for all accounts,
              facilities, services and relationships of/through the
              Mykaasu, existing and future.
            </li>

            <li>
              {" "}
              Collecting, sharing, storing, preserving Information,
              maintaining records and using the Information and
              authentication/verification/ identification records:
            </li>

            <ol type="a">
              <li>for the informed purposes above,</li>

              <li>
                {" "}
                as well as for regulatory and legal reporting and
                filings and/or
              </li>

              <li> where required under applicable law;</li>
            </ol>

            <br />

            <li>
              producing records and logs of the consent, Information or
              of authentication, identification, verification etc. for
              evidentiary purposes including before a court of law, any
              authority or in arbitration.
            </li>
          </ol>
        </li>
        <ol>
          <ol type="a">
            <li>
              {" "}
              I further understand that Aadhaar number and core
              biometrics will not be stored/ shared except as per
              laws/regulations and for CIDR/UIDAI submission.
            </li>

            <li>
              {" "}
              I shall be submitting to the Company any one of the
              Officially Valid documents such as Aadhaar, Voter ID,
              Driving License or Passport for customer due diligence/KYC
              process.
            </li>
          </ol>
        </ol>
      </ol>
      1.2.3. CONSENT DATA AND INFORMATION SHARING FROM FOR DIGILOCKER
      PROVIDED BY MeIT (Ministry of Electronics and Information
      Technology) : I authorise and give my consent to the Mykaasu to
      obtain, share and store Certified Copy of Officially Valid
      Documents (OVD) or equivalent e-documents of OVD including
      documents issued through secured cloud based Digilocker platform
      of MeIT for the purpose of availing Financial Products from
      Mykaasu.
      <br />
      <br />
      </div>
      <div >

      1.2.4. KYC through Video Customer Identification Process(VCIP) –
      If I opt for VCIP Mode, I hereby agree and acknowledge/authorise:
      <br />
      <br />
      <ol type="a">
        <li>
          {" "}
          That video based KYC validation (”Video KYC process”) has been
          provided as alternate method for establishing my identity with
          Mykaasu
        </li>
        <br />
        <li>
          {" "}
          That my KYC will be completed by Mykaasu, through Video KYC
          process, in accordance with process provided under the
          prevailing regulation, including Master Direction Know Your
          Customer (KYC) Direction, 2016, issued by Reserve Bank of
          India(“RBI”) and the amendments thereof.
        </li>
        <br />
        <li>
          {" "}
          All particulars, information and details to be provided by me
          in relation to this Video KYC process represent the true,
          correct and upto date information of myself in all respects.
        </li>
        <br />
        <li>
          {" "}
          That Video KYC process shall include but shall not be limited
          to the following steps concluded by authorised officer of
          Mykaasu:
        </li>
        <ol type="i">
          <li>
            {" "}
            Live capturing of photo, along with live GPS co-ordinate
            (Geo-tagging) of the customer undertaking the VCIP and date
            time stamp where such live photo is being taken;
          </li>

          <li>
            {" "}
            Permanent Account Number (PAN) is mandatory for onboarding
            through V-CIP mode and capturing clear image of PAN card
            during the V-CIP process. The PAN details will be verified
            from the verification facility of the issuing authority.
          </li>

          <li>
            {" "}
            If Aadhaar card is permitted to be accepted as a documentary
            proof under law/regulation, the same will be obtained as
            contemplated under the prevalent law/regulation.
          </li>

          <li>
            Video recording of the interaction (for concluding Video
            KYC) will be stored by Mykaasu in accordance with applicable
            law/regulation.
          </li>
        </ol>
        <br />
        <li>
          {" "}
          I will be required to submit other officially valid document
          (OVD) / deemed to be OVD’s or such other documents as may be
          requested by Mykaasu, as part of this Video KYC process;
        </li>
        <br />
        <li>
          {" "}
          I hereby convey my express consent to Mykaasu for verifying
          any or all the details/documents so provided by me.
        </li>
        <br />
        <li>
          {" "}
          To complete the Video KYC process in full within time
          stipulated by Mykaasu and in case if I fail to complete the
          same for any reason whatsoever Mykaasu shall have sole and
          absolute discretion to reject my loan/product application(s)
          and/or discontinue the service(s)/product(s) for which such
          KYC is mandated.
        </li>
        <br />
        <li>
          {" "}
          That in case where KYC documents/details submitted by me
          during Video KYC process does not match with or if there are
          any discrepancy found therein after validation thereof with
          the original KYC documents/details updated by me, Mykaasu
          shall have sole and exclusive right to reject my loan/product
          application(s) and/or discontinue the service(s)/product(s)
          for which such KYC is mandated.
        </li>
        <br />
        <li>
          {" "}
          That if I could not complete my Video KYC validation process
          or if during the said process if I dropped midway due to
          technical, systematic or server errors/issues or other
          operational issues in that case I may be permitted to complete
          Video KYC process or I may be required to complete my KYC
          compliance by undertaking physical KYC validation process, by
          Mykaasu and I undertake to complete the same and/or submit
          physical KYC.
        </li>
        <br />
        <li>
          {" "}
          That, Mykaasu does not guarantee continuous accessibility of
          the applications and shall not be held liable or responsible
          in any manner whatsoever in respect of any loss, cost or
          damage that may be incurred by me due to any technical,
          systematic or server errors/issues, or any other issue
          occurred while undertaking Video KYC process, including for
          reasons which are beyond reasonable control of the Mykaasu.
        </li>
      </ol>
      <br />
      <br />
      1.2.5. I hereby confirm that all the information and details
      including but not limited to financials/bank statements, KYC
      details provided/to be provided by me pursuant in support of my
      application or to be shared with any third party, including the
      Central KYC Registry, are true, correct and up-to-date. I have not
      withheld any material information that may be required by Mykaasu.
      I undertake to immediately keep Mykaasu updated of any change in
      the information provided by me, specifically KYC/CKYC details,
      Bank details, change of my address/co-ordinates etc. and in case
      any of the above information is found to be false or untrue or
      misleading or misrepresenting, I am aware that I may be held
      liable for it and I will not hold Mykaasu liable for any
      consequences that may arise owing to any delay/lapse on my part.
      </div>
    </p>{" "}
  </div>
  )
}

export default Termscomponent