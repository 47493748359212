import React, { useContext, useEffect, useRef, useState } from 'react'
import Metrixfunction from '../components/MetrixFunction';
import { MyContext } from '../components/GlobalValues';
function Articles() {

  const {  metrixserver} = useContext(MyContext);


  const [imagesLoaded, setImagesLoaded] = useState(0);
  const totalImages = useRef(0);
  
  useEffect(() => {
    // Count the total number of images
    const imageElements = document.querySelectorAll('img');
    totalImages.current = imageElements.length;
  
  
    // Add load event listeners to all images
    if(imageElements.length>0)
    {
      imageElements.forEach(img => {
        if (img.complete) {
          handleImageLoad();
        } else {
          img.addEventListener('load', handleImageLoad);
          img.addEventListener('error', handleImageLoad); // Handle error case
        }
      });
    }
    else{
      Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");
  
    }
  
    // Cleanup function
    return () => {
      imageElements.forEach(img => {
        img.removeEventListener('load', handleImageLoad);
        img.removeEventListener('error', handleImageLoad);
      });
    };
  }, []);
  
  useEffect(() => {
    if (imagesLoaded === totalImages.current && totalImages.current > 0) {
      Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");
  
    }
  }, [imagesLoaded]);
  
  const handleImageLoad = () => {
    setImagesLoaded(prev => prev + 1);
  };
  
  return (
    <div className="bg-white p-0 ">
    {/* Spinner Start */}
    <div
      id="spinner"
      className="bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
    >
      <div
        className="spinner-grow text-danger"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
    {/* Spinner End */}
    {/* Navbar & Hero Start */}
    <div className="position-relative p-0" id="home">
      <custom-header />
      <div className="bg-primary hero-header">
        <div className="container px-lg-5">
          <div className="row g-4 mb-4">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h1 className="mb-4" style={{ color: "white" }}>
                Financial Articles to Ensure Business Success
              </h1>
              {/* <p className="mb-4" style="color: white">Apply Now!</p> */}
            </div>
            <div
              className="col-lg-6 d-flex justify-content-center justify-content-lg-end wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <img src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/service2.png" width="100%" height="100%" />
              {/* <div className="owl-carousel screenshot-carousel">
                            <img className="img-fluid" src="img/01.jpg" alt="">
                            <img className="img-fluid" src="img/02.jpg" alt="">
                            <img className="img-fluid" src="img/03.jpg" alt="">
                            <img className="img-fluid" src="img/04.jpg" alt="">
                            <img className="img-fluid" src="img/05.jpg" alt="">
                        </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Navbar & Hero End */}
    {/* Articles Start */}
    <div className="container mb-5 w-100 p-3  " id="Articles">
      <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h5 className="text-primary-gradient fw-medium">Our Articles</h5>
        <h1 className="mb-5">Everything You Need to Know in Business Loan</h1>
      </div>
      <div
        className="row g-4 bg-light pb-4  rounded wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className="col-12 col-md-6 col-lg-4 ">
          <div
            className=" pb-2 rounded "
            style={{
              backgroundColor: "white",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <img src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/steps.png" height="280px" width="80%" />
            {/* ----Header--- */}
            <div className=" p-4 mb-4">
              <h3 className="text-dark mb-2">
              Important Factors to avail a Personal Loan
              </h3>
              <div className="d-flex  justify-content-between ">
                <span
                  className="text-black-50"
                  style={{ fontFamily: '"Jost", sans-serif' }}
                >
                  Last Updated : 31st October 2023
                </span>
              </div>
            </div>
            {/* ---------body---- */}
            {/* -------footer------ */}
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <a
                href="/article1"
                className="btn btn-light  rounded py-2 px-4 mb-2 border  "
                style={{
                  fontWeight: 500,
                  fontFamily: '"Jost", sans-serif',
                  width: "80%"
                }}
              >
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <div
            className=" pb-2 rounded"
            style={{
              backgroundColor: "white",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <img src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/myth.png" height="280px" width="80%" />
            {/* ----Header--- */}
            <div className=" p-4 mb-4">
              <h3 className="text-dark   mb-2">
                Some Common Myths around Personal Loans
              </h3>
              <div className="d-flex  justify-content-between ">
                <span
                  className="text-black-50 "
                  style={{ fontFamily: '"Jost", sans-serif' }}
                >
                  Last Updated : 31st October 2023
                </span>
              </div>
            </div>
            {/* ---------body---- */}
            {/* -------footer------ */}
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <a
                href="/article2"
                className="btn btn-light  rounded py-2 px-4 mb-2 border  "
                style={{
                  fontWeight: 500,
                  fontFamily: '"Jost", sans-serif',
                  width: "80%"
                }}
              >
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <div
            className=" pb-2 rounded"
            style={{
              backgroundColor: "white",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <img src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/benefits.png" height="280px" width="80%" />
            {/* ----Header--- */}
            <div className=" p-4 mb-4 ">
              <h3 className="text-dark   mb-2">
              The Important Benefits of a Personal Loan
              </h3>
              <div className="d-flex  justify-content-between ">
                <span
                  className="text-black-50"
                  style={{ fontFamily: '"Jost", sans-serif' }}
                >
                  Last Updated : 31st October 2023
                </span>
              </div>
            </div>
            {/* ---------body---- */}
            {/* -------footer------ */}
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <a
                href="/article3"
                className="btn btn-light  rounded py-2 px-4 mb-2 border  "
                style={{
                  fontWeight: 500,
                  fontFamily: '"Jost", sans-serif',
                  width: "80%"
                }}
              >
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className=" col-12 col-md-6 col-lg-4 ">
          <div
            className=" pb-2 rounded"
            style={{
              backgroundColor: "white",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <img src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/cibilscore.png" height="280px" width="80%" />
            {/* ----Header--- */}
            <div className=" p-4 mb-4 ">
              <h3 className="text-dark   mb-2">
                How Can I Improve my CIBIL Score Inorder To Get a Business Loan{" "}
              </h3>
              <div className="d-flex  justify-content-between ">
                <span
                  className="text-black-50"
                  style={{ fontFamily: '"Jost", sans-serif' }}
                >
                  Post Updated : 31st October 2023
                </span>
              </div>
            </div>
            {/* ---------body---- */}
            {/* -------footer------ */}
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <a
                href="/article4"
                className="btn btn-light  rounded py-2 px-4 mb-2 border  "
                style={{
                  fontWeight: 500,
                  fontFamily: '"Jost", sans-serif',
                  width: "80%"
                }}
              >
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className=" col-12 col-md-6 col-lg-4 ">
          <div
            className=" pb-2 rounded"
            style={{
              backgroundColor: "white",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <img src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/personalBusiness.png" height="280px" width="80%" />
            {/* ----Header--- */}
            <div className=" p-4 mb-4 ">
              <h3 className="text-dark   mb-2">
                Business Loans vs. Personal Loans: Unraveling the Financing
                Puzzle.
              </h3>
              <div className="d-flex  justify-content-between ">
                <span
                  className="text-black-50"
                  style={{ fontFamily: '"Jost", sans-serif' }}
                >
                  Last Updated : 31st October 2023
                </span>
              </div>
            </div>
            {/* ---------body---- */}
            {/* -------footer------ */}
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <a
                href="/article5"
                className="btn btn-light  rounded py-2 px-4 mb-2 border  "
                style={{
                  fontWeight: 500,
                  fontFamily: '"Jost", sans-serif',
                  width: "80%"
                }}
              >
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className=" col-12 col-md-6 col-lg-4 ">
          <div
            className=" pb-2 rounded"
            style={{
              backgroundColor: "white",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <img src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/businessMistakes.png" height="280px" width="80%" />
            {/* ----Header--- */}
            <div className=" p-4 mb-4 ">
              <h3 className="text-dark   mb-2">
                The 5 Biggest Mistakes to Avoid When Getting a Business Loan
              </h3>
              <div className="d-flex  justify-content-between ">
                <span
                  className="text-black-50"
                  style={{ fontFamily: '"Jost", sans-serif' }}
                >
                  Last Updated : 31st October 2023
                </span>
              </div>
            </div>
            {/* ---------body---- */}
            {/* -------footer------ */}
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <a
                href="/article3"
                className="btn btn-light  rounded py-2 px-4 mb-2 border  "
                style={{
                  fontWeight: 500,
                  fontFamily: '"Jost", sans-serif',
                  width: "80%"
                }}
              >
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Articles End */}
    {/* Footer Start */}
    <custom-footer />
    {/* Footer End */}
    {/* Back to Top */}
    <a href="#" className="btn btn-lg btn-lg-square back-to-top pt-2">
      <i className="bi bi-arrow-up home-text" />
    </a>
  
  </div>
  

  )
}

export default Articles