import React from 'react'
import "../styles/componentStyles/Loading.css";

function Loading() {
  return (
    <div className="loading-main d-flex justify-content-center align-items-center ">
      <div >
      <div className="loading-spinner">
        <span></span>
        <span></span>
        <span></span>
      </div>
      </div>
    </div>
  );
}

export default Loading

