

import React, {useEffect, useRef, useState } from "react";
import "../styles/componentStyles/Dropdown.css";
import { ChevronDown, ChevronUp } from "react-feather";

const Dropdown = ({
  options,
  onSelect,
  drpdownWidth,
  drpdownHeight,
  placeHolder,
  leftIcon,
  value,
  editable,
  validationError,
  initialValue,
  opentoggle,
  lablekey
}) => {


  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(initialValue);
  const dropdownRef = useRef(null);
  const menuRef = useRef(null);

  const toggleDropdown = () => {
     setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedOption(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   if (menuRef.current) {
  //     const menuPosition = menuRef.current.getBoundingClientRect().bottom;
  //     const windowHeight = window.innerHeight;
  //     const isNearBottom = windowHeight - menuPosition < 50; // Adjust threshold as needed

  //     if (isNearBottom) {
  //       menuRef.current.style.top = 'auto';
  //       menuRef.current.style.bottom = '100%';
  //     } else {
  //       menuRef.current.style.top = '3.3rem';
  //       menuRef.current.style.bottom = 'auto';
  //     }
  //   }
  // }, [isOpen]);


  return (
    <>
      <button
        className={`${editable ? "cmpt-dropdown" : "cmpt-disable-state"}`}
        type="button"
        onClick={(e)=>{editable && toggleDropdown(e)}}
        ref={dropdownRef}
      >
        {leftIcon && leftIcon}
        <div
          style={{
            width: "87%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span style={{ color: value ? editable?'black':'#4a4343' : selectedOption ? "black" : "grey" }}>
            {value ? value : selectedOption ? selectedOption : placeHolder}
          </span>
          <span>
            {editable ? (
              isOpen ? (
                <ChevronUp style={{ animation: "none" }} />
              ) : (
                <ChevronDown style={{ animation: "none" }} />
              )
            ) : (
              ""
            )}
          </span>
        </div>

        {isOpen && (
          <ul className="cmpt-dropdown-menu" ref={menuRef}>
            {options.map((option, index) => (
              <li key={index} id={"id_" + option} onClick={() => handleOptionSelect(option)}>
                {option}
              </li>
            ))}
          </ul>
        )}
      </button>
      {validationError && <p className="validation-error">{validationError}</p>}
    </>
  );
};

export default Dropdown;