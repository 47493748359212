import { useContext, useEffect, useRef, useState } from "react";
import SkeletonLoader from "./SkeletonLoader";
import { XCircle } from "react-feather";
import { MyContext } from "./GlobalValues";

const AssetDisplay = ({
  workinfo,
  fileUploadSate,
  handleEditUploadImage,
  fileType,
  editable,
  labelName,
}) => {
  const { getFileNameFromUrl } = useContext(MyContext);

  const [fileUrl, setfileurl] = useState("");

  const [filename, setfilename] = useState("");
  const getFileName = (url) => {
    if (!url) return "";
    const parts = url.split("/");
    const filename = parts[parts.length - 1];
    return filename;
  };

  const isPdf = (filename) => {
    return filename.endsWith(".pdf");
  };

  const [imgloading, setimgloading] = useState(true);

  const imgRef = useRef(null);

  useEffect(() => {
    setimgloading(true);
    const handleImageLoad = () => {
          setimgloading(false)
      
    };

    const imgElement = imgRef.current;
    if (imgElement) {
      if (imgElement.complete) {
        setTimeout(() => {
          
          handleImageLoad();
        }, 1000);
      } else {
        imgElement.addEventListener("load", handleImageLoad);
      }
    }

    return () => {
      if (imgElement) {
        imgElement.removeEventListener("load", handleImageLoad);
      }
    };
  }, [fileUrl]);
  
  useEffect(() => {


    //   fileUploadSate.length > 0 ,fileUploadSate[0].url,fileType)
    
      let value = workinfo || (fileUploadSate.length > 0 && fileUploadSate[0].url);
      setfileurl(value);


      setfilename(getFileName(value));
    

  
  }, []);

 

  return (
    <div>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ width: "60%" }}
      >
        {labelName && <label>{labelName}</label>}
      </div>
      <div className="mt-2"></div>

      {filename &&
        (isPdf(filename) ? (
          <>
            <div className="mt-2"></div>

            {imgloading && <SkeletonLoader labelName="Loading..." />}
            <div
              style={{
                height: "auto",
                width: "150px",
                position: "relative",
               
                display:  "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <div
               style={{
                width:"100%",
                height:"100%",
                backgroundColor: "white",
                display:imgloading?"none":"block"
               }}
              >
              {editable && (
                <XCircle
                  onClick={() => handleEditUploadImage(fileType,fileUrl)}
                  color="red"
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
              )}
              <a href={fileUrl} target="/blank">
                <img
                  src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/pdf.png"
                  style={{ height: "80%", width: "80%" }}
                  alt=""
                  ref={imgRef}
                ></img>
              </a>
              <p style={{ width: "100%", fontSize: 14, textAlign: "center",marginTop:'1rem' }}>
                {fileUrl.length > 0 && getFileNameFromUrl(fileUrl, 17)}
              </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="col ui-imageWrapper"
            
            >
       {imgloading && <SkeletonLoader labelName="Loading ..." />}

              <div className="ui-imageinnerwrapper" style={{display:imgloading?"none":"block" }}>
                {editable && (
                  <XCircle
                    onClick={() => handleEditUploadImage(fileType)}
                    color="red"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      backgroundColor: "white",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                  />
                )}
                <img
                  src={fileUrl}
                  ref={imgRef}
                  alt=""
                  style={{ height: "100%", width: "100%", }}
                />
              </div>
            </div>
          </>
        ))}
    </div>
  );
};

export default AssetDisplay;
