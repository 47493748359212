import React from "react";
import "../styles/componentStyles/AddressModal.css"; // Import the CSS file
import { X, XCircle } from "react-feather";

const ModalAddress = ({
  isOpen,
  onClose,
  onBack,
  children,
  modalTitle,
  submitButtonPlaceHolder,
  onSave,
  modalref,
  modalwidth = "40%",
  modalHeight,
  onhide = true,
  savebtn = true,
  isMap,
  InitialCloseButton
}) => {

  
  return (
    <div
      className={`addressModal-overlay ${isOpen ? "addressModal-open" : ""}`}
      onClick={onhide && onClose}
    >
      <div
        style={{ width: modalwidth, height: modalHeight}}
        className="addressModal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div style={{display:"flex",flexDirection:"row",alignItems:"center",
          justifyContent:"space-between",padding:"5px 15px",position:"absolute",
          top:0, left:0,height:"60px",width:"100%",backgroundColor:"white",
          zIndex:"999",borderRadius:"20px",marginTop:"10px"}}>
          <h3 style={{margin:"0px",marginLeft:"10px"}}>{modalTitle}</h3>
          <button style={{border:"none",backgroundColor:"transparent"}} onClick={onClose}><X style={{color:"red"}}/></button>
        </div>
          <div className="addressModal-main-content" ref={modalref}>
          {children}
        </div>
        {savebtn && (
          <div className="addressModal-footer">
            {isMap ? 
            <button className="addressModal-close-modal" onClick={onClose}>
              Close
            </button>
            :
            <button className="addressModal-close-modal" onClick={onBack}>
              Go to Map
            </button>
             }


            
      { !InitialCloseButton && <button
              className="addressModal-save-modal"
              onClick={(e) => {
                e.preventDefault();
                onSave();
              }}
            >
              {submitButtonPlaceHolder}
            </button>
}
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalAddress;
