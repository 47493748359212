import React, {useContext, useState } from 'react';
import '../styles/componentStyles/FileUpload.css';
import { XCircle } from 'react-feather';
import SkeletonLoader from './SkeletonLoader';
import Metrixfunction from './MetrixFunction';
import { MyContext } from './GlobalValues';
import CryptoJS from "crypto-js";
import Alert from './Alert';

function FileUpload({ fileType, limitCount, fileName, fileId, onchange = () => {}, files, setFiles }) {

  const [loading, setLoading] = useState(false);

  const {metrixserver} = useContext(MyContext);

  // Load credentials from environment variables
  const secretAccessKey = process.env.REACT_APP_UPLOAD_SECRET_KEY;

  const getEpochTime = () => {
    return Math.floor(Date.now() / 1000);
  };

  // Load credentials from environment variables
  // const spaceEndpoint = new AWS.Endpoint("https://sgp1.digitaloceanspaces.com");
  // const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
  // const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
  // const bucketName = process.env.REACT_APP_BUCKET_NAME;

  // AWS.config.update({
  //   accessKeyId,
  //   secretAccessKey,
  // });

  // const s3 = new AWS.S3({
  //   endpoint: spaceEndpoint,
  // });

  const getFileNameFromUrl = (url,maxLength) => {
    // Split the URL by '/'
    const parts = url.split('/');
    // Get the last part of the URL which is the filename
    const filename = parts[parts?.length - 1];
    // Split the filename by '-'
    const nameParts = filename.split('__');
    // Return the part after the hyphen
    
    let fullFileName='';
    if(nameParts.length > 2){
  
        nameParts.forEach((item,index)=>{
          if(index !== 0){
            fullFileName += item;
          }
        })
      
    }else{
      fullFileName = nameParts[1];
    }
  
   
    // let exctension = fullFileName.split(".");
    // exctension = exctension[exctension.length-1];
  
  
    return fullFileName;
  };
  

 
  const handleFileChange = (event) => {
    const fileList = event.target.files;

    // console.log("checking File upload !");

    // Check if file count exceeds the limit
    if (fileList.length + files.length > limitCount) {
      showAlert(`You can upload maximum ${limitCount} file.`,"danger");
      return;
    }

    // Check file type
    const allowedTypes = fileType.split(',');
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      if (!allowedTypes.includes(file.type)) {
        showAlert(`File type ${file.type} is not allowed.`,"danger");
        return;
      }

      // Check file size (10MB = 10 * 1024   * 1024 bytes)
      const maxSizeInBytes = 10 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        showAlert(`File ${file.name} exceeds the maximum size of 10MB.`,"danger");
        return;
      }
    }

    // Update files state
    for (let i = 0; i < fileList.length; i++) {
      const selectedFile = fileList[i];

      var photoName = fileName + getEpochTime() + "__" + selectedFile.name;

 
      const renamedFile = new File([selectedFile], photoName);

      handleUpload(renamedFile);

      const formData = new FormData();
      formData.append('image', renamedFile);

      onchange(formData);
    }
  };

  // const handleUpload = async (file) => {
  //   if (!file) {
  //     return;
  //   }
  //   setLoading(true)
 

  //   let contentType = '';
    
  //   if (file.name.endsWith('.pdf')) {
  //     contentType = 'application/pdf';
  //   } else if (file.name.endsWith('.png')) {
  //     contentType = 'image/png';
  //   } else if (file.name.endsWith('.jpeg') || file.name.endsWith('.jpg')) {
  //     contentType = 'image/jpeg';
  //   } else {
  //     contentType = 'application/octet-stream'; // Fallback for unknown types
  //   }

  //   const params = {
  //     Bucket: bucketName,
  //     Key: file.name,
  //     Body: file,
  //     ACL: 'public-read',
  //     ContentType : contentType
  //   };  
 

  //   await s3.upload(params, (err, data) => {
     
  //     if (err) {
  //       console.log(err);
  //     } else {
 
  //       if(data.Location.startsWith("sgp1") ){
  //         data.Location = "https://" + data.Location; 
  //       }

  //       setFiles((prevFiles) => [...prevFiles, {"url": data.Location, "name": file.name}]);
        
  //     }

  //     Metrixfunction(metrixserver, "fileUpload", "UPDATE");


  //     setLoading(false)
  //   });
  // };


  const encryptEpochTime = (epochTime) => {
    const secretKey = secretAccessKey;
    const encrypted = CryptoJS.AES.encrypt(epochTime.toString(), secretKey);
    return encrypted.toString();
  };

  const handleUpload = async (file) => {
    if (!file) {
      return;
    }
    setLoading(true);
  
    let contentType = '';
  
    if (file.name.endsWith('.pdf')) {
      contentType = 'application/pdf';
    } else if (file.name.endsWith('.png')) {
      contentType = 'image/png';
    } else if (file.name.endsWith('.jpeg') || file.name.endsWith('.jpg')) {
      contentType = 'image/jpeg';
    } else {
      contentType = 'application/octet-stream'; // Fallback for unknown types

    }

    const epochTime = Math.floor(Date.now() / 1000);
  const encryptedEpochTime = encryptEpochTime(epochTime);


  const modifiedFile = new File([file], file.name, {
    type: contentType, // Change this to the desired MIME type
  });
  
    const formData = new FormData();
    formData.append('file', modifiedFile);
    formData.append("encryptedEpochTime", encryptedEpochTime);
    // console.log(formData.get("file"))
    try {
      const response = await fetch('https://upload.mykaasu.com/upload', {
        method: 'POST',
        body: formData
      });
  
      // if (!response.ok) {
      //   throw new Error('Failed to upload file');
      // }
  
      const data = await response.json();

      // console.log("file upload response ==>",data);

      if(data.Error){
        showAlert(data.Error, "danger");
        setLoading(false);
        return
      }
  
      setFiles((prevFiles) => [...prevFiles, { "url": data.location, "name": file.name }]);
      Metrixfunction(metrixserver, "fileUpload", "UPDATE");
      setLoading(false);
  
    } catch (error) {
      console.log(error);
    } 
  };

  const handleDelete = (index) => {
    setFiles((prevFiles) => prevFiles.filter((file, i) => i !== index));
    document.getElementById(fileId).value = "";
  };  
  
  
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "",
    duration: 3000, // Default duration
  });


  const showAlert = (message, type, duration = 3000) => {
    setAlert({
      show: true,
      message,
      type,
      duration,
    });

    // Automatically close the alert after the duration
    setTimeout(() => {
      setAlert((prevAlert) => ({
        ...prevAlert,
        show: false,
      }));
    }, duration);
  };








 if (loading){

  return <SkeletonLoader labelName="Uploading File..." />
 }  

  return (
    <>
       <div className="fu-container">
         <label htmlFor={fileId} className="fu-fileUpload">
           <svg
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
             style={{ animation: 'none' }}
           >
            <g id="SVGRepo_bgCarrier" strokeWidth={0} />
           <g
             id="SVGRepo_tracerCarrier"
             strokeLinecap="round"
             strokeLinejoin="round"
           />
           <g id="SVGRepo_iconCarrier">
             <path
               d="M7 10V9C7 6.23858 9.23858 4 12 4C14.7614 4 17 6.23858 17 9V10C19.2091 10 21 11.7909 21 14C21 15.4806 20.1956 16.8084 19 17.5M7 10C4.79086 10 3 11.7909 3 14C3 15.4806 3.8044 16.8084 5 17.5M7 10C7.43285 10 7.84965 10.0688 8.24006 10.1959M12 12V21M12 12L15 15M12 12L9 15"
               stroke="#000000"
               strokeWidth="1.5"
               strokeLinecap="round"
               strokeLinejoin="round"
             />{" "}
           </g>
           </svg>{" "}
           <p>Select files to upload!</p>
         </label>
         <div className="fu-files">
          
           {files && files.length > 0 ? files.map((file, index) => (
             <div key={index} className="fu-file">
               <p className="fu-file-name">{getFileNameFromUrl(file?.url,17)}</p>
               <XCircle className='fu-file-delete-btn' onClick={() => handleDelete(index)} />
             </div>
           )) :
           <div className="fu-footer">
             <p>No files selected</p>
           </div>
           }
         </div>
         <input
           id={fileId}
           type="file"
           multiple
           accept={fileType}
           onChange={handleFileChange}
           style={{ display: 'none' }}
         />
       </div>

      {alert.show && (
        <Alert
          message={alert.message}
          type={alert.type}
          show={alert.show}
          duration={alert.duration}
          onClose={() => setAlert({ ...alert, show: false })}
        />
      )}

    </>
   
  );
}

export default FileUpload;
