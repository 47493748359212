import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import '../../styles/screenStyles/Tickets.css';
import DropDown from "../../components/DropDown";
import { ArrowLeft, ArrowRight, ChatLeft, PersonLinesFill, Rulers } from "react-bootstrap-icons";
import Loading from "../../components/Loading";
import Alert from "../../components/Alert";
// import ChatBot from "./ChatBot";
import { MyContext } from "../../components/GlobalValues";
import { setOptions } from "leaflet";
import Metrixfunction from "../../components/MetrixFunction";

// import ChatBot from "./chatbot";



function Tickets() {
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(true)
    const [ticket, setTicket] = useState('')
    const ticketOptions = ['Payment Not Received', 'Loan related', 'Pan Details', 'Bank Details', 'Aadhar Details', 'Other']
    const [allTickets, setAllTickets] = useState([])
    const statusOption = ['OPEN', 'CLOSED']
    const [status, setStatus] = useState('OPEN')
    const [pageNumber, setPageNumber] = useState(1)
    const [previewPressed, setPeviewPressed] = useState(false)
    const styleWidth = 500;
    const [disabledButtons, setDisabledButtons] = useState({});


    const [alert, setAlert] = useState({
        show: false,
        message: "",
        type: "",
        duration: 3000, // Default duration
    });

    const {
        setOpenChat,
        setTicketCreated,
        setTicketId,
        setMessages,
        setTicketStatus, 
        Url,
        windowWidth,
        metrixserver
    } = useContext(MyContext);

    const location=useLocation()

    function removeSlash(port1) {
        let p1 = port1.split("/").join("");
    
        let np = p1;
        return np;
      }

    const showAlert = (message, type, duration = 3000) => {
        setAlert({
            show: true,
            message,
            type,
            duration,
        });

        // Automatically close the alert after the duration
        setTimeout(() => {
            setAlert((prevAlert) => ({
                ...prevAlert,
                show: false,
            }));
        }, duration);
    };



    useEffect(() => {
        getAllTickets(status, 1);
    }, [status])


    const handleSelect = (option) => {
        setTicket(option)
    }

    const handleStatusSelect = (option) => {
        setStatus(option)
    }

    const createTicket = async () => {
        
        const accId = localStorage.getItem("accId");
        const apiKey = localStorage.getItem("apiKey");

        if (ticket) {
            setIsLoading(true)
            setTicket('')
            try {
                await fetch( `${Url}/business/createTicket`,
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            accId: accId,
                            apiKey: apiKey,
                            createdBy: 'user',
                            comment: ticket,
                            commentBy: 'user',
                            actionTo: 'support',
                            assignedTo:'EXTERNAL'
                        }),
                    }).then((res) => res.json())
                    .then((resJson) => {
                        if (resJson.Success) {
                            getAllTickets("OPEN", pageNumber);
                            setStatus("OPEN")
                            showAlert('Ticket created Successfully', 'success')
                        }
                        setIsLoading(false)

                    })
                    .finally(()=>{
                Metrixfunction(metrixserver, "createTicket", "UPDATE");

                    })

            } catch (error) {
                console.log("Error :", error);
                setIsLoading(false)
            }
        } else {
            showAlert('Please select an option', 'danger')
        }

    }

    const getAllTickets = async (type, pgNo) => {
        setStatus(type)
        setPageNumber(pgNo || pageNumber)

        const accId = localStorage.getItem("accId");
        const apiKey = localStorage.getItem("apiKey");
        setIsLoading(true)

        if (accId !== null && apiKey !== null) {


            try {
                await fetch(
                    `${Url}/business/getTicketsByUser`,
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            accId: accId,
                            apiKey: apiKey,
                            assignedTo: 'EXTERNAL',
                            status: type == 'OPEN'? 'ASSIGNED' : 'CLOSED',
                            pageNo: pgNo,
                        }),
                    }).then((res) => res.json())
                    .then((resJson) => {

                        if (resJson.Success) {
                            setAllTickets(resJson.Success)
                            setIsLoading(false)

                        }
                        setIsLoading(false)
                    })
                    .finally(()=>{
                       Metrixfunction(metrixserver, "getTicketsByUser", "ONLOAD");  

                    })
            } catch (error) {
                console.log("Error :", error);
                setIsLoading(false)
            }
        }

    }

    const viewTicket = async (tickId, tickStatus) => {
        localStorage.setItem("ticketId", "");
        await setTicketId(null);
        setMessages([])
        setTicketCreated(false)
        setTicketId(tickId)
        setOpenChat(true)
        setTicketStatus(tickStatus)
    }

    function formatTimeFromTimestamp(date) {
        var timeString = new Date(date).toLocaleTimeString(undefined, {
            timeZone: "Asia/Kolkata",
            hour: '2-digit',
            minute: '2-digit',
        });

        return timeString;
    }

    const handleButtonClick = (ticketId, status) => {
        if (!disabledButtons[ticketId]) {
          viewTicket(ticketId, status);
          setDisabledButtons((prevState) => ({ ...prevState, [ticketId]: true }));
          setTimeout(() => {
            setDisabledButtons((prevState) => ({ ...prevState, [ticketId]: false }));
          }, 5000);
        }
      };

    return (
        <div className="tickets-main">
            <div className="tickets-main-container">
                <p className="tickets-main-title">Your support request</p>
                <div className="ticket-dropdown-container">

                    <div className="ticket-dropdown-wrapper">
                        <div className="ticket-dropdown">
                            <DropDown onSelect={handleSelect} options={ticketOptions} initialValue={ticket} leftIcon={<ChatLeft />} drpdownWidth={"40%"} placeHolder={'Select Reason'} editable={true} />
                        </div>
                        {/* {ticket === 'Other' &&
                            <div className="ticket-input">
                                <InputField editable={true} placeholder={"Enter your query"} leftIcon={<PersonLinesFill />} />
                            </div>
                        } */}
                    </div>
                    <button className="ticket-button" onClick={createTicket}>Create Tickets</button>
                </div>

                <div className="tickets-type-container">
                    <p className="tickets-type-title">All Tickets</p>
                    {/* <div className="ticket-radio">
                        <span className={`ticket-radio-box ${radioValue === 'option1' ? 'ticket-radio-box-active' : ''}`} onClick={() => handleChange("option1")}></span>
                        <span className={`ticket-radio-box ${radioValue === 'option2' ? 'ticket-radio-box-active' : ''}`} onClick={() => handleChange("option2")}></span>
                    </div> */}
                    <div className="ticket-type">
                        <DropDown options={statusOption} editable={true} onSelect={handleStatusSelect} initialValue={status} />
                    </div>
                </div>
                {/* <hr></hr> */}
                {windowWidth <= styleWidth ?
                    allTickets.length != 0 ?
                        <div className="ticket-card-wrapper">
                            {allTickets.map((ticket, index) => {
                                return (
                                    <button key={index} disabled={disabledButtons[ticket.ticketId]} className="ticket-card" onClick={() => { handleButtonClick(ticket.ticketId, ticket.status) }}>
                                        <div className="ticket-card-header-wrapper">
                                            <p className="ticket-card-header">{ticket.comments[0].comment}</p>
                                            <p className={ticket.status == 'CLOSED' ? ' ticket-card-closed' : ' ticket-card-assigned'}>{ticket.status == 'ASSIGNED' ? 'OPEN' : 'CLOSED'}</p>
                                        </div>
                                        <div className="ticket-card-body">
                                            <p className="ticket-card-body-date">{ticket.createdDate.split('T')[0]}</p>
                                            <p className="ticket-card-body-time">{formatTimeFromTimestamp(ticket.createdDate)}</p>
                                        </div>
                                    </button>
                                )
                            })
                            }
                        </div> :
                        <div className="ticket-table-nodata">No Data</div>

                    :
                    <div className="ticket-table">
                        <div className="ticket-table-header">
                            <span className="ticket-table-header-title">Topic</span>
                            <span className="ticket-table-header-title">Date</span>
                            <span className="ticket-table-header-title">Time</span>
                            <span className="ticket-table-header-title">Status</span>
                            {/* <span></span> */}
                        </div>
                        {
                            allTickets.length != 0 ?
                                allTickets.map((ticket, index) => {
                                    return (
                                        <button key={index} disabled={disabledButtons[ticket.ticketId]} className="ticket-table-body" onClick={() => { handleButtonClick(ticket.ticketId, ticket.status) }}>
                                            <span className="ticket-table-body-content">{ticket.comments[0].comment}</span>
                                            <span className="ticket-table-body-content">{ticket.createdDate.split('T')[0]}</span>
                                            <span className="ticket-table-body-content">{formatTimeFromTimestamp(ticket.createdDate)}</span>
                                            <span className={`ticket-table-body-content ${ticket.status == 'CLOSED' ? ' ticket-closed' : ' ticket-assigned'}`}><p>{ticket.status == 'ASSIGNED' ? 'OPEN' : 'CLOSED'}</p></span>
                                        </button>
                                    )
                                })
                                : <div className="ticket-table-nodata">No Data</div>
                        }


                    </div>
                }

                <div className="tickets-footer">
                    {pageNumber > 1 &&
                        <span className="tickets-next-button" onClick={() => { setPeviewPressed(true); getAllTickets(status, pageNumber - 1) }}>
                            <ArrowLeft className="tickets-next-arrow" />
                        </span>
                    }

                    {allTickets.length >= 10 &&
                        <span className="tickets-next-button" onClick={() => { setPeviewPressed(false); getAllTickets(status, pageNumber + 1) }}>
                            <ArrowRight className="tickets-next-arrow" />
                        </span>
                    }
                </div>

            </div>

            {/* <button onClick={() => createTicket()}>create ticket</button> */}
            {isLoading && <Loading />}

            {alert.show && (<Alert message={alert.message} type={alert.type} show={alert.show} duration={alert.duration} onClose={() => setAlert({ ...alert, show: false })} />)}
        </div>
    )
}

export default Tickets;