import React, { useContext, useEffect, useRef, useState } from 'react'
import Metrixfunction from '../components/MetrixFunction';
import { MyContext } from '../components/GlobalValues';
function Careers() {

  const {  metrixserver} = useContext(MyContext);


  const [imagesLoaded, setImagesLoaded] = useState(0);
  const totalImages = useRef(0);
  
  useEffect(() => {
    // Count the total number of images
    const imageElements = document.querySelectorAll('img');
    totalImages.current = imageElements.length;
  
  
    // Add load event listeners to all images
    if(imageElements.length>0)
    {
      imageElements.forEach(img => {
        if (img.complete) {
          handleImageLoad();
        } else {
          img.addEventListener('load', handleImageLoad);
          img.addEventListener('error', handleImageLoad); // Handle error case
        }
      });
    }
    else{
      Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");
  
    }
  
    // Cleanup function
    return () => {
      imageElements.forEach(img => {
        img.removeEventListener('load', handleImageLoad);
        img.removeEventListener('error', handleImageLoad);
      });
    };
  }, []);
  
  useEffect(() => {
    if (imagesLoaded === totalImages.current && totalImages.current > 0) {
      Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");
  
    }
  }, [imagesLoaded]);
  
  const handleImageLoad = () => {
    setImagesLoaded(prev => prev + 1);
  };
  
  return (
    <div className="bg-white p-0 ">
      {/* Spinner Start */}
      <div
        id="spinner"
        className=" bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <div
          className="spinner-grow text-danger"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      {/* Spinner End */}
      {/* Navbar & Hero Start */}
      <div className="position-relative p-0" id="home">
        <custom-header />
        <div className="bg-primary hero-header">
          <div className="container px-lg-5">
            <div className="row g-4 mb-4">
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                <h1 className="mb-4" style={{ color: "white" }}>
                  Find Your Place in Our Vision
                </h1>
                {/* <p className="mb-4" style="color: white">Apply Now!</p> */}
                <div className="row g-4 mb-2" style={{ padding: 10 }}>
                  <a
                    href="#Job"
                    className="col-12 col-md-6 col-lg-8 wow fadeIn d-flex bg-home-text rounded scrollto"
                    style={{ padding: 5 }}
                    data-wow-delay="0.5s"
                  >
                    <div className="col-12">
                      <h5
                        className="home-text"
                        style={{
                          marginTop: 7,
                          textAlign: "center",
                          height: "30px !important",
                        }}
                      >
                        Explore our Openings
                      </h5>
                    </div>
                    {/* <div className="col"><a href="#"  className="icon"><i className="fab fa-apple fa-2x home-text flex-shrink-0"></i></a></div> */}
                    {/* <div className="col">
                      <img
                        src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/right-arrow (1).png"
                        width="40px"
                        style={{
                          marginTop: 2,
                          textAlign: "center",
                          filter: "brightness(0) saturate(100%) invert(34%) sepia(82%) saturate(5816%) hue-rotate(339deg) brightness(94%) contrast(88%)"
                        }}
                      />
                    </div> */}
                  </a>
                </div>
              </div>
              <div
                className="col-lg-6 d-flex justify-content-center justify-content-lg-end wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <img
                  src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/career1.png"
                  width="100%"
                  height="500px"
                />
                {/* <div className="owl-carousel screenshot-carousel">
                            <img className="img-fluid" src="img/01.jpg" alt="">
                            <img className="img-fluid" src="img/02.jpg" alt="">
                            <img className="img-fluid" src="img/03.jpg" alt="">
                            <img className="img-fluid" src="img/04.jpg" alt="">
                            <img className="img-fluid" src="img/05.jpg" alt="">
                        </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Navbar & Hero End */}
      {/* How to Apply Start */}
      <div className="container-fluid" id="about">
        <div className="container  px-lg-5" style={{ position: "relative" }}>
          <div className="row d-flex gap-5 flex-column  align-items-center justify-content-center  ">
            {/* <div className="col-md-4 rounded" style="background-color: #E32f45;">
                    <img className="img-fluid wow fadeInUp" data-wow-delay="0.5s" src="img/career.png">
                </div> */}
            <div
              className="col-10  align-self-end align-self-md-start  col-md-5 wow fadeInUp bg-light p-4 rounded "
              data-wow-delay="0.1s"
              style={{ alignSelf: "flex-start" }}
            >
              {/* <h5 className="text-primary-gradient fw-medium"></h5> */}
              <h2 className="mb-4 border-bottom p-2 ;font-family: 'Jost', sans-serif;">
                Life at MyKaasu!
              </h2>
              <p
                className="mb-4"
                style={{
                  textAlign: "justify",
                  fontFamily: '"Jost", sans-serif',
                }}
              >
                "Life at MyKaasu is a dynamic journey fueled by innovation and
                dedication. We're not just a Non-Banking Financial Company;
                we're a team on a mission to empower business merchants and
                drive growth. With a strong commitment to affordable financing
                and state-of-the-art technology, we're reshaping the way
                businesses access capital. Our culture thrives on adaptability,
                creativity, and a relentless pursuit of excellence. It's a place
                where your ideas matter, and your contributions make a real
                impact.
                <br />
                <br />
                Join us, and be part of a vibrant community that's transforming
                the future of business."
              </p>
              <div
                style={{
                  height: 5,
                  width: 5,
                  borderWidth: "30px 30px 30px 30px",
                  borderColor: "red red red red",
                }}
              />
              {/* <a href="loan.html" className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4">View More</a> */}
            </div>
            <div
              className=" col-10 col-md-5 wow fadeInUp bg-light p-4 rounded"
              data-wow-delay="0.1s"
              style={{ alignSelf: "flex-end" }}
            >
              {/* <h5 className="text-primary-gradient fw-medium"></h5> */}
              <h2 className="mb-4 border-bottom p-2 ;font-family: 'Jost', sans-serif;">
                What You'll Gain!
              </h2>
              <p
                className="mb-4"
                style={{
                  textAlign: "justify",
                  fontFamily: '"Jost", sans-serif',
                }}
              >
                <strong>Software Development Interns:</strong> Valuable hands-on
                experience, mentorship, knowledge of industry best practices,
                portfolio-building opportunities, and networking with
                experienced professionals.
                <br />
                <br />
                <strong>Marketing Executives:</strong> Enhanced marketing
                expertise, proficiency in digital marketing, improved
                communication and presentation skills, data analysis
                capabilities, and a chance to lead and collaborate with
                cross-functional teams.
                <br />
                <br />
                <strong>Support Engineers:</strong> In-depth technical skills,
                honed customer service and communication abilities, real-world
                problem-solving experience, adaptability under pressure, and a
                strong focus on customer satisfaction.
              </p>
              {/* <a href="loan.html" className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4">View More</a> */}
            </div>
            <div
              className="col-10  align-self-end align-self-md-start  col-md-5 wow fadeInUp bg-light p-4 rounded"
              data-wow-delay="0.1s"
              style={{ alignSelf: "flex-start" }}
            >
              {/* <h5 className="text-primary-gradient fw-medium"></h5> */}
              <h2 className="mb-4 border-bottom p-2 ">How to Apply!</h2>
              <p
                className="mb-4"
                style={{
                  textAlign: "justify",
                  fontFamily: '"Jost", sans-serif',
                }}
              >
                If you are passionate about any roles from our openings and
                looking for an internship or full-time that offers real-world
                experience, we encourage you to apply.
                <br />
                <br /> Please submit your resume and a brief cover letter
                explaining your interest in this position to{" "}
                <strong>recruiting@mykaasu.com</strong>
              </p>
              {/* <a href="loan.html" className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4">View More</a> */}
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center  flex-column  divider">
            <div className="stickyImage">
              <img
                src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/plane.png"
                height="50px"
                width="50px"
                className="simg"
                style={{
                   filter: "brightness(0) saturate(100%) invert(34%) sepia(82%) saturate(5816%) hue-rotate(339deg) brightness(94%) contrast(88%)"
                }}
              />
            </div>
            <div
              style={{
                padding: 5,
                height: 10,
                width: 10,
                borderRadius: "50%",
                backgroundColor: "black",
              }}
            ></div>
            <div
              style={{
                padding: 5,
                height: 10,
                width: 10,
                borderRadius: "50%",
                backgroundColor: "black",
              }}
            ></div>
            <div
              style={{
                padding: 5,
                height: 10,
                width: 10,
                borderRadius: "50%",
                backgroundColor: "black",
              }}
            ></div>
            {/* <img src="./img/plane.png" height="60px" width="60px" style="z-index: 998; position: sticky; top:50%; color: white; transform: rotate(43deg);"> */}
          </div>
        </div>
      </div>
      {/* How to apply End */}
      {/* ====================== end ==================================== */}
      {/* JOb OPenings Start */}
      <div className="py-5" id="Job">
        <div className=" py-5 px-lg-5">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h5 className="text-primary-gradient fw-medium">
              Our Job Openings
            </h5>
            <h1 className="mb-5">Join Our Team!</h1>
          </div>
          <div
            className="tab-class text-center  bg-light pricing wow fadeInUp pb-4 px-3 "
            data-wow-delay="0.1s"
          >
            <div className="tab-content text-start">
              <div className="tab-pane fade show p-0 ">
                <div className="row g-4  rounded ">
                  {/* <div className=" col-12 col-lg-4 " >
                            <div className="bg-light rounded bg-home-text" style="border: none; height:100%;" >
                                <div className=" p-4 mb-4 " >
                                    <h3 className="text-primary-gradient mb-2 ">Software Developer Intern</h3>
                                    <div className="d-flex  justify-content-between ">
                                    <span>Job Id : 15235</span>
                                    <span>Experience : 0 -3 years</span>
                                     </div>
  
                                     <button className="btn btn-primary-gradient rounded py-2 px-4 mt-3 w-100 w-md-25 "
                                     data-bs-toggle="modal" data-bs-target="#jobSDE">View Job</button>
                                </div>
  
                            </div>
                        </div> */}
                  <div className=" col-12 col-lg-4 ">
                    <div
                      className=" rounded bg-home-text"
                      style={{ border: "none", height: "100%" }}
                    >
                      <div className=" p-4 d-flex flex-column justify-content-between h-100 ">
                        <h4 className="text-primary-gradient mb-4 border-bottom p-2">
                          Software Developer Intern
                        </h4>
                        <div className="d-flex flex-column ">
                          <div className=" d-flex flex-column flex-md-row justify-content-between flex-wrap  ">
                            <p className="jobdetails1">
                              <i className="fa fa-check text-primary-gradient pt-1 me-3 " />
                              <span>Job Id : 15235</span>
                            </p>
                            <p className="jobdetails2">
                              <i className="fa fa-check text-primary-gradient pt-1 me-3 " />
                              <span>Experience : 0 to 3 years</span>
                            </p>
                            <p className="jobdetails1">
                              <i className="fa fa-check text-primary-gradient pt-1 me-3 " />
                              <span>CTC : 1 to 2 LPA</span>
                            </p>
                            <p className="jobdetails2">
                              <i className="fa fa-check text-primary-gradient pt-1 me-3 " />
                              <span>Location : Chennai,IN</span>
                            </p>
                          </div>
                          <button
                            className="btn btn-primary-gradient rounded py-2 px-4 px-md-2 mt-3 w-50 align-self-center "
                            data-bs-toggle="modal"
                            data-bs-target="#jobSDE"
                          >
                            View Job
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-12 col-lg-4 ">
                    <div
                      className="rounded bg-home-text"
                      style={{ border: "none", height: "100%" }}
                    >
                      <div className=" p-4 d-flex flex-column justify-content-between h-100 ">
                        <h4 className="text-primary-gradient mb-4 p-2 border-bottom ">
                          Marketing Executive
                        </h4>
                        <div className="d-flex flex-column ">
                          <div className=" d-flex flex-column flex-md-row justify-content-between flex-wrap  ">
                            <p className="jobdetails1">
                              <i className="fa fa-check text-primary-gradient pt-1 me-3 " />
                              <span>Job Id : 15236</span>
                            </p>
                            <p className="jobdetails2">
                              <i className="fa fa-check text-primary-gradient pt-1 me-3 " />
                              <span>Experience : 0 to 3 years</span>
                            </p>
                            <p className="jobdetails1">
                              <i className="fa fa-check text-primary-gradient pt-1 me-3 " />
                              <span>CTC : 1.8 to 3 LPA</span>
                            </p>
                            <p className="jobdetails2">
                              <i className="fa fa-check text-primary-gradient pt-1 me-3 " />
                              <span>Location : Chennai,IN</span>
                            </p>
                          </div>
                          <button
                            className="btn btn-primary-gradient rounded py-2 px-4 px-md-2 mt-3 w-50 align-self-center"
                            data-bs-toggle="modal"
                            data-bs-target="#jobME"
                          >
                            View Job
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className=" col-12 col-lg-4 " >
                            <div className="bg-light rounded bg-home-text" style="border: none;height:100%;">
                                <div className=" p-4">
                                    <h4 className="text-primary-gradient mb-4">Marketing Executive</h4>
                                    <div className="d-flex  justify-content-between  border-bottom pb-2">
                                        <span>Job Id : 15236</span>
                                        <span>Experience : 0 -3 years</span>
                                    </div>
      
                                         <button className="btn btn-primary-gradient rounded py-2 px-4 mt-3 w-100 "
                                         data-bs-toggle="modal" data-bs-target="#jobME">View Job</button>
                                </div>
                               
                            </div>
                        </div> */}
                  <div className=" col-12 col-lg-4 ">
                    <div
                      className=" rounded bg-home-text"
                      style={{ border: "none", height: "100%" }}
                    >
                      <div className=" p-4 d-flex flex-column justify-content-between h-100 ">
                        <h4 className="text-primary-gradient mb-4 p-2 border-bottom ">
                          Support Engineer
                        </h4>
                        <div className="d-flex flex-column ">
                          <div className=" d-flex flex-column flex-md-row justify-content-between flex-wrap  ">
                            <p className="jobdetails1">
                              <i className="fa fa-check text-primary-gradient pt-1 me-3 " />
                              <span>Job Id : 15237</span>
                            </p>
                            <p className="jobdetails2">
                              <i className="fa fa-check text-primary-gradient pt-1 me-3 " />
                              <span>Experience : 0 to 3 years</span>
                            </p>
                            <p className="jobdetails1">
                              <i className="fa fa-check text-primary-gradient pt-1 me-3 " />
                              <span>CTC : 1.5 to 2 LPA</span>
                            </p>
                            <p className="jobdetails2">
                              <i className="fa fa-check text-primary-gradient pt-1 me-3 " />
                              <span>Location : Chennai,IN</span>
                            </p>
                          </div>
                          <button
                            className="btn btn-primary-gradient rounded py-2 px-4 px-md-2 mt-3 w-50 align-self-center"
                            data-bs-toggle="modal"
                            data-bs-target="#jobSE"
                          >
                            View Job
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Job Openings End */}
      {/* Modal 1*/}
      <div
        className="modal fade"
        style={{ zIndex: 10000 }}
        id="jobSDE"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header" style={{ border: "none" }}>
              {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="container ">
                <div className="row">
                  <div className="col-12">
                    <div className=" rounded">
                      <div className=" p-3 mb-4 bg-light border-bottom rounded">
                        <h3 className="text-primary-gradient pb-3 border-bottom ">
                          Software Developer Intern
                        </h3>
                        <div className=" d-flex flex-column flex-md-row justify-content-between flex-wrap  ">
                          <p className=" jobdetails1">Job Id : 15235</p>
                          <p className=" jobdetails2 ">
                            Experience : 0 -3 years
                          </p>
                          <p className=" jobdetails1">CTC : 1-2 LPA </p>
                          <p className=" jobdetails2">Location : Chennai,IN</p>
                        </div>
                      </div>
                      <div className="p-4 pt-2 ">
                        <h6 className="mb-3">Qualifications</h6>
                        <div className="d-flex gap-4  mb-3">
                          <i className="fa fa-check text-primary-gradient pt-1" />
                          <span>
                            Currently pursuing a degree in Computer Science,
                            Software Engineering, or a related field.
                          </span>
                        </div>
                        <div className="d-flex gap-4  mb-3">
                          <i className="fa fa-check text-primary-gradient pt-1" />
                          <span>
                            Familiarity with programming languages (e.g., Java,
                            Python, C++, etc.).
                          </span>
                        </div>
                        <div className="d-flex gap-4  mb-3">
                          <i className="fa fa-check text-primary-gradient pt-1" />
                          <span>
                            Good communication and teamwork abilities.
                          </span>
                        </div>
                        <div className="d-flex gap-4  mb-2">
                          <i className="fa fa-check text-primary-gradient pt-1" />
                          <span>
                            Solid problem-solving and analytical skills.
                          </span>
                        </div>
                      </div>
                      <div className="p-4 pt-2 ">
                        <h6 className="mb-3">Key Responsibilities:</h6>
                        <div className="d-flex gap-4  mb-3">
                          <i className="fa fa-check text-primary-gradient pt-1" />
                          <span>
                            Collaborate with the development team to design,
                            develop, test, and maintain software solutions.
                          </span>
                        </div>
                        <div className="d-flex gap-4  mb-3">
                          <i className="fa fa-check text-primary-gradient pt-1" />
                          <span>
                            Learn and apply industry best practices in software
                            development.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="modal-footer">
      <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
      <button type="button" className="btn btn-outline-primary ">Apply Job</button>
    </div> */}
          </div>
        </div>
      </div>
      {/* -------- Modal end ---------- */}
      {/* Modal 2 */}
      <div
        className="modal fade"
        style={{ zIndex: 10000 }}
        id="jobME"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header" style={{ border: "none" }}>
              {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="container ">
                <div className="row">
                  <div className="col-12">
                    <div className=" rounded">
                      <div className=" p-4 mb-4 bg-light border-bottom rounded">
                        <h3 className="text-primary-gradient pb-3 border-bottom ">
                          Marketing Executive
                        </h3>
                        <div className=" d-flex flex-column flex-md-row justify-content-between flex-wrap  ">
                          <p className=" jobdetails1">Job Id : 15236</p>
                          <p className=" jobdetails2 ">
                            Experience : 0 -3 years
                          </p>
                          <p className=" jobdetails1">CTC : 1.8-3 LPA </p>
                          <p className=" jobdetails2">Location : Chennai,IN</p>
                        </div>
                      </div>
                      <div className="p-4 pt-2 ">
                        <h6 className="mb-3">Qualifications</h6>
                        <div className="d-flex gap-4  mb-3">
                          <i className="fa fa-check text-primary-gradient pt-1" />
                          <span>
                            Bachelor's degree in Marketing, Business,
                            Communications, or a related field.
                          </span>
                        </div>
                        <div className="d-flex gap-4  mb-3">
                          <i className="fa fa-check text-primary-gradient pt-1" />
                          <span>
                            A focus on data-driven decision-making and
                            continuous improvement in marketing strategies.
                          </span>
                        </div>
                        <div className="d-flex gap-4  mb-3">
                          <i className="fa fa-check text-primary-gradient pt-1" />
                          <span>
                            Good communication and teamwork abilities.
                          </span>
                        </div>
                        <div className="d-flex gap-4  mb-2">
                          <i className="fa fa-check text-primary-gradient pt-1" />
                          <span>
                            Eagerness to learn and adapt to new challenges.
                          </span>
                        </div>
                      </div>
                      <div className="p-4 pt-2 ">
                        <h6 className="mb-3">Key Responsibilities:</h6>
                        <div className="d-flex gap-4  mb-3">
                          <i className="fa fa-check text-primary-gradient pt-1" />
                          <span>
                            Ability to use data analytics to measure the
                            effectiveness of marketing campaigns.
                          </span>
                        </div>
                        <div className="d-flex gap-4  mb-3">
                          <i className="fa fa-check text-primary-gradient pt-1" />
                          <span>
                            A commitment to ethical marketing practices and
                            adherence to industry regulations.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="modal-footer">
      <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
      <button type="button" className="btn btn-outline-primary ">Apply Job</button>
    </div> */}
          </div>
        </div>
      </div>
      {/* -------- Modal end ---------- */}
      {/* Modal 3*/}
      <div
        className="modal fade"
        style={{ zIndex: 10000 }}
        id="jobSE"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="container ">
                <div className="row">
                  <div className="col-12">
                    <div className=" rounded">
                      <div className=" p-4 mb-4 bg-light border-bottom rounded">
                        <h3 className="text-primary-gradient pb-3 border-bottom ">
                          Support Engineer
                        </h3>
                        <div className=" d-flex flex-column flex-md-row justify-content-between flex-wrap  ">
                          <p className="jobdetails1">Job Id : 15237</p>
                          <p className="jobdetails2 ">
                            Experience : 0 -3 years
                          </p>
                          <p className="jobdetails1">CTC : 1.5-2 LPA </p>
                          <p className="jobdetails2">Location : Chennai,IN</p>
                        </div>
                      </div>
                      <div className="p-4 pt-2 ">
                        <h6 className="mb-3">Qualifications</h6>
                        <div className="d-flex gap-4  mb-3">
                          <i className="fa fa-check text-primary-gradient pt-1" />
                          <span>Bachelor's degree in any related field.</span>
                        </div>
                        <div className="d-flex gap-4  mb-3">
                          <i className="fa fa-check text-primary-gradient pt-1" />
                          <span>
                            Excellent problem-solving abilities to address
                            customer inquiries and troubleshoot technical
                            problems.
                          </span>
                        </div>
                        <div className="d-flex gap-4  mb-3">
                          <i className="fa fa-check text-primary-gradient pt-1" />
                          <span>
                            Clear and concise communication skills to interact
                            with customers and provide solutions.
                          </span>
                        </div>
                        <div className="d-flex gap-4  mb-2">
                          <i className="fa fa-check text-primary-gradient pt-1" />
                          <span>
                            Basic Computer Knowledge and Excel skills are added
                            advantage.
                          </span>
                        </div>
                      </div>
                      <div className="p-4 pt-2 ">
                        <h6 className="mb-3">Key Responsibilities:</h6>
                        <div className="d-flex gap-4  mb-3">
                          <i className="fa fa-check text-primary-gradient pt-1" />
                          <span>
                            A strong focus on customer satisfaction and a
                            commitment to delivering high-quality support.
                          </span>
                        </div>
                        <div className="d-flex gap-4  mb-3">
                          <i className="fa fa-check text-primary-gradient pt-1" />
                          <span>
                            The capability to convey technical information in a
                            non-technical manner.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="modal-footer">
      <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
      <button type="button" className="btn btn-outline-primary ">Apply Job</button>
    </div> */}
          </div>
        </div>
      </div>
      {/* -------- Modal end ---------- */}
      {/* Footer Start */}
      <custom-footer />
      {/* Footer End */}
      {/* Back to Top */}
      <a href="#" className="btn btn-lg btn-lg-square back-to-top pt-2">
        <i className="bi bi-arrow-up home-text" />
      </a>
    </div>
  );
}

export default Careers