import React, { useEffect, useState } from "react";
import "../styles/componentStyles/Breadcrumps.css";

import { useLocation, useNavigate, } from "react-router-dom";
import { ChevronRight } from "react-bootstrap-icons";

const Breadcrumbs = ({ crumbs }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { options } = location.state || {};
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [updateprevpath, setprevpath] = useState(() => {
    const storedPaths = localStorage.getItem("breadcrumbs");
    return storedPaths ? JSON.parse(storedPaths) : [];
  });

  const restrictedscreens=["/profile","/tickets","/settings","/grievance"]

  function removeSlash(port1) {
    let p1 = port1.split("/").join("");

    let np = p1;
    return np;
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const disableCrumbs = 500;

  useEffect(() => {
    if (localStorage.getItem("accId") === null || localStorage.getItem("apiKey") === null) {
      localStorage.clear();
      navigate("/login")
      return
    }
    let eligible=localStorage.getItem("eligibleApproved")


    
    if(location.pathname!=="/dashboard" && location.pathname!=="/checkeligibility")
      {
        if (
          (eligible === null || eligible === "no") &&
          !restrictedscreens.includes(location.pathname)
        ) {

          navigate("/checkeligibility");
        }
      }
     if (location.pathname === "/dashboard" || location.pathname === "/checkeligibility" ) {
      setprevpath([]);
    } else if (options) {
      if (!options?.updateBreadcrumbs) {
        if (!updateprevpath.includes(removeSlash(location.pathname))) {
           setprevpath([
            ...updateprevpath,
            removeSlash(location.pathname),
          ]);
        } else {
          let index = updateprevpath.indexOf(
            removeSlash(location.pathname)
          );

          if (index !== -1) {
            let arr = [...updateprevpath];
            arr = arr.slice(0, index + 1);
             setprevpath(arr);
          }
        }
      } else {
        setprevpath([removeSlash(location.pathname)]);
      }
    } else {
      setprevpath([removeSlash(location.pathname)]);
    }


  }, [location.pathname]);

 
  useEffect(() => {

    localStorage.setItem("breadcrumbs", JSON.stringify(updateprevpath));
  }, [updateprevpath]);


  

  return windowWidth > disableCrumbs &&  (

    (location.pathname === "/dashboard" || location.pathname === "/checkeligibility" || location.pathname === "/") ? null :
      <div className="braedcrumps-container">
        <span className="breadcrumps-home" onClick={() => {localStorage.getItem("eligibleApproved")==="no"? navigate("/checkeligibility"):navigate("/dashboard") }}>Home</span>

        {updateprevpath.map((name, index) => {
          const routePath = `/${name}`;
           return updateprevpath.length - 1 === index ? (
            <div
              className=" "
              key={index}
            >
              <ChevronRight size={14} className="breadcrumps-chevron" />{name}
            </div>
          ) : (

            <div
             key={index}
              onClick={() => {
                let index = updateprevpath.indexOf(name);

 
                if (index !== -1) {
                  // Remove elements from the array starting from the index
                  let arr = [...updateprevpath];
                  arr = arr.slice(0, index + 1);
                   setprevpath(arr)
                }

                let options = { updateBreadcrumbs: false };
                navigate(routePath, { state: { options: options } });
              }}

            >
              <ChevronRight className="breadcrumps-chevron" stroke="#E32f45" color="#E32f45" size={14} /><span className="breadcrumps-home">{name}</span>
            </div>

          );
        })}
      </div>

  ) ;
};

export default Breadcrumbs;
