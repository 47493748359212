import React, { useContext, useEffect, useRef, useState } from "react";
import "../../styles/screenStyles/LoanDetailsScreen.css";
import { Accordion } from "react-bootstrap";
import { ChevronsRight, Download, Info, UserCheck } from "react-feather";
import ProgressBar from "../../components/ProgressBar";
import { Modal } from "react-bootstrap";
import { X } from "react-feather";
import Slider from "../../components/Slider";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "../../components/Alert";
import Loading from "../../components/Loading";
import InputField from "../../components/InputField";
import { Bank, Translate } from "react-bootstrap-icons";
import { MyContext } from "../../components/GlobalValues";
import Metrixfunction from "../../components/MetrixFunction";

function LoanDetailsScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const {Url,metrixserver}  = useContext(MyContext);

  const { loanId } = location.state || {};
  const [activeTab, setActiveTab] = useState(0);
  const [loanStatusCard, setLoanStatusCard] = useState("");
  const [userName, setUserName] = useState("");
  const [loanAmountStore, setLoanAmountStore] = useState("");
  const [paidPrincipleAmount, setPaidPrincipleAmount] = useState("");
  const [unPaidPrincipleAmount, setUnPaidPrincipleAmount] = useState("");
  const [unpaidChargesValue, setUnpaidChargesValue] = useState("");
  const [EMIDaily, setEMIDaily] = useState("");
  const [eligibleInterestStore, setEligibleInterestStore] = useState(0);
  const [loanStartDate, setLoanStartDate] = useState("");
  const [loanEndDate, setLoanEndDate] = useState("");
  const [paymentList, setPaymentList] = useState([]);
  const [loadId, setLoadId] = useState("");
  const [interestAmount, setInterestAmount] = useState("");
  const [settlementAmountStore, setSettlementAmountStore] = useState("");
  const [bankIFSC, setBankIFSC] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [remarksNotes, setRemarksNotes] = useState("");
  const [upcomingPayments, setUpcomingPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSettlementAmount, setShowSettlementAmount] = useState(false);

  const [duesChargesArrayList, setDuesChargesArrayList] = useState([]);
  const [dueCharges, setDueCharges] = useState("");
  const [duesPaid, setDuesPaid] = useState("");
  const [nocLinkData, setNocLinkData] = useState("");
  const [agreementLinkData, setAgreementLinkData] = useState("");
  const [settlementCardOpen, setSettlementCardOpen] = useState(false);
  const [hoverAnimation, setHoverAnimation] = useState(true);

  function removeSlash(port1) {
    let p1 = port1.split("/").join("");

    let np = p1;
    return np;
  }
  const changeTab = (index) => {
    setActiveTab(index);
  };

  let [pendingAmount, setPendingAmount] = useState(80000);
  let emiAmount = 3000;
  let interestRate = 0.12 / 12;

  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "",
    duration: 3000, // Default duration
  });

  const showAlert = (message, type, duration = 3000) => {
    setAlert({
      show: true,
      message,
      type,
      duration,
    });

    // Automatically close the alert after the duration
    setTimeout(() => {
      setAlert((prevAlert) => ({
        ...prevAlert,
        show: false,
      }));
    }, duration);
  };

  //  const calculateInterest = () => {

  //    return interestAmount;
  //  };

  // const calculateNextPayments = () => {
  //   let payments = [];
  //   let remainingAmount = pendingAmount;
  //   let lastpaymentFlag = 0;
  //   let currentDate = new Date();

  //   while (remainingAmount > 0) {
  //     if (remainingAmount < emiAmount) {
  //       emiAmount = remainingAmount;
  //       lastpaymentFlag = 1;
  //     }
  //     let interestAmount = Math.round(remainingAmount * interestRate);
  //     let currPrincipal = emiAmount - interestAmount;
  //     remainingAmount -= currPrincipal;
  //     if (lastpaymentFlag == 1) {
  //       remainingAmount = 0;
  //     }

  //     let nextPaymentDate = new Date(currentDate);
  //     nextPaymentDate.setMonth(nextPaymentDate.getMonth() + payments.length);

  //     payments.push({
  //       emi: emiAmount,
  //       interest: interestAmount,
  //       currPrincipal: currPrincipal,
  //       remainingAmount: remainingAmount, // Ensure remaining amount doesn't go below 0
  //       paymentDate: nextPaymentDate.toLocaleDateString("en-IN", {
  //         year: "numeric",
  //         month: "long",
  //       }),
  //     });
  //   }

  //   return payments;
  // };

  const calculateNextPayments = (
    unPaidPrincipleAmountArg,
    eligibleInterestArg,
    EMIDailyArg,
    tenure
  ) => {
    // console.log(
    //   unPaidPrincipleAmountArg,
    //   EMIDailyArg,
    //   eligibleInterestArg,
    //   tenure
    // );
    let payments = [];
    let remainingAmount = Math.round(unPaidPrincipleAmountArg);
    let lastpaymentFlag = 0;
     let currentDate = new Date();
     currentDate.setDate(currentDate.getDate() + 30);
     currentDate.setDate(1);
    let emiAmount = Math.round(EMIDailyArg);
    let interestRate = Number(
      (Math.round(eligibleInterestArg) / 100 / 12).toFixed(4)
    );
     

    let iterationCount = 0;
    const maxIterations = 100;

    while (remainingAmount > 0) {
       if (remainingAmount < emiAmount) {
        emiAmount = remainingAmount;
        lastpaymentFlag = 1;
      }
      let interestAmount = Math.round(remainingAmount * interestRate);
      let currPrincipal = emiAmount - interestAmount;
      if (currPrincipal < 0) {
        showAlert(
          "Issue with the loan please wait until we verify it",
          "danger"
        );
        break;
      }
      remainingAmount -= currPrincipal;
      if (lastpaymentFlag == 1) {
        remainingAmount = 0;
      }

      let nextPaymentDate = new Date(currentDate);
      nextPaymentDate.setMonth(nextPaymentDate.getMonth() + payments.length);

      payments.push({
        emi: emiAmount,
        interest: interestAmount,
        currPrincipal: currPrincipal,
        remainingAmount: remainingAmount,
        paymentDate: nextPaymentDate.toLocaleDateString("en-IN", {
          year: "numeric",
          month: "long",
        }),
      });

      iterationCount++;
      if (iterationCount >= maxIterations) {
        //console.log(
        //   "Max iterations reached. Breaking the loop to prevent infinite loop."
        // );
        break;
      }
    }
    

    return payments;
  };

  // const upcomingPayments = calculateNextPayments();

   

  const [settlementModalOpen, setSettlementModalOpen] = useState(false);
  const handlesettlementmodalopen = () => setSettlementModalOpen(true);
  const handlesettlementmodalclose = () => {
    setSettlementModalOpen(false);
  };
  const [dueChargesModalOpen, setDueChargesModalOpen] = useState(false);
  const handleduechargesmodalopen = () => setDueChargesModalOpen(true);
  const handleduechargesmodalclose = () => {
    setDueChargesModalOpen(false);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [selectedOption, setSelectedOption] = useState("Partial Payment");

  const [loanAmount, setLoanAmount] = useState(0);
  const handleLoanAmountChange = (newValue) => {
    setLoanAmount(newValue);
  };

  const getLoanDetailsApi = async (loanId) => {
    try {
      const accId = localStorage.getItem("accId");
      const apiKey = localStorage.getItem("apiKey");

      if (accId.trim() === "" || apiKey.trim() === "" || loanId.trim() === "") {
      
        localStorage.clear()
        navigate("/login");
        return;
      }


      const response = await fetch(
        `${Url}/business/v2/getLoanDetails`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            accId,
            apiKey,
            loanId,
          }),
        }
      ).then((res) => res.json())
        .then((resJson) => {
      

      
if (resJson.Success) {
      let sortedPayments = resJson.Success.payments
        .map((payment) => ({
          ...payment,
          date: new Date(payment.date),
        }))
        .sort((a, b) => a.date - b.date);

      setLoadId(resJson.Success.loanId);
      setPaymentList(sortedPayments);
      setInterestAmount(resJson.Success.loanInterestAmount);
      
      setLoanStatusCard(resJson.Success.loanStatus);
      setUserName(localStorage.getItem("userName"));
      setLoanAmountStore(resJson.Success.loanAmount);
      setPaidPrincipleAmount(resJson.Success.paidPrincipalAmount);
      setUnPaidPrincipleAmount(resJson.Success.unpaidPrincipalAmount);
      setEMIDaily(resJson.Success.dailyEMI);
      setEligibleInterestStore(resJson.Success.loanInterestRatePct);
      setLoanStartDate(resJson.Success.loanStartDate);
      setLoanEndDate(resJson.Success.loanEndDate);
      setBankIFSC(resJson.Success.virtualAcc.virtualAccIFSC);
      setBankAccount(resJson.Success.virtualAcc.virtualAccNumber);
      setRemarksNotes(resJson.Success.virtualAcc.virtualAccRemarks);
      setRemarksNotes(resJson.Success.virtualAcc.virtualAccRemarks);
      setDueCharges(resJson.Success.dueCharges);
      setDuesPaid(resJson.Success.duesPaid);

      if (
        resJson.Success.loanStatus == "INPROGRESS" ||
        resJson.Success.loanStatus == "CLOSED"
      ) {
        setAgreementLinkData(resJson.Success.agreementLink);
        if(resJson.Success.loanStatus == "CLOSED") {
          setNocLinkData(resJson.Success.nocLink);
        }
        if (
                resJson.Success.loanStatus == "INPROGRESS" &&
                resJson.Success.loanDisbursedDate.length < 5
              ) {
                setLoanStatusCard("PENDINGAMOUNT");
              }
        const uppAmount =
          Number(resJson.Success.unpaidPrincipalAmount) +
          (Number(resJson.Success.dueCharges) -
            Number(resJson.Success.duesPaid));
              setUnpaidChargesValue(uppAmount);

        setDuesChargesArrayList(resJson.Success.extraCharges);
        setUpcomingPayments(
          calculateNextPayments(
            uppAmount,
            Number(resJson.Success.loanInterestRatePct),
            Number(resJson.Success.dailyEMI),
            Number(resJson.Success.loanDuration)
          )
        );
      }
      setIsLoading(false);
    } else {
       
      showAlert("Not Registered", "danger");
      localStorage.clear()
      navigate("/login");
    }
  })
  .finally(()=>{
      Metrixfunction(metrixserver,"getLoanDetails", "ONLOAD");

  })
    } catch (error) {
      console.log(error);
      showAlert(error, "danger");
    }
  };

  useEffect(() => {
    getLoanDetailsApi(loanId);
  }, []);

  // faq

  const faqItems = [
    {
      question: "What does confirmation status mean in a transaction?",
      answer:
        "When the user goes to the retailer through MyKaasu and completes a transaction, the transaction amount will be received by the retailer. The transaction status will be updated to 'CONFIRMED' at this stage and can be viewed under transactions. Once the amount is added to the customer wallet, the status will change to 'PAID'.",
    },
    {
      question: "What is the difference between Bonus and Cashback?",
      answer:
        "Cashback is the amount you receive from your purchases and rewards are joining/referral bonus you receive when your friend purchases. We pay up to 10% of the cashback every time your referral purchases.",
    },
    {
      question: "Is there any expiry for my Cashback/Rewards?",
      answer:
        "NO. There is no expiry on the Cashback or rewards balance. But if you decide to close your account, then the account balance cannot be withdrawn if the amount is less than 50/-",
    },
    {
      question: "My Cashback/Rewards did not track, what can I do?",
      answer:
        "Please navigate to transactions and add a comment under 'Need Help' against the corresponding transaction. Our customer support team will revert back shortly.",
    },
    {
      question:
        "How can I combine my Cashback and Rewards to request for the Payment?",
      answer:
        "Navigate to 'Withdraw Amount' to view the cashback and rewards balance. You can combine both the balances and withdraw when the conditions are met,\n(i) Your total balance amount is Rs 50/- or higher. Withdraw button will be disabled when balance is low.\n(ii) Bank account details are updated under Account > Bank Account.\n(iii) Previous transaction is not in progress.",
    },
    {
      question: "How much will I earn on referring a friend?",
      answer:
        "There is no limit on the amount you can receive. Currently you are eligible to earn up to 10% of the total referral earnings.",
    },
    {
      question: "Can I pay off my loan early?",
      answer:
        "Some borrowers want to know if they can make extra payments or pay off the loan ahead of schedule without penalties.",
    },
    {
      question: "What is the loan approval timeline?",
      answer:
        "Borrowers often ask how long it takes to get approved for a loan and receive the funds.",
    },
    {
      question: "What are the interest rates?",
      answer:
        "Understanding the interest rates associated with a loan is a common concern, including fixed vs. variable rates.",
    },
  ];

  function loanProgressFunc(loanAmount,unpaidAmount) {
     let finalPercent = Math.round(((loanAmount - unpaidAmount)/loanAmount) * 100)

    
     if(finalPercent < 0) {
      finalPercent = 3
    } else if(finalPercent >= 0 && finalPercent <=5){
      finalPercent = 3;
    } else if(finalPercent > 100){
       finalPercent = 100
     }
     return finalPercent
  }

  function formatDate(isoString) {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }


    const getSettlementsApi = async () => {
      try {
        const accId = localStorage.getItem("accId");
        const apiKey = localStorage.getItem("apiKey");
       

        if (selectedDay === "" || selectedMonth === "" || selectedYear === "") {
          showAlert("Date is empty. Pick the date", "danger");
          return;
        }
          if (
            accId.trim() === "" ||
            apiKey.trim() === "" ||
            loanId.trim() === ""
          ) {
            
            localStorage.clear()
            navigate("/login");
            return;
          }


        const response = await fetch(
          `${Url}/test/getSettlementDetails`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              accId:accId,
              apiKey: apiKey,
              loanId: loadId,
              day:selectedDay,
              month:selectedMonth,
              year:selectedYear,
            }),
          }
        );
        const resJson = await response.json();
         

        if (!resJson.Success) {
         
          showAlert("Not Registered", "danger");
          localStorage.clear()
          navigate("/login");
          return;
        }

        if (resJson.Success) {
          setShowSettlementAmount(true)
          setSettlementAmountStore(resJson.Success.settlementAmt);
        } else if (resJson.Error) {
          setShowSettlementAmount(false);
          showAlert(resJson.Error, "danger");
        }
        setIsLoading(false);

        Metrixfunction(metrixserver, "getSettlementDetails", "ONLOAD");

      } catch (error) {
        console.error(error);
        showAlert(error, "danger");
      }
    };

    // select date time picker for settlement
const [selectedDay, setSelectedDay] = useState("");
const [selectedMonth, setSelectedMonth] = useState("");
const [selectedYear, setSelectedYear] = useState("");

 const currentDate = new Date();
 const currentYear = currentDate.getFullYear();
 const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-indexed month
 const currentDay = currentDate.getDate();

  const generateDays = (month, year) => {
    const daysInMonth = new Date(year, month, 0).getDate();
    let days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

    // Filter out past days if current year and month are selected
    if (year === currentYear && month === currentMonth) {
      days = days.filter((day) => day >= currentDay);
    }

    return days;
  };

  // Generate month options (1 to 12)
  const generateMonths = (year) => {
    let months = Array.from({ length: 12 }, (_, i) => i + 1);

    // Filter out past months if current year is selected
    if (year === currentYear) {
      months = months.filter((month) => month >= currentMonth);
    }

    return months;
  };

  // Generate year options (from current year onwards)
  const generateYears = () => {
    const futureYears = Array.from({ length: 30 }, (_, i) => currentYear + i);
    return futureYears;
  };

   const settlementdays =
     selectedMonth && selectedYear
       ? generateDays(Number(selectedMonth), Number(selectedYear))
       : [];
   const settlementmonths = selectedYear
     ? generateMonths(Number(selectedYear))
     : [];
   const settlementyears = generateYears();


 const handleDayChange = (event) => setSelectedDay(event.target.value);
 const handleMonthChange = (event) => setSelectedMonth(event.target.value);
 const handleYearChange = (event) => setSelectedYear(event.target.value);

 const settlmentCardRef = useRef(null);

  useEffect(() => {
     const handleClickOutside = (event) => {
       if (
         settlmentCardRef.current &&
         !settlmentCardRef.current.contains(event.target)
       ) {
         setSettlementCardOpen(false);
        setHoverAnimation(true);
         setSelectedDay("")
         setSelectedMonth("")
         setSelectedYear("")
         
       }
     };

     document.addEventListener("mousedown", handleClickOutside);
     return () => {
       document.removeEventListener("mousedown", handleClickOutside);
     };
   }, []);

  return (
    (isLoading && <Loading />) || (
      <div className="loan-details-container">
        {loanStatusCard === "INPROGRESS" && (
          <div className="loan-details-partial-container">
            <button
              className="loan-details-partial-button"
              onClick={() => {
                setShowSettlementAmount(false);
                handlesettlementmodalopen();
              }}
            >
              Loan Account
            </button>
            {loanStatusCard === "INPROGRESS" && agreementLinkData.length > 2 ? (
              <a className="loan-details-agreement-button"
              href={agreementLinkData}
              download="agreement.pdf">
                Agreement
              </a>
            ):null}
          </div>
        )}

        {loanStatusCard === "PENDINGAMOUNT" && (
          <div
            className={`home-dynamic-card ${
              loanStatusCard === "PENDINGAMOUNT" && "eligible"
            } ${
              hoverAnimation ? "hover-home-card-dynamic" : ""
            } wow fadeInLeft`}
            data-wow-delay="0.1s"
            onClick={() => {
              navigate("/loanDetails", { state: { loanId: loadId } });
            }}
          >
            <div className="home-dynamic-card-content">
              <div className="home-dynamic-card-top">
                <p className="home-dynamic-card-title">
                  <span className="home-dynamic-card-title-highlight">Hi,</span>{" "}
                  {userName}
                </p>
                <p className="home-dynamic-card-status">
                  PENDING BANK TRANSFER
                </p>
              </div>

              <div className="home-dynamic-card-bottom">
                <p></p>
                {/* <svg
            width="32"
            viewBox="0 -960 960 960"
            height="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M226-160q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-414q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-668q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Z"></path>
          </svg> */}
              </div>
            </div>
            <div className="home-dynamic-card-image-container not-reverse">
              <div className="home-dynamic-card-left-side">
                <h2 className="home-dynamic-card-left-side-title-inprogress">
                  ₹{loanAmountStore}
                </h2>
                <div className="home-dynamic-card-msg-container">
                <p>Your Loan amount will be disbursed soon.</p>
                </div>
              </div>
              <div className="home-dynamic-card-image">
                {/* <img
                src={"./assets/img/joyemoji.gif"}
                className="home-dynamic-card-image-style"
              /> */}
                <div className="home-dynamic-card-left-sted-container">
                  <div className="home-dynamic-card-left-sted-wrapper">
                    <p className="home-dynamic-card-left-sted-title">
                      Start Date
                    </p>
                    <p className="home-dynamic-card-left-sted-subtitle">
                      {loanStartDate.replace(/-/g, "/")}
                    </p>
                  </div>
                  <ChevronsRight size={30} style={{ animation: "none",color:"#262B47" }} />
                  <div className="home-dynamic-card-left-sted-wrapper">
                    <p className="home-dynamic-card-left-sted-title">
                      End Date
                    </p>
                    <p className="home-dynamic-card-left-sted-subtitle">
                      {loanEndDate.replace(/-/g, "/")}
                    </p>
                  </div>
                </div>
                <ProgressBar
                  bgcolor={"#e32f45"}
                  completed={loanProgressFunc(
                    loanAmountStore,
                    unpaidChargesValue
                  )}
                  bgcolorContainer={"#dbdbdb"}
                />
                <div
                  className="home-dynamic-card-image-button-container"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div className="home-dynamic-card-image-button">
                    <p className="home-dynamic-card-image-button-title">
                      Unpaid
                    </p>
                    <p className="home-dynamic-card-image-button-subtitle">
                      ₹
                      {Math.round(Number(unPaidPrincipleAmount)) +
                        (Math.round(Number(dueCharges)) -
                          Math.round(Number(duesPaid))) >
                      0
                        ? Math.round(Number(unPaidPrincipleAmount)) +
                          (Math.round(Number(dueCharges)) -
                            Math.round(Number(duesPaid)))
                        : 0}
                      <p
                        style={{
                          position: "relative",
                          marginLeft: 5,
                          zIndex: 20,
                          width: 30,
                          height: 25,
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setShowSettlementAmount(false);
                          setSettlementCardOpen(true);
                          setHoverAnimation(false);
                        }}
                      >
                        <Info
                          style={{
                            width: 18,
                            height: 18,
                            cursor: "pointer",
                            zIndex: 10,
                          }}
                        />
                      </p>
                    </p>
                  </div>
                  {settlementCardOpen && (
                    <div
                      ref={settlmentCardRef}
                      className="home-dynamic-result-disbursed-info-modal"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="home-dynamic-result-disbursed-info-container">
                        <p className="home-dynamic-result-disbursed-info-key">
                          The actual amount will vary due to interest or other
                          charges associated with your account
                        </p>
                      </div>
                      {showSettlementAmount === false ? (
                        <>
                          <div className="home-dynamic-date-selectors">
                            <label>
                              <select
                                value={selectedDay}
                                onChange={handleDayChange}
                              >
                                <option value="">Day</option>
                                {settlementdays.map((day) => (
                                  <option key={day} value={day}>
                                    {day}
                                  </option>
                                ))}
                              </select>
                            </label>
                            <label>
                              <select
                                value={selectedMonth}
                                onChange={handleMonthChange}
                              >
                                <option value="">Month</option>
                                {settlementmonths.map((month) => (
                                  <option key={month} value={month}>
                                    {month}
                                  </option>
                                ))}
                              </select>
                            </label>
                            <label>
                              <select
                                value={selectedYear}
                                onChange={handleYearChange}
                              >
                                <option value="">Year</option>
                                {settlementyears.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                            </label>
                          </div>
                          <div className="home-dynamic-result-disbursed-info-container">
                            <p
                              className="home-dynamic-get-settlement"
                              onClick={(e) => {
                                e.stopPropagation();
                                getSettlementsApi();
                              }}
                            >
                              Get Settlement
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <label className="home-dynamic-result-disbursed-info-key">
                            Settlement Amount
                          </label>
                          <div style={{ width: "90%", marginLeft: "5%" }}>
                            <InputField
                              placeholder="Settlement Amount"
                              maxLength={25}
                              value={settlementAmountStore}
                              editable={false}
                              leftIcon={
                                <Bank
                                  style={{
                                    animation: "none",
                                    color: "#868585",
                                  }}
                                />
                              }
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  <div className="home-dynamic-card-image-button">
                    <p className="home-dynamic-card-image-button-title">
                      Interest(APR)
                    </p>
                    <p className="home-dynamic-card-image-button-subtitle">
                      {eligibleInterestStore}%
                    </p>
                  </div>
                  <div className="home-dynamic-card-image-button">
                    <p className="home-dynamic-card-image-button-title">EMI</p>
                    <p className="home-dynamic-card-image-button-subtitle">
                      ₹{Math.round(EMIDaily)}
                    </p>
                  </div>
                </div>
                {Math.round(Number(unPaidPrincipleAmount)) +
                  (Math.round(Number(dueCharges)) -
                    Math.round(Number(duesPaid))) <=
                  0 && (
                  <p style={{ marginTop: 5, opacity: 0.8 }}>
                    * You have made all payments. Soon your loan will be closed.
                  </p>
                )}

                {/* <svg
          width="48"
          viewBox="0 -960 960 960"
          height="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-73 85 40-280H160l360-520h80l-40 320h240L400-80h-80Zm153-395Z"></path>
        </svg> */}
              </div>
            </div>
          </div>
        )}

        {loanStatusCard === "INPROGRESS" || loanStatusCard === "PAUSED" ? (
          <div
            className={`home-dynamic-card ${
              loanStatusCard === "INPROGRESS" && "eligible"
            } ${
              hoverAnimation ? "hover-home-card-dynamic" : ""
            } wow fadeInLeft`}
            data-wow-delay="0.1s"
            style={{ marginTop: 30 }}
          >
            <div className="home-dynamic-card-content">
              <div className="home-dynamic-card-top">
                <p className="home-dynamic-card-title">
                  <span className="home-dynamic-card-title-highlight">Hi,</span>{" "}
                  {userName}
                </p>
                <p className="home-dynamic-card-status">IN PROGRESS</p>
              </div>

              <div className="home-dynamic-card-bottom">
                <p></p>
                {/* <svg
            width="32"
            viewBox="0 -960 960 960"
            height="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M226-160q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-414q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-668q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Z"></path>
          </svg> */}
              </div>
            </div>
            <div className="home-dynamic-card-image-container not-reverse">
              <div className="home-dynamic-card-left-side">
                <h2 className="home-dynamic-card-left-side-title-inprogress">
                  ₹{loanAmountStore}
                </h2>
                <div className="home-dynamic-card-msg-container">
                <p>You have an upcoming payment.</p>
                </div>
              </div>
              <div className="home-dynamic-card-image">
                {/* <img
                src={"./assets/img/joyemoji.gif"}
                className="home-dynamic-card-image-style"
              /> */}
                <div className="home-dynamic-card-left-sted-container">
                  <div className="home-dynamic-card-left-sted-wrapper">
                    <p className="home-dynamic-card-left-sted-title">
                      Start Date
                    </p>
                    <p className="home-dynamic-card-left-sted-subtitle">
                      {loanStartDate.replace(/-/g, "/")}
                    </p>
                  </div>
                  <ChevronsRight size={30} style={{ animation: "none",color:"#262B47" }} />
                  <div className="home-dynamic-card-left-sted-wrapper">
                    <p className="home-dynamic-card-left-sted-title">
                      End Date
                    </p>
                    <p className="home-dynamic-card-left-sted-subtitle">
                      {loanEndDate.replace(/-/g, "/")}
                    </p>
                  </div>
                </div>
                <ProgressBar
                  bgcolor={"#e32f45"}
                  completed={loanProgressFunc(
                    loanAmountStore,
                    unpaidChargesValue
                  )}
                  bgcolorContainer={"#dbdbdb"}
                />
                <div className="home-dynamic-card-image-button-container">
                  <div className="home-dynamic-card-image-button">
                    <p className="home-dynamic-card-image-button-title">
                      Unpaid
                    </p>
                    <p className="home-dynamic-card-image-button-subtitle">
                      ₹
                      {Math.round(Number(unPaidPrincipleAmount)) +
                        (Math.round(Number(dueCharges)) -
                          Math.round(Number(duesPaid))) >
                      0
                        ? Math.round(Number(unPaidPrincipleAmount)) +
                          (Math.round(Number(dueCharges)) -
                            Math.round(Number(duesPaid)))
                        : 0}
                      <p
                        style={{
                          position: "relative",
                          marginLeft: 5,
                          zIndex: 20,
                          width: 30,
                          height: 25,
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setShowSettlementAmount(false);
                          setSettlementCardOpen(true);
                          setHoverAnimation(false);
                        }}
                      >
                        <Info
                          style={{
                            width: 18,
                            height: 18,
                            cursor: "pointer",
                            zIndex: 10,
                          }}
                        />
                      </p>
                    </p>
                  </div>
                  {settlementCardOpen && (
                    <div
                      ref={settlmentCardRef}
                      className="home-dynamic-result-disbursed-info-modal"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="home-dynamic-result-disbursed-info-container">
                        <p className="home-dynamic-result-disbursed-info-key">
                          The actual amount will vary due to interest or other
                          charges associated with your account
                        </p>
                      </div>
                      {showSettlementAmount === false ? (
                        <>
                          <div className="home-dynamic-date-selectors">
                            <label>
                              <select
                                value={selectedDay}
                                onChange={handleDayChange}
                              >
                                <option value="">Day</option>
                                {settlementdays.map((day) => (
                                  <option key={day} value={day}>
                                    {day}
                                  </option>
                                ))}
                              </select>
                            </label>
                            <label>
                              <select
                                value={selectedMonth}
                                onChange={handleMonthChange}
                              >
                                <option value="">Month</option>
                                {settlementmonths.map((month) => (
                                  <option key={month} value={month}>
                                    {month}
                                  </option>
                                ))}
                              </select>
                            </label>
                            <label>
                              <select
                                value={selectedYear}
                                onChange={handleYearChange}
                              >
                                <option value="">Year</option>
                                {settlementyears.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                            </label>
                          </div>
                          <div className="home-dynamic-result-disbursed-info-container">
                            <p
                              className="home-dynamic-get-settlement"
                              onClick={(e) => {
                                e.stopPropagation();
                                getSettlementsApi();
                              }}
                            >
                              Get Settlement
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <label className="home-dynamic-result-disbursed-info-key">
                            Settlement Amount
                          </label>
                          <div style={{ width: "90%", marginLeft: "5%" }}>
                            <InputField
                              placeholder="Settlement Amount"
                              maxLength={25}
                              value={settlementAmountStore}
                              editable={false}
                              leftIcon={
                                <Bank
                                  style={{
                                    animation: "none",
                                    color: "#868585",
                                  }}
                                />
                              }
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  <div className="home-dynamic-card-image-button">
                    <p className="home-dynamic-card-image-button-title">
                      Interest(APR)
                    </p>
                    <p className="home-dynamic-card-image-button-subtitle">
                      {eligibleInterestStore}%
                    </p>
                  </div>
                  <div className="home-dynamic-card-image-button">
                    <p className="home-dynamic-card-image-button-title">EMI</p>
                    <p className="home-dynamic-card-image-button-subtitle">
                      ₹{Math.round(EMIDaily)}
                    </p>
                  </div>
                </div>
                {Math.round(Number(unPaidPrincipleAmount)) +
                  (Math.round(Number(dueCharges)) -
                    Math.round(Number(duesPaid))) <=
                  0 && (
                  <p style={{ marginTop: 5, opacity: 0.8 }}>
                    * You have made all payments. Soon your loan will be closed.
                  </p>
                )}
                {/* <svg
          width="48"
          viewBox="0 -960 960 960"
          height="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-73 85 40-280H160l360-520h80l-40 320h240L400-80h-80Zm153-395Z"></path>
        </svg> */}
              </div>
            </div>
          </div>
        ) : null}

        {/* {loanStatusCard === "PAUSED" && (
          <div
            className={`home-dynamic-card ${
              loanStatusCard === "INPROGRESS" && "eligible"
            } wow fadeInLeft`}
            data-wow-delay="0.1s"
            style={{ marginTop: 30 }}
          >
            <div className="home-dynamic-card-content">
              <div className="home-dynamic-card-top">
                <p className="home-dynamic-card-title">
                  <span className="home-dynamic-card-title-highlight">Hi,</span>{" "}
                  {userName}
                </p>
                <p className="home-dynamic-card-status">IN PROGRESS</p>
              </div>

              <div className="home-dynamic-card-bottom">
                <p></p>
               
              </div>
            </div>
            <div className="home-dynamic-card-image-container not-reverse">
              <div className="home-dynamic-card-left-side">
                <h2 className="home-dynamic-card-left-side-title-inprogress">
                  ₹{loanAmountStore}
                </h2>
                <div className="home-dynamic-card-msg-container">
                  <p>Hi, your loan has been completed thank you </p>
                </div>
              </div>
              <div className="home-dynamic-card-image">
               
                <div className="home-dynamic-card-left-sted-container">
                  <div className="home-dynamic-card-left-sted-wrapper">
                    <p className="home-dynamic-card-left-sted-title">
                      Start Date
                    </p>
                    <p className="home-dynamic-card-left-sted-subtitle">
                      {loanStartDate.replace(/-/g, "/")}
                    </p>
                  </div>
                  <ChevronsRight size={30} style={{ animation: "none",color:"#262B47" }} />
                  <div className="home-dynamic-card-left-sted-wrapper">
                    <p className="home-dynamic-card-left-sted-title">
                      End Date
                    </p>
                    <p className="home-dynamic-card-left-sted-subtitle">
                      {loanEndDate.replace(/-/g, "/")}
                    </p>
                  </div>
                </div>
                <ProgressBar
                  bgcolor={"#e32f45"}
                  completed={loanProgressFunc(
                    loanAmountStore,
                    unpaidChargesValue
                  )}
                  bgcolorContainer={"#dbdbdb"}
                />
                <div className="home-dynamic-card-image-button-container">
                  <div className="home-dynamic-card-image-button">
                    <p className="home-dynamic-card-image-button-title">
                      Unpaid
                    </p>
                    <p className="home-dynamic-card-image-button-subtitle">
                      ₹
                      {Math.round(Number(unPaidPrincipleAmount)) +
                        (Math.round(Number(dueCharges)) -
                          Math.round(Number(duesPaid))) >
                      0
                        ? Math.round(Number(unPaidPrincipleAmount)) +
                          (Math.round(Number(dueCharges)) -
                            Math.round(Number(duesPaid)))
                        : 0}
                    </p>
                  </div>
                  <div className="home-dynamic-card-image-button">
                    <p className="home-dynamic-card-image-button-title">
                      Interest(APR)
                    </p>
                    <p className="home-dynamic-card-image-button-subtitle">
                      {eligibleInterestStore}%
                    </p>
                  </div>
                  <div className="home-dynamic-card-image-button">
                    <p className="home-dynamic-card-image-button-title">EMI</p>
                    <p className="home-dynamic-card-image-button-subtitle">
                      ₹{Math.round(EMIDaily)}
                    </p>
                  </div>
                </div>
                {Math.round(Number(unPaidPrincipleAmount)) +
                  (Math.round(Number(dueCharges)) -
                    Math.round(Number(duesPaid))) <=
                  0 && (
                  <p style={{ marginTop: 5, opacity: 0.8 }}>
                    * You have made all payments. Soon your loan will be closed.
                  </p>
                )}
             
              </div>
            </div>
          </div>
        )} */}
        {loanStatusCard === "CLOSED" && (
          <div
            className={`home-dynamic-card ${
              loanStatusCard === "CLOSED" && "eligible"
            } wow fadeInLeft`}
            data-wow-delay="0.1s"
            onClick={() => {
              navigate("/loanDetails");
            }}
            style={{ marginTop: 30 }}
          >
            <div className="home-dynamic-card-content">
              <div className="home-dynamic-card-top">
                <p className="home-dynamic-card-title">
                  <span className="home-dynamic-card-title-highlight">Hi,</span>{" "}
                  {userName}
                </p>
                <p className="home-dynamic-card-status">CLOSED</p>
              </div>

              <div className="home-dynamic-card-bottom">
                <p></p>
                {/* <svg
            width="32"
            viewBox="0 -960 960 960"
            height="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M226-160q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-414q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-668q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Z"></path>
          </svg> */}
              </div>
            </div>
            <div className="home-dynamic-card-image-container not-reverse">
              <div className="home-dynamic-card-left-side">
                <h2 className="home-dynamic-card-left-side-title-inprogress">
                  ₹{loanAmountStore}
                </h2>
                <div className="home-dynamic-card-msg-container">
                  <p>Hi, your loan has been completed thank you </p>
                </div>
              </div>
              <div className="home-dynamic-card-image">
                {/* <img
                src={"./assets/img/joyemoji.gif"}
                className="home-dynamic-card-image-style"
              /> */}
                <div className="home-dynamic-card-left-sted-container">
                  <div className="home-dynamic-card-left-sted-wrapper">
                    <p className="home-dynamic-card-left-sted-title">
                      Start Date
                    </p>
                    <p className="home-dynamic-card-left-sted-subtitle">
                      {loanStartDate.replace(/-/g, "/")}
                    </p>
                  </div>
                  <ChevronsRight size={30} style={{ animation: "none",color:"#262B47" }} />
                  <div className="home-dynamic-card-left-sted-wrapper">
                    <p className="home-dynamic-card-left-sted-title">
                      End Date
                    </p>
                    <p className="home-dynamic-card-left-sted-subtitle">
                      {loanEndDate.replace(/-/g, "/")}
                    </p>
                  </div>
                </div>
                <ProgressBar
                  bgcolor={"#e32f45"}
                  completed={loanProgressFunc(
                    loanAmountStore,
                    unpaidChargesValue
                  )}
                  bgcolorContainer={"#dbdbdb"}
                />
                <div className="home-dynamic-card-image-button-container">
                  <div className="home-dynamic-card-image-button">
                    <p className="home-dynamic-card-image-button-title">
                      Unpaid
                    </p>
                    <p className="home-dynamic-card-image-button-subtitle">
                      ₹
                      {Math.round(Number(unPaidPrincipleAmount)) +
                        (Math.round(Number(dueCharges)) -
                          Math.round(Number(duesPaid))) >
                      0
                        ? Math.round(Number(unPaidPrincipleAmount)) +
                          (Math.round(Number(dueCharges)) -
                            Math.round(Number(duesPaid)))
                        : 0}
                    </p>
                  </div>
                  <div className="home-dynamic-card-image-button">
                    <p className="home-dynamic-card-image-button-title">
                      Interest(APR)
                    </p>
                    <p className="home-dynamic-card-image-button-subtitle">
                      {eligibleInterestStore}%
                    </p>
                  </div>
                  <div className="home-dynamic-card-image-button">
                    <p className="home-dynamic-card-image-button-title">EMI</p>
                    <p className="home-dynamic-card-image-button-subtitle">
                      ₹{Math.round(EMIDaily)}
                    </p>
                  </div>
                </div>
                {/* <svg
          width="48"
          viewBox="0 -960 960 960"
          height="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-73 85 40-280H160l360-520h80l-40 320h240L400-80h-80Zm153-395Z"></path>
        </svg> */}
              </div>
            </div>
          </div>
        )}

  {loanStatusCard === "LOANREJECTED" && (
          <div
            className={`home-dynamic-card ${
              loanStatusCard === "LOANREJECTED" && "noteligible"
            }`}
          >
            <div className="home-dynamic-card-content">
              <div className="home-dynamic-card-top">
                {/* <span className="home-dynamic-card-title">Welcome</span> */}
              </div>

              <div className="home-dynamic-card-bottom">
                <p></p>
                {/* <svg
            width="32"
            viewBox="0 -960 960 960"
            height="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M226-160q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-414q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-668q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Z"></path>
          </svg> */}
              </div>
            </div>
            <div className="home-dynamic-card-image-container reverse">
              <div className="home-dynamic-card-left-side">
                <h2 className="home-dynamic-card-left-side-title noteligibleTitle">
                  Your Loan has been Rejected
                </h2>
                <p className="home-dynamic-card-left-side-subtitle">
                  Sorry, we could not approve at this time.
                </p>
                <p className="home-dynamic-card-left-side-subtitle">
                  Please try after 6 months
                </p>
              </div>
              <div className="home-dynamic-card-image">
                <img
                  src={"https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/gif/sademoji.gif"}
                  className="home-dynamic-card-image-style"
                />
                <div className="home-dynamic-card-progress-container">
                <ProgressBar
                    bgcolor={"#E32f45"}
                    completed={100}
                   bgcolorContainer={"#dbdbdb"}
                  />
                  <div className="home-dynamic-card-progress-wrapper">
                    <p className="home-dynamic-card-progress-text">Intiated</p>
                    <p className="home-dynamic-card-progress-text">
                      Kyc Pending
                    </p>
                    <p className="home-dynamic-card-progress-text">
                    Verification
                    </p>
                    <p className="home-dynamic-card-progress-text">
                      Loan Rejected
                    </p>
                  </div>
                </div>

                {/* <svg
          width="48"
          viewBox="0 -960 960 960"
          height="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-73 85 40-280H160l360-520h80l-40 320h240L400-80h-80Zm153-395Z"></path>
        </svg> */}
              </div>
            </div>
          </div>
        )}
        {loanStatusCard === "KYCPENDING" && (
          <div
            className={`home-dynamic-card ${
              loanStatusCard === "KYCPENDING" && "eligible"
            }`}
          >
            <div className="home-dynamic-card-content">
              <div className="home-dynamic-card-top">
                {/* <span className="home-dynamic-card-title">Welcome</span> */}
              </div>

              <div className="home-dynamic-card-bottom">
                <p></p>
                {/* <svg
            width="32"
            viewBox="0 -960 960 960"
            height="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M226-160q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-414q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-668q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Z"></path>
          </svg> */}
              </div>
            </div>
            <div className="home-dynamic-card-image-container reverse">
              <div className="home-dynamic-card-left-side">
                <h2 className="home-dynamic-card-left-side-title">
                  We noticed some issues with your documents
                </h2>
                <p className="home-dynamic-card-left-side-subtitle">
                  Please resubmit your documents after changes
                </p>
              </div>
              <div className="home-dynamic-card-image">
                <img
                  src={"https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/gif/noideaemoji.gif"}
                  className="home-dynamic-card-image-style"
                />
                <div className="home-dynamic-card-progress-container">
                  <ProgressBar
                    bgcolor={"#e32f45"}
                    completed={62}
                    bgcolorContainer={"#dbdbdb"}
                  />
                  <div className="home-dynamic-card-progress-wrapper">
                    <p className="home-dynamic-card-progress-text">Initiated</p>
                    <p className="home-dynamic-card-progress-text">
                      Kyc Pending
                    </p>
                    <p className="home-dynamic-card-progress-text">
                    Verification
                    </p>
                    <p className="home-dynamic-card-progress-text">
                      Loan Disbursed
                    </p>
                  </div>
                </div>

                {/* <svg
          width="48"
          viewBox="0 -960 960 960"
          height="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-73 85 40-280H160l360-520h80l-40 320h240L400-80h-80Zm153-395Z"></path>
        </svg> */}
              </div>
            </div>
          </div>
        )}

        <div className="loan-details-buttons-container">
          {loanStatusCard == "CLOSED" && (
            <a
              className="loan-details-button-noc"
              href={nocLinkData}
              download="NOC.pdf"
            >
              <Download className="loan-details-button-noc-icon" /> NOC
            </a>
          )}
          {duesChargesArrayList.length != 0 && (
            <button
              className="loan-details-button-due-charges"
              onClick={handleduechargesmodalopen}
            >
              <Info className="loan-details-button-due-charges-icon" />
              Due charges
            </button>
          )}
        </div>

        {loanStatusCard == "INPROGRESS" || loanStatusCard == "CLOSED" ? (
          <>
            <div className="loan-details-tab-container">
              <input
                type="radio"
                name="tab"
                id="tab1"
                className="loan-details-tab loan-details-tab--1"
                onClick={() => changeTab(0)}
              />
              <label className="loan-details-tab_label" for="tab1">
                Upcoming Payments
              </label>

              <input
                type="radio"
                name="tab"
                id="tab2"
                className="loan-details-tab loan-details-tab--2"
                onClick={() => changeTab(1)}
              />
              <label className="loan-details-tab_label" for="tab2">
                Past Payments
              </label>

              <div className="loan-details-tab-indicator"></div>
            </div>

            {activeTab === 0 && (
              <div className="loan-details-tab-content">
                {loanStatusCard == "INPROGRESS" ||
                loanStatusCard == "PENDINGAMOUNT" ? (
                  <>
                    {upcomingPayments.length > 0 ? (
                      <Accordion>
                        {upcomingPayments.map((payment, index) => (
                          <Accordion.Item
                            key={index}
                            className="p-0 m-1 mt-3 "
                            eventKey={index}
                          >
                            <Accordion.Header color="grey">
                              <div className="loan-details-tab-content-card">
                                <div className="loan-details-tab-content-img-container">
                                  <div className="loan-details-tab-content-img-wrapper">
                                    <img
                                      className="loan-details-tab-content-img-style"
                                      src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/transaction.png"
                                    />
                                  </div>
                                  <div className="loan-details-tab-content-paid-wrapper">
                                    <p className="loan-details-tab-content-paid-title">
                                      {payment.paymentDate}
                                    </p>
                                    <p className="loan-details-tab-content-paid-subtitle">
                                      {payment.date}{" "}
                                      {/* Assuming you have a date property in your payment object */}
                                    </p>
                                  </div>
                                </div>
                                <p className="loan-details-tab-content-amount">
                                  ₹{Math.round(payment.emi)}
                                </p>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="loan-details-tab-Body-container-cover">
                                <div className="loan-details-tab-Body-container">
                                  <div className="loan-details-tab-Body-wrapper">
                                    <p className="loan-details-tab-Body-key">
                                      Principle Amount
                                    </p>
                                    <p className="loan-details-tab-Body-value">
                                      ₹{Math.round(payment.currPrincipal)}
                                    </p>
                                  </div>
                                  <div className="loan-details-tab-Body-wrapper">
                                    <p className="loan-details-tab-Body-key">
                                      Interest Amount
                                    </p>
                                    <p className="loan-details-tab-Body-value">
                                      ₹{Math.round(payment.interest)}
                                    </p>
                                  </div>
                                </div>
                                <div className="loan-details-tab-Body-container">
                                  <div className="loan-details-tab-Body-wrapper">
                                    <p className="loan-details-tab-Body-key">
                                      EMI Amount
                                    </p>
                                    <p className="loan-details-tab-Body-value">
                                      ₹{Math.round(payment.emi)}
                                    </p>
                                  </div>
                                  <div className="loan-details-tab-Body-wrapper">
                                    <p className="loan-details-tab-Body-key">
                                      Pending Amount
                                    </p>
                                    <p className="loan-details-tab-Body-value">
                                      ₹{Math.round(payment.remainingAmount)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    ) : (
                      <img
                        src={"https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/No-data.png"}
                        alt="No loans found"
                        className="nothing-img"
                        style={{
                          display: "flex",
                          position: "relative",
                          left: "50%",
                          transform: "translate(-50%)",
                        }}
                      />
                    )}
                  </>
                ) : (
                  <img
                    src={"https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/No-data.png"}
                    alt="No loans found"
                    className="nothing-img"
                    style={{
                      display: "flex",
                      position: "relative",
                      left: "50%",
                      transform: "translate(-50%)",
                    }}
                  />
                )}
              </div>
            )}
            {activeTab === 1 && (
              <div className="loan-details-tab-content">
                {paymentList.length === 0 ? (
                  <img
                    src={"https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/No-data.png"}
                    alt="No loans found"
                    className="nothing-img"
                    style={{
                      display: "flex",
                      position: "relative",
                      left: "50%",
                      transform: "translate(-50%)",
                    }}
                  />
                ) : (
                  <Accordion>
                    {paymentList.map((item, index) => (
                      <Accordion.Item
                        key={index}
                        className="p-0 m-1 mt-3 "
                        eventKey={index}
                      >
                        <Accordion.Header color="grey">
                          <div className="loan-details-tab-content-card">
                            <div className="loan-details-tab-content-img-container">
                              <div className="loan-details-tab-content-img-wrapper">
                                <img
                                  className="loan-details-tab-content-img-style"
                                  src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/transaction.png"
                                />
                              </div>
                              <div className="loan-details-tab-content-paid-wrapper">
                                <p className="loan-details-tab-content-paid-title">
                                  {item.paymentType}
                                </p>
                                <p className="loan-details-tab-content-paid-subtitle">
                                  {formatDate(item.date)}
                                  {/* Assuming you have a date property in your payment object */}
                                </p>
                              </div>
                            </div>
                            <p className="loan-details-tab-content-amount">
                              ₹{Math.round(item.amt) || 0}
                            </p>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="loan-details-tab-Body-container-cover">
                            <div className="loan-details-tab-Body-container-past">
                              <p className="loan-details-tab-Body-key-past">
                                Principle Amount
                              </p>
                              <p className="loan-details-tab-Body-value-past">
                                ₹{Math.round(item.principal)}
                              </p>
                            </div>
                            <div className="loan-details-tab-Body-container-past">
                              <p className="loan-details-tab-Body-key-past">
                                Interest Amount
                              </p>
                              <p className="loan-details-tab-Body-value-past">
                                ₹{Math.round(item.interest)}
                              </p>
                            </div>
                          </div>
                          {Number(item.dues) > 0 && (
                            <div
                              className="loan-details-tab-Body-container-cover"
                              style={{ marginTop: 15 }}
                            >
                              <div className="loan-details-tab-Body-container-past">
                                <p className="loan-details-tab-Body-key-past">
                                  Dues
                                </p>
                                <p className="loan-details-tab-Body-value-past">
                                  ₹{Math.round(item.dues)}
                                </p>
                              </div>
                            </div>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                )}
              </div>
            )}
          </>
        ) : null}

        {loanStatusCard == "REJECTED" || loanStatusCard == "KYCPENDING" ? (
          <>
            <h2 style={{ marginTop: 60 }}>Frequently Asked Questions</h2>
            <div className="loan-details-tab-content">
              <Accordion>
                {faqItems.map((item, index) => (
                  <Accordion.Item
                    key={index}
                    className="p-0 m-1 mt-3 "
                    eventKey={index}
                  >
                    <Accordion.Header color="grey">
                      <div className="loan-details-tab-content-card">
                        {item.question}
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="loan-details-tab-Body-container-cover">
                        <p>{item.answer}</p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </>
        ) : null}

        <Modal
          show={dueChargesModalOpen}
          onHide={handleduechargesmodalclose}
          centered
          className="loan-details-modal-body"
        >
          <Modal.Header
            style={{
              border: "none",
            }}
            className="loan-details-modal-header"
          >
            <Modal.Title className="p-2 d-flex  border-bottom w-100 border-dark-subtle flex-column ">
              <div
                className="fw-bolder d-flex justify-content-between align-items-center w-100"
                style={{ fontSize: "18px" }}
              >
                <h4 style={{ color: "#333" }}>Due Charges</h4>
                <X
                  // color="#dc3545"
                  onClick={handleduechargesmodalclose}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <span style={{ fontSize: "16px", color: "#969696" }}>
                Dues will be added to your final EMI payment
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="loan-details-duecharges-table-responsive">
              <table className="loan-details-duecharges-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {duesChargesArrayList.length > 0 ? (
                    duesChargesArrayList.map((charge, index) => (
                      <tr key={index}>
                        <td>{new Date(charge.date).toLocaleDateString()}</td>
                        <td>{charge.type}</td>
                        <td>{charge.amt}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">No due charges found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Modal.Body>
          {/* <Modal.Footer className="loan-details-modal-footer">
            <button className="loan-details-close-modal">Close</button>
            <button className="loan-details-save-modal">Loan Account</button>
          </Modal.Footer> */}
        </Modal>

        <Modal
          show={settlementModalOpen}
          onHide={handlesettlementmodalclose}
          centered
          className="loan-details-modal-body"
        >
          <Modal.Header
            style={{
              border: "none",
            }}
            className="loan-details-modal-header"
          >
            <Modal.Title className="p-2 d-flex align-items-center border-bottom w-100 border-dark-subtle justify-content-between">
              <div className="fw-bolder d-flex " style={{ fontSize: "18px" }}>
                <h4 style={{ color: "#333" }}>Loan Account</h4>
              </div>
              <X
                // color="#dc3545"
                onClick={handlesettlementmodalclose}
                style={{ cursor: "pointer" }}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div className="loan-details-modal-settlement-container">
              <h3>Select Payment Type</h3>
              <div className="preview-label-wrapper">
                <label className="preview-radio-label">
                  <input
                    type="radio"
                    name="loan"
                    value="Partial Payment"
                    checked={selectedOption === "Partial Payment"}
                    className="preview-radio-input"
                    onChange={handleOptionChange}
                  />
                  <div className="loan-details-payment-type-font">
                    Partial Payment
                  </div>
                </label>
                <label className="preview-radio-label">
                  <input
                    type="radio"
                    name="loan"
                    value="Full Payment"
                    checked={selectedOption === "Full Payment"}
                    className="preview-radio-input"
                    onChange={handleOptionChange}
                  />
                  <div className="loan-details-payment-type-font">
                    Full Payment
                  </div>
                </label>
              </div>
              {selectedOption == "Partial Payment" && (
                <div className="loan-details-modal-min-max-container">
                  <div className="loan-details-modal-amount-selected-wrapper">
                    <p className="loan-details-modal-amount-selected">
                      {loanAmount}
                    </p>
                  </div>
                  <div className="loan-details-modal-min-max-wrapper">
                    <p className="loan-details-modal-min-max-title">min</p>
                    <p className="loan-details-modal-min-max-title">max</p>
                  </div>
                  <Slider
                    min={100000}
                    max={10000000}
                    step={10000}
                    initialValue={loanAmount}
                    onChange={handleLoanAmountChange}
                  />
                  <div className="loan-details-modal-min-max-wrapper">
                    <p className="loan-details-modal-min-max-text">100000</p>
                    <p className="loan-details-modal-min-max-text">10000000</p>
                  </div>
                </div>
              )}
            </div> */}
            <label>Bank Account Number</label>

            <InputField
              placeholder="Account Holder Name"
              maxLength={25}
              value={bankAccount}
              editable={false}
              leftIcon={<Bank style={{ animation: "none" }} />}
            />

            <label>Bank IFSC</label>
            <InputField
              placeholder="Account Holder Name"
              maxLength={25}
              value={bankIFSC}
              editable={false}
              leftIcon={<Bank style={{ animation: "none" }} />}
            />

            <label>Remarks/Notes</label>
            <InputField
              placeholder="Account Holder Name"
              maxLength={25}
              value={remarksNotes}
              editable={false}
              leftIcon={<Bank style={{ animation: "none" }} />}
            />
            {showSettlementAmount === false ? (
              <>
                <div>
                  <label>
                    Day:
                    <select value={selectedDay} onChange={handleDayChange}>
                      <option value="">Day</option>
                      {settlementdays.map((day) => (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label>
                    Month:
                    <select value={selectedMonth} onChange={handleMonthChange}>
                      <option value="">Month</option>
                      {settlementmonths.map((month) => (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label>
                    Year:
                    <select value={selectedYear} onChange={handleYearChange}>
                      <option value="">Year</option>
                      {settlementyears.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                <div
                  className="loan-details-get-settlement"
                  onClick={() => {
                    getSettlementsApi();
                  }}
                >
                  Get Settlement Amount
                </div>
              </>
            ) : (
              <>
                <label>Settlement Amount</label>
                <InputField
                  placeholder="Settlement Amount"
                  maxLength={25}
                  value={settlementAmountStore}
                  editable={false}
                  leftIcon={<Bank style={{ animation: "none" }} />}
                />
              </>
            )}
          </Modal.Body>
          <Modal.Footer className="loan-details-modal-footer"></Modal.Footer>
        </Modal>

        {alert.show && (
          <Alert
            message={alert.message}
            type={alert.type}
            show={alert.show}
            duration={alert.duration}
            onClose={() => setAlert({ ...alert, show: false })}
          />
        )}
      </div>
    )
  );
}

export default LoanDetailsScreen;
