import React, { useContext, useEffect, useRef, useState } from 'react'
import { MyContext } from '../../components/GlobalValues';
import Metrixfunction from '../../components/MetrixFunction';
function BlogTwo() {

  const {  metrixserver} = useContext(MyContext);


  const [imagesLoaded, setImagesLoaded] = useState(0);
  const totalImages = useRef(0);
  
  useEffect(() => {
    // Count the total number of images
    const imageElements = document.querySelectorAll('img');
    totalImages.current = imageElements.length;
  
  
    // Add load event listeners to all images
    if(imageElements.length>0)
    {
      imageElements.forEach(img => {
        if (img.complete) {
          handleImageLoad();
        } else {
          img.addEventListener('load', handleImageLoad);
          img.addEventListener('error', handleImageLoad); // Handle error case
        }
      });
    }
    else{
      Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");
  
    }
  
    // Cleanup function
    return () => {
      imageElements.forEach(img => {
        img.removeEventListener('load', handleImageLoad);
        img.removeEventListener('error', handleImageLoad);
      });
    };
  }, []);
  
  useEffect(() => {
    if (imagesLoaded === totalImages.current && totalImages.current > 0) {
      Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");
  
    }
  }, [imagesLoaded]);
  
  const handleImageLoad = () => {
    setImagesLoaded(prev => prev + 1);
  };
  
  return (
    <div className="bg-white p-0">
      {/* Spinner Start */}
      <div
        id="spinner"
        className="bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <div
          className="spinner-grow text-danger"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      {/* Spinner End */}
      {/* Navbar & Hero Start */}
      <div className="position-relative p-0" id="home">
        <custom-header />
        <div className="hero-heade">
          <div className="container px-lg-5">
            <div className="row g-4">
              <div className="col-12 col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
                {/* <h1 className="mb-4" style="color: white">Financial Articles to Ensure Business Success</h1> */}
                {/* <p className="mb-4" style="color: white">Posted At: 01/11/2023</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Navbar & Hero End */}
      <div className="container mt-4 px-3">
        <div className="row p-2">
          <div className="col-12">
            {/* ----header---- */}
            <div className="row d-flex justify-content-start">
              <h1 className="w-auto p-4 bg-custom-color rounded">
                Some Common Myths around Personal Loans
              </h1>
              <p style={{ color: "black" }}>
                <span style={{ fontWeight: 500 }}>Posted:</span> July 4<sup>th</sup> 2024
              </p>
            </div>
            {/* -----------header end ----- */}
            {/* --------Body----------- */}
            <div className="row border-1 border-danger d-flex align-items-center">
              <div className="col-12 col-md-5 d-flex justify-content-center align-items-center p-0">
                <img src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/service2.png" height="100%" width="100%" alt="Personal Loans" />
              </div>
              <div className="col-12 col-md-7">
                <h3 className="bg-custom-color p-2" style={{ color: "#e32f45" }}>
                  Introduction
                </h3>
                <p style={{ lineHeight: 2, fontSize: "1.1rem", fontWeight: 400 }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Personal loans are a valuable financial tool
                  for individuals looking to address various financial needs. However, misconceptions about
                  personal loans can prevent people from making informed decisions. Here, we dispel some common
                  myths surrounding personal loans.
                </p>
              </div>
              <div className="col-12 col-md-12">
                <h3 className="bg-custom-color p-2 mb-4" style={{ color: "#e32f45" }}>
                  Debunking Common Myths
                </h3>
                <ul style={{ lineHeight: 2, fontSize: "1.1rem", fontWeight: 400 }}>
                  <li>
                    <h4>Myth 1: Personal Loans Are Only for Emergencies</h4>
                    <p>
                      It’s a common belief that personal loans should only be used for emergencies.
                      While personal loans are indeed useful for unexpected expenses like medical bills
                      or home repairs, they can also be used for planned expenses such as weddings,
                      vacations, or home renovations. Personal loans offer flexibility in usage,
                      catering to various financial needs.
                    </p>
                  </li>
                  <li>
                    <h4>Myth 2: Personal Loans Have High Interest Rates</h4>
                    <p>
                      There’s a misconception that personal loans always come with high interest rates.
                      In reality, interest rates on personal loans can vary depending on factors such as
                      credit score, loan amount, and repayment tenure. Borrowers with good credit histories
                      can often secure personal loans at competitive interest rates, making them an affordable
                      option for financing.
                    </p>
                  </li>
                  <li>
                    <h4>Myth 3: Taking a Personal Loan Will Hurt Your Credit Score</h4>
                    <p>
                      Some believe that taking out a personal loan will negatively impact their credit score.
                      While applying for any type of credit may initially cause a slight dip in your credit score
                      due to the inquiry, responsible borrowing and timely repayment of the loan can actually
                      improve your credit score over time. It demonstrates to creditors that you can manage credit
                      responsibly.
                    </p>
                  </li>
                  <li>
                    <h4>Myth 4: Personal Loans Are Difficult to Obtain</h4>
                    <p>
                      Another myth is that personal loans are difficult to obtain, especially for individuals
                      with average or less-than-perfect credit scores. Many lenders offer personal loans with
                      flexible eligibility criteria and streamlined application processes, making them accessible
                      to a wide range of borrowers. It’s advisable to explore different lenders and compare
                      offerings to find a personal loan that suits your financial situation.
                    </p>
                  </li>
                  <li>
                    <h4>Myth 5: You Must Have Collateral to Get a Personal Loan</h4>
                    <p>
                      Unlike secured loans that require collateral (such as a car or home) to secure the loan,
                      personal loans are typically unsecured. This means you don’t need to pledge any assets
                      to qualify for a personal loan. Unsecured personal loans are approved based on factors
                      like creditworthiness, income, and repayment capacity.
                    </p>
                  </li>
                </ul>
              </div>
              {/* ----------footer --------- */}
              <div className="col-12 col-md-12">
                <h3 className="bg-custom-color p-2" style={{ color: "#e32f45" }}>
                  Conclusion
                </h3>
                <p style={{ lineHeight: 2, fontSize: "1.1rem", fontWeight: 400 }}>
                  Personal loans are a versatile financial tool that can help individuals achieve their financial goals,
                  whether it’s covering unexpected expenses, funding important life events, or consolidating debt.
                  By understanding the reality behind these myths, individuals can make informed decisions about
                  using personal loans to improve their financial well-being.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Start */}
      <custom-footer />
      {/* Footer End */}
      {/* Back to Top */}
      <a href="#" className="btn btn-lg btn-lg-square back-to-top pt-2">
        <i className="bi bi-arrow-up home-text" />
      </a>
    </div>
  )
}

export default BlogTwo
