import React, { useEffect, useRef, useState, useContext } from "react";
import "../../styles/screenStyles/MyKaasuHome.css";
import { ChevronsRight, File, Info, UserCheck } from "react-feather";
import ProgressBar from "../../components/ProgressBar";
import { Bank, Calculator, ClockHistory } from "react-bootstrap-icons";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "../../components/Alert";
import Loading from "../../components/Loading";
import { Modal } from "react-bootstrap";
import { X } from "react-feather";
import InputField from "../../components/InputField";
import { MyContext } from "../../components/GlobalValues";
import Metrixfunction from "../../components/MetrixFunction";

function MyKaasuHome() {
  const [loanStatusCard, setLoanStatusCard] = useState("");
  const [cibilScore, setCibilScore] = useState();
  const [cibilScoreColor, setCibilScoreColor] = useState("#e32f45");
  const [eligibleLoanAmountStore, setEligibleLoanAmountStore] = useState(0);
  const [eligibleInterestStore, setEligibleInterestStore] = useState(0);
  const [eligibleTenureStore, setEligibleTenureStore] = useState(0);
  const [eligibleMinTenureStore, setEligibleMinTenureStore] = useState(0);
  const [eligibleMinAmountStore, setEligibleMinAmountStore] = useState(0);
  const [eligibleFeeStore, setEligibleFeeStore] = useState(0);
  const [userName, setUserName] = useState("");
  const [loanAmount, setLoanAmount] = useState("");
  const [paidPrincipleAmount, setPaidPrincipleAmount] = useState("");
  const [unPaidPrincipleAmount, setUnPaidPrincipleAmount] = useState("");
  const [unpaidChargesValue, setUnpaidChargesValue] = useState("");
  const [EMIDaily, setEMIDaily] = useState("");
  const [loanStartDate, setLoanStartDate] = useState("");
  const [loanEndDate, setLoanEndDate] = useState("");
  const [paymentList, setPaymentList] = useState([]);
  const [loadId, setLoadId] = useState("");
  const [upcomingPayments, setUpcomingPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loanDuration, setLoanDuration] = useState("");
  const [loanInterest, setLoanInterest] = useState("");
  const [dueCharges, setDueCharges] = useState("");
  const [duesPaid, setDuesPaid] = useState("");
  const [settlementCardOpen, setSettlementCardOpen] = useState(false);
  const [hoverAnimation, setHoverAnimation] = useState(true);

  const [showSettlementAmount, setShowSettlementAmount] = useState(false);
  const [settlementAmountStore, setSettlementAmountStore] = useState("");

  const { setNotification, Url,setCreateNewTicket,setTicketStatus,setTicketId,metrixserver } = useContext(MyContext);

  const [isMandatoryModalOpen, setIsMandatoryModalOpen] = useState(false);
  const handlemandatorymodalopen = () => setIsMandatoryModalOpen(true);
  const handlemandatorymodalclose = () => {
    setIsMandatoryModalOpen(false);
  };

  function removeSlash(port1) {
    let p1 = port1.split("/").join("");

    let np = p1;
    return np;
  }

  const location=useLocation()

  const [verifyModalOpen, setVerifyModalOpen] = useState(false);
  const handleverifymodalopen = () => setVerifyModalOpen(true);
  const handleverifymodalclose = () => {
    setVerifyModalOpen(false);
  };

  const [amountOne, setAmountOne] = useState("");
  const [amountTwo, setAmountTwo] = useState("");

  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "",
    duration: 3000, // Default duration
  });

  const showAlert = (message, type, duration = 3000) => {
    setAlert({
      show: true,
      message,
      type,
      duration,
    });

    // Automatically close the alert after the duration
    setTimeout(() => {
      setAlert((prevAlert) => ({
        ...prevAlert,
        show: false,
      }));
    }, duration);
  };

  const navigate = useNavigate();
  useEffect(() => {
    // Check if cibilScore is not null or undefined
    if (cibilScore !== null && cibilScore !== undefined) {
      if (cibilScore >= 900) {
        setCibilScoreColor("#E32f45");
      } else if (cibilScore >= 600) {
        setCibilScoreColor("#0054b5");
      } else if (cibilScore >= 400) {
        setCibilScoreColor("#612dba");
      } else if (cibilScore >= 100) {
        setCibilScoreColor("#e67300");
      } else {
        // Handle other cases, if necessary
      }
    }
  }, [cibilScore]);

  const [activeTab, setActiveTab] = useState(0);

  const changeTab = (index) => {
    setActiveTab(index);
  };

  // convert eliglible amount to words
  const convertToUnits = (amount) => {
    const units = [
      { value: 1e7, singular: "crore", plural: "crores" },
      { value: 1e5, singular: "lakh", plural: "lakhs" },
      { value: 1e3, singular: "thousand", plural: "thousands" },
    ];

    for (let i = 0; i < units.length; i++) {
      if (amount >= units[i].value) {
        const quotient = Math.floor(amount / units[i].value);
        const unit = quotient > 1 ? units[i].plural : units[i].singular;
        return `${quotient} ${unit}`;
      }
    }

    return `${amount} ` + (amount > 1 ? "units" : "unit");
  };

  const getHomeApi = async () => {
    try {
      const accId = localStorage.getItem("accId");
      const apiKey = localStorage.getItem("apiKey");

      if (accId.trim() === "" || apiKey.trim() === "") {
      
        showAlert("Not Registered", "danger");
        localStorage.clear()
        navigate("/login");
        return;
      }



      await fetch(`${Url}/business/getHome`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          accId,
          apiKey,
        }),
      })
        .then((res) => res.json())
        .then((resJson) => {
           
          if (!resJson.Success) {
             
            showAlert("Not Registered", "danger");
            localStorage.clear()
            navigate("/login");
            return;
          }

          if (resJson.Success) {
            setNotification(resJson.Success.notificationsEnabled);
            checkTicketStatus(resJson.Success.ticketId);
          }

          if (resJson.Success.eligibleApproved == "no") {
            showAlert("Not Eligible", "danger");
            localStorage.setItem("eligibleApproved", "no");
            navigate("/checkeligibility");
          } else if (resJson.Success.eligibleApproved == "yes") {
             
            // alert("Eligible")
            localStorage.setItem("eligibleApproved", "yes");

            setIsLoading(false);
            setLoanStatusCard(resJson.Success.loanEligible);
            setCibilScore(resJson.Success.cibilScore);
            setEligibleLoanAmountStore(resJson.Success.eligibleAmount);
            setEligibleInterestStore(resJson.Success.eligibleInterest);
            setEligibleTenureStore(resJson.Success.eligibleTenure);
            setEligibleMinTenureStore(resJson.Success.eligibleMinTenure);
            setEligibleMinAmountStore(resJson.Success.eligibleMinAmount);
            setEligibleFeeStore(resJson.Success.eligibleProcessingFee);
            setUserName(resJson.Success.userName);
            localStorage.setItem('userName', resJson.Success.userName);

          } else if (resJson.Success.loanEligible == "INPROGRESS") {
            localStorage.setItem("eligibleApproved", "yes");
            setIsLoading(false);
            setLoanStatusCard(resJson.Success.loanStatus);
            setUserName(resJson.Success.userName);
            localStorage.setItem('userName', resJson.Success.userName);
            setLoanAmount(resJson.Success.loanAmount);
            setCibilScore(resJson.Success.cibilScore);
            setPaidPrincipleAmount(resJson.Success.paidPrincipalAmount);
            setUnPaidPrincipleAmount(resJson.Success.unpaidPrincipalAmount);
            setEMIDaily(resJson.Success.dailyEMI);
            setEligibleInterestStore(resJson.Success.loanInterestRatePct);
            setLoanStartDate(resJson.Success.loanStartDate);
            setLoanEndDate(resJson.Success.loanEndDate);

            let sortedPayments = resJson.Success.pastPayments
              .map((payment) => ({
                ...payment,
                date: new Date(payment.date),
              }))
              .sort((a, b) => a.date - b.date);

            setLoadId(resJson.Success.loanId);
            setPaymentList(sortedPayments.slice(0, 2));
            setLoanDuration(resJson.Success.loanDuration);
            setLoanInterest(resJson.Success.loanInterestRatePct);
            setDueCharges(resJson.Success.dueCharges);
            setDuesPaid(resJson.Success.duesPaid);

            if (
              resJson.Success.loanStatus == "INPROGRESS" ||
              resJson.Success.loanStatus == "PAUSED"
            ) {

              if (
                resJson.Success.loanStatus == "INPROGRESS" &&
                resJson.Success.loanDisbursedDate.length < 5
              ) {
                setLoanStatusCard("PENDINGAMOUNT");
              } 
              const uppAmount =
                Number(resJson.Success.unpaidPrincipalAmount) +
                (Number(resJson.Success.dueCharges) -
                  Number(resJson.Success.duesPaid));
              setUnpaidChargesValue(uppAmount)
              setUpcomingPayments(
                calculateNextPayments(
                  uppAmount,
                  Number(resJson.Success.loanInterestRatePct),
                  Number(resJson.Success.dailyEMI),
                  Number(resJson.Success.loanDuration)
                ).slice(0, 2)
              );
            }
          } else if (resJson.Success.loanEligible == "LOANREJECTED") {
            if (resJson.Success.loanStatus == "CLOSED") {
              setLoanStatusCard(resJson.Success.loanStatus);
              setIsLoading(false);

              const lastEligibleDate = new Date(
                resJson.Success.lastEligibleDate
              );
              const currentDate = new Date();

              const oneYearInMillis = 365 * 24 * 60 * 60 * 1000;
              const differenceInMillis = currentDate - lastEligibleDate;

              if (differenceInMillis > oneYearInMillis) {
                 
                setLoanStatusCard("CHECKELIGIBLE");
              } else {
                 
                setLoanStatusCard("APPROVED");
                setCibilScore(resJson.Success.cibilScore);
                setEligibleLoanAmountStore(resJson.Success.eligibleAmount);
                setEligibleInterestStore(resJson.Success.eligibleInterest);
                setEligibleTenureStore(resJson.Success.eligibleTenure);
                setEligibleFeeStore(resJson.Success.eligibleProcessingFee);
              }
            } else {
              setLoanStatusCard(resJson.Success.loanEligible);
              setCibilScore(resJson.Success.cibilScore);
              setIsLoading(false);
            }
          }
        })
        .finally(()=>{
           Metrixfunction(metrixserver, "getHome", "ONLOAD");

        })
    } catch (error) {
      console.log(error);
      showAlert(error, "danger");
    }
  };

  const calculateNextPayments = (
    unPaidPrincipleAmountArg,
    eligibleInterestArg,
    EMIDailyArg,
    tenure
  ) => {
     
    let payments = [];
    let remainingAmount = Math.round(unPaidPrincipleAmountArg);
    let lastpaymentFlag = 0;
    // let currentDate = new Date();
    // currentDate.setMonth(currentDate.getMonth() + 1);
    // currentDate.setDate(1);
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 30);
    currentDate.setDate(1);
    let emiAmount = Math.round(EMIDailyArg);
    let interestRate = Number(
      (Math.round(eligibleInterestArg) / 100 / 12).toFixed(4)
    );
     

    let iterationCount = 0;
    const maxIterations = 100;

    while (remainingAmount > 0) {
       
      if (remainingAmount < emiAmount) {
        emiAmount = remainingAmount;
        lastpaymentFlag = 1;
      }
      let interestAmount = Math.round(remainingAmount * interestRate);
      let currPrincipal = emiAmount - interestAmount;
      if (currPrincipal < 0) {
        showAlert(
          "Issue with the loan please wait until we verify it",
          "danger"
        );
        break;
      }
      remainingAmount -= currPrincipal;
      if (lastpaymentFlag == 1) {
        remainingAmount = 0;
      }

      let nextPaymentDate = new Date(currentDate);
      nextPaymentDate.setMonth(nextPaymentDate.getMonth() + payments.length);

      payments.push({
        emi: emiAmount,
        interest: interestAmount,
        currPrincipal: currPrincipal,
        remainingAmount: remainingAmount,
        paymentDate: nextPaymentDate.toLocaleDateString("en-IN", {
          year: "numeric",
          month: "long",
        }),
      });

      iterationCount++;
      if (iterationCount >= maxIterations) {
        //console.log(
        //   "Max iterations reached. Breaking the loop to prevent infinite loop."
        // );
        break;
      }
    }
     

    return payments;
  };

  function loanProgressFunc(loanAmount,unpaidAmount) {
    let finalPercent = Math.round(
      ((loanAmount - unpaidAmount) / loanAmount) * 100
    );

    if (finalPercent < 0) {
      finalPercent = 3;
    } else if (finalPercent >= 0 && finalPercent <= 5) {
      finalPercent = 3;
    } else if (finalPercent > 100) {
      finalPercent = 100;
    }
    return finalPercent;
  }

  function formatDate(isoString) {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const createPayment = async () => {
    try {
      const accId = localStorage.getItem("accId");
      const apiKey = localStorage.getItem("apiKey");
       

      if (accId.trim() === "" || apiKey.trim() === "" || loadId.trim() === "") {
         
        showAlert("Not Registered", "danger");
        localStorage.clear()
        navigate("/login");
        return;
      }

      const response = await fetch(`${Url}/business/verifyBank`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          accId: accId,
          apiKey: apiKey,
          loanId: loadId,
          amt1: amountOne,
          amt2: amountTwo,
        }),
      });
      const resJson = await response.json();
       
      if (resJson.Success) {
        handleverifymodalclose();
        showAlert(resJson.Success, "success");
        getHomeApi();
      } else if (resJson.Error) {
        showAlert(resJson.Error, "danger");
      }
      Metrixfunction(metrixserver, "verifyBank", "UPDATE");

       
    } catch (error) {
      console.log(error);
      showAlert(error, "danger");
    }
  };

  const handleAmountOneChange = (text) => {
    // Remove any non-numeric characters

    const numericText = text.replace(/[^0-9.]/g, "");
    if (Number(numericText) < 100) {
      setAmountOne(numericText);
    }
  };

  const handleAmountTwoChange = (text) => {
    // Remove any non-numeric characters
    const numericText = text.replace(/[^0-9.]/g, "");
    if (Number(numericText) < 100) {
      setAmountTwo(numericText);
    }
  };

  // select date time picker for settlement
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-indexed month
  const currentDay = currentDate.getDate();

  const generateDays = (month, year) => {
    const daysInMonth = new Date(year, month, 0).getDate();
    let days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

    // Filter out past days if current year and month are selected
    if (year === currentYear && month === currentMonth) {
      days = days.filter((day) => day >= currentDay);
    }

    return days;
  };

  // Generate month options (1 to 12)
  const generateMonths = (year) => {
    let months = Array.from({ length: 12 }, (_, i) => i + 1);

    // Filter out past months if current year is selected
    if (year === currentYear) {
      months = months.filter((month) => month >= currentMonth);
    }

    return months;
  };

  // Generate year options (from current year onwards)
  const generateYears = () => {
    const futureYears = Array.from({ length: 30 }, (_, i) => currentYear + i);
    return futureYears;
  };

  const settlementdays =
    selectedMonth && selectedYear
      ? generateDays(Number(selectedMonth), Number(selectedYear))
      : [];
  const settlementmonths = selectedYear
    ? generateMonths(Number(selectedYear))
    : [];
  const settlementyears = generateYears();

  const handleDayChange = (event) => setSelectedDay(event.target.value);
  const handleMonthChange = (event) => setSelectedMonth(event.target.value);
  const handleYearChange = (event) => setSelectedYear(event.target.value);

  const getSettlementsApi = async () => {
    try {
      const accId = localStorage.getItem("accId");
      const apiKey = localStorage.getItem("apiKey");
       

      if (selectedDay === "" || selectedMonth === "" || selectedYear === "") {
        showAlert("Date is empty. Pick the date", "danger");
        return;
      }
      if (accId.trim() === "" || apiKey.trim() === "" || loadId.trim() === "") {
         
        localStorage.clear()
        navigate("/login");
        return;
      }

      Metrixfunction(metrixserver, "getSettlementDetails", "ONLOAD");
      
      const response = await fetch(`${Url}/test/getSettlementDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          accId: accId,
          apiKey: apiKey,
          loanId: loadId,
          day: selectedDay,
          month: selectedMonth,
          year: selectedYear,
        }),
      });
      const resJson = await response.json();
       

      if (!resJson.Success) {
         
        showAlert("Not Registered", "danger");
        return;
      }

      if (resJson.Success) {
        setShowSettlementAmount(true);
        setSettlementAmountStore(resJson.Success.settlementAmt);
      } else if (resJson.Error) {
        setShowSettlementAmount(false);
        showAlert(resJson.Error, "danger");
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      showAlert(error, "danger");
    }
  };

  const settlmentCardRef = useRef(null);

  useEffect(() => {
    getHomeApi();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        settlmentCardRef.current &&
        !settlmentCardRef.current.contains(event.target)
      ) {
        setSettlementCardOpen(false);
        setHoverAnimation(true);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const checkTicketStatus = async (ticketID) => {
  
    const accId = localStorage.getItem("accId");
    const apiKey = localStorage.getItem("apiKey");
    if ((accId !== null || apiKey !== null) && (ticketID !== null && ticketID !== undefined && ticketID !== "")) {
      try {


        await fetch(
          `${Url}/business/getTicketById`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              accId: accId,
              apiKey: apiKey,
              ticketId: ticketID
            }),
          }).then((res) => res.json())
          .then((resJson) => {
             
            if (resJson.Success) {
              if (resJson.Success[0].status !== "CLOSED") {
               
                localStorage.setItem("ticketId", resJson.Success[0].ticketId);
                setTicketId(resJson.Success[0].ticketId);
                setTicketStatus(resJson.Success[0].status);
                setCreateNewTicket(true);
                // setticket
              }else{
                localStorage.setItem("ticketId", "");
              }
            }
          })
          .finally(()=>{
        Metrixfunction(metrixserver, "getTicketById", "ONLOAD");

          })
      } catch (error) {
        console.log("Error :", error);
      }
    }

  }

  return (
    (isLoading && <Loading />) || (
      <div className="home-dynamic-container">

        {loanStatusCard === "REJECTED" && (
          <div
            className={`home-dynamic-card ${
              loanStatusCard === "REJECTED" && "noteligible"
            }`}
          >
            <div className="home-dynamic-card-content">
              <div className="home-dynamic-card-top">
                {/* <span className="home-dynamic-card-title">Welcome</span> */}
              </div>

              <div className="home-dynamic-card-bottom">
                <p></p>
                {/* <svg
            width="32"
            viewBox="0 -960 960 960"
            height="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M226-160q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-414q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-668q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Z"></path>
          </svg> */}
              </div>
            </div>
            <div className="home-dynamic-card-image-container reverse">
              <div className="home-dynamic-card-left-side">
                <h2 className="home-dynamic-card-left-side-title noteligibleTitle">
                  You are not eligible for loan
                </h2>
                <p className="home-dynamic-card-left-side-subtitle">
                  Please Apply after 6 months
                </p>
              </div>
              <div className="home-dynamic-card-image">
                <img
                  src={"https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/gif/sademoji.gif"}
                  className="home-dynamic-card-image-style"
                />

                {/* <svg
          width="48"
          viewBox="0 -960 960 960"
          height="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-73 85 40-280H160l360-520h80l-40 320h240L400-80h-80Zm153-395Z"></path>
        </svg> */}
              </div>
            </div>
          </div>
        )}
        {loanStatusCard === "APPROVED" && (
          <div
            className={`home-dynamic-card ${
              loanStatusCard === "APPROVED" && "eligible"
            }`}
          >
            <div className="home-dynamic-card-content">
              <div className="home-dynamic-card-top">
                {/* <span className="home-dynamic-card-title">Welcome</span> */}
              </div>

              <div className="home-dynamic-card-bottom">
                <p></p>
                {/* <svg
            width="32"
            viewBox="0 -960 960 960"
            height="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M226-160q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-414q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-668q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Z"></path>
          </svg> */}
              </div>
            </div>

            <div className="home-dynamic-card-image-container reverse">
              <div className="home-dynamic-card-left-side">
                <h2 className="home-dynamic-card-left-side-title eligibleTitle ">
                  Congrats! You are eligible for loan
                </h2>
                <p className="home-dynamic-card-left-side-subtitle">
                  You can avail the loan upto ₹
                  {eligibleLoanAmountStore
                    ? convertToUnits(Number(eligibleLoanAmountStore))
                    : ""}
                </p>
                <div className="home-dynamic-card-left-side-button-container">
                  <button
                    className="home-dynamic-card-left-side-button"
                    onClick={() => {
                      navigate("/apply", {
                        state: {
                          eligibleLoanAmount: eligibleLoanAmountStore,
                          eligibleInterest: eligibleInterestStore,
                          eligibleMinTenure: eligibleMinTenureStore,
                          eligibleMinAmount: eligibleMinAmountStore,
                          eligibleTenure: eligibleTenureStore,
                          eligibleFee: eligibleFeeStore,
                        },
                      });
                    }}
                  >
                    Apply Loan
                  </button>
                  <div
                    className="home-dynamic-card-mandatory-wrapper"
                    onClick={(e) => {
                      e.stopPropagation();
                      handlemandatorymodalopen();
                    }}
                  >
                    <Info />
                  </div>
                  {/* <button
                    className="home-dynamic-card-left-side-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handlemandatorymodalopen();
                    }}
                  >
                    Mandatory
                  </button> */}
                </div>
              </div>
              <div className="home-dynamic-card-image">
                <img
                  src={"https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/gif/joyemoji.gif"}
                  className="home-dynamic-card-image-style"
                />

                {/* <svg
          width="48"
          viewBox="0 -960 960 960"
          height="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-73 85 40-280H160l360-520h80l-40 320h240L400-80h-80Zm153-395Z"></path>
        </svg> */}
              </div>
            </div>
          </div>
        )}
        {loanStatusCard === "CHECKELIGIBLE" && (
          <div
            className={`home-dynamic-card ${
              loanStatusCard === "APPROVED" && "eligible"
            }`}
          >
            <div className="home-dynamic-card-content">
              <div className="home-dynamic-card-top">
                {/* <span className="home-dynamic-card-title">Welcome</span> */}
              </div>

              <div className="home-dynamic-card-bottom">
                <p></p>
                {/* <svg
            width="32"
            viewBox="0 -960 960 960"
            height="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M226-160q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-414q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-668q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Z"></path>
          </svg> */}
              </div>
            </div>
            <div className="home-dynamic-card-image-container reverse">
              <div className="home-dynamic-card-left-side">
                <h2 className="home-dynamic-card-left-side-title">
                  Hi! long time no see.
                </h2>
                <p className="home-dynamic-card-left-side-subtitle">
                  To take up a loan we have to again check your eligibility
                </p>
                <div className="home-dynamic-card-left-side-button-container">
                  <button
                    className="home-dynamic-card-left-side-button"
                    onClick={() => {
                      navigate("/checkeligibility");
                    }}
                  >
                    Check Eligible
                  </button>
                  <div
                    className="home-dynamic-card-mandatory-wrapper"
                    onClick={(e) => {
                      e.stopPropagation();
                      handlemandatorymodalopen();
                    }}
                  >
                    <Info />
                  </div>
                  {/* <button
                    className="home-dynamic-card-left-side-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handlemandatorymodalopen();
                    }}
                  >
                    Mandatory
                  </button> */}
                </div>
              </div>
              <div className="home-dynamic-card-image">
                <img
                  src={"https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/gif/joyemoji.gif"}
                  className="home-dynamic-card-image-style"
                />

                {/* <svg
          width="48"
          viewBox="0 -960 960 960"
          height="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-73 85 40-280H160l360-520h80l-40 320h240L400-80h-80Zm153-395Z"></path>
        </svg> */}
              </div>
            </div>
          </div>
        )}
        {loanStatusCard === "INITIATED" && (
          <div
            className={`home-dynamic-card ${
              loanStatusCard === "INITIATED" && "eligible"
            }`}
          >
            <div className="home-dynamic-card-content">
              <div className="home-dynamic-card-top">
                {/* <span className="home-dynamic-card-title">Welcome</span> */}
              </div>

              <div className="home-dynamic-card-bottom">
                <p></p>
                {/* <svg
            width="32"
            viewBox="0 -960 960 960"
            height="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M226-160q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-414q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-668q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Z"></path>
          </svg> */}
              </div>
            </div>
            <div className="home-dynamic-card-image-container reverse">
              <div className="home-dynamic-card-left-side">
                <h2 className="home-dynamic-card-left-side-title">
                  Your loan has been submitted
                </h2>
                <p className="home-dynamic-card-left-side-subtitle">
                  Our team is verifying your documents.
                </p>
                <p className="home-dynamic-card-left-side-subtitle">
                  Soon, your loan will be disbursed.
                </p>
              </div>
              <div className="home-dynamic-card-image">
                <img
                  src={"https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/gif/happyemoji.gif"}
                  className="home-dynamic-card-image-style"
                />
                <div className="home-dynamic-card-progress-container">
                  <ProgressBar
                    bgcolor={"#E32f45"}
                    completed={5}
                   bgcolorContainer={"#dbdbdb"}
                  />
                  <div className="home-dynamic-card-progress-wrapper">
                    <p className="home-dynamic-card-progress-text">Initiated</p>
                    <p className="home-dynamic-card-progress-text">
                      Kyc Pending
                    </p>
                    <p className="home-dynamic-card-progress-text">
                      Verification
                    </p>
                    <p className="home-dynamic-card-progress-text">
                      Loan Disbursed
                    </p>
                  </div>
                </div>

                {/* <svg
          width="48"
          viewBox="0 -960 960 960"
          height="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-73 85 40-280H160l360-520h80l-40 320h240L400-80h-80Zm153-395Z"></path>
        </svg> */}
              </div>
            </div>
          </div>
        )}
        {loanStatusCard === "VERIFIED" && (
          <div
            className={`home-dynamic-card ${
              loanStatusCard === "VERIFIED" && "eligible"
            }`}
          >
            <div className="home-dynamic-card-content">
              <div className="home-dynamic-card-top">
                {/* <span className="home-dynamic-card-title">Welcome</span> */}
              </div>

              <div className="home-dynamic-card-bottom">
                <p></p>
                {/* <svg
            width="32"
            viewBox="0 -960 960 960"
            height="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M226-160q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-414q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-668q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Z"></path>
          </svg> */}
              </div>
            </div>
            <div className="home-dynamic-card-image-container reverse">
              <div className="home-dynamic-card-left-side">
                <h2 className="home-dynamic-card-left-side-title">
                  Your loan documents is under verification
                </h2>
                <p className="home-dynamic-card-left-side-subtitle">
                  Our team is verifying your documents.
                </p>
                <p className="home-dynamic-card-left-side-subtitle">
                  Soon, your{" "}
                  <span className="home-dynamic-card-left-side-subtitle-highlight">
                    ₹{loanAmount}
                  </span>{" "}
                  loan will be disbursed.
                </p>
              </div>
              <div className="home-dynamic-card-image">
                <img
                  src={"https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/gif/happyemoji.gif"}
                  className="home-dynamic-card-image-style"
                />
                <div className="home-dynamic-card-progress-container">
                <ProgressBar
                    bgcolor={"#E32f45"}
                    completed={32}
                    bgcolorContainer={"#dbdbdb"}
                  />
                  <div className="home-dynamic-card-progress-wrapper">
                    <p className="home-dynamic-card-progress-text">Initiated</p>
                    <p className="home-dynamic-card-progress-text">
                      Kyc Pending
                    </p>
                    <p className="home-dynamic-card-progress-text">
                     Verification 
                    </p>
                    <p className="home-dynamic-card-progress-text">
                      Loan Disbursed
                    </p>
                  </div>
                </div>

                {/* <svg
          width="48"
          viewBox="0 -960 960 960"
          height="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-73 85 40-280H160l360-520h80l-40 320h240L400-80h-80Zm153-395Z"></path>
        </svg> */}
              </div>
            </div>
          </div>
        )}
        {loanStatusCard === "BANKVERIFY" && (
          <div
            className={`home-dynamic-card ${
              loanStatusCard === "BANKVERIFY" && "eligible"
            }`}
          >
            <div className="home-dynamic-card-content">
              <div className="home-dynamic-card-top">
                {/* <span className="home-dynamic-card-title">Welcome</span> */}
              </div>

              <div className="home-dynamic-card-bottom">
                <p></p>
                {/* <svg
            width="32"
            viewBox="0 -960 960 960"
            height="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M226-160q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-414q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-668q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Z"></path>
          </svg> */}
              </div>
            </div>
            <div className="home-dynamic-card-image-container reverse">
              <div className="home-dynamic-card-left-side">
                <h2 className="home-dynamic-card-left-side-title">
                  Please verify your bank account to transfer fund
                </h2>
                <div className="home-dynamic-card-left-side-button-container">
                  <button
                    className="home-dynamic-card-left-side-button"
                    onClick={() => {
                      handleverifymodalopen();
                    }}
                  >
                    Verify
                  </button>
                </div>
              </div>
              <div className="home-dynamic-card-image">
                <img
                  src={"https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/gif/happyemoji.gif"}
                  className="home-dynamic-card-image-style"
                />
                <div className="home-dynamic-card-progress-container">
                <ProgressBar
                    bgcolor={"#E32f45"}
                    completed={62}
                   bgcolorContainer={"#dbdbdb"}
                  />
                  <div className="home-dynamic-card-progress-wrapper">
                    <p className="home-dynamic-card-progress-text">Initiated</p>
                    <p className="home-dynamic-card-progress-text">
                       Kyc Pending
                    </p>
                    <p className="home-dynamic-card-progress-text">
                    Verification
                    </p>
                    <p className="home-dynamic-card-progress-text">
                      Loan Disbursed
                    </p>
                  </div>
                </div>

                {/* <svg
          width="48"
          viewBox="0 -960 960 960"
          height="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-73 85 40-280H160l360-520h80l-40 320h240L400-80h-80Zm153-395Z"></path>
        </svg> */}
              </div>
            </div>
          </div>
        )}
        {loanStatusCard === "KYCPENDING" && (
          <div
            className={`home-dynamic-card ${
              loanStatusCard === "KYCPENDING" && "eligible"
            }`}
          >
            <div className="home-dynamic-card-content">
              <div className="home-dynamic-card-top">
                {/* <span className="home-dynamic-card-title">Welcome</span> */}
              </div>

              <div className="home-dynamic-card-bottom">
                <p></p>
                {/* <svg
            width="32"
            viewBox="0 -960 960 960"
            height="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M226-160q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-414q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-668q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Z"></path>
          </svg> */}
              </div>
            </div>
            <div className="home-dynamic-card-image-container reverse">
              <div className="home-dynamic-card-left-side">
                <h2 className="home-dynamic-card-left-side-title">
                  We noticed some issues with your documents
                </h2>
                <p className="home-dynamic-card-left-side-subtitle">
                  Please resubmit your documents after changes
                </p>
              </div>
              <div className="home-dynamic-card-image">
                <img
                  src={"https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/gif/noideaemoji.gif"}
                  className="home-dynamic-card-image-style"
                />
                <div className="home-dynamic-card-progress-container">
                <ProgressBar
                    bgcolor={"#E32f45"}
                    completed={32}
                   bgcolorContainer={"#dbdbdb"}
                  />
                  <div className="home-dynamic-card-progress-wrapper">
                    <p className="home-dynamic-card-progress-text">Initiated</p>
                    <p className="home-dynamic-card-progress-text">
                      Kyc Pending
                    </p>
                    <p className="home-dynamic-card-progress-text">
                    Verification
                    </p>
                    <p className="home-dynamic-card-progress-text">
                      Loan Disbursed
                    </p>
                  </div>
                </div>

                {/* <svg
          width="48"
          viewBox="0 -960 960 960"
          height="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-73 85 40-280H160l360-520h80l-40 320h240L400-80h-80Zm153-395Z"></path>
        </svg> */}
              </div>
            </div>
          </div>
        )}
        {loanStatusCard === "APPLIED" && (
          <div
            className={`home-dynamic-card ${
              loanStatusCard === "APPLIED" && "eligible"
            }`}
          >
            <div className="home-dynamic-card-content">
              <div className="home-dynamic-card-top">
                {/* <span className="home-dynamic-card-title">Welcome</span> */}
              </div>

              <div className="home-dynamic-card-bottom">
                <p></p>
                {/* <svg
            width="32"
            viewBox="0 -960 960 960"
            height="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M226-160q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-414q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-668q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Z"></path>
          </svg> */}
              </div>
            </div>
            <div className="home-dynamic-card-image-container reverse">
              <div className="home-dynamic-card-left-side">
                <h2 className="home-dynamic-card-left-side-title">
                  Your Loan Eligibility is in pending
                </h2>
                <p className="home-dynamic-card-left-side-subtitle">
                  it will take few minutes to complete please wait
                </p>
              </div>
              <div className="home-dynamic-card-image">
                <img
                  src={"https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/gif/confuseemoji.gif"}
                  className="home-dynamic-card-image-style"
                />
                <div className="home-dynamic-card-progress-container">
                <ProgressBar
                    bgcolor={"#E32f45"}
                    completed={10}
                   bgcolorContainer={"#dbdbdb"}
                  />
                  <div className="home-dynamic-card-progress-wrapper">
                    <p className="home-dynamic-card-progress-text">
                      Check Eligibility
                    </p>
                    <p className="home-dynamic-card-progress-text">
                      Apply Loan
                    </p>
                    <p className="home-dynamic-card-progress-text">
                      Verification
                    </p>
                    <p className="home-dynamic-card-progress-text">
                      Loan Disbursed
                    </p>
                  </div>
                </div>

                {/* <svg
          width="48"
          viewBox="0 -960 960 960"
          height="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-73 85 40-280H160l360-520h80l-40 320h240L400-80h-80Zm153-395Z"></path>
        </svg> */}
              </div>
            </div>
          </div>
        )}
        {loanStatusCard === "LOANREJECTED" && (
          <div
            className={`home-dynamic-card ${
              loanStatusCard === "LOANREJECTED" && "noteligible"
            }`}
          >
            <div className="home-dynamic-card-content">
              <div className="home-dynamic-card-top">
                {/* <span className="home-dynamic-card-title">Welcome</span> */}
              </div>

              <div className="home-dynamic-card-bottom">
                <p></p>
                {/* <svg
            width="32"
            viewBox="0 -960 960 960"
            height="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M226-160q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-414q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-668q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Z"></path>
          </svg> */}
              </div>
            </div>
            <div className="home-dynamic-card-image-container reverse">
              <div className="home-dynamic-card-left-side">
                <h2 className="home-dynamic-card-left-side-title noteligibleTitle">
                  Your Loan has been Rejected
                </h2>
                <p className="home-dynamic-card-left-side-subtitle">
                  Sorry, we could not approve at this time.
                </p>
                <p className="home-dynamic-card-left-side-subtitle">
                  Please try after 6 months
                </p>
              </div>
              <div className="home-dynamic-card-image">
                <img
                  src={"https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/gif/sademoji.gif"}
                  className="home-dynamic-card-image-style"
                />
                <div className="home-dynamic-card-progress-container">
                <ProgressBar
                    bgcolor={"#E32f45"}
                    completed={100}
                   bgcolorContainer={"#dbdbdb"}
                  />
                  <div className="home-dynamic-card-progress-wrapper">
                    <p className="home-dynamic-card-progress-text">Intiated</p>
                    <p className="home-dynamic-card-progress-text">
                      Kyc Pending
                    </p>
                    <p className="home-dynamic-card-progress-text">
                    Verification
                    </p>
                    <p className="home-dynamic-card-progress-text">
                      Loan Rejected
                    </p>
                  </div>
                </div>

                {/* <svg
          width="48"
          viewBox="0 -960 960 960"
          height="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-73 85 40-280H160l360-520h80l-40 320h240L400-80h-80Zm153-395Z"></path>
        </svg> */}
              </div>
            </div>
          </div>
        )}
    
        {loanStatusCard === "INPROGRESS" && (
          <div
            className={`home-dynamic-card ${
              loanStatusCard === "INPROGRESS" && "eligible"
            } ${
              hoverAnimation ? "hover-home-card-dynamic" : ""
            } wow fadeInLeft`}
            data-wow-delay="0.1s"
            onClick={() => {
              navigate("/loanDetails", { state: { loanId: loadId } });
            }}
          >
            <div className="home-dynamic-card-content">
              <div className="home-dynamic-card-top">
                <p className="home-dynamic-card-title">
                  <span className="home-dynamic-card-title-highlight">Hi,</span>{" "}
                  {userName}
                </p>
                <p className="home-dynamic-card-status">IN PROGRESS</p>
              </div>

              <div className="home-dynamic-card-bottom">
                <p></p>
                {/* <svg
            width="32"
            viewBox="0 -960 960 960"
            height="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M226-160q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-414q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-668q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Z"></path>
          </svg> */}
              </div>
            </div>
            <div className="home-dynamic-card-image-container not-reverse">
              <div className="home-dynamic-card-left-side">
                <h2 className="home-dynamic-card-left-side-title-inprogress">
                  ₹{loanAmount}
                </h2>
                <div className="home-dynamic-card-msg-container">
                  <p>You have an upcoming payment.</p>
                </div>
              </div>
              <div className="home-dynamic-card-image">
                {/* <img
                src={"./assets/img/joyemoji.gif"}
                className="home-dynamic-card-image-style"
              /> */}
                <div className="home-dynamic-card-left-sted-container">
                  <div className="home-dynamic-card-left-sted-wrapper">
                    <p className="home-dynamic-card-left-sted-title">
                      Start Date
                    </p>
                    <p className="home-dynamic-card-left-sted-subtitle">
                      {loanStartDate.replace(/-/g, "/")}
                    </p>
                  </div>
                  <ChevronsRight size={30} style={{ animation: "none",color:"#262B47" }} />
                  <div className="home-dynamic-card-left-sted-wrapper">
                    <p className="home-dynamic-card-left-sted-title">
                      End Date
                    </p>
                    <p className="home-dynamic-card-left-sted-subtitle">
                      {loanEndDate.replace(/-/g, "/")}
                    </p>
                  </div>
                </div>
                <ProgressBar
                  bgcolor={"#e32f45"}
                  completed={loanProgressFunc(loanAmount, unpaidChargesValue)}
                  bgcolorContainer={"#dbdbdb"}
                />
                <div
                  className="home-dynamic-card-image-button-container"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div className="home-dynamic-card-image-button">
                    <p className="home-dynamic-card-image-button-title">
                      Unpaid
                    </p>
                    <p className="home-dynamic-card-image-button-subtitle">
                      ₹
                      {Math.round(Number(unPaidPrincipleAmount)) +
                        (Math.round(Number(dueCharges)) -
                          Math.round(Number(duesPaid))) >
                      0
                        ? Math.round(Number(unPaidPrincipleAmount)) +
                          (Math.round(Number(dueCharges)) -
                            Math.round(Number(duesPaid)))
                        : 0}
                      <p
                        style={{
                          position: "relative",
                          marginLeft: 5,
                          zIndex: 20,
                          width: 30,
                          height: 25,
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setShowSettlementAmount(false);
                          setSettlementCardOpen(true);
                          setHoverAnimation(false);
                        }}
                      >
                        <Info
                          style={{
                            width: 18,
                            height: 18,
                            cursor: "pointer",
                            zIndex: 10,
                          }}
                        />
                      </p>
                    </p>
                  </div>
                  {settlementCardOpen && (
                    <div
                      ref={settlmentCardRef}
                      className="home-dynamic-result-disbursed-info-modal"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="home-dynamic-result-disbursed-info-container">
                        <p className="home-dynamic-result-disbursed-info-key">
                          The actual amount will vary due to interest or other
                          charges associated with your account
                        </p>
                      </div>
                      {showSettlementAmount === false ? (
                        <>
                          <div className="home-dynamic-date-selectors">
                            <label>
                              <select
                                value={selectedDay}
                                onChange={handleDayChange}
                              >
                                <option value="">Day</option>
                                {settlementdays.map((day) => (
                                  <option key={day} value={day}>
                                    {day}
                                  </option>
                                ))}
                              </select>
                            </label>
                            <label>
                              <select
                                value={selectedMonth}
                                onChange={handleMonthChange}
                              >
                                <option value="">Month</option>
                                {settlementmonths.map((month) => (
                                  <option key={month} value={month}>
                                    {month}
                                  </option>
                                ))}
                              </select>
                            </label>
                            <label>
                              <select
                                value={selectedYear}
                                onChange={handleYearChange}
                              >
                                <option value="">Year</option>
                                {settlementyears.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                            </label>
                          </div>
                          <div className="home-dynamic-result-disbursed-info-container">
                            <p
                              className="home-dynamic-get-settlement"
                              onClick={(e) => {
                                e.stopPropagation();
                                getSettlementsApi();
                              }}
                            >
                              Get Settlement
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <label className="home-dynamic-result-disbursed-info-key">
                            Settlement Amount
                          </label>
                          <div style={{ width: "90%", marginLeft: "5%" }}>
                            <InputField
                              placeholder="Settlement Amount"
                              maxLength={25}
                              value={settlementAmountStore}
                              editable={false}
                              leftIcon={
                                <Bank
                                  style={{
                                    animation: "none",
                                    color: "#868585",
                                  }}
                                />
                              }
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  <div className="home-dynamic-card-image-button">
                    <p className="home-dynamic-card-image-button-title">
                      Interest(APR)
                    </p>
                    <p className="home-dynamic-card-image-button-subtitle">
                      {eligibleInterestStore}%
                    </p>
                  </div>
                  <div className="home-dynamic-card-image-button">
                    <p className="home-dynamic-card-image-button-title">EMI</p>
                    <p className="home-dynamic-card-image-button-subtitle">
                      ₹{Math.round(EMIDaily)}
                    </p>
                  </div>
                </div>
                {Math.round(Number(unPaidPrincipleAmount)) +
                  (Math.round(Number(dueCharges)) -
                    Math.round(Number(duesPaid))) <=
                  0 && (
                  <p style={{ marginTop: 5, opacity: 0.8 }}>
                    * You have made all payments. Soon your loan will be closed.
                  </p>
                )}

                {/* <svg
          width="48"
          viewBox="0 -960 960 960"
          height="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-73 85 40-280H160l360-520h80l-40 320h240L400-80h-80Zm153-395Z"></path>
        </svg> */}
              </div>
            </div>
          </div>
        )}

        {loanStatusCard === "PENDINGAMOUNT" && (
          <div
            className={`home-dynamic-card ${
              loanStatusCard === "PENDINGAMOUNT" && "eligible"
            } ${
              hoverAnimation ? "hover-home-card-dynamic" : ""
            } wow fadeInLeft`}
            data-wow-delay="0.1s"
            onClick={() => {
              navigate("/loanDetails", { state: { loanId: loadId } });
            }}
          >
            <div className="home-dynamic-card-content">
              <div className="home-dynamic-card-top">
                <p className="home-dynamic-card-title">
                  <span className="home-dynamic-card-title-highlight">Hi,</span>{" "}
                  {userName}
                </p>
                <p className="home-dynamic-card-status">
                  PENDING BANK TRANSFER
                </p>
              </div>

              <div className="home-dynamic-card-bottom">
                <p></p>
                {/* <svg
            width="32"
            viewBox="0 -960 960 960"
            height="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M226-160q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-414q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-668q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Z"></path>
          </svg> */}
              </div>
            </div>
            <div className="home-dynamic-card-image-container not-reverse">
              <div className="home-dynamic-card-left-side">
                <h2 className="home-dynamic-card-left-side-title-inprogress">
                  ₹{loanAmount}
                </h2>
                <div className="home-dynamic-card-msg-container">
                  <p>Your Loan amount will be disbursed soon.</p>
                </div>
              </div>
              <div className="home-dynamic-card-image">
                {/* <img
                src={"./assets/img/joyemoji.gif"}
                className="home-dynamic-card-image-style"
              /> */}
                <div className="home-dynamic-card-left-sted-container">
                  <div className="home-dynamic-card-left-sted-wrapper">
                    <p className="home-dynamic-card-left-sted-title">
                      Start Date
                    </p>
                    <p className="home-dynamic-card-left-sted-subtitle">
                      {loanStartDate.replace(/-/g, "/")}
                    </p>
                  </div>
                  <ChevronsRight size={30} style={{ animation: "none",color:"#262B47" }} />
                  <div className="home-dynamic-card-left-sted-wrapper">
                    <p className="home-dynamic-card-left-sted-title">
                      End Date
                    </p>
                    <p className="home-dynamic-card-left-sted-subtitle">
                      {loanEndDate.replace(/-/g, "/")}
                    </p>
                  </div>
                </div>
                <ProgressBar
                  bgcolor={"#e32f45"}
                  completed={loanProgressFunc(loanAmount, unpaidChargesValue)}
                  bgcolorContainer={"#dbdbdb"}
                />
                <div
                  className="home-dynamic-card-image-button-container"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div className="home-dynamic-card-image-button">
                    <p className="home-dynamic-card-image-button-title">
                      Unpaid
                    </p>
                    <p className="home-dynamic-card-image-button-subtitle">
                      ₹
                      {Math.round(Number(unPaidPrincipleAmount)) +
                        (Math.round(Number(dueCharges)) -
                          Math.round(Number(duesPaid))) >
                      0
                        ? Math.round(Number(unPaidPrincipleAmount)) +
                          (Math.round(Number(dueCharges)) -
                            Math.round(Number(duesPaid)))
                        : 0}
                      <p
                        style={{
                          position: "relative",
                          marginLeft: 5,
                          zIndex: 20,
                          width: 30,
                          height: 25,
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setShowSettlementAmount(false);
                          setSettlementCardOpen(true);
                          setHoverAnimation(false);
                        }}
                      >
                        <Info
                          style={{
                            width: 18,
                            height: 18,
                            cursor: "pointer",
                            zIndex: 10,
                          }}
                        />
                      </p>
                    </p>
                  </div>
                  {settlementCardOpen && (
                    <div
                      ref={settlmentCardRef}
                      className="home-dynamic-result-disbursed-info-modal"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="home-dynamic-result-disbursed-info-container">
                        <p className="home-dynamic-result-disbursed-info-key">
                          The actual amount will vary due to interest or other
                          charges associated with your account
                        </p>
                      </div>
                      {showSettlementAmount === false ? (
                        <>
                          <div className="home-dynamic-date-selectors">
                            <label>
                              <select
                                value={selectedDay}
                                onChange={handleDayChange}
                              >
                                <option value="">Day</option>
                                {settlementdays.map((day) => (
                                  <option key={day} value={day}>
                                    {day}
                                  </option>
                                ))}
                              </select>
                            </label>
                            <label>
                              <select
                                value={selectedMonth}
                                onChange={handleMonthChange}
                              >
                                <option value="">Month</option>
                                {settlementmonths.map((month) => (
                                  <option key={month} value={month}>
                                    {month}
                                  </option>
                                ))}
                              </select>
                            </label>
                            <label>
                              <select
                                value={selectedYear}
                                onChange={handleYearChange}
                              >
                                <option value="">Year</option>
                                {settlementyears.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                            </label>
                          </div>
                          <div className="home-dynamic-result-disbursed-info-container">
                            <p
                              className="home-dynamic-get-settlement"
                              onClick={(e) => {
                                e.stopPropagation();
                                getSettlementsApi();
                              }}
                            >
                              Get Settlement
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <label className="home-dynamic-result-disbursed-info-key">
                            Settlement Amount
                          </label>
                          <div style={{ width: "90%", marginLeft: "5%" }}>
                            <InputField
                              placeholder="Settlement Amount"
                              maxLength={25}
                              value={settlementAmountStore}
                              editable={false}
                              leftIcon={
                                <Bank
                                  style={{
                                    animation: "none",
                                    color: "#868585",
                                  }}
                                />
                              }
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  <div className="home-dynamic-card-image-button">
                    <p className="home-dynamic-card-image-button-title">
                      Interest(APR)
                    </p>
                    <p className="home-dynamic-card-image-button-subtitle">
                      {eligibleInterestStore}%
                    </p>
                  </div>
                  <div className="home-dynamic-card-image-button">
                    <p className="home-dynamic-card-image-button-title">EMI</p>
                    <p className="home-dynamic-card-image-button-subtitle">
                      ₹{Math.round(EMIDaily)}
                    </p>
                  </div>
                </div>
                {Math.round(Number(unPaidPrincipleAmount)) +
                  (Math.round(Number(dueCharges)) -
                    Math.round(Number(duesPaid))) <=
                  0 && (
                  <p style={{ marginTop: 5, opacity: 0.8 }}>
                    * You have made all payments. Soon your loan will be closed.
                  </p>
                )}

                {/* <svg
          width="48"
          viewBox="0 -960 960 960"
          height="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-73 85 40-280H160l360-520h80l-40 320h240L400-80h-80Zm153-395Z"></path>
        </svg> */}
              </div>
            </div>
          </div>
        )}

        {loanStatusCard === "INITIATED" ||
        loanStatusCard === "VERIFIED" ||
        loanStatusCard === "KYCPENDING" ? (
          <div
            className="home-dynamic-loan-initiated-container wow fadeInRight"
            data-wow-delay="0.1s"
          >
            <div className="home-dynamic-loan-initiated-wrapper">
              <p className="home-dynamic-loan-initiated-wrapper-key">
                Loan Amount
              </p>
              <p className="home-dynamic-loan-initiated-wrapper-value">
                ₹{loanAmount}
              </p>
            </div>
            <div className="home-dynamic-loan-initiated-wrapper">
              <p className="home-dynamic-loan-initiated-wrapper-key">
                Loan Interest
              </p>
              <p className="home-dynamic-loan-initiated-wrapper-value">
                {loanInterest}%
              </p>
            </div>
            <div className="home-dynamic-loan-initiated-wrapper">
              <p className="home-dynamic-loan-initiated-wrapper-key">
                Loan Tenure
              </p>
              <p className="home-dynamic-loan-initiated-wrapper-value">
                {loanDuration} {loanDuration > 1 ? "Months" : "Month"}
              </p>
            </div>
          </div>
        ) : null}

        {cibilScore != 0 && (
          <div
            className="home-dynamic-cibil-container wow fadeInRight"
            data-wow-delay="0.1s"
          >
            <p className="home-dynamic-cibil-key">Your Cibil Score</p>
            <div className="home-dynamic-cibil-value-container">
              <div className="home-dynamic-cibil-value-wrapper">
                <p
                  className="home-dynamic-cibil-value"
                  style={{ color: cibilScoreColor }}
                >
                  {cibilScore}
                </p>
                <p className="home-dynamic-cibil-value-sub">/ 900</p>
              </div>
              <p className="home-dynamic-cibil-value-status"></p>
            </div>
          </div>
        )}

        {loanStatusCard === "INPROGRESS" && (
          <>
            <div className="home-dynamic-view-all-wrapper">
              <p
                className="home-dynamic-view-all"
                onClick={() => {
                  navigate("/loanDetails", { state: { loanId: loadId } });
                }}
              >
                View all
              </p>
            </div>
            <div
              className="home-dynamic-tab-container wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <input
                type="radio"
                name="tab"
                id="tab1"
                className="home-dynamic-tab home-dynamic-tab--1"
                onClick={() => changeTab(0)}
              />
              <label className="home-dynamic-tab_label" for="tab1">
                Upcoming Payments
              </label>

              <input
                type="radio"
                name="tab"
                id="tab2"
                className="home-dynamic-tab home-dynamic-tab--2"
                onClick={() => changeTab(1)}
              />
              <label className="home-dynamic-tab_label" for="tab2">
                Past Payments
              </label>

              <div className="home-dynamic-tab-indicator"></div>
            </div>

            {activeTab === 0 && (
              <div
                className="home-dynamic-tab-content wow fadeInUp"
                data-wow-delay="0.1s"
              >
                {upcomingPayments.length > 0 ? (
                  <>
                    {upcomingPayments.map((payment, index) => (
                      <div
                        className="home-dynamic-tab-content-card"
                        key={index}
                      >
                        <div className="home-dynamic-tab-content-img-container">
                          <div className="home-dynamic-tab-content-img-wrapper">
                            <img
                              className="home-dynamic-tab-content-img-style"
                              src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/transaction.png"
                            />
                          </div>
                          <div className="home-dynamic-tab-content-paid-wrapper">
                            <p className="home-dynamic-tab-content-paid-title">
                              {payment.paymentDate}
                            </p>
                            <p className="home-dynamic-tab-content-paid-subtitle">
                              {payment.date}{" "}
                            </p>
                          </div>
                        </div>
                        <p className="home-dynamic-tab-content-amount">
                          ₹{Math.round(Number(payment.emi))}
                        </p>
                      </div>
                    ))}
                  </>
                ) : (
                  <img
                    src={"https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/No-data.png"}
                    alt="No loans found"
                    className="nothing-img"
                    style={{
                      display: "flex",
                      position: "relative",
                      left: "50%",
                      transform: "translate(-50%)",
                    }}
                  />
                )}
              </div>
            )}
            {activeTab === 1 && (
              <div
                className="home-dynamic-tab-content wow fadeInUp"
                data-wow-delay="0.1s"
              >
                {paymentList.length === 0 ? (
                  <img
                    src={"https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/No-data.png"}
                    alt="No loans found"
                    className="nothing-img"
                    style={{
                      display: "flex",
                      position: "relative",
                      left: "50%",
                      transform: "translate(-50%)",
                    }}
                  />
                ) : (
                  <>
                    {paymentList.map((item, index) => (
                      <div
                        key={index}
                        className="home-dynamic-tab-content-card"
                      >
                        <div className="home-dynamic-tab-content-img-container">
                          <div className="home-dynamic-tab-content-img-wrapper">
                            <img
                              className="home-dynamic-tab-content-img-style"
                              src={
                                item.imgSrc || "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/transaction.png"
                              }
                              alt="Transaction"
                            />
                          </div>
                          <div className="home-dynamic-tab-content-paid-wrapper">
                            <p className="home-dynamic-tab-content-paid-title">
                              {item.paymentType}
                            </p>
                            <p className="home-dynamic-tab-content-paid-subtitle">
                              {formatDate(item.date)}
                            </p>
                          </div>
                        </div>
                        <p className="home-dynamic-tab-content-amount">
                          ₹{Math.round(Number(item.amt)) || 0}
                        </p>
                      </div>
                    ))}
                  </>
                )}
              </div>
            )}
          </>
        )}
        <div className="home-dynamic-features-title-wrapper">
          {/* <h1 className="mb-5">Quick Links</h1> */}
          {/* <h5 className="text-primary-gradient fw-medium">Features</h5> */}
        </div>

        {/* ----------------fetaures -------- */}
        <div
          className="home-dynamic-features-container wow fadeInLeft"
          data-wow-delay="0.1s"
        >
          <div
            className="home-dynamic-features-card"
            onClick={() => {
              setTimeout(() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }, 0);
              navigate("/calculator");
            }}
          >
            <Calculator className="home-dynamic-features-card-icon" />
            <p className="home-dynamic-features-card-value">Loan Calculator</p>
          </div>

          <div
            className="home-dynamic-features-card"
            onClick={() => {
              setTimeout(() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }, 0);
              navigate("/history");
            }}
          >
            <ClockHistory className="home-dynamic-features-card-icon" />
            <p className="home-dynamic-features-card-value">Loan History</p>
          </div>

          <div
            className="home-dynamic-features-card"
            onClick={() => {
              setTimeout(() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }, 0);
              navigate("/account");
            }}
          >
            <File className="home-dynamic-features-card-icon" />
            <p className="home-dynamic-features-card-value">Documents</p>
          </div>
        </div>
        <div className="container mb-5 w-100 p-3  " id="Articles">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h5 className="text-primary-gradient fw-medium">Our Articles</h5>
            <h1 className="mb-5">
              Everything You Need to Know in Business Loan
            </h1>
          </div>
          <div
            className="row g-4 bg-light pb-4  rounded wow fadeInUp d-flex justify-content-center flex-wrap "
            data-wow-delay="0.1s"
          >
            <div className=" col-12 col-lg 4 ">
              <div
                className=" pb-2 rounded "
                style={{
                  backgroundColor: "white",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/steps.png" height="280px" width="80%" />
                {/* ----Header--- */}
                <div className=" p-4 mb-4">
                  <h3 className="text-dark mb-2">
                    Steps to Get a Business loan to start a Business
                  </h3>
                  <div className="d-flex  justify-content-between ">
                    <span
                      className="text-black-50"
                      style={{ fontFamily: '"Jost", sans-serif' }}
                    >
                      Last Updated : 31st October 2023
                    </span>
                  </div>
                </div>
                {/* ---------body---- */}
                {/* -------footer------ */}
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <a
                    href="/article1"
                    className="btn btn-light  rounded py-2 px-4 mb-2 border  "
                    style={{
                      fontWeight: 500,
                      fontFamily: '"Jost", sans-serif',
                      width: "80%",
                    }}
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className=" col-12 col-lg-4 ">
              <div
                className=" pb-2 rounded"
                style={{
                  backgroundColor: "white",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/myth.png" height="280px" width="80%" />
                {/* ----Header--- */}
                <div className=" p-4 mb-4">
                  <h3 className="text-dark   mb-2">
                    Some Common Myths around Business Loans
                  </h3>
                  <div className="d-flex  justify-content-between ">
                    <span
                      className="text-black-50 "
                      style={{ fontFamily: '"Jost", sans-serif' }}
                    >
                      Last Updated : 31st October 2023
                    </span>
                  </div>
                </div>
                {/* ---------body---- */}
                {/* -------footer------ */}
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <a
                    href="/article2"
                    className="btn btn-light  rounded py-2 px-4 mb-2 border  "
                    style={{
                      fontWeight: 500,
                      fontFamily: '"Jost", sans-serif',
                      width: "80%",
                    }}
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className=" col-12 col-lg-4">
              <div
                className=" pb-2 rounded"
                style={{
                  backgroundColor: "white",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/benefits.png"
                  height="280px"
                  width="80%"
                />
                {/* ----Header--- */}
                <div className=" p-4 mb-4 ">
                  <h3 className="text-dark   mb-2">
                    The Important Benefits of a Business Loan
                  </h3>
                  <div className="d-flex  justify-content-between ">
                    <span
                      className="text-black-50"
                      style={{ fontFamily: '"Jost", sans-serif' }}
                    >
                      Last Updated : 31st October 2023
                    </span>
                  </div>
                </div>
                {/* ---------body---- */}
                {/* -------footer------ */}
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <a
                    href="/article3"
                    className="btn btn-light  rounded py-2 px-4 mb-2 border  "
                    style={{
                      fontWeight: 500,
                      fontFamily: '"Jost", sans-serif',
                      width: "80%",
                    }}
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12  d-flex justify-content-center">
              <a
                href="/articles"
                className="btn btn-primary-gradient rounded py-2 px-4 mt-4 "
                style={{ fontFamily: '"Jost", sans-serif' }}
              >
                View More
              </a>
            </div>
          </div>
        </div>
        <Modal
          show={isMandatoryModalOpen}
          onHide={handlemandatorymodalclose}
          centered
          className="loan-details-modal-body"
        >
          <Modal.Header
            style={{
              border: "none",
            }}
            className="loan-details-modal-header"
          >
            <Modal.Title className="p-2 d-flex  border-bottom w-100 border-dark-subtle flex-column ">
              <div
                className="fw-bolder d-flex justify-content-between align-items-center w-100"
                style={{ fontSize: "18px" }}
              >
                <h4 style={{ color: "#333" }}>Documents Required</h4>
                <X
                  // color="#dc3545"
                  onClick={handlemandatorymodalclose}
                  style={{ cursor: "pointer" }}
                />
              </div>

              <span style={{ fontSize: "16px", color: "#969696" }}>
                Keep these documents handy
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ol className="loan-details-mandatory-styled-list">
              <li>Aadhaar Card</li>
              <li>Pan Card</li>
              <li>Bank Details(Account No, IFSC)</li>
              <li>Employee ID (PDF or Image)</li>
              <li>Current Company Joining Letter</li>
              <li>Last 3 Months Bank Statement</li>
              <li>
                Any Utility Bill (Electricity Bill, Water Bill, Gas Bill,
                Internet Bill, Property Tax Bill)
              </li>
            </ol>
          </Modal.Body>
          {/* <Modal.Footer className="loan-details-modal-footer">
            <button className="loan-details-close-modal">Close</button>
            <button className="loan-details-save-modal">Make Payment</button>
          </Modal.Footer> */}
        </Modal>

        <Modal
          show={verifyModalOpen}
          onHide={handleverifymodalclose}
          centered
          className="loan-details-modal-body"
        >
          <Modal.Header
            style={{
              border: "none",
            }}
            className="loan-details-modal-header"
          >
            <Modal.Title className="p-2 d-flex  border-bottom w-100 border-dark-subtle flex-column ">
              <div
                className="fw-bolder d-flex justify-content-between align-items-center w-100"
                style={{ fontSize: "18px" }}
              >
                <h4 style={{ color: "#333" }}>Bank Verification</h4>
                <X
                  // color="#dc3545"
                  onClick={handleverifymodalclose}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <span style={{ fontSize: "16px", color: "#969696" }}>
                We sent to IMPS transaction to your account. Enter the amount to
                process your loan payment.
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>Amount one(to transfer the amount)</label>

            <InputField
              placeholder="1"
              maxLength={25}
              value={amountOne}
              onChangeText={handleAmountOneChange}
              leftIcon={<Bank style={{ animation: "none" }} />}
            />

            <label>Amount two(to transfer the amount)</label>
            <InputField
              placeholder="2"
              maxLength={25}
              value={amountTwo}
              onChangeText={handleAmountTwoChange}
              leftIcon={<Bank style={{ animation: "none" }} />}
            />
          </Modal.Body>
          <Modal.Footer className="loan-details-modal-footer">
            <button
              className="loan-details-save-modal"
              onClick={() => {
                createPayment();
              }}
            >
              Submit
            </button>
          </Modal.Footer>
        </Modal>
        {alert.show && (
          <Alert
            message={alert.message}
            type={alert.type}
            show={alert.show}
            duration={alert.duration}
            onClose={() => setAlert({ ...alert, show: false })}
          />
        )}
      </div>
    )
  );
}

export default MyKaasuHome;
