import React, { useContext, useEffect, useRef, useState } from 'react'
import Metrixfunction from '../components/MetrixFunction';
import { MyContext } from '../components/GlobalValues';
function Faq() {

  const {  metrixserver} = useContext(MyContext);


  const [imagesLoaded, setImagesLoaded] = useState(0);
const totalImages = useRef(0);

useEffect(() => {
  // Count the total number of images
  const imageElements = document.querySelectorAll('img');
  totalImages.current = imageElements.length;


  // Add load event listeners to all images
  if(imageElements.length>0)
  {
    imageElements.forEach(img => {
      if (img.complete) {
        handleImageLoad();
      } else {
        img.addEventListener('load', handleImageLoad);
        img.addEventListener('error', handleImageLoad); // Handle error case
      }
    });
  }
  else{
    Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");

  }

  // Cleanup function
  return () => {
    imageElements.forEach(img => {
      img.removeEventListener('load', handleImageLoad);
      img.removeEventListener('error', handleImageLoad);
    });
  };
}, []);

useEffect(() => {
  if (imagesLoaded === totalImages.current && totalImages.current > 0) {
    Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");

  }
}, [imagesLoaded]);

const handleImageLoad = () => {
  setImagesLoaded(prev => prev + 1);
};
  useEffect(() => {
    const accItems = document.querySelectorAll(".accordion__item");

    function toggleAcc(event) {
      // Remove active class from all items except the current item
      accItems.forEach((item) => {
        if (item !== event.currentTarget) {
          item.classList.remove("accordion__item--active");
        }
      });

      // Toggle active class on the current item
      event.currentTarget.classList.toggle("accordion__item--active");
    }

    // Add click event listener to all items
    accItems.forEach((acc) => acc.addEventListener("click", toggleAcc));

    // Cleanup function to remove event listeners
    return () => {
      accItems.forEach((acc) => acc.removeEventListener("click", toggleAcc));
    };
  }, []);
  return (
    <div className="bg-white p-0">
      {/* Spinner Start */}
      <div
        id="spinner"
        className=" bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <div
          className="spinner-grow text-danger"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      {/* Spinner End */}
      {/* Navbar & Hero Start */}
      <div className="position-relative p-0" id="home">
        <custom-header />
        <div className="bg-primary hero-header">
          <div className="container px-lg-5">
            <div className="row g-4">
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                <h1 className="mb-4" style={{ color: "white" }}>
                  Frequently Asked Questions About myKaasu.
                </h1>
                {/* <p className="mb-4" style="color: white">Apply Now!</p> */}
              </div>
              <div
                className="col-lg-6 d-flex justify-content-center justify-content-lg-end wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <img
                  src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/FAQs.png"
                  width="100%"
                  height="100%"
                />
                {/* <div className="owl-carousel screenshot-carousel">
                            <img className="img-fluid" src="img/01.jpg" alt="">
                            <img className="img-fluid" src="img/02.jpg" alt="">
                            <img className="img-fluid" src="img/03.jpg" alt="">
                            <img className="img-fluid" src="img/04.jpg" alt="">
                            <img className="img-fluid" src="img/05.jpg" alt="">
                        </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Navbar & Hero End */}
      {/* FAQ start */}
      <div className="wow fadeInUp" data-wow-delay="0.3s" id="faq">
        <div className="container-xxl py-5">
          <h2 className="accordion__heading">Frequently Asked Questions</h2>
          <br />
          <div className="accordion__item">
            <button className="accordion__btn">
              <span className="accordion__caption">
                What is the repayment tenure for loans?
              </span>
              <span className="accordion__icon">
                <i className="fa fa-plus" />
              </span>
            </button>
            <div className="accordion__content">
              <p>
                The repayment tenure for personal loans typically ranges from 12
                to 60 months, while for business loans, it can range from 12
                months to several years, depending on the loan amount and terms
                agreed upon.
              </p>
            </div>
          </div>
          <div className="accordion__item">
            <button className="accordion__btn">
              <span className="accordion__caption">
                Are there any prepayment or foreclosure charges?
              </span>
              <span className="accordion__icon">
                <i className="fa fa-plus" />
              </span>
            </button>
            <div className="accordion__content">
              <p>
                Prepayment or foreclosure charges vary by lender. Some lenders
                may charge a fee for early repayment, while others may allow it
                without any additional charges. It's important to check the
                terms and conditions of your loan agreement.
              </p>
            </div>
          </div>
          <div className="accordion__item">
            <button className="accordion__btn">
              <span className="accordion__caption">
                What happens if I miss a loan repayment?
              </span>
              <span className="accordion__icon">
                <i className="fa fa-plus" />
              </span>
            </button>
            <div className="accordion__content">
              <p>
                Missing a loan repayment can result in late fees and negatively
                impact your credit score. It’s important to contact Mykaasu
                immediately if you anticipate difficulties in making a payment
                to discuss potential solutions.
              </p>
            </div>
          </div>
          <div className="accordion__item">
            <button className="accordion__btn">
              <span className="accordion__caption">
                Can I apply for a loan online?
              </span>
              <span className="accordion__icon">
                <i className="fa fa-plus" />
              </span>
            </button>
            <div className="accordion__content">
              <p>
                Yes, we offer an online application process for both personal
                and business loans. You can apply through the Mykaasu website by
                filling out the necessary forms and uploading required
                documents.
              </p>
            </div>
          </div>
          <div className="accordion__item">
            <button className="accordion__btn">
              <span className="accordion__caption">
                How is my loan eligibility determined?
              </span>
              <span className="accordion__icon">
                <i className="fa fa-plus" />
              </span>
            </button>
            <div className="accordion__content">
              <p>
                Loan eligibility is determined based on various factors,
                including your credit score, income or revenue, existing debt,
                and repayment capacity. Your financial history and stability
                also heavily determine your eligibility process.
              </p>
            </div>
          </div>
          <div className="accordion__item">
            <button className="accordion__btn">
              <span className="accordion__caption">
                What is the difference between a secured and an unsecured loan?
              </span>
              <span className="accordion__icon">
                <i className="fa fa-plus" />
              </span>
            </button>
            <div className="accordion__content">
              <p>
                A secured loan is backed by collateral, such as property or
                other assets, while an unsecured loan does not require any
                collateral. Unsecured loans typically have higher interest rates
                due to the higher risk for the lender.
              </p>
            </div>
          </div>
          <div className="accordion__item">
            <button className="accordion__btn">
              <span className="accordion__caption">
                How can I improve my credit score to qualify for a loan
              </span>
              <span className="accordion__icon">
                <i className="fa fa-plus" />
              </span>
            </button>
            <div className="accordion__content">
              <p>
                To improve your credit score, pay your bills on time, reduce
                existing debt, avoid applying for too much new credit, and
                regularly check your credit report for errors. Maintaining a
                good credit history over time will help improve your score.
              </p>
            </div>
          </div>
          <div className="accordion__item">
            <button className="accordion__btn">
              <span className="accordion__caption">
                What are the processing fees for loans?{" "}
              </span>
              <span className="accordion__icon">
                <i className="fa fa-plus" />
              </span>
            </button>
            <div className="accordion__content">
              <p>
                Processing fees vary by lender and can range from 1% to 3% of
                the loan amount. These fees are usually deducted from the loan
                amount at disbursement.
              </p>
            </div>
          </div>
          <div className="accordion__item">
            <button className="accordion__btn">
              <span className="accordion__caption">
                Can I get a loan if I have a low credit score?{" "}
              </span>
              <span className="accordion__icon">
                <i className="fa fa-plus" />
              </span>
            </button>
            <div className="accordion__content">
              <p>
                While having a low credit score can make it more difficult to
                get a loan, some lenders offer loans to individuals with lower
                scores, often at higher interest rates. Improving your credit
                score or providing collateral may increase your chances of
                approval.
              </p>
            </div>
          </div>
          <div className="accordion__item">
            <button className="accordion__btn">
              <span className="accordion__caption">
                What is the difference between fixed and floating interest
                rates?{" "}
              </span>
              <span className="accordion__icon">
                <i className="fa fa-plus" />
              </span>
            </button>
            <div className="accordion__content">
              <p>
                A fixed interest rate remains constant throughout the loan
                tenure, providing predictable monthly payments. A floating (or
                variable) interest rate can change periodically based on market
                conditions, which may result in varying monthly payments.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Start */}
      <custom-footer />
      {/* Footer End */}
    </div>
  );
}

export default Faq;
