import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import '../../styles/screenStyles/MyAccount.css'
import '../../styles/screenStyles/Profile.css'
import { Person, Shop, Bank, CardHeading, PersonVcard, ArrowRightShort, Gear } from "react-bootstrap-icons";
import { MyContext } from "../../components/GlobalValues";
import Metrixfunction from "../../components/MetrixFunction";


function MyAccount() {
  const navigate = useNavigate()

  const [accountdetails, setaccountdetails] = useState([])
  const [buttonClicked, setButtonClicked] = useState(false);
  const navigateScreenWidth = 500;

  const { windowWidth,metrixserver } = useContext(MyContext);


    const accountContent = [
        {
            name: 'Work Details',
            icon: <Shop className="myaccount-card-icon" />,
            link: "/work"
        },
        {
            name: 'Personal Details',
            icon: <Person className="myaccount-card-icon" />,
            link: "/user"
        },
        {
            name: 'Bank Account',
            icon: <Bank className="myaccount-card-icon" />,
            link: "/bank"
        },
        {
            name: 'PAN Details',
            icon: <CardHeading className="myaccount-card-icon" />,
            link: "/pan"
        },
        {
            name: 'Aadhar Details',
            icon: <PersonVcard className="myaccount-card-icon" />,
            link: "/aadhaar"
        },

  ]


  useEffect(()=>{
    Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");
  },[])

  return (

    <div className="myaccounts-main">

      <div className="myaccounts-main-container">

        <div className="myaccounts-main-content">
          <p>My Account</p>

          <div className="myaccount-content-card-container">
            {accountContent.map((value, ind) => {
              return navigateScreenWidth < windowWidth ? (
                <button
                   key={ind}
                  className="myaccount-content-card"
                  onClick={() => {
                    setButtonClicked(true);
                    let options = { updateBreadcrumbs: false };
                    navigate(value.link, {
                      state: {
                        accountdetails: accountdetails,
                        options: options,
                      },
                    });
                  }}
                  disabled={buttonClicked}
                >
                  <div className="myaccount-card-header">
                    {value.icon}
                    <p>{value.name}</p>
                    <p>
                      You can see the {value.name} details here
                    </p>
                  </div>
                </button>
              ) : (
                <button
                  key={ind}
                  className="profile-content-card"
                  onClick={() => {
                    setButtonClicked(true);
                    let options = { updateBreadcrumbs: false };
                    navigate(value.link, {
                      state: {
                        accountdetails: accountdetails,
                        options: options,
                      },
                    });
                  }}
                  disabled={buttonClicked}
                >
                  <span className="d-flex align-items-center gap-3 ">
                    {value.icon}
                    <p className="profile-card-content-text">{value.name}</p>
                  </span>
                  <ArrowRightShort className="myaccount-card-icon" />
                </button>
              );
            })
            }
          </div>

        </div>

      </div>
    </div >

  )
}

export default MyAccount;
