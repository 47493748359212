import React, { useEffect, useState } from "react";
import "../styles/componentStyles/stepper.css";
import { Check } from "react-feather";

function Stepper({ stepcount, circletype, circleimage,circletext, curretstepper }) {

  const [circles, setcircles] = useState([1, 2]);

  useEffect(() => {
    let arr = [];
    for (let i = 1; i <= stepcount; i++) {
      arr.push(i);
    }
    setcircles(arr);
    document.documentElement.style.setProperty('--stepcount', stepcount);
  }, [stepcount]);

  return (
    <>
    <div className="stepper-container">
      <div
        className={`progress-container ${
         (circletype === "text" || circletype === "both")? "progress-container-text":"progress-container-image"
        }`}
      >
        <div
          // className={`progress ${
          //   circletype === "image" ? "progress-image" : "progress-text"
          // }`}

          className={`progress ${
            (circletype === "text" || circletype === "both")? "progress-text":"progress-image"
           }`}
          style={{
            width: `${((curretstepper - 1) / (circles.length - 1)) * 99}%`,
          }}
        ></div>
        {circles.map((circle, idx) => (
          <div key={idx} className="stepper-circle-outer">
            <div className={` ${curretstepper===idx+1 && "outer-circle-stepper"}`}
            ></div>
            <div
              className={`stepper-circle ${
                idx < curretstepper ? "active" : ""
              }`}
            >
              {circleimage?.map((ele, circleind) => {
                if (idx === circleind) {
                  return idx < curretstepper - 1 ? (
                    <Check
                      style={{ animation: "none", fontWeight: "600" }}
                      size={20}
                      key={circleind}
                    />
                  ) : (circletype === "image" || circletype === "both") ? (
                    <img src={ele}  key={circleind} className={`circle-step-img ${idx < curretstepper && "circle-step-img-active"}`} alt="" />
                  ) : (
                    <span  key={circleind} >{circle}</span>
                  );
                } else {
                  return null;
                }
              })}

            
            </div>
            {(circletype === "text" || circletype === "both") && (
              <div className={`circle-label ${idx < curretstepper && "circle-label-active"}`} >{circletext[idx]}</div>
            )}
          </div>
        ))}
      </div>
      </div>
    </>
  );
}

export default Stepper;
