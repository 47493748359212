import React from 'react'
import '../styles/utlisStyles/SkeletonLoader.css';

function SkeletonLoader({labelName}) {
  return (
    <div style={{marginTop:"50px"}}>
     <label>{labelName}</label>
    <div className="skeletonLoader-loader">
        <div className="skeletonLoader-wrapper">
            <div className="skeletonLoader-circle"></div>
            <div className="skeletonLoader-line-1"></div>
            <div className="skeletonLoader-line-2"></div>
            <div className="skeletonLoader-line-3"></div>
            <div className="skeletonLoader-line-4"></div>
        </div>
    </div>
    </div>
  )
}

export default SkeletonLoader;