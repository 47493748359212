import React, { useContext, useEffect, useState } from "react";
import Stepper from "../../components/Stepper";
import "../../styles/screenStyles/Loanapply.css";
import Aadhardetails from "./Aadhardetails";
import Pandetails from "./Pandetails";
import Previewscreen from "./Previewscreen";
import UserInfo from "./UserInfo";
import WorkInfo from "./WorkInfo";
import { useLocation, useNavigate } from "react-router-dom";
import BankInformation from "../dynamic/BankInformation";
import Loanselect from "./Loanselect";
import Additionalinformation from "./Additionalinformation";
import Alert from "../../components/Alert";
import { MyContext } from "../../components/GlobalValues";
import { validationRegex } from "../../utlis/Regex";
import Loading from "../../components/Loading";
import Metrixfunction from "../../components/MetrixFunction";

// import { MyContext } from "../../components/GlobalValues";

function Loanapply() {
  const {
    Url,
    utilityFile,
    setUtilityFile,
    setpandata,
    pandata,
    aadhardata,
    setaadhardata,
    aadhaarverify,
    setaadhaarverify,
    loandetails,
    bankDetails,
    setBankDetails,
    reBankAccNo,
    setReBankAccNo,
    updateuserinfo,
    updateworkinfo,
    updatebank,
    initialbankDetails,
    setinitialbankdetails,
    userverify,
    userinfo,
    setuserinfo,
    workinfo,
    setpanverify,
    panverify,
    userBankStatementPdf,
    setBankStatementPdf,
    eligibilitydetails,
    setworkinfo,
    setloandetails,
    userEmpIdFile,
    userJoiningLetterPdf,
    userSalarySlipPdf,
    metrixserver
  } = useContext(MyContext);

  // const [accountdetails, setaccountdetails] = useState([]);

  const location=useLocation()


  function removeSlash(port1) {
    let p1 = port1.split("/").join("");

    let np = p1;
    return np;
  }

  const [loadingscreen,setloadingscreen]=useState(false)

  const fieldConfigurations = {
    userName: { label: "User Name", minLength: 3 },
    userEmail: { label: "Email", minLength: 3 },
    userDOB: { label: " DOB", minLength: 3 },
    currentCountry: { label: "Current Country", minLength: 3 },
    currentState: { label: "Current State", minLength: 3 },
    currentCity: { label: "Current City", minLength: 3 },
    currentDistrict: { label: "Current District", minLength: 3 },
    currentPincode: { label: "Current Pincode", minLength: 3 },
    currentHouseNo: { label: "Current House No", minLength: 2 },
    currentStreet: { label: "Current Street", minLength: 3 },
    currentLandmark: { label: "Current Landmark", minLength: 3 },
    permanentCountry: { label: "Permanent Country", minLength: 3 },
    permanentState: { label: "Permanent State", minLength: 3 },
    permanentCity: { label: "Permanent City", minLength: 3 },
    permanentDistrict: { label: "Permanent District", minLength: 3 },
    permanentPincode: { label: "Permanent Pincode", minLength: 3 },
    permanentHouseNo: { label: "Permanent House No", minLength: 2 },
    permanentStreet: { label: "Permanent Street", minLength: 3 },
    permanentLandmark: { label: "Permanent Landmark", minLength: 3 },
    userGender: { label: " Gender", minLength: 3 },
    userFatherName: { label: " Father Name", minLength: 3 },
    userMotherName: { label: " Mother Name", minLength: 3 },
    userMaritalStatus: { label: " Marital Status", minLength: 3 },
    userCommunity: { label: " Community", minLength: 3 },
    userNationality: { label: " Nationality", minLength: 3 },
    userBloodGroup: { label: " Blood Group", minLength: 3 },
    userEducation: { label: " Education", minLength: 2 },
    userResidentialOwnerShip: { label: " Residential Ownership", minLength: 3 },
    userLatitude: { label: " Latitude", minLength: 3 },
    userLongitude: { label: " Longitude", minLength: 3 },
    utilityBillType: { label: "Utility Bill Type", minLength: 3 },
    utilityBillImgUrl: { label: "Utility Bill Image", minLength: 3 },
    userGST: { label: "Utility Bill Image", minLength: 3 },
  };

  const handleUserInfoall = () => {

    const keys = Object.keys(workinfo);

    let errors= {
      companyEmail: "",
      companySalary: "",
      companyJoinDate: "",
      companyEmployeeId: "",
      salarySlip: [],
      companyName: "",
      companyType: "",
      companyDesignation: "",
      companyCity: "",
      companyDistrict: "",
      companyState: "",
      companyCountry: "",
      companyPincode: "",
      companyStreet: "",
      companyLandmark: "",
      companyTotalExp: "",
      companyIdPhoto: "",
      companyJoiningLetter: "",
    }

    const companyNameRegex = /^[a-zA-Z0-9,.'-][a-zA-Z0-9\s,.'-]{1,}[a-zA-Z0-9,.'-]( {0,3})?$/; // At least 3 characters, no leading/trailing spaces beyond 3
    const joiningDateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(\d{4})$/; // MM/DD/YYYY format
    const salaryRegex = /^[0-9]*$/; // Only numeric digits
    const empIdRegex = /^[a-zA-Z0-9]{5,}$/; 
    const experienceRegex = /^\d{1,2}$/;

    for (let index = 0; index < keys?.length; index++) {
      let key = keys[index];

      switch (key) {
        case "companyName":
          if (!companyNameRegex.test(workinfo[key])) {
            errors[key] = "Invalid Company Name";
          }
          break;
        case "companyJoinDate":
          if (!joiningDateRegex.test(workinfo[key])) {
            errors[key] = "Invalid Joining Date";
          }
          break;
        case "companySalary":
          if (!salaryRegex.test(workinfo[key])) {
            errors[key] = "Invalid Salary";
          }
          
          break;
        case "companyEmployeeId":
          if (!empIdRegex.test(workinfo[key])) {
            errors[key] = "Invalid Employee Id";
          }
          break;
        case "companyTotalExp":
          if (!experienceRegex.test(workinfo[key])) {
            errors[key] = "Invalid Experience in years";
          }
          break;
        default:
          break;
      }

    }
    let isValid = true;


   
      
    if (workinfo.companyName.length == 0 || errors.companyName) {
      alertmessage("Please enter the Valid company name", "danger");
      return isValid = false;
    }
    if (workinfo.companyTotalExp == "" || errors.companyTotalExp) {
      alertmessage("Please enter the valid total experience", "danger");
      return isValid = false;
    }
    if (workinfo.companyType.length == 0 || errors.companyType) {
      alertmessage("Please enter the company type", "danger");
      return isValid = false;
    }
    if (workinfo.companyEmail.length == 0 || errors.companyEmail) {
      alertmessage("Please enter the company email", "danger");
      return isValid = false;
    }
    if (workinfo.companySalary.length == 0 || errors.companySalary) {
      alertmessage("Please enter the Monthly   salary", "danger");
      return isValid = false;
    }
    if (workinfo.companyJoinDate.length == 0 || errors.companyJoinDate) {
      alertmessage("Please enter the company join date", "danger");
      return isValid = false;
    }
    if (workinfo.companyEmployeeId.length == 0 || errors.companyEmployeeId) {
      alertmessage("Please enter the company employee ID", "danger");
      return isValid = false;
    }
   
    if (workinfo.companyCity.length == 0 || errors.companyCity) {
      alertmessage("Please enter the company address", "danger");
      return isValid = false;
    }
    if (workinfo.companyDistrict.length == 0 || errors.companyDistrict) {
      alertmessage("Please enter the company address", "danger");
      return isValid = false;
    }
    if (workinfo.companyState.length == 0 || errors.companyState) {
      alertmessage("Please enter the company address", "danger");
      return isValid = false;
    }
    if (workinfo.companyCountry.length == 0 || errors.companyCountry) {
      alertmessage("Please enter the company address", "danger");
      return isValid = false;
    }
    if (workinfo.companyPincode.length == 0 || errors.companyPincode) {
      alertmessage("Please enter the company address", "danger");
      return isValid = false;
    }
    if (workinfo.companyLandmark.length == 0 || errors.companyLandmark) {
      alertmessage("Please enter the company address", "danger");
      return isValid = false;
    }
    if (workinfo.companyStreet.length == 0 || errors.companyStreet) {
      alertmessage("Please enter the company address", "danger");
      return isValid = false;
    }

    if(userEmpIdFile.length == 0){
      alertmessage("Please upload your employee id", "danger");
      return isValid = false;
    }

    if(userJoiningLetterPdf.length == 0){
      alertmessage("Please upload your joining letter", "danger");
      return isValid = false;
    }

    if(userSalarySlipPdf.length  < 3){
      alertmessage("Please upload your salary slip", "danger");
      return isValid = false;
    }
    
    // Proceed with further logic
    return isValid;
  };

  // h

  const [currentActive, setCurrentActive] = useState(1);

  const [loanbtn,setloanbtn]=useState(false)

 
  const [validationErrors, setValidationErrors] = useState({
    userName: "",
    userEmail: "",
    userDOB: "",
    currentCountry: "",
    currentState: "",
    currentCity: "",
    currentDistrict: "",
    currentPincode: "",
    currentHouseNo: "",
    currentStreet: "",
    currentLandmark: "",
    permanentCountry: "",
    permanentState: "",
    permanentCity: "",
    permanentDistrict: "",
    permanentPincode: "",
    permanentHouseNo: "",
    permanentStreet: "",
    permanentLandmark: "",
    userGender: "",
    userFatherName: "",
    userMotherName: "",
    userMaritalStatus: "",
    userCommunity: "",
    userNationality: "",
    userBloodGroup: "",
    userEducation: "",
    userResidentialOwnerShip: "",
    userLatitude: "",
    userLongitude: "",
    utilityBillType: "",
    utilityBillImgUrl: "",
  });

  const [showalert, setshowalert] = useState({
    show: false,
    msg: "",
    color: "",
  });

  const alertmessage = (msg, color) => {
    setshowalert({ ...showalert, show: true, msg: msg, type: color });

    setTimeout(() => {
      setshowalert({ ...showalert, show: false, msg: "", type: "" });
    }, 3000);
  };

  let payload = {
    accId: localStorage.getItem("accId"),
    apiKey: localStorage.getItem("apiKey"),
    loanAmount: parseInt(loandetails.loanAmount),
    loanDuration: parseInt(loandetails.loanDuration),
    altContact1Phone: loandetails.altcontact1,
    altContact2Phone: loandetails.altcontact2,
    altContact1Name: loandetails.altname1,
    altContact2Name: loandetails.altname2,
    altContact1Relation: loandetails.altrelation1,
    altContact2Relation: loandetails.altrelation2,


// loanInterestRatePct: loandetails.loanInterestRatePct,
    // signImageURL: "https://picsum.photos/200/300",
    // processingCharges: loandetails.processingCharges.toString(),
        // dailyEMI: parseInt(loandetails.monthlyEMI),

  };

  const navigate = useNavigate();

  const createloan = async () => {
 

    await fetch(`${Url}/business/createLoan`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
         if (data.Success) {
          alertmessage(`${data.Success}`, "success");
          navigate("/dashboard");
        } else {
          alertmessage(`${data.Error}`, "danger");
        }
        setloanbtn(false)
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(()=>{
    Metrixfunction(metrixserver, "createLoan", "UPDATE");

      })
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 0);
  };

  // const images=[call,download,employee,job,signzy]

  const images = [
    "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/user-icon.png",
    "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/work-info.png",
    "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/businessman.png",
    "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/job-search.png",
    "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/wallet.png",
    "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/loan.png",
    "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/preview-info.png",
  ];

  const text = [
    "Loan Details",
    "KYC",
    "User Information",
    "Work Information",
    "Bank Information",
    "Apply Loan",
  ];

  // const text=[]

  const stepcount = 6;

 
 
  const handleAllInputsValid = (ObjSchema) => {
 
    let valid = true;
    let responseObj = {
      isValid: true,
      errorField: "",
      errorKeyvalue:"",
    };
    let errorMessage="";
    let errorKey="";


    const keys = Object.keys(ObjSchema);

    
    for (let index = 0; index < keys?.length; index++) {
      let key = keys[index];
      if (
        key !== "loanStatus" &&
        key !== "userProfilePic" &&
        key !== "userAge" &&
        key !== "userGender" &&
        key !== "passcode" &&
        key !== "userLatitude" &&
        key !== "userLongitude"
      ) {
       
        let value = "";
        const regex = validationRegex[key];

         

        
        
      
          value =userinfo[key];
        
       

        const minCriteria = fieldConfigurations[key];


        
        if (value === "" || (regex && !regex.test(value))) {
           if (
            key === "userName" ||
            key === "userEducation" ||
            key === "userFatherName" ||
            key === "userMotherName" ||
            key === "userCommunity" ||
            key === "userNationality"
          ) {
            valid = false;
            errorMessage =
              value?.length >= 2
                ? `Invalid ${minCriteria.label}.`
                : `Please enter valid ${minCriteria.label}`;
                errorKey= key;
                break;
          }
    
          if (key === "userEmail") {
            valid = false;
            errorMessage =
              value !== ""
                ? `Invalid ${minCriteria.label}.`
                : `Please enter valid ${minCriteria.label}`;
                errorKey= key;
                break;
          }
          if (key === "userGST") {
            if(userinfo[key].length!==0)
              {

                valid = false;
                errorMessage =
                  value !== ""
                  ? `Invalid ${minCriteria.label}.`
                  : `Please enter valid ${minCriteria.label}`;
                    break;
              }
          }
    
          if (
            key === "userMaritalStatus" ||
            key === "userBloodGroup" ||
            key === "userDOB" ||
            key === "utilityBillType" ||
            key === "userResidentialOwnerShip"
          ) {
            
            valid = false;
            errorMessage = `Please select ${minCriteria.label}`;
            errorKey= key;
            break;
          }
    
          if (
            key === "currentHouseNo" ||
            key === "currentStreet" ||
            // key === "currentArea"||
            key === "currentCity" ||
            key === "currentDistrict" ||
            key === "currentState" ||
            // key === "currentCountry" ||
            key === "currentPincode" ||
            key === "currentLandmark"
          ) {
            valid = false;
            let length;
            length = key === "currentPincode" ? 6 : 2;
            errorMessage =
              value?.length >= length
              ? `Invalid ${minCriteria.label}.`
              : `Please enter valid ${minCriteria.label}`;
                errorKey= key;
                break;
          }
    
          if (
            key === "permanentHouseNo" ||
            key === "permanentStreet" ||
            // key === "permanentArea" ||
            key === "permanentCity" ||
            key === "permanentDistrict" ||
            key === "permanentState" ||
            // key === "permanentCountry" ||
            key === "permanentPincode" ||
            key === "permanentLandmark"
          ) {
            valid = false;
            let length;
            length = key === "currentPincode" ? 6 : 2;
            errorMessage =
              value?.length >= length
              ? `Invalid ${minCriteria.label}.`
              : `Please enter valid ${minCriteria.label}`;
                errorKey= key;
                break;
          }
    
          if (userinfo["utilityBillType"] != "" && key === "userUtilityBill") {
            valid = false;
            errorMessage = `Please upload ${minCriteria.label}`;
            errorKey= key;
            break;
          }
        }
       

        if (key === "userResidentialOwnerShip" && userinfo[key] === "No") {
          valid = false;
          errorMessage = `Please upload ${minCriteria.label}`;
          errorKey= key;
          break;
        }

        if(key==="utilityBillImgUrl" && (utilityFile.length<1 && userinfo[key].length<1))
          {
            valid = false;
          errorMessage = `Please upload ${minCriteria.label}`;
          errorKey= key;
          break;
          }
      }
    }
     


    if (!valid) {
      responseObj.isValid = valid;
      responseObj.errorField =errorMessage;
      responseObj.errorKeyvalue = errorKey;

    }

    return responseObj;
  };


  const userNameregex = validationRegex["userName"];

  const handleNext = async () => {
    let nextcond = false;

    if (currentActive === 1) {
      nextcond = true;
      
    } else if (currentActive === 2) {
      if (!aadhaarverify) {
        alertmessage("Please verify Aadhar before proceed", "danger");
      } 
      else if (!panverify) {
        alertmessage("Please verify Pan before proceed", "danger");
      }  else if (loandetails.altrelation1.length < 1) {
        alertmessage("Select the valid Family Member Relation", "danger");
      } else if (loandetails.altrelation2.length < 1) {
        alertmessage("Select the valid Others Relation", "danger");
      }
      else if (loandetails.altcontact1.length < 10) {
        alertmessage(`Enter the valid phone number of ${loandetails.altrelation1}`, "danger");
      } else if (loandetails.altcontact2.length < 10) {
        alertmessage(`Enter the valid phone number of ${loandetails.altrelation2}`, "danger");
      }
      else if (loandetails.altname1.length < 3 || !userNameregex.test(loandetails.altname1)) {
       alertmessage(`Enter the valid name of ${loandetails.altrelation1}`, "danger");

     } else if (loandetails.altname2.length < 3  || !userNameregex.test(loandetails.altname2)) {
      alertmessage(`Enter the valid name of ${loandetails.altrelation2}`, "danger");
    }
     else if(loandetails.altcontact1===loandetails.altcontact2)
      {
         alertmessage("Family member contact and others contact will not be same","danger")
      }
     else if(loandetails.altcontact1===localStorage.getItem("mobileNo"))
      {
         alertmessage("Family member contact will not be same as yours","danger")
      }
     else if(loandetails.altcontact2===localStorage.getItem("mobileNo"))
      {
         alertmessage("others contact will not be same as yours","danger")
      }
     else if (loandetails.altrelation1.length < 1) {
      alertmessage("Select the valid Family Member Relation", "danger");
    } else if (loandetails.altname2.length < 3) {
      alertmessage("Select the valid Others contact Relation", "danger");
    }
     else {
      // getaccountdetails()
        nextcond = true;
      }
    } else if (currentActive === 3) {
      try {

        const resvalid = handleAllInputsValid(userinfo);

        if ((resvalid && resvalid.isValid)) {
          if (userverify) {
            setloanbtn(true)
            const data = await updateuserinfo();
            

            if (data.Success) {
              nextcond = true;

            } else if (data.Warning) {
              
              nextcond = true;
            } else if (data.Error) {
              alertmessage(data.Error, "danger");
            }
            if(data)
              {
                setloanbtn(false)
              }
          } else {
            alertmessage("Please Verify Email before proceed", "danger");
          }
        } else {
          
        alertmessage(resvalid.errorField, "danger")
        }
      } catch (err) {
        console.log(err, "error");
      }
    } else if (currentActive === 4) {
      let valid = handleUserInfoall();
      if(valid)
        {
      try {
        const data = await updateworkinfo();
        
        if (data.Success) {
          nextcond = true;
        } else if (data.Warning) {
          nextcond = true;
        } else if (data.Error) {
          alertmessage(data.Error, "danger");
        }
      } catch (err) {
        console.log(err, "error");
      }
    }

    } else if (currentActive === 5) {
      try {
        if(bankDetails.bankAcctName.length<3)
          {
            alertmessage("Bank account holder name must be atleast 3 characters long","danger")
          }
          else if(bankDetails.bankAcctNum.length<9)
             {
               alertmessage("Bank account number  must be atleast 9 digits","danger")
             }
          else if(bankDetails.bankAcctNum !== reBankAccNo)
             {
               alertmessage("Bank account number  and Re-Bank Account Number must be same","danger")
             }
          else if(bankDetails.bankIfsc.length<11)
          {
            alertmessage("Bank IFSC number must be atleast 11 digits","danger")
          }
          else if(bankDetails.bankName.length<1)
          {
            alertmessage("Bank name field must not be empty","danger")
          }
          else if((userBankStatementPdf.length<1) && bankDetails.bankStatement.length<1)
          {
            alertmessage("Please upload your 3 months bank statement","danger")
          }
          else{
            const data = await updatebank();
             
            if (data.Success) {
              nextcond = true;
            } else if (data.Warning) {
              nextcond = true;
            } else if (data.Error) {
              alertmessage(data.Error, "danger");
            }
          }
      } catch (err) {
        console.log(err, "error");
      }
    }

    if (nextcond) {
      // if(currentActive+1===2)
      //   {
      //     getaccountdetails(2);
        
      //   }
      // if(currentActive+1===5)
      //   {
      //     getaccountdetails(5);
        
      //   }
      setCurrentActive((prevActive) =>
        prevActive < stepcount ? prevActive + 1 : prevActive
      );

      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 0);
    }
  };

 

  const handlePrev = () => {
    setCurrentActive((prevActive) =>
      prevActive > 1 ? prevActive - 1 : prevActive
    );

 
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 0);
  };


  const getaccountdetails = async (step) => {
    let query = {
      accId: localStorage.getItem("accId"),
      apiKey: localStorage.getItem("apiKey"),
      type: "kyc",
    };

     

    try {
      // setloadingscreen(true)

      await fetch(`${Url}/business/getDetails`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(query),
      })
        .then((res) => res.json())
        .then((resJson) => {
          
          if (resJson.Success) {
            if(step===2)
              {
            setpandata({ panno: resJson.Success.panNum,panName:resJson.Success.panName,panImg:resJson.Success.panImgUrl });
            resJson.Success.panName.length>0?setpanverify(true):setpanverify(false)
            setaadhardata({
              ...aadhardata,
              aadharNo: resJson.Success.aadhaarNum,
              name: resJson.Success.aadhaarName,
              dob: resJson.Success.aadhaarDOB,
              address: resJson.Success.aadhaarAddress,
            });
            if (resJson.Success.aadhaarNum.length > 0) {
              setaadhaarverify(true);
            }
          }
          if(step===5)
            {
              setBankDetails({
                ...bankDetails,
                bankAcctName: resJson.Success.bankAcctName,
                bankAcctNum: resJson.Success.bankAcctNum,
                bankName: resJson.Success.bankName,
                bankIfsc: resJson.Success.bankIfsc,
                bankStatement:resJson.Success.bankStatement
              });
              setinitialbankdetails({
                ...initialbankDetails,
                bankAcctName: resJson.Success.bankAcctName,
                bankAcctNum: resJson.Success.bankAcctNum,
                bankName: resJson.Success.bankName,
                bankIfsc: resJson.Success.bankIfsc,
                bankStatement:resJson.Success.bankStatement
  
              });
              if(resJson.Success.bankStatement.length>0)
                {
                  setBankStatementPdf([{url:resJson.Success.bankStatement}]);
                }
              setReBankAccNo(resJson.Success.bankAcctNum);
            }
           
          }
          setloadingscreen(false)

        })
        .finally(()=>{
      Metrixfunction(metrixserver, "getDetails", "ONLOAD");

        })
    } catch (error) {
      console.log("Error :", error);
      setloadingscreen(false)

      // setMobileNoError('Invalid Mobile No');
    }
  };



  const preventClose = (e) => {
    e.preventDefault();
    e.returnValue = ''; 
  };

  useEffect(() => {
    window.addEventListener('beforeunload', preventClose);

    return () => {
      window.removeEventListener('beforeunload', preventClose);

    };
  }, []);

  useEffect(()=>{
      return ()=>{
        setuserinfo({
          loanStatus: "",
          userName: "",
          userEmail: "",
          userDOB: "",
          currentCountry: "",
          currentState: "",
          currentCity: "",
          currentDistrict: "",
          currentPincode: "",
          currentHouseNo: "",
          currentStreet: "",
          currentLandmark: "",
          permanentCountry: "",
          permanentState: "",
          permanentCity: "",
          permanentDistrict: "",
          permanentPincode: "",
          permanentHouseNo: "",
          permanentStreet: "",
          permanentLandmark: "",
          userProfilePic: "",
          userAge: 0,
          userGender: "",
          userFatherName: "",
          userMotherName: "",
          userMaritalStatus: "",
          userCommunity: "",
          userNationality: "",
          userBloodGroup: "",
          userEducation: "",
          userResidentialOwnerShip: "owned",
          passcode: "SG5kQjZEVVV1ajBRN29jQytqZldIUT09",
          utilityBillImgUrl:"",
          utilityBillType:""
      
        })
  
        setpandata({
          panno:"",
          panImg:"",
          panName:""
        })
  
        setaadhardata({
          aadharNo: "",
          name: "",
          dob: "",
          address: "",
        })
  
        setBankDetails({
          bankAcctName: "",
          bankAcctNum: "",
          bankName: "",
          bankIfsc: "",
          bankStatement :"",
        })
  
        setloandetails({
          loanAmount: 0,
          monthlyEMI: 0,
          loanDuration: 0,
          loanInterestRatePct: 0,
          processingCharges: 0,
          altcontact1: "",
          altcontact2: "",
          altname1: "",
          altname2: "",
          signimgurl: "",
          chequeimgurl: "",
          altrelation1:"",
          altrelation2:"",
        })
      }
  },[])
  return (
    loadingscreen? <Loading/> :
    (<div className="check-eligibility-container">
      <div className="stepper-outer-wrapper">
        <div className="loanstepper-inner-wrapper">
          <Stepper
            stepcount={stepcount}
            circletype={"both"} // give a value either image or text
            circleimage={images} // if you give a value image
            circletext={text} // if you give a value image
            curretstepper={currentActive}
            // // text={text} // if you give a value text
          />
        </div>
      </div>

      <div
        onSubmit={(event) => {
           
          event.preventDefault();
          window.removeEventListener("beforeunload", preventClose);
        }}
      >
        <div className="eligibility-form-wrapper">
          {currentActive === 1 && (
            <Loanselect
              componentdata={loandetails}
              componenttype={"loanapply"}
            />
          )}
          {currentActive === 2 && (
            <>
              <Aadhardetails component={true} componenttype={"loanapply"} />
              <Pandetails component={true} componenttype={"loanapply"} />
              <Additionalinformation />
            </>
          )}
          {currentActive === 3 && (
            <UserInfo component={true} componenttype={"loanapply"} />
          )}
          {currentActive === 4 && (
            <WorkInfo component={true} componenttype={"loanapply"} />
          )}
          {currentActive === 5 && (
            <BankInformation component={true} componenttype={"loanapply"} />
          )}
          {currentActive === 6 && (
            <Previewscreen component={true} componenttype={"loanapply"} />
          )}
        </div>

        <div className="stepper-button-outerwrapper">
          <div className="stepper-button-innerwrapper">
            {currentActive > 1 && (
              <button
                className="loan-next-wrapper"
                onClick={(event) => {
                  event.preventDefault();
                  window.removeEventListener("beforeunload", preventClose);
                  handlePrev();
                }}
              >
                Back
              </button>
            )}
            {currentActive === stepcount ? (
              <button
                className="loan-next-wrapper"
                onClick={(event) => {
                  event.preventDefault();
                  window.removeEventListener("beforeunload", preventClose);
                  if(eligibilitydetails.termagree)
                    {
                      setloanbtn(true)
                      createloan();
                    }
                    else{
                      alertmessage("please Accept the terms and condition before proceed","danger")
                    }
                }}
                disabled={loanbtn}
              >
                Finish
              </button>
            ) : (
              <button
                className="loan-next-wrapper"
                onClick={(event) => {
                  event.preventDefault();
                  window.removeEventListener("beforeunload", preventClose);
                  handleNext();
                }}
                disabled={loanbtn}
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
      {showalert.show && (
        <Alert
          show={true}
          message={showalert.msg}
          type={showalert.type}
          duration={3000}
          onClose={() => {
            setshowalert({ show: false, message: "", type: "" });
          }}
        />
      )}
    </div>
  ));
}

export default Loanapply;
 