import '../../styles/screenStyles/DeactivateAccount.css';
import Alert from "../../components/Alert";
import React, { useContext, useEffect, useRef, useState } from 'react'
import { MyContext } from '../../components/GlobalValues';
import Metrixfunction from '../../components/MetrixFunction';

function DeactivateAccount() {

    const {  metrixserver} = useContext(MyContext);

    const [alert, setAlert] = useState({
        show: false,
        message: '',
        type: '',
        duration: 3000, // Default duration
    });

    const showAlert = (message, type, duration = 3000) => {
        setAlert({
            show: true,
            message,
            type,
            duration,
        });

        // Automatically close the alert after the duration
        setTimeout(() => {
            setAlert((prevAlert) => ({
                ...prevAlert,
                show: false,
            }));
        }, duration);
    };

    const deleteAccount = () =>{
        if(true){
            showAlert("You have a oustanding loan...", "danger");
        }else{
            showAlert("Account Deactivated", "success");
        }
    }

    useEffect(()=>{
        Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");
      },[])
    return (
        <div className="grievance-main">
            <div className="grievance-main-container">
                <p className="grievance-heading">Grievance Redressal</p>
                <div className="grievance-content">
                    <p className="grievance-body-text">Our commitment is to provide seamless services to our customers, ensuring their satisfaction and addressing any concerns they may have. This Grievance Redressal Policy aims to provide a structured mechanism for customers to register their grievances regarding personal loans and ensure their timely resolution.</p>

                    <p className="grievance-body-title">1. Definition of Grievance:</p>
                    <p className="grievance-body-text">A grievance is defined as any dissatisfaction expressed by a customer regarding our products, services, or the manner in which they are delivered. Grievances can include but are not limited to delays in loan processing, incorrect information provided, unsatisfactory customer service, billing disputes, or any other concern related to the personal loan process.</p>

                    <p className="grievance-body-title">2. Channels for Grievance Redressal:</p>
                    <p className="grievance-body-text mb-2">Customers can register their grievances through the following channels:</p>
                    <ul className="grievance-body-text">
                        <li><b>Customer Care:</b> Customers can contact our dedicated customer care team via phone, email, or online chat.</li>
                        <li><b>Branch Visit:</b> Customers can visit any of our branches and meet with our staff to discuss their grievances in person.</li>
                        <li><b>Online Portal:</b> Customers can log in to our online portal and submit their grievances through the designated grievance redressal section.</li>
                    </ul>

                    <p className="grievance-body-title">3. Grievance Registration:</p>
                    <p className="grievance-body-text">Upon receiving a grievance, we will promptly acknowledge its receipt through the respective channel chosen by the customer. The acknowledgment will include a unique reference number for future correspondence.</p>

                    <p className="grievance-body-title">4. Grievance Resolution Timeframe:</p>
                    <p className="grievance-body-text mb-2 ">We are committed to resolving grievances in a timely manner. The resolution timeframe will depend on the nature and complexity of the grievance but will generally adhere to the following guidelines:</p>
                    <ul className="grievance-body-text">
                        <li>Simple grievances: Within 7 working days</li>
                        <li>Complex grievances: Within 30 working days</li>
                    </ul>

                    <p className="grievance-body-title">5. Escalation Matrix:</p>
                    <p className="grievance-body-text mb-2 ">If a customer is not satisfied with the resolution provided at the first level, they can escalate the grievance to the next level of authority. The escalation matrix is as follows:</p>
                    <ul className="grievance-body-text">
                        <li>Level 1: Customer Service Representative</li>
                        <li>Level 2: Branch Manager</li>
                        <li>Level 3: Grievance Redressal Officer</li>
                        <li>Level 4: Appellate Authority (Designated Officer at Head Office)</li>
                    </ul>

                    <p className="grievance-body-title">6. Communication of Resolution:</p>
                    <p className="grievance-body-text">Once the grievance is resolved, we will communicate the resolution to the customer through the same channel used for registration. The communication will include details of the resolution and any further steps, if applicable.</p>

                    <p className="grievance-body-title">7. Review and Feedback:</p>
                    <p className="grivance-body-text">We value feedback from our customers regarding their grievance redressal experience. Customers will be provided with an opportunity to provide feedback on the resolution process, which will be used to improve our services.</p>

                    <p className="grievance-body-title">8. Regulatory Compliance:</p>
                    <p className="grievance-body-text">This Grievance Redressal Policy complies with all regulatory requirements governing personal loans and grievance redressal mechanisms.</p>

                    <div className="d-flex align-items-center gap-2 ">
                        <p className="grievance-body-title d-flex ">To Delete Your Account</p>
                        <p className="grievance-body-delete" onClick={deleteAccount}>Click Here</p>
                    </div>

                </div>
            </div>
            {alert.show && (<Alert message={alert.message} type={alert.type} show={alert.show} duration={alert.duration} onClose={() => setAlert({ ...alert, show: false })}/>)}
            
        </div>
    )
}

export default DeactivateAccount;